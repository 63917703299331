'use strict';
/* globals RegistrationCtrl, AdminCtrl, ArchiveCtrl, AuditCtrl, DashboardCtrl, OrgsCtrl, OrgDetailCtrl, UserBatchCtrl, NetworksCtrl, NetworkDetailCtrl, ADTCtrl, UsersCtrl */
/* globals UserDetailCtrl, DepartmentsCtrl, GroupsCtrl, PatientsCtrl, ArchiveDetailCtrl, ReportsCtrl, EscalationsCtrl, SecurityCtrl, MassMessagingCtrl */
/* globals DefaultsCtrl, OrgDatabaseCtrl, BroadcastCtrl, AdminChangePWCtrl, SearchabilityCtrl, TicketingPoolsCtrl, TicketingPoolsDetailCtrl */

AuditService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
AdobeProvisioningService.$inject = ['$q', '$resource', 'TokenService', 'BacklineAPI', 'SettingKeys', 'SettingService'];
BroadcastService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAPI'];
ChatService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
ConfirmationService.$inject = ['$rootScope'];
DashboardService.$inject = ['$rootScope', '$q', '$filter', '$resource', 'TokenService', 'ErrorService', 'BacklineAdminAPI', 'Time'];
DebugService.$inject = ['$log', 'ServerEnv', 'DebugTypes', 'SanitizedFields', 'BacklineAPI'];
DepartmentService.$inject = ['$log', '$q', '$resource', 'TokenService', 'ErrorService', 'BacklineAdminAPI'];
ErrorService.$inject = ['$rootScope', 'DebugService', 'DefaultErrors'];
GroupService.$inject = ['$log', '$q', '$resource', 'TokenService', 'ErrorService', 'BacklineAdminAPI'];
LdapService.$inject = ['BacklineAdminAPI', 'TokenService', '$http'];
LocalStorageService.$inject = ['$log', '$cookies'];
MedHxService.$inject = ['BacklineAdminAPI', 'TokenService', '$http'];
NetworkSelectorService.$inject = ['$rootScope', 'TokenService', 'PermissionsService', 'LocalStorageService'];
NetworkService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
OrgSelectorService.$inject = ['$rootScope', 'TokenService', 'PermissionsService', 'LocalStorageService'];
OrgService.$inject = ['$rootScope', '$log', '$q', '$resource', 'TokenService', 'ErrorService', 'BacklineAdminAPI', 'BacklineAPI'];
PatientService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
PermissionsService.$inject = ['$rootScope', '$log', 'LocalStorageService', 'AdminRoles'];
QueueService.$inject = ['$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
ReportService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI'];
EscalationService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPI', 'BacklineAPI'];
ToastService.$inject = ['$rootScope', 'ErrorService'];
TokenService.$inject = ['$rootScope', '$log', '$q', '$http', '$state', '$resource', '$window', 'LocalStorageService', 'PermissionsService', 'ErrorService', 'MultiOrgService', 'AuthStatus', 'BacklineURL', 'BacklineAdminAPI', 'BacklineAPI'];
SessionService.$inject = ['$log', '$q', '$resource', 'ErrorService', 'BacklineAPI', 'BacklineAdminAPI', 'MultiOrgService'];
SettingService.$inject = ['$q', '$resource', 'TokenService', 'BacklineAPI'];
SupportService.$inject = ['$resource', 'TokenService', 'BacklineAdminAPI', 'BacklineSupportAPI', '$q', 'AppConsts'];
TwilioServiceFn.$inject = ['$rootScope', '$log', '$q', '$resource', 'TokenService', 'OrgSelectorService', 'ErrorService', 'BacklineAdminAPI', 'BacklineAPI'];
UserService.$inject = ['$log', '$q', '$resource', 'TokenService', 'ErrorService', 'MultiOrgService', 'BacklineAdminAPI', 'BacklineAPI', 'SettingService', 'SettingKeys'];
MassMessagingService.$inject = ['$log', '$q', '$resource', 'TokenService', 'BacklineAdminAPIV2', '$http'];
AdminChangePWCtrl.$inject = ['$log', '$stateParams', '$timeout', 'SessionService', 'Time', 'WebplusUrl', 'questions'];
AdminCtrl.$inject = ['$rootScope', 'TokenService', 'PermissionsService', 'ConfirmationService', 'ActivityService', 'AdminVersion', 'JSCSPassed', 'JSHintPassed', 'ServerEnv', 'currentUser', 'adtEnabled', 'insightsEnabled', '$state', 'SupportService', 'massMessagingEnabled'];
ADTCtrl.$inject = ['$q', '$timeout', 'SettingService', 'PermissionsService', 'ToastService', 'OrgSelectorService', 'SettingKeys', 'events', 'positions', 'pccs'];
AgentsCtrl.$inject = ['currentUser', '$q', '$uibModal', 'SupportService', 'OrgService', 'OrgSelectorService'];
ArchiveCtrl.$inject = ['$state', 'ChatService', 'OrgSelectorService', 'ArchiveTypes', 'FeedTypes', 'OrgService', 'currentUser', '$rootScope', '$scope'];
ArchiveDetailCtrl.$inject = ['$log', '$q', 'ChatService', 'FeedTypes', 'FeedInfo', 'chatInfo'];
AuditCtrl.$inject = ['$filter', 'PermissionsService', 'OrgSelectorService', 'AuditTypes', 'Time', 'currentUser'];
BroadcastCtrl.$inject = ['$rootScope', 'BroadcastService', 'OrgSelectorService', 'ToastService', 'bots'];
ChangePWCtrl.$inject = ['$log', '$stateParams', '$timeout', 'SessionService', 'WebplusUrl'];
DashboardCtrl.$inject = ['$timeout', 'TokenService', 'ToastService', 'OrgService', 'DashboardService', 'Time', 'org', 'charts'];
DebugCtrl.$inject = ['DebugService', 'DebugTypes'];
DefaultsCtrl.$inject = ['$q', 'OrgService', 'MedHxService', 'SettingService', 'ErrorService', 'ToastService', 'SettingKeys', 'ChatWithPatientOptions', 'WeblinkOptions', 'MedlistOptions', 'model', 'ippEnabled', 'idmEnabled', 'idmSystemEnabled', 'chatwithpatientExpiration', 'timeZone', 'weblinkExpiration', 'medlistExpiration', 'emsWorkflowEnabled', 'medHx2Username', 'medHx2Password', 'TimeZones'];
DepartmentsCtrl.$inject = ['ConfirmationService', 'DepartmentService', 'GroupService', 'OrgSelectorService', 'OrgService', 'SettingKeys', 'SettingService', 'departmentList', 'currentUser', 'org', 'idmEnabled', 'idmSystemEnabled'];
EHRCtrl.$inject = ['$log', '$sce', 'TokenService', 'SettingsUrl', 'OrgSelectorService', 'currentUser'];
GroupsCtrl.$inject = ['GroupService', 'OrgSelectorService', 'groupList', 'currentUser', 'org', 'OrgService'];
LoginCtrl.$inject = ['$state', 'TokenService', 'WebplusUrl', 'AdminVersion'];
LoginOtpCtrl.$inject = ['$state', '$stateParams', 'TokenService', 'AdminVersion'];
NetworkDetailCtrl.$inject = ['$q', '$filter', '$state', 'NetworkService', 'ErrorService', 'ConfirmationService', 'OrgSelectorService', 'model'];
NetworksCtrl.$inject = ['$q', 'NetworkService', 'networkList'];
UserBatchCtrl.$inject = ['$scope', '$log', '$interval', '$filter', '$stateParams', 'OrgSelectorService', 'OrgService', 'UserService', 'ErrorService', 'FileUploader', 'SettingService', 'SettingKeys', 'currentUser', 'batchPastUpload'];
OrgDatabaseCtrl.$inject = ['OrgService', 'model'];
OrgDetailCtrl.$inject = ['$q', '$state', 'OrgService', 'UserService', 'OrgSelectorService', 'model', 'users', 'orgHieEnabled', 'ippEnabled', 'assignmentsEnabled', 'ImplList', 'externalPatientSearch', 'ExternalPatientSearchOptions', 'insightsEnabled', 'emsWorkflowEnabled', 'emsWebWorkflowEnabled', 'esignEnabled', 'twilioVideoChatEnabled', 'twilioExamRoomsEnabled', 'webMsgProtocol', 'pointClickCare', 'populusMedia', 'schedules', 'SettingService', 'SettingKeys', 'LdapService', 'massMessaging', 'crossNetworkFeatureEnabled', 'currentUser'];
OrgsCtrl.$inject = ['$log', '$timeout', '$state', 'TokenService', 'OrgService', 'PermissionsService', 'AppConsts', 'orgList'];
PatientsCtrl.$inject = ['$q', 'ErrorService', 'PatientService', 'OrgSelectorService', 'patientList', 'currentUser', 'org', 'hieEnabled', 'hieOids'];
QueueManagersCtrl.$inject = ['currentUser', '$q', '$uibModal', 'SupportService', 'OrgService', 'OrgSelectorService', 'AppConsts'];
QueuesCtrl.$inject = ['currentUser', '$q', '$uibModal', 'QueueService', 'SupportService', 'filterFilter', 'OrgService', 'OrgSelectorService', 'AppConsts', 'SettingService', 'SettingKeys', 'timeZone'];
RegistrationCtrl.$inject = ['$log', '$stateParams', '$timeout', 'SessionService', 'WebplusUrl', 'questions'];
ReportsCtrl.$inject = ['$timeout', 'TokenService', 'ReportService', 'OrgSelectorService', 'currentUser', 'reportList'];
EscalationsCtrl.$inject = ['$timeout', 'EscalationService', 'OrgSelectorService', 'currentUser', 'ConfirmationService'];
ResetPWCtrl.$inject = ['SessionService'];
SearchabilityCtrl.$inject = ['$q', '$filter', 'OrgService', 'OrgSelectorService', 'SettingService', 'ErrorService', 'ToastService', 'SettingKeys', 'currentOrg'];
SecurityCtrl.$inject = ['$log', '$window', 'OrgService', 'OrgSelectorService', 'ErrorService', 'TwilioService', 'ToastService', 'Time', 'PinOptions', 'model', 'SettingKeys', 'SettingService', 'AdobeProvisioningService', 'PermissionsService', 'currentUser'];
SubmittersCtrl.$inject = ['currentUser', '$q', '$uibModal', 'SupportService', 'PermissionsService', 'OrgService', 'OrgSelectorService'];
SettingsCtrl.$inject = ['$q', 'currentUser', 'OrgService', 'OrgSelectorService', 'SettingService', 'SettingKeys', 'ErrorService', 'ToastService', 'PermissionsService'];
TicketingPoolsCtrl.$inject = ['TokenService', '$uibModal', 'SupportService', 'OrgService', 'OrgSelectorService', 'PermissionsService', 'AppConsts'];
TicketingPoolsDetailCtrl.$inject = ['$state', '$stateParams', '$uibModal', '$scope', 'filterFilter', 'ConfirmationService', 'SupportService', 'OrgSelectorService', 'NetworkSelectorService', 'ToastService', 'PermissionsService', 'TokenService', 'AppConsts'];
UserDetailCtrl.$inject = ['$log', '$q', '$state', 'UserService', 'PermissionsService', 'ConfirmationService', 'LdapService', 'LicenseTypes', 'PinOptions', 'model', 'orgHieEnabled', 'orgSchedulingEnabled', 'userHieEnabled', 'networkOnly', 'deleteSchedulesEnabled', 'scheduleSiteManageEnabled', 'scheduleEntriesManageEnabled', 'scheduleableEnabled', 'scheduleViewEnabled', 'SettingService', 'SettingKeys', 'currentUser'];
UsersCtrl.$inject = ['$log', '$q', '$state', '$timeout', 'UserService', 'userList', 'currentUser', 'org', 'orgHieEnabled', 'orgModel'];
MassMessagingCtrl.$inject = ['$timeout', '$scope', 'MassMessagingService', 'OrgSelectorService', 'currentUser', 'ConfirmationService'];
AboutModal.$inject = ['$state', '$timeout', 'DebugService', 'ServerEnv', 'AdminVersion', 'AdminSha'];
AddBroadcastModal.$inject = ['BroadcastService', 'OrgSelectorService'];
AddDepartmentModal.$inject = ['DepartmentService', 'OrgSelectorService'];
AddGroupModal.$inject = ['GroupService', 'OrgSelectorService'];
AddNetworkModal.$inject = ['$q', 'NetworkService'];
AddOrgModal.$inject = ['$log', '$q', 'OrgService', 'UserService', 'Time', 'PermissionsService'];
AddOrgUserModal.$inject = ['$q', 'UserService', 'OrgSelectorService', 'DepartmentService'];
AddPatientModal.$inject = ['$q', 'OrgSelectorService', 'PatientService', 'SettingService'];
AddUserModal.$inject = ['$q', 'UserService', 'ToastService', 'LdapService', 'SettingService', 'SettingKeys', 'SupportService', 'DepartmentService'];
AddEscalationModal.$inject = ['$q', 'EscalationService', 'OrgSelectorService'];
AddMassMessageModal.$inject = ['$q', '$interpolate', '$stateParams', 'MassMessagingService', 'OrgSelectorService', 'FileUploader', 'SettingService', 'SettingKeys', 'UserService', 'ConfirmationService', '$timeout'];
AdobeProvisioningModal.$inject = ['$q', 'AdobeProvisioningService', 'OrgService', 'OrgSelectorService'];
AgentDropdown.$inject = ['SupportService'];
AgentQueuesModal.$inject = ['QueueService'];
AuditList.$inject = ['AuditService'];
ChatList.$inject = ['FeedTypes', 'FeedInfo'];
Confirm.$inject = ['$timeout', 'ConfirmationService'];
CreateAgentModal.$inject = ['SupportService', 'OrgSelectorService'];
CreateQueueManagerModal.$inject = ['SupportService', 'OrgSelectorService'];
CreateQueueModal.$inject = ['$q', 'QueueService', 'Time', 'BusinessHourQueueTimeouts', 'OffHourQueueTimeouts', 'AppStrings', 'TimeZones', 'InactiveAgentTimeouts'];
CreateSubmitterModal.$inject = ['SupportService', 'OrgSelectorService'];
CreateTicketingPoolModal.$inject = ['$q', 'PermissionsService', 'SupportService', 'NetworkSelectorService', 'OrgSelectorService'];
DepartmentMembersModal.$inject = ['$state', '$timeout', 'DepartmentService', 'OrgSelectorService'];
DepartmentSelect.$inject = ['$filter'];
EditBroadcastModal.$inject = ['BroadcastService'];
EditDepartmentModal.$inject = ['$q', 'DepartmentService', 'OrgSelectorService', 'OrgService', 'SettingKeys', 'SettingService'];
EditGroupModal.$inject = ['$q', 'GroupService'];
EditOrgModal.$inject = ['$q', 'OrgService', 'UserService', 'PermissionsService'];
EditOrgUserModal.$inject = ['$q', 'UserService', 'ConfirmationService', 'SupportService', 'ErrorService', 'TokenService', 'AdminRoles'];
EditPatientModal.$inject = ['$q', 'OrgSelectorService', 'PatientService', 'SettingService'];
EditQueueModal.$inject = ['$q', 'QueueService', 'Time', 'BusinessHourQueueTimeouts', 'OffHourQueueTimeouts', 'TimeZones', 'InactiveAgentTimeouts'];
EditUserModal.$inject = ['$q', 'PermissionsService', 'UserService', 'LdapService', 'SettingService', 'SettingKeys'];
EntityDropdown.$inject = ['AuditService', 'PermissionsService', 'OrgSelectorService', 'AuditTypes'];
ErrorModal.$inject = ['ErrorService', 'BacklineAPI'];
GroupMembersModal.$inject = ['$state', '$timeout', 'GroupService'];
MaskCallerSettingsModal.$inject = ['$q'];
MessageList.$inject = ['ChatService', 'FeedInfo', 'DeviceTypes', 'MessageTypes'];
MigrateUserModal.$inject = ['$timeout', 'UserService', 'OrgService', 'OrgSelectorService', 'LicenseTypes'];
NetworkOrgDropdown.$inject = ['NetworkService'];
EscalationTargetDropdown.$inject = ['OrgSelectorService', 'GroupService', 'UserService'];
NetworksDropdown.$inject = ['PermissionsService', 'NetworkSelectorService', 'OrgService', 'OrgSelectorService', 'AppConsts'];
OrgGroupSelect.$inject = ['GroupService'];
OrgDepartmentSelect.$inject = ['OrgService'];
OrgDropdown.$inject = ['OrgService', 'OrgSelectorService', 'PermissionsService'];
SelectiveOrgDropdown.$inject = ['OrgService', 'OrgSelectorService', 'PermissionsService'];
OrgSecurityDetail.$inject = ['PinOptions', 'SettingService', 'SettingKeys'];
OrgStatus.$inject = ['OrgService'];
PatientMembersModal.$inject = ['$state', '$timeout', 'PatientService'];
QueueDropdown.$inject = ['QueueService'];
QueueManagersDropdown.$inject = ['SupportService'];
QueueManagersQueuesModal.$inject = ['QueueService', 'SupportService'];
QueueAgentsModal.$inject = ['$state', 'SupportService'];
QueueManagersModal.$inject = ['$state', 'SupportService'];
QueueOrgsModal.$inject = ['$state', 'OrgService', 'QueueService'];
RegisterLandlineModal.$inject = ['$q', 'TwilioService'];
RenameTicketingPoolModal.$inject = ['$q', 'PermissionsService', 'SupportService', 'NetworkSelectorService', 'OrgSelectorService'];
RoleSelect.$inject = ['PermissionsService', 'AdminRoles', 'RoleList', 'AppConsts'];
SessionTimeout.$inject = ['ActivityService', 'TokenService', 'AuthStatus'];
SubmitterTicketingPoolModal.$inject = ['$state', '$timeout', 'SupportService', 'NetworkSelectorService', 'OrgSelectorService'];
Toast.$inject = ['$timeout', 'ToastService'];
UnregisterLandlineModal.$inject = ['$q', 'TwilioService', 'ToastService'];
UserDropdown.$inject = ['UserService', 'PermissionsService'];
OrgNetworksDropdown.$inject = ['AppConsts'];
UserOrgs.$inject = ['UserService', 'PermissionsService'];
UserRegStatus.$inject = ['UserService'];
UserSelect.$inject = ['UserService', 'OrgSelectorService'];
UserSupport.$inject = ['$uibModal'];
UserStatusSelect.$inject = ['UserStatuses'];
VerifyLandlineModal.$inject = ['$q', '$interval', 'TwilioService', 'TwilioRegStatus'];
AgentsTable.$inject = ['$state', '$uibModal', 'filterFilter'];
NetworksTable.$inject = ['NetworkService'];
QueueManagersTable.$inject = ['$state', '$uibModal', 'filterFilter'];
TicketingPoolsTable.$inject = ['$state', '$uibModal', '$q', 'filterFilter', 'ConfirmationService', 'SupportService', 'NetworkSelectorService', 'OrgSelectorService', 'PermissionsService', 'TokenService'];
QueuesTable.$inject = ['$uibModal', 'filterFilter'];
SubmittersTable.$inject = ['$state', '$uibModal', 'filterFilter'];
ActivityService.$inject = ['$rootScope', '$interval', '$timeout', 'TokenService', 'AuthStatus', 'TimeoutPeriod'];
var includes = [
  'ngAnimate',
  'ui.router',
  'ngCookies',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngTouch',
  'ui.select',
  'ui.bootstrap',
  'angularFileUpload'
];

// only add sentry if not on local
// if (SERVER_ENV !== 'local') {
//   includes.push('ngRaven');
// }

/*
 *  Main application module
 *  @name backlineAdminPlus
 */
angular.module('backlineAdminPlus', includes)
  .config(['$stateProvider', '$animateProvider', '$httpProvider', '$urlRouterProvider', '$locationProvider', '$injector', function ($stateProvider, $animateProvider, $httpProvider, $urlRouterProvider, $locationProvider, $injector) {

    $stateProvider
      /* .state('sessions' {}) */
      .state('login', {
        url: '/login',
        templateUrl: 'views/sessions/login.html',
        controller: 'LoginCtrl',
        controllerAs: 'login',

        module: 'sessions'
      })

      .state('loginotp', {
        url: '/login/otp',
        templateUrl: 'views/sessions/loginotp.html',
        controller: 'LoginOtpCtrl',
        controllerAs: 'loginOtp',
        params: {
          email: '',
          password: '',
          firstTime: false
        },

        module: 'sessions'
      })

      .state('resetpw', {
        url: '/passwords/reset',
        templateUrl: 'views/sessions/resetpw.html',
        controller: 'ResetPWCtrl',
        controllerAs: 'resetpw',
        module: 'sessions'
      })

      .state('changepw', {
        url: '/sessions/change_password?reset_password_token',
        templateUrl: 'views/sessions/changepw.html',
        controller: 'ChangePWCtrl',
        controllerAs: 'changepw',
        module: 'sessions'
      })

      .state('adminchangepw', {
        url: '/sessions/change_password_questions?reset_password_token',
        templateUrl: 'views/sessions/adminchangepw.html',
        controller: 'AdminChangePWCtrl',
        controllerAs: 'changepw',
        resolve: AdminChangePWCtrl.resolve,
        module: 'sessions'
      })

      .state('register', {
        url: '/sessions/registration_confirmation?confirmation_token',
        templateUrl: 'views/sessions/register.html',
        controller: 'RegistrationCtrl',
        controllerAs: 'register',

        resolve: RegistrationCtrl.resolve,

        module: 'sessions'
      })

      .state('admin', {
        url: '',
        templateUrl: 'views/main.html',
        controller: 'AdminCtrl',
        controllerAs: 'admin',

        'abstract': true,

        resolve: AdminCtrl.resolve
      })

      .state('admin.debug', {
        url: '/debug',
        templateUrl: 'views/debug.html',
        controller: 'DebugCtrl',
        controllerAs: 'debug'
      })

      .state('admin.dashboard', {
        url: '/',
        templateUrl: 'views/dashboard.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',

        resolve: DashboardCtrl.resolve
      })

      .state('admin.orgs', {
        url: '/orgs',
        templateUrl: 'views/orgs.html',
        controller: 'OrgsCtrl',
        controllerAs: 'orgs',

        resolve: OrgsCtrl.resolve
      })

      .state('admin.orgs.detail', {
        url: '/{id:int}',
        views: {
          '@admin': {
            templateUrl: 'views/orgs/detail.html',
            controller: 'OrgDetailCtrl',
            controllerAs: 'org'
          }
        },

        resolve: OrgDetailCtrl.resolve
      })

      .state('admin.orgs.database', {
        url: '/{id:int}/database',
        views: {
          '@admin': {
            templateUrl: 'views/orgs/database.html',
            controller: 'OrgDatabaseCtrl',
            controllerAs: 'org'
          }
        },

        resolve: OrgDatabaseCtrl.resolve
      })

      .state('admin.networks', {
        url: '/networks',
        templateUrl: 'views/networks.html',
        controller: 'NetworksCtrl',
        controllerAs: 'networks',

        resolve: NetworksCtrl.resolve
      })

      .state('admin.networks.detail', {
        url: '/{id:int}',
        views: {
          '@admin': {
            templateUrl: 'views/networks/detail.html',
            controller: 'NetworkDetailCtrl',
            controllerAs: 'network'
          }
        },
        resolve: NetworkDetailCtrl.resolve
      })

      .state('admin.users', {
        url: '/users',
        templateUrl: 'views/users.html',
        controller: 'UsersCtrl',
        controllerAs: 'users',

        resolve: UsersCtrl.resolve
      })

      .state('admin.users.detail', {
        url: '/{id:int}',
        views: {
          '@admin': {
            templateUrl: 'views/users/detail.html',
            controller: 'UserDetailCtrl',
            controllerAs: 'user'
          }
        },
        resolve: UserDetailCtrl.resolve
      })

      .state('admin.batch', {
        url: '/batch',
        views: {
          '@admin': {
            templateUrl: 'views/users/batch.html',
            controller: 'UserBatchCtrl',
            controllerAs: 'batch',
            resolve: UserBatchCtrl.resolve

          }
        },
        resolve: UserBatchCtrl.resolve
      })

      .state('admin.departments', {
        url: '/departments',
        templateUrl: 'views/departments.html',
        controller: 'DepartmentsCtrl',
        controllerAs: 'departments',

        resolve: DepartmentsCtrl.resolve
      })

      .state('admin.groups', {
        url: '/groups',
        templateUrl: 'views/groups.html',
        controller: 'GroupsCtrl',
        controllerAs: 'groups',

        resolve: GroupsCtrl.resolve
      })

      .state('admin.patients', {
        url: '/patients',
        templateUrl: 'views/patients.html',
        controller: 'PatientsCtrl',
        controllerAs: 'patients',

        resolve: PatientsCtrl.resolve
      })

      .state('admin.archives', {
        url: '/archives',
        templateUrl: 'views/archives.html',
        controller: 'ArchiveCtrl',
        controllerAs: 'archives',

        resolve: ArchiveCtrl.resolve
      })

      .state('admin.archives.detail', {
        url: '/{id:int}',
        views: {
          '@admin': {
            templateUrl: 'views/archives/detail.html',
            controller: 'ArchiveDetailCtrl',
            controllerAs: 'archive'
          }
        },
        resolve: ArchiveDetailCtrl.resolve
      })

      .state('admin.archives.detail.log', {
        url: '/log',
        views: {
          '@admin': {
            templateUrl: 'views/archives/log.html',
            controller: 'ArchiveDetailCtrl',
            controllerAs: 'archive'
          }
        }
      })

      .state('admin.audit', {
        url: '/audit',
        templateUrl: 'views/audit.html',
        controller: 'AuditCtrl',
        controllerAs: 'audit',

        resolve: AuditCtrl.resolve
      })

      .state('admin.broadcast', {
        url: '/broadcast',
        templateUrl: 'views/broadcast.html',
        controller: 'BroadcastCtrl',
        controllerAs: 'broadcast',

        resolve: BroadcastCtrl.resolve
      })

      .state('admin.messaging', {
        url: '/mass-messaging',
        templateUrl: 'views/mass-messaging.html',
        controller: 'MassMessagingCtrl',
        controllerAs: 'messaging',

        resolve: MassMessagingCtrl.resolve
      })

      .state('admin.reports', {
        url: '/reports',
        templateUrl: 'views/reports.html',
        controller: 'ReportsCtrl',
        controllerAs: 'reports',

        resolve: ReportsCtrl.resolve
      })

      .state('admin.escalations', {
        url: '/escalations',
        templateUrl: 'views/escalations.html',
        controller: 'EscalationsCtrl',
        controllerAs: 'escalations',

        resolve: EscalationsCtrl.resolve
      })

      .state('admin.insights', {
        url: '/insights',
        templateUrl: 'views/insightsreports.html',
        controller: 'ReportsCtrl',
        controllerAs: 'reports',

        resolve: ReportsCtrl.resolve
      })

      .state('admin.configs', {
        url: '/configs',
        'abstract': true
      })

      .state('admin.configs.security', {
        url: '/security',
        views: {
          '@admin': {
            templateUrl: 'views/configs/security.html',
            controller: 'SecurityCtrl',
            controllerAs: 'security'
          }
        },

        resolve: SecurityCtrl.resolve
      })

      .state('admin.configs.defaults', {
        url: '/defaults',
        views: {
          '@admin': {
            templateUrl: 'views/configs/defaults.html',
            controller: 'DefaultsCtrl',
            controllerAs: 'defaults'
          }
        },

        resolve: DefaultsCtrl.resolve
      })
      .state('admin.configs.searchability', {
        url: '/searchability',
        views: {
          '@admin': {
            templateUrl: 'views/configs/searchability.html',
            controller: 'SearchabilityCtrl',
            controllerAs: 'searchability'
          }
        },

        resolve: SearchabilityCtrl.resolve
      })

      .state('admin.configs.adt', {
        url: '/adt',
        views: {
          '@admin': {
            templateUrl: 'views/configs/adt.html',
            controller: 'ADTCtrl',
            controllerAs: 'adt'
          }
        },

        resolve: ADTCtrl.resolve
      })

      .state('admin.configs.ehr', {
        url: '/ehr',
        views: {
          '@admin': {
            templateUrl: 'views/configs/ehr.html',
            controller: 'EHRCtrl',
            controllerAs: 'ehr'
          }
        },

        resolve: DefaultsCtrl.resolve
      })

      .state('admin.support', {
        url: '/support',
        'abstract': true
      })

      .state('admin.support.agents', {
        url: '/agents',
        views: {
          '@admin': {
            templateUrl: 'views/support/agents.html',
            controller: 'AgentsCtrl',
            controllerAs: 'ctrl'
          }
        }
      })

      .state('admin.support.queue_managers', {
        url: '/queue_managers',
        views: {
          '@admin': {
            templateUrl: 'views/support/queue_managers.html',
            controller: 'QueueManagersCtrl',
            controllerAs: 'ctrl'
          }
        }
      })

      .state('admin.support.ticketing_pools', {
        url: '/ticketing_pools',
        views: {
          '@admin': {
            templateUrl: 'views/support/ticketing_pools.html',
            controller: 'TicketingPoolsCtrl',
            controllerAs: 'poolsCtrl',
            resolve: TicketingPoolsCtrl.resolve
          }
        }
      })
      .state('admin.support.ticketing_pools.detail', {
        url: '/{id:int}',
        views: {
          '@admin': {
            templateUrl: 'views/support/ticketing_pools/detail.html',
            controller: 'TicketingPoolsDetailCtrl',
            controllerAs: 'poolCtrl',
            resolve: TicketingPoolsDetailCtrl.resolve
          }
        }
      })

      .state('admin.support.submitters', {
        url: '/submitters',
        views: {
          '@admin': {
            templateUrl: 'views/support/submitters.html',
            controller: 'SubmittersCtrl',
            controllerAs: 'ctrl'
          }
        }
      })

      .state('admin.support.settings', {
        url: '/settings',
        views: {
          '@admin': {
            templateUrl: 'views/support/settings.html',
            controller: 'SettingsCtrl',
            controllerAs: 'ctrl'
          }
        }
      })

      .state('admin.support.queues', {
        url: '/queues',
        views: {
          '@admin': {
            templateUrl: 'views/support/queues.html',
            controller: 'QueuesCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: DefaultsCtrl.resolve
      });

    // hack around for possible [$rootScope:infdig] errors caused by interaction with $stateProvider
    // see angular-ui/ui-router#600
    $urlRouterProvider.otherwise(function ($injector, $location) {
      $injector.get('$state').go('login');
    });

    // intercept http requests in order to log
    $httpProvider.interceptors.push(['$q', 'DebugService', 'BacklineAPI', function ($q, DebugService, BacklineAPI) {

      return {

        'response': function (response) {

          // if this was a request to ADAMA, create a copy of the request details to send to the debug logger
          if (response.config.url.indexOf(BacklineAPI) > -1) {

            var responseSnapshot = {
              config: angular.copy(response.config),
              status: angular.copy(response.status),
              statusText: angular.copy(response.statusText)
            };

            responseSnapshot.config.url = responseSnapshot.config.url.replace(BacklineAPI, '');
            DebugService.logServiceCall(responseSnapshot);
          }

          // return untouched request to continue http call
          return $q.resolve(response);
        }
      };
    }]);
  }])

  .run(['$rootScope', '$state', '$http', 'TokenService', 'ActivityService', 'OrgSelectorService', 'ToastService', 'DebugService', 'AdminSha', function ($rootScope, $state, $http, TokenService, ActivityService, OrgSelectorService, ToastService, DebugService, AdminSha) {

    // Restart activity timeout if they refreshed the page
    if (TokenService.isLoggedIn()) {
      ActivityService.beginTimeout();
    }

    // Used by body event listeners to reset timer on mousemove and keydown
    $rootScope.doActivity = ActivityService.active;

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

      // log state change
      DebugService.logStateChange(event, toState, toParams);

      if (!(toState.module === 'sessions' || TokenService.isLoggedIn())) {

        // go to login page if you navigate to a non-session page and you are not logged in
        event.preventDefault();
        $state.go('login');
      } else if (toState.name === 'login' && TokenService.isLoggedIn()) {

        // go to dashboard if you navigate to login page and you are already logged in
        event.preventDefault();
        $state.go('admin.dashboard');
      }
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      // check version and display refresh message if needed
      $http.get('/deploy.js', {
        cache: false,
        params: {
          '_cache': new Date().getTime()
        }
      }).then(function (response) {
        if (AdminSha !== response.data.sha) {
          ToastService.refresh();
        }
      }, function (error) {
        // noop
      });
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      // log state change error
      DebugService.reportStateChangeError(event, toState, toParams, error);
    });
  }])
  .config(['$provide', function ($provide) {
    $provide.decorator('uibDaypickerDirective', ['$delegate', function ($delegate) {
      var directive = $delegate[0];

      directive.templateUrl = 'views/daypicker.html';

      return $delegate;
    }]);
  }])
  .config(['$provide', function ($provide) {
    $provide.decorator('uibMonthpickerDirective', ['$delegate', function ($delegate) {
      var directive = $delegate[0];

      directive.templateUrl = 'views/monthpicker.html';

      return $delegate;
    }]);
  }])
  .config(['$provide', function ($provide) {
    $provide.decorator('uibYearpickerDirective', ['$delegate', function ($delegate) {
      var directive = $delegate[0];

      directive.templateUrl = 'views/yearpicker.html';

      return $delegate;
    }]);
  }])
  .config(['$provide', function ($provide) {
    $provide.decorator('uibTimepickerDirective', ['$delegate', function ($delegate) {
      var directive = $delegate[0];

      directive.templateUrl = 'views/timepicker.html';
      angular.extend(directive, {
        compile: function (uibTimepickerConfig) {
          return {
            pre: function (scope, element, attrs, ctrls) {
              var meridians = angular.isDefined(attrs.meridians) ? scope.$parent.$eval(attrs.meridians) : uibTimepickerConfig.meridians || [ 'AM', 'PM' ];

              scope.handleMeridianKeyPress = function ($event) {
                switch ($event.which) {
                  case 97: // a
                    if (scope.meridian === meridians[1]) {
                      scope.toggleMeridian();
                    }
                    break;
                  case 112: // p
                    if (scope.meridian === meridians[0]) {
                      scope.toggleMeridian();
                    }
                    break;
                }
              };
              directive.link(scope, element, attrs, ctrls);
            }
          };
        }
      });

      return $delegate;
    }]);
  }]);
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  var consts = {
    NETWORK_TYPE: {
      PARTNER: 1,
      PARENT_CHILD: 2,
      CUSTOMER_SUPPORT: 3
    }
  };

  app.value('AppConsts', consts);
})();
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  var defaultErrors = {
    internalServerError: {
      title: 'Internal Server Error',
      status: 500,
      message: 'An internal server error has occurred. We apologize for any inconvenience'
    },

    forbidden: {
      title: 'Forbidden',
      status: 403,
      message: 'You are not allowed to view that resource'
    },

    notFound: {
      title: 'Not Found',
      status: 404,
      message: 'The resource you have requested was not found on the server'
    },

    unauthorized: {
      title: 'Unauthorized',
      status: 401,
      message: 'Your session has expired or become invalid. Please log-out and sign back in to resume'
    },

    malformedRequest: {
      title: 'Client Error',
      status: 900,
      message: 'An error was encountered. Please show details to send feedback on this issue'
    }
  };

  var debugTypes = {
    service: 1,
    state: 2
  };

  var sanitizedFields = [
    'auth_token',
    'pw',
    'password',
    'patient[fname]',
    'patient[lname]',
    'patient[dob]',
    'patient[mrn]',
    'subject',
    'content'
  ];

  app.value('DefaultErrors', defaultErrors);
  app.value('DebugTypes', debugTypes);
  app.value('SanitizedFields', sanitizedFields);
})();
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  var authstatus = {
    loggedOut: 0,
    loggedIn: 1
  };

  var timeoutPeriod = {
    warning: 19 /* minutes */ * 60 * 1000,
    logout: 20 /* minutes */ * 60 * 1000
  };

  var roles = {
    user: 1,
    limitedAdmin: 10,
    fullAdmin: 20,
    drSalesAdmin: 30,
    drLimitedAdmin: 40,
    drFullAdmin: 50,
    drSuperAdmin: 60
  };

  var implList = [
    {
      id: 'Canceled',
      name: 'Canceled'
    },
    {
      id: 'InProgress',
      name: 'In Progress'
    },
    {
      id: 'Live',
      name: 'Live'
    },
    {
      id: 'OnHold',
      name: 'On Hold'
    }
  ];

  var roleList = [
    {
      id: 1,
      name: 'User'
    },
    {
      id: 10,
      name: 'Admin Limited'
    },
    {
      id: 20,
      name: 'Admin Full'
    },
    {
      id: 30,
      name: 'Dr First Sales Admin'
    },
    {
      id: 40,
      name: 'Dr First Limited Admin'
    },
    {
      id: 50,
      name: 'Dr First Full Admin'
    },
    {
      id: 60,
      name: 'Dr First Super Admin'
    }
  ];

  var feedTypes = {
    pcc: 1,
    publicGroup: 2,
    privateChat: 3,
    inviteGroup: 4,
    alert: 5,
    bot: 6,
    broadcast: 7,
    groups: '2,4',
    medlists: 9,
    examrooms: 11,
    multiUser: 13
  };

  var feedInfo = [
    {
      label: 'Unknown',
      icon: 'fa-question-cirle'
    },
    {
      label: 'Patient Centered Chat',
      icon: 'fa-bed'
    },
    {
      label: 'Public Group Chat',
      icon: 'fa-users'
    },
    {
      label: 'Private Chat',
      icon: 'fa-user'
    },
    {
      label: 'Private Group Chat',
      icon: 'fa-lock'
    },
    {
      label: 'Alerts',
      icon: 'fa-warning'
    },
    {
      label: 'Bot Message',
      icon: 'fa-bullhorn'
    },
    {
      label: 'Ticket',
      icon: 'fa-tag'
    },
    {
      label: 'Queues',
      icon: ''
    },
    {
      label: 'MedLists',
      icon: 'fa-barcode'
    },
    {
      label: 'none',
      icon: ''
    },
    {
      label: 'Exam Rooms',
      icon: ''
    },
    {
      label: 'Report',
      icon: ''
    },
    {
      label: 'Multi User Chat',
      icon: 'fa-users'
    }
  ];

  var archiveTypes = [
    {
      label: 'Any',
      params: {
        feed_type: '1,2,3,4,5,6,7,9,11,13'
      }
    },
    {
      label: 'Patient Centered Chats',
      params:{
        feed_type: 1
      }
    },
    {
      label: 'Group Chats',
      params: {
        feed_type: '2,4'
      }
    },
    {
      label: 'Chats',
      params: {
        feed_type: '3,13',
        unregistered_users: false
      }
    },
    {
      label: 'Weblink 1-on-1',
      params: {
        feed_type: 3,
        unregistered_users: true
      }
    },
    {
      label: 'Tickets',
      params: {
        feed_type: 7
      }
    },
    {
      label: 'MedLists',
      params: {
        feed_type: 9
      }
    },
    {
      label: 'Exam Rooms',
      params: {
        feed_type: 11
      }
    }
  ];

  var messageTypes = {
    file: 'file',
    chat: 'chat',
    event: 'event',
    system: 'system',
    isReadable: function ( type ) {
      return type != this.event;
    }
  };

  var deviceTypes = [
    {
      label: 'Unknown',
      icon: 'fa-question-cirle'
    },
    {
      label: 'Web',
      icon: 'fa-desktop'
    },
    {
      label: 'iOS',
      icon: 'fa-apple'
    },
    {
      label: 'Android',
      icon: 'fa-android'
    }
  ];

  var auditTypes = [
    {
      label: 'All'
    },
    {
      label: 'Orgs',
      dropdown: true
    },
    {
      label: 'Users',
      dropdown: true
    },
    {
      label: 'Chat Archive',
      dropdown: true
    },
    {
      label: 'Logins',
      dropdown: true
    },
    {
      label: 'Groups'
    },
    {
      label: 'Patients'
    },
    {
      label: 'Notifications',
      dropdown: true
    }
  ];

  var userStatuses = [
    {
      id: 1,
      label: 'Pending'
    },
    {
      id: 2,
      label: 'Expired'
    },
    {
      id: 3,
      label: 'Registered'
    },
    {
      id: 4,
      label: 'Inactive'
    },
    {
      id: 10,
      label: 'Patients'
    }
  ];

  var licenseTypes = {
    none: 0,
    community: 1,
    enterprise: 2
  };

  var twilioRegStatus = {
    none: 1,
    inProgress: 2,
    success: 3,
    failed: 4
  };

  app.value('AuthStatus', authstatus);
  app.value('TimeoutPeriod', timeoutPeriod);
  app.value('AdminRoles', roles);
  app.value('ImplList', implList);
  app.value('RoleList', roleList);
  app.value('FeedTypes', feedTypes);
  app.value('FeedInfo', feedInfo);
  app.value('DeviceTypes', deviceTypes);
  app.value('MessageTypes', messageTypes);
  app.value('ArchiveTypes', archiveTypes);
  app.value('AuditTypes', auditTypes);
  app.value('UserStatuses', userStatuses);
  app.value('LicenseTypes', licenseTypes);
  app.value('TwilioRegStatus', twilioRegStatus);
})();
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  app.value('BacklineURL', API_PROTOCOL + ADAMA_URL);
  app.value('BacklineAPI', API_PROTOCOL + API_DOMAIN + '/api');
  app.value('BacklineAdminAPI', API_PROTOCOL + API_DOMAIN + '/api/enterprise');
  app.value('BacklineAdminAPIV2', API_PROTOCOL + API_DOMAIN + '/api/enterprise/v2');
  app.value('BacklineSupportAPI', API_PROTOCOL + API_DOMAIN + '/api/support');
  app.value('SettingsUrl', API_PROTOCOL + SETTINGS_URL);
  app.value('WebplusUrl', API_PROTOCOL + WEBPLUS_URL);
  app.value('ServerEnv', SERVER_ENV);
  app.value('AdminVersion', VERSION);
  app.value('AdminSha', SHA);

  app.value('JSCSPassed', null);
  app.value('JSHintPassed', null);

  if (SERVER_ENV === 'local' && typeof(JSCS_PASSED) !== 'undefined') {
    app.value('JSCSPassed', JSCS_PASSED);
    app.value('JSHintPassed', JSHINT_PASSED);
  }

})();
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  var time = {
    seconds: function ( number ) {
      return number * 1000;
    },

    minutes: function ( number ) {
      return this.seconds(number * 60);
    },

    hours: function ( number ) {
      return this.minutes(number * 60);
    },

    days: function ( number ) {
      return this.hours(number * 24);
    },

    weeks: function ( number ) {
      return this.days(number * 7);
    },

    inSeconds: function ( ms ) {
      return ms / 1000;
    },

    inMinutes: function ( seconds ) {
      return seconds / 60;
    },

    stringToDate: function (hours, minutes) {
      var date = new Date();

      date.setHours(hours, minutes, 0);
      return date.toISOString();
    }
  };

  var settingKeys = {

    adtEnabled: 'alert.send.hl7.menu_enabled',

    allowedEvents: 'org.adt.events.allowed',
    allowedPositions: 'org.adt.positions.allowed',
    allowedPCCEvents: 'org.adt.pcc.allowed',

    chatWithPatientEnabled: 'org.chat.with.patient.expiration',
    chatWithPatientExpiration: 'org.chat.with.patient.expiration',
    weblinkExpiration: 'org.weblink.expiration',
    medlistExpiration: 'org.med_hist.expiration',

    orgHieEnabled: 'org.hie.enabled',
    userHieEnabled: 'user.hie.enabled',
    deleteSchedulesEnabled: 'user.scheduling.delete.enabled',
    scheduleSiteManageEnabled: 'user.scheduling.site.manage.enabled',
    scheduleEntriesManageEnabled: 'user.scheduling.entries.manage.enabled',
    scheduleableEnabled: 'user.scheduling.scheduleable.enabled',
    scheduleViewEnabled: 'user.scheduling.view.enabled',
    hieOids: 'org.hie.oids',

    ticketsIppEnabled: 'org.tickets.ipp.enabled',
    ippEnabled: 'org.ipp.enabled',
    idmSystemEnabled: 'system.feed_distribution_lists.enabled',
    idmEnabled: 'org.feed_distribution_lists.enabled',
    insightsEnabled: 'org.insights.enabled',
    emsWorkflowEnabled: 'org.ems.workflow.enabled',
    emsWebWorkflowEnabled: 'org.ems.web.workflow.enabled',
    medHx2Username: 'org.med.hx.v2.username',
    medHx2Password: 'org.med.hx.v2.password',
    emsUserWorkflowEnabled: 'user.ems.workflow.enabled',
    esignEnabled: 'org.adobe.esign.enabled',
    esignProvisioned: 'org.adobe.esign.provisioning',
    twilioVideoChatEnabled: 'org.twilio.video.chat.enabled',
    twilioExamRoomsEnabled: 'org.twilio.exam.rooms.enabled',
    webMsgProtocol: 'org.web.messaging_protocol',
    pointClickCare: 'org.point.click.care.enabled',
    populusMedia: 'org.populus.media.enabled',
    schedules: 'org.scheduling.enabled',
    massMessaging: 'org.mass_messaging.enabled',
    assignmentsEnabled: 'org.assignments.feature.enabled',
    timeZone: 'org.time_zone',

    multiLocationEnabled: 'org.multi_location.enabled',

    crossNetworkFeatureEnabled: 'network.internetwork.user.access.feature.enabled',
    crossNetworkEnabled: 'network.internetwork.user.access.enabled',
    awaitingResponseAutoCloseDuration: 'network.awaiting_response.auto_close_duration',
    awaitingResponseReminderInterval: 'network.awaiting_response.reminder_interval',

    fingerprintEnabled: 'org.security.fingerprint.enabled',
    externalPatientSearch: 'org.external.patient.search',
    maskCallerIDEnabled: 'org.security.masked.phone.calls',
    allowShareAttachments: 'org.security.allow.attachment.sharing',
    twoFactorAuthenticationEnabled: 'org.2fa.enabled',

    adobeRefreshToken: 'org.adobe.esign.refresh_token',
    adobeClientId: 'org.adobe.esign.client_id',
    adobeClientSecret: 'org.adobe.esign.client_secret',
    adobeWebhookDomain: 'org.adobe.esign.webhooks_domain',
    adobeRedirectURL: 'org.adobe.esign.redirect_url',
    adobeCompletionRedirectURL: 'org.adobe.esign.completion.redirect_url',
    adobeIsPartnerOrg: 'org.adobe.esign.is.partner.org',
    s3BucketUrl: 'org.s3.static.assets.bucket.url'
  };

  var weblinkOptions = [
    {
      value: time.inSeconds(time.hours(12)),
      label: '12 hours'
    },
    {
      value: time.inSeconds(time.days(1)),
      label: '24 hours'
    },
    {
      value: time.inSeconds(time.days(2)),
      label: '48 hours'
    },
    {
      value: time.inSeconds(time.days(3)),
      label: '3 days'
    },
    {
      value: time.inSeconds(time.weeks(1)),
      label: '1 week'
    }
  ];

  var timeZones = [
    {
      value: 'Alaska',
      label: 'Alaska'
    },
    {
      value: 'Atlantic Time (Canada)',
      label: 'Atlantic Time (Canada)'
    },
    {
      value: 'Central Time (US & Canada)',
      label: 'Central Time (US & Canada)'
    },
    {
      value: 'Eastern Time (US & Canada)',
      label: 'Eastern Time (US & Canada)'
    },
    {
      value: 'Hawaii',
      label: 'Hawaii'
    },
    {
      value: 'Mountain Time (US & Canada)',
      label: 'Mountain Time (US & Canada)'
    },
    {
      value: 'America/Phoenix',
      label: 'Arizona Time (US)'
    },
    {
      value: 'Pacific Time (US & Canada)',
      label: 'Pacific Time (US & Canada)'
    }
  ];

  var chatWithPatientOptions = [
    {
      value: time.inSeconds(time.minutes(15)),
      label: '15 minutes'
    },
    {
      value: time.inSeconds(time.minutes(30)),
      label: '30 minutes'
    },
    {
      value: time.inSeconds(time.hours(1)),
      label: '1 hour'
    },
    {
      value: time.inSeconds(time.hours(2)),
      label: '2 hours'
    },
    {
      value: time.inSeconds(time.hours(6)),
      label: '6 hours'
    },
    {
      value: time.inSeconds(time.hours(12)),
      label: '12 hours'
    },
    {
      value: time.inSeconds(time.days(1)),
      label: '24 hours'
    },
    {
      value: time.inSeconds(time.days(2)),
      label: '48 hours'
    },
    {
      value: time.inSeconds(time.days(3)),
      label: '3 days'
    },
    {
      value: time.inSeconds(time.weeks(1)),
      label: '1 week'
    },
    {
      value: time.inSeconds(time.days(30)),
      label: '30 days'
    },
    {
      value: time.inSeconds(time.days(60)),
      label: '60 days'
    },
    {
      value: time.inSeconds(time.days(90)),
      label: '90 days'
    },
    {
      value: time.inSeconds(time.days(120)),
      label: '120 days'
    },
    {
      value: time.inSeconds(time.days(180)),
      label: '180 days'
    },
    {
      value: time.inSeconds(time.hours(0)),
      label: 'Never'
    }
  ];

  var medlistOptions = [
    {
      value: time.inSeconds(time.hours(1)),
      label: '1 hour'
    },
    {
      value: time.inSeconds(time.hours(2)),
      label: '2 hours'
    },
    {
      value: time.inSeconds(time.hours(3)),
      label: '3 hours'
    },
    {
      value: time.inSeconds(time.hours(4)),
      label: '4 hours'
    },
    {
      value: time.inSeconds(time.hours(8)),
      label: '8 hours'
    },
    {
      value: time.inSeconds(time.days(1)),
      label: '24 hours'
    },
    {
      value: time.inSeconds(time.days(2)),
      label: '48 hours'
    },
    {
      value: time.inSeconds(time.weeks(1)),
      label: '1 week'
    }
  ];

  var externalPatientSearchOptions = [
    {
      value: 1,
      label: 'Normal Integration User'
    },
    {
      value: 2,
      label: 'Pharmacy User'
    },
    {
      value: 3,
      label: 'Pharmacy Extended User'
    }
  ];

  var pinOptions = [
    {
      value: time.inSeconds(time.seconds(10)),
      label: '10 seconds'
    },
    {
      value: time.inSeconds(time.seconds(30)),
      label: '30 seconds'
    },
    {
      value: time.inSeconds(time.minutes(1)),
      label: '1 minute'
    },
    {
      value: time.inSeconds(time.minutes(2)),
      label: '2 minutes'
    },
    {
      value: time.inSeconds(time.minutes(5)),
      label: '5 minutes'
    },
    {
      value: time.inSeconds(time.minutes(10)),
      label: '10 minutes'
    },
    {
      value: time.inSeconds(time.minutes(12)),
      label: '12 minutes'
    }
  ];

  var businessHourQueueTimeouts = [
    {
      value: time.inSeconds(time.minutes(1)),
      label: '1 minute'
    },
    {
      value: time.inSeconds(time.minutes(2)),
      label: '2 minutes'
    },
    {
      value: time.inSeconds(time.minutes(3)),
      label: '3 minutes'
    },
    {
      value: time.inSeconds(time.minutes(4)),
      label: '4 minutes'
    },
    {
      value: time.inSeconds(time.minutes(5)),
      label: '5 minutes'
    },
    {
      value: time.inSeconds(time.minutes(30)),
      label: '30 minutes'
    },
    {
      value: time.inSeconds(time.hours(1)),
      label: '1 hour'
    },
    {
      value: time.inSeconds(time.hours(12)),
      label: '12 hours'
    },
    {
      value: time.inSeconds(time.hours(24)),
      label: '24 hours'
    },
    {
      value: time.inSeconds(time.weeks(52)),
      label: 'Never'
    }
  ];

  var offHourQueueTimeouts = [
    {
      value: time.inSeconds(time.minutes(1)),
      label: '1 minute'
    },
    {
      value: time.inSeconds(time.minutes(2)),
      label: '2 minutes'
    },
    {
      value: time.inSeconds(time.minutes(3)),
      label: '3 minutes'
    },
    {
      value: time.inSeconds(time.minutes(4)),
      label: '4 minutes'
    },
    {
      value: time.inSeconds(time.minutes(5)),
      label: '5 minutes'
    },
    {
      value: time.inSeconds(time.minutes(10)),
      label: '10 minutes'
    },
    {
      value: time.inSeconds(time.minutes(15)),
      label: '15 minutes'
    },
    {
      value: time.inSeconds(time.minutes(20)),
      label: '20 minutes'
    },
    {
      value: time.inSeconds(time.minutes(30)),
      label: '30 minutes'
    },
    {
      value: time.inSeconds(time.hours(1)),
      label: '1 hour'
    },
    {
      value: time.inSeconds(time.hours(12)),
      label: '12 hours'
    },
    {
      value: time.inSeconds(time.hours(24)),
      label: '24 hours'
    },
    {
      value: time.inSeconds(time.weeks(52)),
      label: 'Never'
    }
  ];

  var inactiveAgentTimeouts = [
    {
      value: time.inSeconds(time.minutes(1)),
      label: '1 minute'
    },
    {
      value: time.inSeconds(time.minutes(2)),
      label: '2 minutes'
    },
    {
      value: time.inSeconds(time.minutes(3)),
      label: '3 minutes'
    },
    {
      value: time.inSeconds(time.minutes(4)),
      label: '4 minutes'
    },
    {
      value: time.inSeconds(time.minutes(5)),
      label: '5 minutes'
    }
  ];

  app.value('Time', time);

  app.value('SettingKeys', settingKeys);
  app.value('ChatWithPatientOptions', chatWithPatientOptions);
  app.value('WeblinkOptions', weblinkOptions);
  app.value('MedlistOptions', medlistOptions);
  app.value('ExternalPatientSearchOptions', externalPatientSearchOptions);
  app.value('PinOptions', pinOptions);
  app.value('BusinessHourQueueTimeouts', businessHourQueueTimeouts);
  app.value('OffHourQueueTimeouts', offHourQueueTimeouts);
  app.value('InactiveAgentTimeouts', inactiveAgentTimeouts);
  app.value('TimeZones', timeZones);
})();
;
'use strict';

(function () {

  var app = angular.module('backlineAdminPlus');

  var strings = {
    DEFAULT_BUSINESS_HOUR_TIMEOUT_MESSAGE: 'All agents are busy assisting other clients at this time. Please call your dedicated line for immediate assistance.',
    DEFAULT_OFF_HOUR_TIMEOUT_MESSAGE: 'All agents are busy assisting other clients at this time. Please call your dedicated line for immediate assistance.'
  };

  app.value('AppStrings', strings);
})();
;
'use strict';

angular.module('backlineAdminPlus')
  .animation('.anim-slide', function () {
    var NG_HIDE_CLASS = 'ng-hide';

    return {
      beforeAddClass: function ( element, className, done ) {
        if (className === NG_HIDE_CLASS) {
          element.slideUp(done);
        }
      },
      removeClass: function ( element, className, done ) {
        if (!!element && !!element.hide && className === NG_HIDE_CLASS) {
          element.hide().slideDown(done);
        }
      }
    };
  });
;
'use strict';

/*
 *  Factory for managing audit records and entities
 *  @name backlineAdminPlus.factory:AuditService
 *  @ngInject
 */
function AuditService( $log, $q, $resource, TokenService, BacklineAdminAPI ) {

  var AuditService = {};

  var _auditResource = $resource(BacklineAdminAPI + '/audits', {}, {
    list: {
      method: 'GET'
    },

    entities: {
      url: BacklineAdminAPI + '/audits/entities',
      method: 'GET'
    }
  });

  /*
   *  Find audit records matching search params
   */
  AuditService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_auditResource.list, params, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get auditable entites for the given org id, and entity type
   */
  AuditService.entities = function ( orgId, entityType ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_auditResource.entities, {
      org_id: orgId,
      entity_type: entityType
    }, function ( response ) {

      deferred.resolve(response.entities);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return AuditService;
}

angular.module('backlineAdminPlus')
  .factory('AuditService', AuditService);
;
'use strict';

/*
 *  Factory for tracking if user is active or not
 *  @name backlineApp.controller:ActivityService
 *  @description
 */
function ActivityService( $rootScope, $interval, $timeout, TokenService, AuthStatus, TimeoutPeriod ) {

  var ActivityService = {},
    _lastActivityTime = new Date().getTime(),
    _isAway = false,
    _interval,
    _timeout;

  // Event fired when you are away past the interval
  ActivityService.fireWarning = 'ActivityService.fireWarning';

  /*
   *  Callback for interval tick when status changes
   */
  function _activityStatusChanged( away ) {

    if (away) {

      $rootScope.$broadcast(ActivityService.fireWarning);

      _timeout = $timeout(function () {
        TokenService.logout();
      }, TimeoutPeriod.logout - TimeoutPeriod.warning);
    } else {
      if (_timeout) {
        $timeout.cancel(_timeout);
        _timeout = undefined;
      }
    }
  }

  /*
   *  Tick for checking activity status
   */
  function _checkActivity( callback ) {
    var diff = new Date().getTime() - _lastActivityTime;
    var isAway = (diff > TimeoutPeriod.warning);

    if (isAway != _isAway) {
      _activityStatusChanged(isAway);
      _isAway = isAway;
    }
  }

  /*
   *  Timer to track inactivity
   */
  ActivityService.beginTimeout = function () {

    _interval = $interval(_checkActivity, 1000);
  };

  /*
   *  Call when user does some activity to reset activity tracker
   */
  ActivityService.active = function () {

    _lastActivityTime = new Date().getTime();
  };

  /*
   *  Listen for log in / out to start / stop tracking
   */
  $rootScope.$on(TokenService.authenticationStatusChanged, function ( $event, status ) {

    if (status == AuthStatus.loggedIn) {

      _lastActivityTime = new Date().getTime();
      ActivityService.beginTimeout();

    } else {

      $interval.cancel(_interval);
      _interval = undefined;
    }
  });

  return ActivityService;
}

angular.module('backlineAdminPlus')
  .factory('ActivityService', ActivityService);
;
'use strict';

/**
 * Create a typedef for the object model for a Setting
 * @typedef {Object} BacklineSetting
 * @property {number} org_id
 * @property {number} setting_id
 * @property {string} setting_name
 * @property {string} setting_type - the scope of the setting, 'User' | 'Org' | null.
 * @property {number} user_id
 * @property {string} value
 * @property {string} value_type
 */

/**
 * Create a typedef for the object model for a Setting
 * @typedef {Object} Setting
 * @property {string} key
 * @property {number} id
 * @property {string} type
 * @property {string} valueType
 * @property {string | boolean | number} value
 * @property {number} orgId
 * @property {number} userId
 */

/*
 *  Factory for settings
 *  @name backlineAdminPlus.factory:SettingService
 *  @ngInject
 */
function AdobeProvisioningService( $q, $resource, TokenService, BacklineAPI, SettingKeys, SettingService ) {

  var AdobeProvisioningService = {};

  var _adobeProvisioningService = $resource(BacklineAPI + '/adobe/provisioning', { id: '@id' }, {

    get_provisioning_info: {
      method: 'GET'
    },

    create_provisioning: {
      method: 'POST'
    }

  });

  /*
   *  Find a setting by name for an org
   */
  AdobeProvisioningService.provision = function ( org, email, fname, lname, phone, password ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_adobeProvisioningService.create_provisioning, {
      org_id: org.id,
      company: org.name,
      email: email,
      firstName: fname,
      lastName: lname,
      phone: phone,
      password: password
    }, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  AdobeProvisioningService.getProvisioningInfo = function (org) {
    var deferred = $q.defer();

    SettingService.find(SettingKeys.esignEnabled, org)
      .then(function (setting) {
        if (setting.value == 1) {
          TokenService.wrapServiceCall(_adobeProvisioningService.get_provisioning_info, {
            org_id: org.id
          }, function ( response ) {

            deferred.resolve(response);

          }, function ( error ) {

            deferred.reject(error.data.error_text);

          }, true);
        } else {

        }
      });

    return deferred.promise;
  };

  return AdobeProvisioningService;
}

angular.module('backlineAdminPlus')
  .service('AdobeProvisioningService', AdobeProvisioningService);

;
'use strict';

/*
 *  Factory for sending broadcast messages
 *  @name backlineAdminPlus.factory:BroadcastService
 *  @ngInject
 */
function BroadcastService( $log, $q, $resource, TokenService, BacklineAPI ) {

  var BroadcastService = {};

  var _broadcastResource = $resource('', { id: '@id' }, {
    bots: {
      url: BacklineAPI + '/bot/broadcast',
      method: 'GET'
    },

    add: {
      url: BacklineAPI + '/bot',
      method: 'POST'
    },

    edit: {
      url: BacklineAPI + '/bot/:id',
      method: 'PUT'
    },

    history: {
      url: BacklineAPI + '/bot/:id/broadcast/messages',
      method: 'GET'
    },

    send: {
      url: BacklineAPI + '/bot/:id/broadcast/send',
      method: 'POST'
    }
  });

  /*
   *  Get broadcast bots for specified org
   */
  BroadcastService.bots = function ( org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_broadcastResource.bots, {
      org_id: org.id
    }, function ( response ) {

      deferred.resolve(response.bots);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Create a broadcast bot with supplied params
   */
  BroadcastService.add = function ( org, bot ) {
    var deferred = $q.defer();

    angular.extend(bot, {
      org_id: org.id
    });

    TokenService.wrapServiceCall(_broadcastResource.add, bot, function ( response ) {

      deferred.resolve(response.bot);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Edit the supplied broadcast bot
   */
  BroadcastService.edit = function ( bot ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_broadcastResource.edit, bot, function ( response ) {

      deferred.resolve(response.bot);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get the past message history for the broadcast bot with supplied id
   */
  BroadcastService.history = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_broadcastResource.history, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.messages);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Send a broadcast using the bot with id supplied with the message params specified
   */
  BroadcastService.send = function ( bot, params ) {
    var deferred = $q.defer();

    angular.extend(params, {
      id: bot
    });

    TokenService.wrapServiceCall(_broadcastResource.send, params, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  return BroadcastService;
}

angular.module('backlineAdminPlus')
  .factory('BroadcastService', BroadcastService);
;
'use strict';

/*
 *  Factory for managing chat archives
 *  @name backlineAdminPlus.factory:ChatService
 *  @ngInject
 */
function ChatService( $log, $q, $resource, TokenService, BacklineAdminAPI ) {

  var ChatService = {};

  var _chatsResource = $resource(BacklineAdminAPI + '/chats', { id: '@id' }, {
    list: {
      method: 'GET'
    },

    show: {
      url: BacklineAdminAPI + '/chats/:id',
      method: 'GET'
    },

    messages: {
      url: BacklineAdminAPI + '/chats/:id/messages',
      method: 'GET'
    },

    view: {
      url: BacklineAdminAPI + '/chats/:id/view',
      method: 'PUT'
    }
  });

  /*
   *  Search for chats matching the given params
   */
  ChatService.search = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_chatsResource.list, params, function ( response ) {

      deferred.resolve({
        chats: response.chats,
        pages: response.pages
      });

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get a chat with supplied id
   */
  ChatService.show = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_chatsResource.show, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.chat);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get messages for the supplied chat for params
   */
  ChatService.messages = function ( chat, params ) {
    var deferred = $q.defer();

    angular.extend(params, {
      id: chat
    });

    TokenService.wrapServiceCall(_chatsResource.messages, params, function ( response ) {

      deferred.resolve({
        messages: response.messages,
        pages: response.pages
      });

      ChatService.view(chat);

    }, function ( error ) {
      deferred.reject('An error ocurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Send a view notification for auditing
   */
  ChatService.view = function ( chat ) {
    TokenService.wrapServiceCall(_chatsResource.view, {
      id: chat
    }, function ( response ) {}, function ( error ) {});
  };

  /*
   *  Navigate to the location of the given file attachment
   */
  ChatService.openAttachment = function ( attachment ) {

    TokenService.openSecureFileLocation(attachment);
  };

  /*
   *  Navigate to the export api link, to download the pdf
   */
  ChatService.exportPDF = function ( id ) {

    TokenService.openSecureFileLocation('/api/enterprise/chats/' + id + '/pdf');
  };

  return ChatService;
}

angular.module('backlineAdminPlus')
  .factory('ChatService', ChatService);
;
'use strict';

/*
 *  Factory for displaying a confirmation dialog for certain actions
 *  @name backlineAdminPlus.factory:ConfirmationService
 *  @ngInject
 */
function ConfirmationService( $rootScope ) {

  var ConfirmationService = {};

  // Event fired when modal is ready
  ConfirmationService.fireConfirm = 'ConfirmationService.fireConfirm';

  /*
   *  Fire a confirmation modal with given params
   */
  ConfirmationService.confirm = function ( params ) {
    $rootScope.$broadcast(ConfirmationService.fireConfirm, params);
  };

  return ConfirmationService;
}

angular.module('backlineAdminPlus')
  .factory('ConfirmationService', ConfirmationService);
;
'use strict';

/*
 *  Factory for managing dashboards
 *  @name backlineAdminPlus.factory:DashboardService
 *  @ngInject
 */
function DashboardService( $rootScope, $q, $filter, $resource, TokenService, ErrorService, BacklineAdminAPI, Time ) {

  var DashboardService = {};

  var _dashboardResource = $resource('', {}, {
    chats: {
      url: BacklineAdminAPI + '/dashboard/chats-by-type',
      method: 'GET'
    },

    usage: {
      url: BacklineAdminAPI + '/dashboard/usage',
      method: 'GET'
    },

    average: {
      url: BacklineAdminAPI + '/dashboard/average-daily-chats',
      method: 'GET'
    },

    devices: {
      url: BacklineAdminAPI + '/dashboard/device-type-cumulative',
      method: 'GET'
    },

    groups: {
      url: BacklineAdminAPI + '/dashboard/group-type',
      method: 'GET'
    }
  });

  DashboardService.firstLoggedIn = true;

  /*
   *  Get all dashboard charts based on provided info
   */
  DashboardService.charts = function ( org, endDate, startDate ) {
    var deferred = $q.defer();

    // set default dates to (2 weeks ago - now) if non provided
    endDate = endDate || new Date();
    startDate = startDate || new Date(+new Date() - Time.weeks(6));

    // change date format to YYYY-MM-DD
    endDate = $filter('date')(endDate, 'yyyy-M-d');
    startDate = $filter('date')(startDate, 'yyyy-M-d');

    // package params for each request
    var params = {
      org_id: org,
      start_date: startDate,
      end_date: endDate,
      auth_token: TokenService.getAuthToken()
    };

    // gather all requests needed to get all charts
    // get $promise explicitly because of the strange way $resource returns when not deferred
    var requests = {
      chats: _dashboardResource.chats(params).$promise,
      usage: _dashboardResource.usage(params).$promise,
      average: _dashboardResource.average(params).$promise,
      groups: _dashboardResource.groups(params).$promise,

      /* devices doesnt take dates */
      devices: _dashboardResource.devices(params).$promise
    };

    // wrap all requests into one promise
    $q.all(requests).then(function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {

      if (error.status == 401) {
        TokenService.logout();
        return;
      }

      ErrorService.handle(error);
      deferred.reject('An error has occurred');
    });

    return deferred.promise;
  };

  return DashboardService;
}

angular.module('backlineAdminPlus')
  .factory('DashboardService', DashboardService);
;
'use strict';

/*
 *  Factory for logging api calls and related errors
 *  @name backlineAdminPlus.factory:DebugService
 *  @ngInject
 */
function DebugService( $log, ServerEnv, DebugTypes, SanitizedFields, BacklineAPI ) {

  var DebugService = {},
        _sanitizeOutput = ServerEnv == 'production';

  // store logs for display later
  DebugService.logs = [];

  // console output defaults to false on production, otherwise defaults to true
  DebugService.logToConsole = ServerEnv != 'production';

  // return a string to mask sensitive fields
  function _outputMask() {

    // for now, return 16 x's as a placeholder
    return 'xxxxxxxxxxxxxxxx';
  }

  // sanitize potential PHI or sensitive information in debug output
  function _sanitize( params ) {

    // if sanitize output flag is not set, just return params back untouched
    if (!_sanitizeOutput) {
      return params;
    }

    var output = angular.copy(params);

    angular.forEach(params, function ( value, key ) {

      // mask if we get a match in our filtered parameters list, and value is not null or undefined
      if (SanitizedFields.indexOf(key) > -1 && value) {

        value = _outputMask();
      }

      // reset this value in output
      this[key] = value;

    }, output);

    return output;
  }

  /*
     *  Toggles console output of all logs received
     */
  DebugService.toggleConsoleOutput = function () {

    DebugService.logToConsole = !DebugService.logToConsole;
  };

  /*
     *  Logs that a service call was made, prior to knowledge about success or failure
     */
  DebugService.logServiceCall = function ( response ) {

    // get response data
    var status = response.status,
      url = response.config.url,
      method = response.config.method,
      date = new Date().toISOString(),
      code = status + ' | ' + response.statusText;

    // sanitize outbound parameters
    var parameters = _sanitize(response.config.data || response.config.params);

    // add to logs
    DebugService.logs.push({
      url: url,
      params: parameters,
      method: method,
      date: date,
      status: status,
      code: code,
      error: false,
      type: DebugTypes.service
    });

    // log to console if enabled
    if (DebugService.logToConsole) {
      $log.debug('[' + date + ']', method, url, parameters, code);
    }
  };

  /*
     *  Logs an error with a service call, reported by ErrorService
     */
  DebugService.reportServiceError = function ( error ) {

    // get request data
    var status = error.status == -1 ? 500 : error.status,
            url = error.config.url.replace(BacklineAPI, ''),
            method = error.config.method,
      date = new Date().toISOString(),
            code = status;

    // get error message from server, if any
    if (error.data) {
      code = error.data.error_code + ' | ' + error.data.error_text;
    }

    // sanitize outbound parameters
    var parameters = _sanitize(error.config.data || error.config.params);

    // add to logs
    DebugService.logs.push({
      url: url,
      params: parameters,
      method: method,
      date: date,
      status: status,
      code: code,
      error: true,
      type: DebugTypes.service
    });

    // log to console if enabled
    if (DebugService.logToConsole) {
      $log.error('[' + date + ']', method, url, parameters, status, code);
    }
  };

  /*
   *  Logs any state change made in app
   */
  DebugService.logStateChange = function ( event, state, params ) {

    // gather state information
    var id = (typeof params == 'object') ? params.id : null,
      name = state.name,
      date = new Date().toISOString();

    // dont log debug state changes
    if (name == 'admin.debug') { return; }

    // add to logs
    DebugService.logs.push({
      id: id,
      message: 'Navigated to ' + name,
      date: date,
      error: false,
      type: DebugTypes.state
    });

    // log to console if enabled
    if (DebugService.logToConsole) {
      $log.debug('[' + date + '] Navigated to', name, id);
    }
  };

  /*
   *  Reports an error while transitioning state
   */
  DebugService.reportStateChangeError = function ( event, state, params, error ) {

    // gather state information
    var id = (typeof params == 'object') ? params.id : null,
      name = state.name,
      date = new Date().toISOString();

    // add to logs
    DebugService.logs.push({
      id: id,
      message: 'Navigation failed on ' + name,
      date: date,
      error: true,
      type: DebugTypes.state
    });

    // log to console and raise error if console logging enabled
    if (DebugService.logToConsole) {
      $log.error('[' + date + '] Navigation to', name, id, 'raised', error.message);
      throw error;
    }
  };

  return DebugService;
}

angular.module('backlineAdminPlus')
    .factory('DebugService', DebugService);
;
'use strict';

/*
 *  Factory for managing departments
 *  @name backlineAdminPlus.factory:DepartmentService
 *  @ngInject
 */
function DepartmentService( $log, $q, $resource, TokenService, ErrorService, BacklineAdminAPI ) {

  var DepartmentService = {};

  var _departmentsResource = $resource(BacklineAdminAPI + '/orgs/:org_id/departments', { org_id: '@org_id', department_id: '@department_id', user_id: '@user_id' }, {
    list: {
      method: 'GET'
    },

    add: {
      url: BacklineAdminAPI + '/orgs/:org_id/departments',
      method: 'POST'
    },

    update: {
      url: BacklineAdminAPI + '/orgs/:org_id/departments/:department_id',
      method: 'PUT'
    },

    remove: {
      url: BacklineAdminAPI + '/orgs/:org_id/departments/:department_id',
      method: 'DELETE'
    },

    addUser: {
      url: BacklineAdminAPI + '/orgs/:org_id/departments/:department_id/users/:user_id',
      method: 'POST'
    },

    removeUser: {
      url: BacklineAdminAPI + '/orgs/:org_id/departments/:department_id/users/:user_id',
      method: 'DELETE'
    }
  });

  /*
   *  Add a new department to the org
   */
  DepartmentService.add = function ( org, department ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.add, {
      departments: department.name,
      org_id: org.id

    }, function ( response ) {
      deferred.resolve(response.departments[0]);
    }, function ( error ) {

      if (error.status == 409) {
        ErrorService.show(409, {
          title: 'Department Exists',
          message: 'A department with that name already exists'
        });
        deferred.reject();
      } else {
        deferred.reject('An error occurred');
      }
    });

    return deferred.promise;
  };

  /*
   *  List all departments that match the given params
   */
  DepartmentService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.list, params, function ( response ) {
      deferred.resolve({
        departments: response.departments,
        pages: response.pages
      });
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  List all departments that match the given params
   */
  DepartmentService.dropdown = function ( orgId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.list, {
      forPulldown: true,
      org_id: orgId,
      per_page: 1000
    }, function ( response ) {
      deferred.resolve(response.departments);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Perform an update with no error catching
   *  Used for the on page toggles
   */
  DepartmentService.toggle = function ( department ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.update, {
      org_id: department.org_id,
      department_id: department.id,
      feed_id: department.group.feed_id,
      feed_type: department.group.feed_type,
      soft_delete_enabled: department.group.soft_delete_enabled,
      enabled: department.group.enabled,
      idm: department.group.allow_to_create_distribution_list,
      shortname: department.group.short_name
    }, function ( response ) {
      deferred.resolve(response.department);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Perform an update to a department
   */
  DepartmentService.edit = function ( department, newName, newIdm, newShortname ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.update, {
      org_id: department.org_id,
      department_id: department.id,
      new_name: newName,
      allow_to_create_distribution_list: newIdm,
      short_name: newShortname
    }, function ( response ) {
      deferred.resolve(response.department);
    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, false);

    return deferred.promise;
  };

  /*
   *  Perform an remove on a department
   */
  DepartmentService.remove = function ( department ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.remove, {
      org_id: department.org_id,
      department_id: department.id

    }, function ( response ) {
      deferred.resolve(response.department);
    }, function ( error ) {

      if (error.status == 403) {
        ErrorService.show(403, {
          title: 'Forbidden',
          message: error.data.error_text
        });
        deferred.reject();
      } else {
        deferred.reject('An error occurred');
      }
    }, false);

    return deferred.promise;
  };

  /*
   *  Subscribe a user to a department
   */
  DepartmentService.addUser = function ( department, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.addUser, {
      org_id: department.org_id,
      department_id: department.id,
      user_id: userId

    }, function ( response ) {
      deferred.resolve(response.users);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Unsubscribe a user from a department
   */
  DepartmentService.removeUser = function ( department, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_departmentsResource.removeUser, {
      org_id: department.org_id,
      department_id: department.id,
      user_id: userId

    }, function ( response ) {
      deferred.resolve(response.users);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return DepartmentService;
}

angular.module('backlineAdminPlus')
  .factory('DepartmentService', DepartmentService);
;
'use strict';

/*
 *  Factory for displaying error messages
 *  @name backlineAdminPlus.factory:ErrorService
 *  @ngInject
 */
function ErrorService( $rootScope, DebugService, DefaultErrors ) {

  var ErrorService = {},
    _errorActive = false,
    _lastError;

  // Event fired when modal is ready
  ErrorService.fireModal = 'ErrorService.fireModal';

  // Defaults for all errors received
  ErrorService.defaultError = {
    data: {
      error_text: 'Unknown Error',
      error_code: 'Unknown Code',
      response_text: 'Unknown Response'
    },
    config: {
      url: 'Unknown URL',
      params: {},
      method: 'UKN'
    }
  };

  // removes all null or undefined values for an object
  function _prune( obj ) {

    angular.forEach(obj, function ( value, prop ) {
      if (angular.isUndefined(value) || value === null) {

        delete obj[prop];

      } else if (angular.isObject(value)) {

        obj[prop] = _prune(value);
      }
    });

    return obj;
  }

  /*
   *  Automatically handles an error with given error info and status
   *  Causes an error modal to be displayed to the user
   *  If error includes service data, the information is stored as the last service error
   */
  ErrorService.handle = function ( error, status ) {

    var params = {};

    // add error properties to default error
    error = angular.merge({}, ErrorService.defaultError, _prune(error));

    // Populate default data based on status
    switch (status) {

      case 500:
      case -1:
        params = DefaultErrors.internalServerError;
        break;

      case 403:
        params = DefaultErrors.forbidden;
        break;

      case 404:
        params = DefaultErrors.notFound;
        break;

      case 401:
        params = DefaultErrors.unauthorized;
        break;

      default:
        params.title = 'Unknown Error';
        params.message = error.data.error_text;
        params.status = status;
        break;
    }

    // Add error data to modal params
    angular.extend(params, {
      url: error.config.url,
      params: angular.toJson(error.config.params),
      method: error.config.method,
      error: error.data.error_text,
      code: error.data.error_code + ' ' + error.data.response_text
    });

    // Store this error as last service error recorded
    _lastError = params;

    // Give latest error to debug service
    DebugService.reportServiceError(error);

    // Fire modal event
    $rootScope.$broadcast(ErrorService.fireModal, params);
  };

  /*
   *  Get last service error that came through `handle`
   */
  ErrorService.latest = function () {
    return _lastError;
  };

  /*
   *  Used to show a custom error message
   */
  ErrorService.show = function ( status, config ) {

    var params = {
      status: status,
      title: config.title,
      message: config.message
    };

    $rootScope.$broadcast(ErrorService.fireModal, params);
  };

  return ErrorService;
}

angular.module('backlineAdminPlus')
  .factory('ErrorService', ErrorService);
;
'use strict';

/*
 *  Factory for managing groups
 *  @name backlineAdminPlus.factory:GroupService
 *  @ngInject
 */
function GroupService( $log, $q, $resource, TokenService, ErrorService, BacklineAdminAPI ) {

  var GroupService = {};

  var _groupsResource = $resource(BacklineAdminAPI + '/groups', { id: '@id' }, {
    list: {
      method: 'GET'
    },

    add: {
      method: 'POST'
    },

    update: {
      url: BacklineAdminAPI + '/groups/:id',
      method: 'PUT'
    },

    addUser: {
      url: BacklineAdminAPI + '/groups/:id/users/subscribe',
      method: 'POST'
    },

    removeUser: {
      url: BacklineAdminAPI + '/groups/:id/users/unsubscribe',
      method: 'DELETE'
    }
  });

  /*
   *  Add a new group to the org
   */
  GroupService.add = function ( org, group ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.add, {
      feed_name: group.feed_name,
      description: group.description,
      feed_type: group.feed_type,
      org_id: org.id

    }, function ( response ) {
      deferred.resolve(response.group);
    }, function ( error ) {

      if (error.status == 422) {
        ErrorService.show(422, {
          title: 'Group Exists',
          message: 'A group with that name already exists'
        });
        deferred.reject();
      } else {
        deferred.reject('An error occurred');
      }
    });

    return deferred.promise;
  };

  /*
   *  List all groups that match the given params
   */
  GroupService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.list, params, function ( response ) {
      deferred.resolve({
        groups: response.groups,
        pages: response.pages
      });
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  List all groups that match the given params
   */
  GroupService.dropdown = function ( orgId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.list, {
      forPulldown: true,
      org_id: orgId,
      per_page: 1000
    }, function ( response ) {
      deferred.resolve(response.groups);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Perform an update with no error catching
   *  Used for the on page toggles
   */
  GroupService.toggle = function ( group ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.update, {
      id: group.id,
      feed_type: group.feed_type,
      soft_delete_enabled: group.soft_delete_enabled,
      enabled: group.enabled
    }, function ( response ) {
      deferred.resolve(response.group);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Perform an update to a group
   */
  GroupService.edit = function ( group ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.update, {
      id: group.id,
      feed_name: group.feed_name,
      description: group.description

    }, function ( response ) {
      deferred.resolve(response.group);
    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, false);

    return deferred.promise;
  };

  /*
   *  Subscribe a user to a group
   */
  GroupService.addUser = function ( id, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.addUser, {
      id: id,
      user_id: userId

    }, function ( response ) {
      deferred.resolve(response.group.users);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Unsubscribe a user from a group
   */
  GroupService.removeUser = function ( id, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_groupsResource.removeUser, {
      id: id,
      user_id: userId

    }, function ( response ) {
      deferred.resolve(response.group.users);
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return GroupService;
}

angular.module('backlineAdminPlus')
  .factory('GroupService', GroupService);
;
'use strict';

/**
 *  Service for the LDAP business logic
 *  @class
 *  @name backlineAdminPlus.service:LdapService
 *  @ngInject
 */
function LdapService(BacklineAdminAPI, TokenService, $http) {

  /**
   * @private
   * @const
   */
  var LdapService = this;

  /**
   * Returns a default LDAP username based on the email of an user.
   *
   * @name backlineAdminPlus.service:LdapService#createDefaultUsername
   * @function
   * @param email {string}
   * @return {string}
   */
  LdapService.createDefaultUsername = createDefaultUsername;

  /**
   * Returns a default LDAP username based on the email of an user.
   *
   * @name backlineAdminPlus.service:LdapService#createDefaultUsername
   * @function
   * @param host {string}
   * @param port {number}
   * @param username {string}
   * @param password {string}
   * @returns {Object} ping - The ping details from the connection attempt
   * @returns {boolean} ping.connected - if the ping successfully connected
   * @returns {string} ping.error - The error if the ping did not connect, connected == false
   * @returns {number} ping.ping - the round-trip time the connection attempt took
   */
  LdapService.ping = ping;

  /**
   * @private
   * @ignore
   * @param email {string}
   * @return {string}
   */
  function createDefaultUsername(email) {
    if (!email) {
      return '';
    }

    var emailParts;

    emailParts = email.split('@', 1);

    return emailParts.length === 0 ? '' : emailParts[0];
  }

  /**
   * @private
   * @ignore
   */
  function ping (host, port, username, password, base) {
    if (!base) {
      return {
        connected: false,
        error: 'Invalid base'
      };
    }
    if (!host) {
      return {
        connected: false,
        error: 'Invalid host'
      };
    }
    if (!port) {
      return {
        connected: false,
        error: 'Invalid port'
      };
    }
    if (!username) {
      return {
        connected: false,
        error: 'Invalid username'
      };
    }
    if (!password) {
      return {
        connected: false,
        error: 'Invalid port'
      };
    }

    var response = {
      connected: null
    };

    $http.post(BacklineAdminAPI + '/system/ldap/ping', {
      base: base,
      host: host,
      port: port,
      canonical_name: username,
      password: password
    }, {
      headers: {'X-Auth-Token': TokenService.getAuthToken()}
    }).then(
      function (data) {
        data = data.data;
        response.connected = data.connected;
        response.error = data.error;
        response.ping = data.ping;
      },
      function (error) {
        response.connected = false;
        response.error = '(' + error.data.error_code + ') ' + error.data.error_text;
      });

    return response;
  }
}

angular.module('backlineAdminPlus')
  .service('LdapService', LdapService);
;
'use strict';

/*
 *  Factory for managing user authentication
 *  @name backlineAdminPlus.factory:LocalStorageService
 *  @ngInject
 */
function LocalStorageService( $log, $cookies ) {

  var LocalStorageService = {},
    _enabled;

  /*
   *  Generate a random guid
   */
  function _guid() {
    function section() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return section() + section() + '-' + section() + '-' + section() + '-' +
      section() + '-' + section() + section() + section();
  }

  /*
   *  Returns whether local storage is enabled on this browser
   */
  LocalStorageService.enabled = function () {

    if (angular.isDefined(_enabled)) { return _enabled; }

    try {

      if (typeof window.localStorage == 'object') {

        window.localStorage.setItem('testLS', 1);
        window.localStorage.removeItem('testLS');

        return (_enabled = true);

      } else {

        return (_enabled = false);
      }

    } catch ( e ) {

      $log.error('Local Storage is not enabled!');

      window.alert('Local Storage is not enabled on your device. This lack of this feature may cause improper functionality. Please contact support for more information.');

      return (_enabled = false);
    }
  };

  /*
   *  Returns the GUID stored if there is one, if not creates and stores a new one
   */
  LocalStorageService.guid = function () {

    var stored = LocalStorageService.getString('_guid');

    if (stored) { return stored; }

    var uuid = _guid();

    LocalStorageService.set('_guid', uuid);

    return uuid;
  };

  /*
   *  Sets an item to local storage, or cookie
   */
  LocalStorageService.set = function ( key, value ) {

    if (LocalStorageService.enabled()) {

      window.localStorage.setItem(key, value);

    } else {

      $cookies.put(key, value);
    }
  };

  /*
   *  Sets an item as json to local storage, or cookie
   */
  LocalStorageService.setObj = function ( key, value ) {

    LocalStorageService.set(key, angular.toJson(value));
  };

  /*
   *  Gets an item from local storage or cookie without parsing
   */
  LocalStorageService.getString = function ( key ) {
    var value;

    if (LocalStorageService.enabled()) {

      value = window.localStorage.getItem(key);

    } else {

      value = $cookies.get(key);
    }

    if (value === null || value === 'null' || value === '' || value === 'undefined') {
      value = undefined;
    }

    return value;
  };

  /*
   *  Gets an item from local storage or cookie and parses to object
   */
  LocalStorageService.getObj = function ( key ) {

    var value = LocalStorageService.getString(key);

    if (value) {
      return angular.fromJson(value);
    }
  };

  /*
   *  Gets an item from local storage or cookie and parses to number
   */
  LocalStorageService.getInt = function ( key ) {

    var value = LocalStorageService.getString(key);

    if (value) {
      return Number(value);
    }
  };

  /*
   *  Removes an item from local storage or cookie
   */
  LocalStorageService.remove = function ( key ) {

    if (LocalStorageService.enabled()) {

      window.localStorage.removeItem(key);

    } else {

      $cookies.remove(key);
    }
  };

  return LocalStorageService;
}

angular.module('backlineAdminPlus')
  .factory('LocalStorageService', LocalStorageService);
;
'use strict';

/**
 *  Service for the LDAP business logic
 *  @class
 *  @name backlineAdminPlus.service:MedHxService
 *  @ngInject
 */
function MedHxService(BacklineAdminAPI, TokenService, $http) {

  /**
   * @private
   * @const
   */
  var MedHxService = this;

  /**
   * Returns a default LDAP username based on the email of an user.
   *
   * @name backlineAdminPlus.service:MedHxService#createDefaultUsername
   * @function
   * @param host {string}
   * @param port {number}
   * @param username {string}
   * @param password {string}
   * @returns {Object} ping - The ping details from the connection attempt
   * @returns {boolean} ping.connected - if the ping successfully connected
   * @returns {string} ping.error - The error if the ping did not connect, connected == false
   * @returns {number} ping.ping - the round-trip time the connection attempt took
   */
  MedHxService.ping = ping;

  /**
   * @private
   * @ignore
   */
  function ping (org, username, password) {
    if (!org) {
      return {
        connected: false,
        error: 'Invalid org'
      };
    }
    if (!username) {
      return {
        connected: false,
        error: 'Invalid username'
      };
    }
    if (!password) {
      return {
        connected: false,
        error: 'Invalid port'
      };
    }

    var response = {
      connected: null
    };

    $http.post(BacklineAdminAPI + '/system/medhx/ping', {
      org_id: org.id,
      username: username,
      password: password
    }, {
      headers: {'X-Auth-Token': TokenService.getAuthToken()}
    }).then(
      function (data) {
        data = data.data;
        response.status = data.status;
        response.error = data.error;
        response.access_token = data.access_token;
      },
      function (error) {
        response.status = error.data.status;
        response.error = error.data.error;
      });

    return response;
  }
}

angular.module('backlineAdminPlus')
  .service('MedHxService', MedHxService);
;
'use strict';

/**
 *  Service for MultiOrg business logic to denormalize User objects
 *  @class
 *  @name backlineAdminPlus.service:MultiOrgService
 *  @ngInject
 */
function MultiOrgService() {

  /**
   * @private
   * @const
   */
  var MultiOrgService = this;

  /**
   * Normalize the given user to set their user.primaryOrg and user.org to their primary org.
   *
   * @name backlineAdminPlus.service:MultiOrgService#normalizeUser
   * @function
   * @template
   * @param user
   * @return user
   */
  MultiOrgService.normalizeUser = normalizeUser;

  /**
   * Find the primary org given a list of organizations
   *
   * @name backlineAdminPlus.service:MultiOrgService#findPrimaryOrg
   * @function
   * @template T
   * @param orgs {Array.<T>}
   * @return {T}
   */
  MultiOrgService.findPrimaryOrg = findPrimaryOrg;

  /**
   * @private
   * @ignore
   * @param user
   * @return user
   */
  function normalizeUser(user) {

    user.primaryOrg = MultiOrgService.findPrimaryOrg(user.orgs);
    if (user.primaryOrg === null || user.primaryOrg.is_primary === false) {
      console.log('User id, ' + user.id + ' does not have a primary org');
    }

    if (user.primaryOrg !== null) {
      user.org = user.primaryOrg.org;
    }

    return user;
  }

  /**
   * @private
   * @ignore
   * @template T
   * @param orgs {Array.<T>}
   * @return {T}
   */
  function findPrimaryOrg(orgs) {
    if (!orgs) {
      return null;
    }

    for (var i = 0; i < orgs.length; i++) {
      if (orgs[i].is_primary === true) {
        return orgs[i];
      }
    }

    if (orgs.length > 0) {
      return orgs[0];
    }

    return null;
  }
}

angular.module('backlineAdminPlus')
  .service('MultiOrgService', MultiOrgService);
;
'use strict';

/**
 *  Factory for storing an network context across the entire application
 *  @name backlineAdminPlus.factory:NetworkSelectorService
 *  @ngInject
 */
function NetworkSelectorService($rootScope, TokenService, PermissionsService, LocalStorageService) {

  var NetworkSelectorService = {},
    _selectedNetwork;

  // Event for when network changes
  NetworkSelectorService.networkChanged = 'NetworkSelectorService.networkChanged';

  /*
   *  Gets the selected network
   */
  NetworkSelectorService.getNetwork = function () {

    // select cached network if you can choose
    if (!_selectedNetwork && PermissionsService.canSearchOrgs) {
      _selectedNetwork = LocalStorageService.getObj('_selectedNetwork');
    }

    // fallback to default network
    if (!_selectedNetwork) {
      NetworkSelectorService.select(TokenService.getCachedUser().org.networks[0]);
    }

    return _selectedNetwork;
  };

  /*
   *  Change the selected network
   */
  NetworkSelectorService.select = function (network) {
    if (!network) {
      this.getNetwork();
      return;
    }

    // broadcast change
    $rootScope.$broadcast(NetworkSelectorService.networkChanged, network);

    _selectedNetwork = {
      id: network.id,
      name: network.name,
      display_name: network.name + ' (' + network.backline_id + ')',
      soft_delete_groups: network.soft_delete_groups
    };

    // store in local storage
    LocalStorageService.setObj('_selectedNetwork', _selectedNetwork);
  };

  return NetworkSelectorService;
}

angular.module('backlineAdminPlus')
  .factory('NetworkSelectorService', NetworkSelectorService);
;
'use strict';

/*
 *  Factory for managing networks
 *  @name backlineAdminPlus.factory:NetworkService
 *  @ngInject
 */
function NetworkService( $log, $q, $resource, TokenService, BacklineAdminAPI ) {

  var NetworkService = {};

  var _networksResource = $resource(BacklineAdminAPI + '/networks', { id: '@id', suborg: '@suborg' }, {
    list: {
      method: 'GET'
    },

    add: {
      method: 'POST'
    },

    types: {
      method: 'GET',
      url: BacklineAdminAPI + '/networks/types',
      headers: {
        'X-Auth-Token': function () {
          return TokenService.getAuthToken();
        }
      },
      cache: true
    },

    show: {
      url: BacklineAdminAPI + '/networks/:id',
      method: 'GET'
    },

    update: {
      url: BacklineAdminAPI + '/networks/:id',
      method: 'PUT'
    },

    suborgs: {
      url: BacklineAdminAPI + '/networks/:id/orgs?minimal=true',
      method: 'GET'
    },

    addSuborg: {
      url: BacklineAdminAPI + '/networks/:id/orgs',
      method: 'POST'
    },

    moveSuborg: {
      url: BacklineAdminAPI + '/orgs/:id/move?network_id_to=:network_id_to',
      method: 'PUT'
    },

    removeSuborg: {
      url: BacklineAdminAPI + '/networks/:id/orgs/:suborg',
      method: 'DELETE'
    },

    deleteNetwork: {
      url: BacklineAdminAPI + '/networks/:id',
      method: 'DELETE'
    }
  });

  /*
   *  List all networks
   */
  NetworkService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.list, params, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get all network types
   */
  NetworkService.types = function () {
    var types = _networksResource.types();

    if (types.$resolved) {
      return types;
    }

    // Defaults
    return angular.extend(types, {
      1: 'Partner Network',
      2: 'Parent/Child Network',
      3: 'Customer Support Network'
    });
  };

  /*
   *  Get all business type options
   */
  NetworkService.business_type_options = function () {
    return {
      0: 'Retail',
      1: 'Partner'
    };
  };

  /*
   *  Get auto-cross options
   */
  NetworkService.searchability_options = function () {
    return {
      0: 'Off',
      1: 'On',
      2: 'Control to Sub-Orgs Only'
    };
  };

  /*
   *  Get external patient data-source options
   */
  NetworkService.external_patient_data_source_options = function () {
    return {
      0: 'None',
      1: 'Hospidirect'
    };
  };

  /**
   *  Parse external patient data-source from enum name
   */
  NetworkService.external_patient_data_source_id = function (name) {

    var options = NetworkService.external_patient_data_source_options();

    for (var i in options) {
      if (options[i] == name) {
        return i;
      }
    }
    return 0;
  };

  /*
   *  Create a network
   */
  NetworkService.add = function ( network ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.add, {
      name: network.name,
      control_org_id: network.control_org_id,
      type: network.type,
      business_type: network.business_type,
      searchability: network.searchability,
      external_patient_data_source: network.external_patient_data_source
    }, function ( response ) {

      deferred.resolve(response.network);

    }, function ( error ) {
      deferred.reject(error.data.response_text);
    });

    return deferred.promise;
  };

  /*
   *  Get details for the network with given id
   */
  NetworkService.show = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.show, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.network);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Update network name
   */
  NetworkService.update = function ( network ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.update, {
      id: network.id,
      name: network.name,
      searchability: network.searchability,
      external_patient_data_source: network.external_patient_data_source,
      business_type: network.business_type
    }, function ( response ) {

      deferred.resolve(response.network);

    }, function ( error ) {
      deferred.reject(error.data.response_text);
    });

    return deferred.promise;
  };

  /*
   *  List suborgs for given network. Does not include control org
   */
  NetworkService.suborgs = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.suborgs, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.orgs);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Add a suborg to the network
   */
  NetworkService.addSuborg = function ( id, suborg ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.addSuborg, {
      id: id,
      org_id: suborg
    }, function ( response ) {

      deferred.resolve(response.orgs);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
 *  Move a suborg to the network
 */
  NetworkService.moveSuborg = function ( id, suborg ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.moveSuborg, {
      id: suborg,
      network_id_to: id
    }, function ( response ) {

      deferred.resolve(response.orgs);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Remove a suborg from the network
   */
  NetworkService.removeSuborg = function ( id, suborg ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.removeSuborg, {
      id: id,
      suborg: suborg
    }, function ( response ) {

      deferred.resolve(response.orgs);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Delete this network
   */
  NetworkService.deleteNetwork = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_networksResource.deleteNetwork, {
      id: id
    }, function ( response ) {

      deferred.resolve();

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return NetworkService;
}

angular.module('backlineAdminPlus')
  .factory('NetworkService', NetworkService);
;
'use strict';

/**
 *  Factory for storing an org context across the entire application
 *  @name backlineAdminPlus.factory:OrgSelectorService
 *  @ngInject
 */
function OrgSelectorService( $rootScope, TokenService, PermissionsService, LocalStorageService ) {

  var OrgSelectorService = {},
    _selectedOrg;

  // Event for when org changes
  OrgSelectorService.orgChanged = 'OrgSelectorService.orgChanged';

  /*
   *  Gets the selected org
   */
  OrgSelectorService.getOrg = function () {

    // select cached org if you can choose
    if (!_selectedOrg && PermissionsService.canSearchOrgs) {
      _selectedOrg = LocalStorageService.getObj('_selectedOrg');
    }

    // fallback to default org
    if (!_selectedOrg) {
      OrgSelectorService.select(TokenService.getCachedUser().org);
    }

    return _selectedOrg;
  };

  /*
   *  Change the selected org
   */
  OrgSelectorService.select = function ( org ) {

    // broadcast change
    $rootScope.$broadcast(OrgSelectorService.orgChanged, org);

    _selectedOrg = {
      id: org.id,
      name: org.name,
      display_name: org.name + ' (' + org.backline_id + ')',
      soft_delete_groups: org.soft_delete_groups
    };

    // store in local storage
    LocalStorageService.setObj('_selectedOrg', _selectedOrg);
  };

  return OrgSelectorService;
}

angular.module('backlineAdminPlus')
  .factory('OrgSelectorService', OrgSelectorService);
;
'use strict';

/*
 *  Factory for managing orgs
 *  @name backlineAdminPlus.factory:OrgService
 *  @ngInject
 */
function OrgService( $rootScope, $log, $q, $resource, TokenService, ErrorService, BacklineAdminAPI, BacklineAPI ) {

  var OrgService = {};

  var _orgsResource = $resource(BacklineAdminAPI + '/orgs', { id: '@id'}, {
    list: {
      method: 'GET'
    },

    add: {
      method: 'POST'
    },

    show: {
      url: BacklineAdminAPI + '/orgs/:id',
      method: 'GET'
    },

    users: {
      url: BacklineAdminAPI + '/users',
      method: 'GET'
    },

    update: {
      url: BacklineAdminAPI + '/orgs/:id',
      method: 'PUT'
    },

    departments: {
      url: BacklineAPI + '/orgs/:id/departments',
      method: 'GET'
    },

    linkOrgs: {
      url: BacklineAdminAPI + '/orgs/:id/link',
      method: 'POST'
    },

    unlinkOrgs: {
      url: BacklineAdminAPI + '/orgs/:id/unlink',
      method: 'POST'
    },

    types: {
      url: BacklineAdminAPI + '/orgs/types',
      method: 'GET',
      isArray: true
    },

    states: {
      url: BacklineAdminAPI + '/orgs/states',
      method: 'GET',
      isArray: true
    },

    inQueue: {
      headers: {
        'X-Auth-Token': function () {
          return TokenService.getAuthToken();
        }
      },
      isArray: true,
      method: 'GET',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        return json.orgs;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/orgs'
    },
    
    adSync: {
      headers: {
        'X-Auth-Token': function () {
          return TokenService.getAuthToken();
        }
      },
      url: BacklineAdminAPI + '/orgs/:id/ad_sync',
      method: 'POST'
    }
  });

  /*
   *  Get a list of orgs matching the search params
   */
  OrgService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.list, params,
      function ( response ) {

        deferred.resolve(response);
      },
      function ( error ) {
        deferred.reject('An error occurred');
      }, true);

    return deferred.promise;
  };

  /*
   *  Add new org with attributes
   */
  OrgService.add = function ( org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.add, {
      org: org
    }, function ( response ) {

      deferred.resolve(response.org);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  List orgs in dropdown format
   */
  OrgService.dropdown = function (networkOnly) {
    var deferred = $q.defer();
    
    TokenService.wrapServiceCall(_orgsResource.list, {
      forPulldown: true,
      network_only: networkOnly
    }, function ( response ) {

      deferred.resolve(response.orgs);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  orgs that can submit to the given queue
   */
  OrgService.inQueue = function (queueId) {
    return _orgsResource.inQueue({ queue_id: queueId });
  };

  /*
   *  List orgs in dropdown format
   */
  OrgService.selectiveOrgsDropDown = function (parentOrg) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.list, {
      id: parentOrg.id,
      forPulldown: true,
      onlyCrossOrgSearchable: true
    }, function ( response ) {
      deferred.resolve(response.orgs);

    }, function ( error ) {
      if (error.status == 400 || error.status == 422) {
        deferred.reject(error.data.error_text);
      } else {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      }

    }, false);

    return deferred.promise;
  };

  /*
   *  Get information for an org with id
   */
  OrgService.getOrg = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.show, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.org);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get list of users for the org with id, matching search params
   */
  OrgService.users = function ( id, params ) {
    var deferred = $q.defer();

    angular.extend(params, {
      org_id: id
    });

    TokenService.wrapServiceCall(_orgsResource.users, params,
      function ( response ) {

        deferred.resolve(response);
      },
      function ( error ) {
        deferred.reject('An error occurred');
      }, true);

    return deferred.promise;
  };
  
  /*
   *  Run the AD sync job for the org
   */
  OrgService.adSync = function ( org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.adSync, {
      id: org.id
    }, function ( response ) {

      deferred.resolve(response.org);

    }, function ( error ) {
      if (error.status == 400 || error.status == 422) {
        deferred.reject(error.data.error_text);
      } else {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      }

    }, false);

    return deferred.promise;
  };
  
  /*
   *  Update the org with given attributes
   *  ID of org is included in attributes
   */
  OrgService.update = function ( org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.update, {
      id: org.id,
      org: org
    }, function ( response ) {

      deferred.resolve(response.org);

    }, function ( error ) {
      if (error.status == 400 || error.status == 422) {
        deferred.reject(error.data.error_text);
      } else {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      }

    }, false);

    return deferred.promise;
  };

  /*
   *  Update the org with given attributes
   *  ID of org is included in attributes
   */
  OrgService.unlinkOrgs = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.unlinkOrgs, {
      id: params.id,
      linked_org_id: params.linked_org_id

    }, function ( response ) {
      deferred.resolve(response.org);

    }, function ( error ) {
      if (error.status == 400 || error.status == 422) {
        deferred.reject(error.data.error_text);
      } else {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      }

    }, false);

    return deferred.promise;
  };

  /*
   *  Update the org with given attributes
   *  ID of org is included in attributes
   */
  OrgService.linkOrgs = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.linkOrgs, {
      id: params.id,
      linked_org_id: params.linked_org_id

    }, function ( response ) {
      deferred.resolve(response.org);

    }, function ( error ) {
      if (error.status == 400 || error.status == 422) {
        deferred.reject(error.data.error_text);
      } else {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      }

    }, false);

    return deferred.promise;
  };

  /*
   *  Get all active departments with their ids for this org
   */
  OrgService.active_departments = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.departments, {
      id: id,
      enabled: true
    }, function ( response ) {

      deferred.resolve(response.departments);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get all departments with their ids for this org
   */
  OrgService.types = function () {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.types, {

    }, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  OrgService.states = function () {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_orgsResource.states, {

    }, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /**
   * Returns if the current org given its type can change to the target type.
   *
   * @param currentType the org's current type_id
   * @param targetType the type.value which you are trying to change to
   * @return {boolean}
   */
  OrgService.typeChangeable = function (currentType, targetType) {
    if (currentType == 1 || currentType == 2) {
      return targetType == 1 || targetType == 2;
    }

    return currentType == targetType;
  };

  /*
   *  Request PDF of users
   */
  OrgService.exportUsers = function ( id ) {

    TokenService.openSecureFileLocation('/api/enterprise/orgs/' + id + '/export');
  };

  /*
   *  Get batch upload template
   */
  OrgService.downloadBatchTemplate = function () {

    window.open('https://s3.amazonaws.com/backline-enterprise/user-uploads/Akario+Backline+Batch+Upload+Template.csv');
  };

  /*
   *  URL to give AngularFileUploader for batch upload
   */
  OrgService.batchUploadUrl = TokenService.secureFileLocation('/api/enterprise/users/batch-add');

  return OrgService;
}

angular.module('backlineAdminPlus')
  .factory('OrgService', OrgService);
;
'use strict';

/*
 *  Factory for managing patients
 *  @name backlineAdminPlus.factory:PatientService
 *  @ngInject
 */
function PatientService( $log, $q, $resource, TokenService, BacklineAdminAPI ) {

  var PatientService = {};

  var _patientsResource = $resource(BacklineAdminAPI + '/patients', { id: '@id', user: '@user' }, {
    list: {
      method: 'GET'
    },

    add: {
      method: 'POST'
    },

    update: {
      url: BacklineAdminAPI + '/patients/:id',
      method: 'PUT'
    },

    addUser: {
      url: BacklineAdminAPI + '/patients/:id/users/:user/subscribe',
      method: 'POST'
    },

    removeUser: {
      url: BacklineAdminAPI + '/patients/:id/users/:user/unsubscribe',
      method: 'DELETE'
    }
  });

  PatientService.add = function ( org, patient ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_patientsResource.add, {
      org_id: org.id,
      lname: patient.lname,
      mname: patient.mname,
      fname: patient.fname,
      is_private: patient.is_private,
      dob: moment(patient.dob).utc(true).toISOString(),
      mrn: patient.mrn,
      gender: patient.gender,
      account_number: patient.account_number,
      status: patient.status,
      phone: patient.phone,
      admit_date: angular.isDate(patient.admit_date) ? moment(patient.admit_date).toISOString() : null,
      discharge_date: angular.isDate(patient.discharge_date) ? moment(patient.discharge_date).toISOString() : null,
      zip_code: patient.zip_code,
      location: patient.location,
      deceased: patient.deceased,
      hie_oid: patient.hie_oid
    }, function ( response ) {

      deferred.resolve(response.patient);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    });

    return deferred.promise;
  };

  PatientService.list = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_patientsResource.list, params, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  PatientService.update = function ( patient ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_patientsResource.update, {
      id: patient.id,
      lname: patient.lname,
      mname: patient.mname,
      fname: patient.fname,
      is_private: patient.is_private,
      active: patient.active,
      dob: moment(patient.dob).utc(true).toISOString(),
      mrn: patient.mrn,
      gender: patient.gender,
      account_number: patient.account_number,
      status: patient.status,
      phone: patient.phone,
      admit_date: angular.isDate(patient.admit_date) ? moment(patient.admit_date).toISOString() : null,
      discharge_date: angular.isDate(patient.discharge_date) ? moment(patient.discharge_date).toISOString() : null,
      zip_code: patient.zip_code,
      location: patient.location,
      deceased: patient.deceased,
      hie_oid: patient.hie_oid,
      email: patient.email
    }, function ( response ) {

      deferred.resolve(response.patient);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  PatientService.addUser = function ( id, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_patientsResource.addUser, {
      id: id,
      user: userId
    }, function ( response ) {

      deferred.resolve(response.patient.users);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  PatientService.removeUser = function ( id, userId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_patientsResource.removeUser, {
      id: id,
      user: userId
    }, function ( response ) {

      deferred.resolve(response.group.users);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return PatientService;
}

angular.module('backlineAdminPlus')
  .factory('PatientService', PatientService);
;
'use strict';

/*
 *  Factory for managing user authentication
 *  @name backlineAdminPlus.factory:PermissionsService
 *  @ngInject
 */
function PermissionsService($rootScope, $log, LocalStorageService, AdminRoles) {

  var PermissionsService = {};

  var _roles,
    _isOrgAdmin = false,
    _isOrgFullAdmin = false,
    _isNetworkAdmin = false,
    _isNetworkFullAdmin = false,
    _isDrFirstSalesAdmin = false,
    _isDrFirstAdmin = false,
    _isDrFirstFullAdmin = false,
    _isDrFirstSuperAdmin = false,
    _isNetworkDrFirstFullAdmin = false,
    _isNetworkDrFirstAdmin = false,
    _defaultAuditOrg;

  PermissionsService.hasAdminPrivs = false;
  PermissionsService.hasFullAdminPrivs = false;
  PermissionsService.hasNetworkAdminPrivs = false;
  PermissionsService.hasNetworkFullAdminPrivs = false;
  PermissionsService.hasDrFirstSalesAdminPrivs = false;
  PermissionsService.hasDrFirstAdminPrivs = false;
  PermissionsService.hasDrFirstFullAdminPrivs = false;
  PermissionsService.hasDrFirstSuperAdminPrivs = false;
  PermissionsService.hasLimitedAdminOrHigherPrivs = false;
  PermissionsService.hasNetworkDrFirstFullAdminPrivs = false;
  PermissionsService.hasNetworkDrFirstAdminPrivs = false;

  PermissionsService.canSearchOrgs = false;

  /*
   *  Gets role for org, or -1 if does not belong to org
   */
  PermissionsService.orgRole = function (orgId) {

    for (var i = 0; i < _roles.length; i++) {
      if (_roles[i][0] == orgId || _roles[i][1] >= AdminRoles.drSalesAdmin) {
        return _roles[i][1];
      }
    }

    return -1;
  };

  /*
   *  Returns true if they have the role in any org, or false
   */
  PermissionsService.hasRole = function (roleId) {
    var hasRole = false;

    angular.forEach(_roles, function (role) {
      if (role[1] == roleId) {
        hasRole = true;
      }
    });

    return hasRole;
  };

  PermissionsService.hasPermission = function (minRoleId, excludedRoles) {
    var hasPermission = false;
    var excludedRolesArray = excludedRoles;

    angular.forEach(_roles, function (role) {
      if (!excludedRolesArray.includes(role[1]) && role[1] >= minRoleId) {
        hasPermission = true;
      }
    });

    return hasPermission;
  };

  /*
   *  Generates the matrix of permissions that this admin has
   */
  PermissionsService.generate = function (user) {

    PermissionsService.reset();

    _roles = [];

    angular.forEach(user.orgs, function (orgUser) {
      if (orgUser.role_id >= AdminRoles.limitedAdmin) {
        this.push([ orgUser.org.id, orgUser.role_id ]);
      }
    }, _roles);

    if (_roles.length === 0) {
      _roles.push([ user.org_id, user.role_id ]);
    }

    _defaultAuditOrg = user.org;

    _isOrgAdmin = true;
    _isOrgFullAdmin = PermissionsService.hasRole(AdminRoles.fullAdmin);
    _isDrFirstSalesAdmin = PermissionsService.hasRole(AdminRoles.drSalesAdmin);
    _isDrFirstAdmin = PermissionsService.hasRole(AdminRoles.drLimitedAdmin);
    _isDrFirstFullAdmin = PermissionsService.hasRole(AdminRoles.drFullAdmin) || PermissionsService.hasRole(AdminRoles.drSuperAdmin);
    _isDrFirstSuperAdmin = PermissionsService.hasRole(AdminRoles.drSuperAdmin);

    if (user.network) {
      var _networkRole = PermissionsService.orgRole(user.network.control_org_id);

      _isNetworkFullAdmin = _networkRole === AdminRoles.fullAdmin;
      _isNetworkAdmin = (_networkRole === AdminRoles.limitedAdmin || _isNetworkFullAdmin);
      _isNetworkDrFirstFullAdmin = _networkRole === AdminRoles.drFullAdmin;
      _isNetworkDrFirstAdmin = _networkRole === AdminRoles.drLimitedAdmin || _isNetworkDrFirstFullAdmin;
    }

    PermissionsService.hasAdminPrivs = !_isDrFirstSalesAdmin;
    PermissionsService.hasFullAdminPrivs = _isOrgFullAdmin || _isDrFirstFullAdmin;
    PermissionsService.hasLimitedAdminPrivs = _isDrFirstAdmin || PermissionsService.hasRole(AdminRoles.limitedAdmin);

    PermissionsService.hasNetworkFullAdminPrivs = _isNetworkFullAdmin;
    PermissionsService.hasNetworkAdminPrivs = _isNetworkAdmin || PermissionsService.hasNetworkFullAdminPrivs;
    PermissionsService.hasNetworkDrFirstFullAdminPrivs = _isNetworkDrFirstFullAdmin;
    PermissionsService.hasNetworkDrFirstAdminPrivs = _isNetworkDrFirstAdmin || PermissionsService.hasNetworkDrFirstFullAdminPrivs;

    PermissionsService.hasLimitedAdminOrHigherPrivs = PermissionsService.hasPermission(AdminRoles.limitedAdmin, [ AdminRoles.drSalesAdmin ]);

    PermissionsService.hasDrFirstFullAdminPrivs = _isDrFirstFullAdmin;
    PermissionsService.hasDrFirstAdminPrivs = _isDrFirstAdmin || PermissionsService.hasDrFirstFullAdminPrivs;
    PermissionsService.hasDrFirstSalesAdminPrivs = _isDrFirstSalesAdmin || PermissionsService.hasDrFirstAdminPrivs;
    PermissionsService.hasDrFirstSuperAdminPrivs = _isDrFirstSuperAdmin;

    PermissionsService.canSearchOrgs = PermissionsService.hasDrFirstSalesAdminPrivs || PermissionsService.hasNetworkAdminPrivs;
  };

  /*
   *  Humanizes the role ids into readable labels
   */
  PermissionsService.humanize = function (role) {

    switch (role) {
      case 1:
        return 'User';
      case 10:
        return 'Admin Limited';
      case 20:
        return 'Admin Full';
      case 30:
        return 'Dr First Sales Admin';
      case 40:
        return 'Dr First Limited Admin';
      case 50:
        return 'Dr First Full Admin';
      case 60:
        return 'Dr Super Admin';
    }

    return 'User';
  };

  /*
   *  Lists the org ids this user has roles in
   *  Optionally filter by role id
   */
  PermissionsService.orgs = function (roleFilter) {

    // Default to all roles
    roleFilter = typeof roleFilter !== 'undefined' ?
      roleFilter : [ AdminRoles.limitedAdmin, AdminRoles.fullAdmin, AdminRoles.drSalesAdmin, AdminRoles.drLimitedAdmin, AdminRoles.drFullAdmin, AdminRoles.drSuperAdmin ];

    var orgs = [];

    angular.forEach(_roles, function (role) {
      if (roleFilter.indexOf(role[1]) != -1) {
        this.push(role[0]);
      }
    }, orgs);

    return orgs;
  };

  /*
   *  Get the org object to use for filtering audits
   */
  PermissionsService.getAuditOrg = function () {

    if (PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasNetworkAdminPrivs) {
      return {id: undefined}; // no org id
    }

    return _defaultAuditOrg;
  };

  /*
   *  Resets the permissions matrix
   */
  PermissionsService.reset = function (reset) {

    _roles = undefined;

    _isOrgAdmin = false;
    _isOrgFullAdmin = false;
    _isNetworkAdmin = false;
    _isNetworkFullAdmin = false;
    _isDrFirstSalesAdmin = false;
    _isDrFirstAdmin = false;
    _isDrFirstFullAdmin = false;
  };

  return PermissionsService;
}

angular.module('backlineAdminPlus')
  .factory('PermissionsService', PermissionsService);
;
'use strict';
/* jshint -W024 */

/**
 * Created by dpark on 9/29/17.
 */

/*
 *  Factory for managing reports
 *  @name backlineAdminPlus.factory:QueueService
 *  @ngInject
 */
function QueueService($q, $resource, TokenService, BacklineAdminAPI) {

  var QueueService = {};

  var errorHandler = function (response) {
    var status = response.status;

    if (response.data) {
      status = response.data.response_code || status;
    }

    if (status === 401) {
      TokenService.logout();
      return $q.reject('Invalid token');
    }

    return $q.reject(response);
  };

  var DEFAULT_HEADERS = {
    'X-Auth-Token': function () {
      return TokenService.getAuthToken();
    }
  };

  var DEFAULT_MAPPER = function (data) {
    var json = angular.fromJson(data);

    return json.queue;
  };

  var Queue = $resource(BacklineAdminAPI + '/networks/:network_id/support_queues/:id', { id: '@id' }, {
    query: {
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query'
      },
      isArray: true,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json.queues)) {
          return json;
        }

        return json.queues;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    withAgent: {
      headers: DEFAULT_HEADERS,
      isArray: true,
      method: 'GET',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json.queues)) {
          return json;
        }

        return json.queues;
      },
      url: BacklineAdminAPI + '/orgs/:org_id/support/providers/:user_id/queues',
      interceptor: {
        responseError: errorHandler
      }
    },

    withManager: {
      headers: DEFAULT_HEADERS,
      isArray: true,
      method: 'GET',
      params: {
        networkId: '@network_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json.queues)) {
          return json;
        }

        return json.queues;
      },
      url: BacklineAdminAPI + '/orgs/:org_id/support/managers/:user_id/queues',
      interceptor: {
        responseError: errorHandler
      }
    },

    get: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      transformResponse: DEFAULT_MAPPER,
      url: BacklineAdminAPI + '/support_queues/:id',
      interceptor: {
        responseError: errorHandler
      }
    },

    addAgent: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      transformResponse: DEFAULT_MAPPER,
      params: {
        id: '@id'
      },
      url: BacklineAdminAPI + '/support_queues/:id/providers',
      interceptor: {
        responseError: errorHandler
      }
    },

    removeAgent: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      transformResponse: DEFAULT_MAPPER,
      params: {
        id: '@id'
      },
      url: BacklineAdminAPI + '/support_queues/:id/providers/:user_id',
      interceptor: {
        responseError: errorHandler
      }
    },

    enableRouting: {
      method: 'PUT',
      headers: DEFAULT_HEADERS,
      params: {
        id: '@id',
        user_id: '@user_id'
      },
      transformResponse: DEFAULT_MAPPER,
      url: BacklineAdminAPI + '/support_queues/:id/providers/:user_id/routing/enable',
      interceptor: {
        responseError: errorHandler
      }
    },

    disableRouting: {
      method: 'PUT',
      headers: DEFAULT_HEADERS,
      params: {
        id: '@id',
        user_id: '@user_id'
      },
      transformResponse: DEFAULT_MAPPER,
      url: BacklineAdminAPI + '/support_queues/:id/providers/:user_id/routing/disable',
      interceptor: {
        responseError: errorHandler
      }
    },

    addOrg: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      params: { id: '@id' },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json.queues)) {
          return json;
        }

        return json.queues;
      },
      url: BacklineAdminAPI + '/support_queues/:id/orgs',
      interceptor: {
        responseError: errorHandler
      }
    },

    removeOrg: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: { id: '@id' },
      transformResponse: DEFAULT_MAPPER,
      url: BacklineAdminAPI + '/support_queues/:id/orgs/:org_id',
      interceptor: {
        responseError: errorHandler
      }
    },

    create: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      transformResponse: DEFAULT_MAPPER,
      params: { id: '@id' },
      url: BacklineAdminAPI + '/networks/:network_id/support_queues',
      interceptor: {
        responseError: errorHandler
      }
    },

    update: {
      method: 'PUT',
      headers: DEFAULT_HEADERS,
      transformResponse: DEFAULT_MAPPER,
      params: { id: '@id' },
      url: BacklineAdminAPI + '/support_queues/:id',
      interceptor: {
        responseError: errorHandler
      }
    },

    delete: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: { id: '@id' },
      url: BacklineAdminAPI + '/support_queues/:id',
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  /*
   *  Get all queues
   */
  QueueService.query = function ( networkId, orgId, query ) {
    if (!query) {
      query = '';
    }

    return Queue.query({ network_id: networkId, org_id: orgId, query: query });
  };

  /**
   * Subscribe org to queue
   */
  QueueService.addOrg = function ( networkId, queueId, orgId, isDefaultQueue ) {
    return Queue.addOrg({
      id: queueId,
      org_id: orgId,
      is_default_queue: isDefaultQueue
    });
  };

  /*
   *  Get all queues that the given agent is in
   */
  QueueService.withAgent = function (orgId, userId) {
    return Queue.withAgent({ org_id: orgId, user_id: userId });
  };

  /*
   *  Get all queues that the given manager is in
   */
  QueueService.withManager = function (orgId, userId, netrworkId) {
    return Queue.withManager({ org_id: orgId, user_id: userId, network_id: netrworkId });
  };

  /*
   *  Get a queue by id
   */
  QueueService.get = function ( id ) {
    return Queue.get({ id: id });
  };

  /*
   *  Create a queue
   */
  QueueService.create = function ( networkId, queue, businessTimeStart, businessTimeEnd, manualTimeZoneEnabled, manualTimeZone ) {
    var timezone;

    if (manualTimeZoneEnabled) {
      timezone = manualTimeZone;
    }

    var payload = { closed_display_in_seconds: queue.closed_display_in_seconds * 3600,
                    business_hour_start: new Date(businessTimeStart).getHours(),
                    business_minute_start: new Date(businessTimeStart).getMinutes(),
                    business_hour_end: new Date(businessTimeEnd).getHours(),
                    business_minute_end: new Date(businessTimeEnd).getMinutes(),
                    manual_timezone_enabled: manualTimeZoneEnabled,
                    timezone: timezone
                  };

    var q = new Queue(Object.assign(queue, payload));

    return q.$create({ network_id: networkId });
  };

  /*
   *  Update a queue
   */
  QueueService.update = function ( queue, businessTimeStart, businessTimeEnd, manualTimeZoneEnabled, manualTimeZone, closedDisplayInSeconds ) {
    var timezone;

    if (manualTimeZoneEnabled) {
      timezone = manualTimeZone;
    }

    var payload = { closed_display_in_seconds: closedDisplayInSeconds * 3600,
                    business_hour_start: new Date(businessTimeStart).getHours(),
                    business_minute_start: new Date(businessTimeStart).getMinutes(),
                    business_hour_end: new Date(businessTimeEnd).getHours(),
                    business_minute_end: new Date(businessTimeEnd).getMinutes(),
                    manual_timezone_enabled: manualTimeZoneEnabled,
                    timezone: timezone
                  };

    var q = new Queue(Object.assign(queue, payload));

    return q.$update({ id: queue.id });
  };

  /*
   *  add an agent's user_id to the given queue
   */
  QueueService.addAgent = function ( queue, userId ) {
    return Queue.addAgent({
      id: queue.id,
      user_id: userId
    });
  };

  /*
   *  remove an agent's user_id from the given queue
   */
  QueueService.removeAgent = function ( queue, userId ) {
    return Queue.removeAgent({
      id: queue.id,
      user_id: userId
    });
  };

  /*
   *  remove an agent's user_id from the given queue
   */
  QueueService.enableRouting = function ( queue, userId ) {
    return Queue.enableRouting({
      id: queue.id,
      user_id: userId
    });
  };

  /*
   *  remove an agent's user_id from the given queue
   */
  QueueService.disableRouting = function ( queue, userId ) {
    return Queue.disableRouting({
      id: queue.id,
      user_id: userId
    });
  };

  return QueueService;
}

angular.module('backlineAdminPlus')
  .factory('QueueService', QueueService);
;
'use strict';

/*
 *  Factory for managing reports
 *  @name backlineAdminPlus.factory:ReportService
 *  @ngInject
 */
function ReportService( $log, $q, $resource, TokenService, BacklineAdminAPI ) {

  var ReportService = {};

  var _reportsResource = $resource(BacklineAdminAPI + '/reports', { id: '@id' }, {
    list: {
      method: 'GET'
    },

    run: {
      url: BacklineAdminAPI + '/reports/:id',
      method: 'PUT'
    }
  });

  /*
   *  List all reports available to user for org
   */
  ReportService.list = function ( orgId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_reportsResource.list, {
      org_id: orgId
    }, function ( response ) {

      deferred.resolve(response.reports);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Kick off a report to run now
   */
  ReportService.run = function ( id ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_reportsResource.run, {
      id: id
    }, function ( response ) {

      deferred.resolve(response.report);

    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Get the report generated file
   */
  ReportService.download = function ( id ) {

    TokenService.openSecureFileLocation('/api/enterprise/reports/' + id);
  };

  return ReportService;
}

angular.module('backlineAdminPlus')
  .factory('ReportService', ReportService);
;
'use strict';

/*
 *  Factory for managing escalations
 *  @name backlineAdminPlus.factory:EscalationService
 *  @ngInject
 */
function EscalationService($log, $q, $resource, TokenService, BacklineAdminAPI, BacklineAPI) {

  var EscalationService = {};

  var _escalationsResource = $resource(BacklineAdminAPI + '/escalations', { id: '@id' }, {
    list: {
      method: 'GET',
      isArray: true,
      interceptor: {
        response: function (response) {
          return response;
        }
      }
    },
    remove: {
      url: BacklineAdminAPI + '/escalations/:id',
      method: 'DELETE'
    },
    create: {
      url: BacklineAdminAPI + '/escalations/',
      method: 'POST'
    },
    update: {
      url: BacklineAdminAPI + '/escalations/:id',
      method: 'PUT'
    }
  });

  var _escalationsSettingResource = $resource(BacklineAPI + '/settings/180', { }, {
    find: {
      method: 'GET'
    },
    save: {
      method: 'PUT'
    }
  });

  EscalationService.enabled = function ( orgId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsSettingResource.find, {
        org_id: orgId
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject('An error occurred');
      }, false
    );

    return deferred.promise;
  };

  EscalationService.saveSetting = function ( orgId, newValue ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsSettingResource.save, {
        org_id: orgId, value: newValue
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject('An error occurred');
      }, false
    );

    return deferred.promise;
  };

  /*
   *  List all escalations available to user for org
   */
  EscalationService.list = function (orgId, page) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsResource.list, {
        org_id: orgId, page: page
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject('An error occurred');
      }, false
    );

    return deferred.promise;
  };

  EscalationService.create = function (escalation) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsResource.create, escalation, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject(error);
      }, false
    );

    return deferred.promise;
  };

  EscalationService.update = function (escalation) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsResource.update, escalation, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject(error);
      }, false
    );

    return deferred.promise;
  };

  EscalationService.remove = function (escalation, orgId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_escalationsResource.remove, {
        id: escalation.id,
        org_id: orgId
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject('An error occurred');
      }, false
    );

    return deferred.promise;
  };

  return EscalationService;
}

angular.module('backlineAdminPlus').factory('EscalationService', EscalationService);
;
'use strict';

/*
 *  Factory for displaying toasts
 *  @name backlineAdminPlus.factory:ToastService
 *  @ngInject
 */
function ToastService( $rootScope, ErrorService ) {

  var ToastService = {},
    _refreshActive = false;

  // Event fired when modal is ready
  ToastService.fireToast = 'ToastService.fireToast';

  /*
   *  Fire an event to show a toast with the specified params
   */
  ToastService.show = function ( params ) {
    $rootScope.$broadcast(ToastService.fireToast, params);
  };

  /*
   *  Fire a refresh toast
   */
  ToastService.refresh = function () {

    var params = {
      title: 'Update',
      message: 'A new version is available',
      type: 'refresh',
      refresh: true
    };

    $rootScope.$broadcast(ToastService.fireToast, params);
    _refreshActive = true;
  };

  return ToastService;
}

angular.module('backlineAdminPlus')
  .factory('ToastService', ToastService);
;
'use strict';

/*
 *  Factory for managing user authentication
 *  @name backlineAdminPlus.factory:TokenService
 *  @ngInject
 */
function TokenService( $rootScope, $log, $q, $http, $state, $resource, $window, LocalStorageService, PermissionsService, ErrorService, MultiOrgService, AuthStatus, BacklineURL, BacklineAdminAPI, BacklineAPI ) {

  var TokenService = {};

  var _currentUser,
    _userId,
    _authToken,
    _deviceId;

  var _tokensResource = $resource('', { id: '@id' }, {
    login: {
      url: BacklineAdminAPI + '/tokens',
      method: 'POST'
    },

    logout: {
      url: BacklineAPI + '/tokens',
      method: 'DELETE'
    },

    loginOtp: {
      url: BacklineAdminAPI + '/otp/token',
      method: 'POST'
    },

    regenerateOtp: {
      url: BacklineAdminAPI + '/otp/regenerate',
      method: 'POST'
    },

    currentUser: {
      url: BacklineAdminAPI + '/users/:id',
      method: 'GET',
      params: {
        id: function () {
          return TokenService.getCurrentUserId();
        }
      }
    }
  });

  /*
   *  Events fired when authentication changes
   */
  TokenService.authenticationStatusChanged = 'TokenService.authenticationStatusChanged';
  TokenService.currentUserChanged = 'TokenService.currentUserChanged';

  /*
   *  Sends a login request to ADAMA with email and password
   *  Also caches user id and auth token in local storage and updates permissions
   */
  TokenService.login = function ( email, password ) {
    var tokenData,
      deferred = $q.defer();

    tokenData = {
      device_uid: LocalStorageService.guid(),
      device_type: 'pc',
      device_os: window.navigator.oscpu,
      device_user_agent: window.navigator.userAgent,
      device_name: window.navigator.appName
    };

    angular.extend(tokenData, {
      email: email,
      pw: password
    });

    _tokensResource.login(tokenData, function ( response ) {
      if ( !response.user || !response.auth_token ) {
        var firstTime = true;

        if (response.error_code) {
          firstTime = response.error_code === '201.etp.3';
        }
        deferred.resolve({user: null, firstTime: firstTime});
      } else {
        var user = response.user;

        MultiOrgService.normalizeUser(user);

        _userId = user.id;
        _authToken = response.auth_token;

        LocalStorageService.set('user_id', _userId);
        LocalStorageService.set('auth_token', _authToken);

        TokenService.updateCurrentUser(user);

        if (SENTRY_ENABLED) {
          Raven.setUserContext({
            id: user.id,
            email: user.email,
            device: tokenData.device_uuid
          });
        }

        $rootScope.$broadcast(TokenService.currentUserChanged, _currentUser);
        $rootScope.$broadcast(TokenService.authenticationStatusChanged, AuthStatus.loggedIn);

        deferred.resolve({ user: user });
      }
    }, function ( error ) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  /*
   *  Sends a login request to ADAMA with email and password and otp code
   *  Also caches user id and auth token in local storage and updates permissions
   */
  TokenService.loginOtp = function ( email, password, otpAttempt) {
    var tokenData,
      deferred = $q.defer();

    tokenData = {
      device_uid: LocalStorageService.guid(),
      device_type: 'pc',
      device_os: window.navigator.oscpu,
      device_user_agent: window.navigator.userAgent,
      device_name: window.navigator.appName
    };

    angular.extend(tokenData, {
      email: email,
      pw: password,
      otp_code: otpAttempt
    });

    _tokensResource.loginOtp(tokenData, function ( response ) {

      var user = response.user;

      MultiOrgService.normalizeUser(user);

      _userId = user.id;
      _authToken = response.auth_token;

      LocalStorageService.set('user_id', _userId);
      LocalStorageService.set('auth_token', _authToken);

      TokenService.updateCurrentUser(user);

      if (SENTRY_ENABLED) {
        Raven.setUserContext({
          id: user.id,
          email: user.email,
          device: tokenData.device_uuid
        });
      }

      $rootScope.$broadcast(TokenService.currentUserChanged, _currentUser);
      $rootScope.$broadcast(TokenService.authenticationStatusChanged, AuthStatus.loggedIn);

      deferred.resolve(user);

    }, function ( error ) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  /*
   *  Sends a otp regenerate request to ADAMA with email and password to send an email with new qr code and backup codes for 2FA
   */
  TokenService.regenerateOtp = function ( email, password) {
      var tokenData,
        deferred = $q.defer();

      tokenData = {
        device_uid: LocalStorageService.guid(),
        device_type: 'pc',
        device_os: window.navigator.oscpu,
        device_user_agent: window.navigator.userAgent,
        device_name: window.navigator.appName
      };

      angular.extend(tokenData, {
        email: email,
        pw: password
      });

      _tokensResource.regenerateOtp(tokenData, function ( response ) {

        deferred.resolve();
      }, function ( error ) {
        deferred.reject(error);
      });

      return deferred.promise;
    };
  /*
   *  Wraps a $resource call with authentication token and some error handling
   */
  TokenService.wrapServiceCall = function ( resource, params, success, failure, handleAnyError ) {

    params.auth_token = TokenService.getAuthToken();

    // delete key if its value is undefined or empty
    // this cleans up API query strings
    Object.keys(params).forEach(function (key) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key];
      }
    });

    resource(params, function ( response ) {

      success(response);

    }, function ( error ) {

      var status = error.status;

      if (error.data) {
        status = error.data.response_code || status;
      }

      if (status == 401) {
        TokenService.logout();
        return;
      }

      if (handleAnyError || status == 500) {
        ErrorService.handle(JSON.parse(JSON.stringify(error)), status);
      }

      failure(error);
    });
  };

  /*
   *  Gets current user object
   */
  TokenService.getCurrentUser = function () {
    var deferred = $q.defer();

    if (_currentUser) {
      deferred.resolve(_currentUser);
    } else {

      TokenService.wrapServiceCall(_tokensResource.currentUser, {}, function ( response ) {

        MultiOrgService.normalizeUser(response.user);
        TokenService.updateCurrentUser(response.user);
        deferred.resolve(response.user);

      }, function ( error ) {
        deferred.reject(error.status);
      });
    }

    return deferred.promise;
  };

  /*
   *  Updates current user object
   *  Also sends a user changed broadcast to all listeners
   */
  TokenService.updateCurrentUser = function ( user ) {
    if (user) {
      _currentUser = user;
      PermissionsService.generate(_currentUser);
      $rootScope.$broadcast(TokenService.currentUserChanged, user);
    }
  };

  /*
   *  Gets cached current user
   */
  TokenService.getCachedUser = function () {
    return _currentUser;
  };

  /*
   *  Gets current user id
   */
  TokenService.getCurrentUserId = function () {
    if (!_userId) {
      _userId = LocalStorageService.getString('user_id');
    }

    return _userId;
  };

  /*
   *  Gets current auth token
   */
  TokenService.getAuthToken = function () {
    if (!_authToken) {
      _authToken = LocalStorageService.getString('auth_token');
    }

    return _authToken;
  };

  /*
   *  Returns whether has cached credentials
   */
  TokenService.isLoggedIn = function () {

    return TokenService.getCurrentUserId() &&
        TokenService.getAuthToken() ? true : false;
  };

  /*
   *  Returns a url with auth token included
   */
  TokenService.secureFileLocation = function ( url ) {

    return BacklineURL + url + '?auth_token=' + TokenService.getAuthToken();
  };

  /*
   *  Opens the url with auth token
   */
  TokenService.openSecureFileLocation = function ( url ) {

    window.open(TokenService.secureFileLocation(url));
  };

  /*
   *  Sends token revoke request to ADAMA
   *  Also deletes local storage cache and permissions
   */
  TokenService.logout = function () {
    var deferred = $q.defer();

    function completeLogout() {

      _currentUser = undefined;
      _userId = undefined;
      _authToken = undefined;

      // Clear cache
      LocalStorageService.remove('user_id');
      LocalStorageService.remove('auth_token');
      LocalStorageService.remove('_selectedOrg');

      PermissionsService.reset();

      if (SENTRY_ENABLED) {
        Raven.setUserContext();
      }

      $rootScope.$broadcast(TokenService.authenticationStatusChanged, AuthStatus.loggedOut);

      window.location.href = '';

      deferred.resolve();
    }

    _tokensResource.logout({
      auth_token: TokenService.getAuthToken()
    }, function ( response ) {
      completeLogout();
    }, function ( error ) {
      completeLogout();
    });

    return deferred.promise;
  };

  return TokenService;
}

angular.module('backlineAdminPlus')
  .factory('TokenService', TokenService);
;
'use strict';

/*
 *  Factory for session related tasks
 *  @name backlineAdminPlus.factory:SessionService
 *  @ngInject
 */
function SessionService( $log, $q, $resource, ErrorService, BacklineAPI, BacklineAdminAPI, MultiOrgService ) {

  var SessionService = {};

  var _sessionsResource = $resource('', {}, {
    resetpw: {
      url: BacklineAPI + '/passwords/reset',
      method: 'POST'
    },

    resetUser: {
      url: BacklineAPI + '/passwords/by_reset_password_token',
      method: 'GET'
    },

    answer: {
      url: BacklineAPI + '/security_questions/answer',
      method: 'PUT'
    },

    changepw: {
      url: BacklineAPI + '/passwords/reset',
      method: 'PUT'
    },

    confirmUser: {
      url: BacklineAPI + '/passwords/by_confirmation_token',
      method: 'GET'
    },

    confirm: {
      url: BacklineAPI + '/passwords/confirm',
      method: 'PUT'
    },

    questions: {
      url: BacklineAdminAPI + '/securityQuestions',
      method: 'GET'
    },

    checkPasswordRequirements: {
      url: BacklineAPI + '/passwords/check_requirements',

      // url: 'http://127.0.0.1:3000/api/passwords/check_requirements',
      method: 'GET'
    }
  });

  SessionService.checkPasswordRequirements = function (pw) {
    var deferred = $q.defer();

    _sessionsResource.checkPasswordRequirements({
      pw: pw
    }, function ( r ) {
      deferred.resolve(r);

    }, function ( error ) {

      deferred.resolve(error);
      deferred.reject(error.data.error_text);
    });

    return deferred.promise;
  };

  /*
   *  Sends an user initiated password reset for the given email
   */
  SessionService.resetpw = function ( email ) {
    var deferred = $q.defer();

    _sessionsResource.resetpw({
      email: email,
      from_admin: true
    }, function ( response ) {

      deferred.resolve(response);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    });

    return deferred.promise;
  };

  /*
   *  Find the user for this reset password token
   */
  SessionService.userByResetToken = function ( token ) {
    var deferred = $q.defer();

    _sessionsResource.resetUser({
      reset_password_token: token
    }, function ( response ) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function ( error ) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  /*
   *  Attempts to answer this security question
   */
  SessionService.answer = function ( params ) {
    var deferred = $q.defer();

    _sessionsResource.answer(params, function ( response ) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function ( error ) {
      if (error.status == 400) {
        deferred.reject({ status: 400, label: 'Incorrect Answer', user: error.data.user });
      } else if (error.status == 406) {
        deferred.reject({ status: 406, label: 'Token Expired', user: error.data.user });
      } else {
        deferred.reject(error.data);
      }
    });

    return deferred.promise;
  };

  /*
   *  Submits a new password for this user
   */
  SessionService.changePW = function ( params ) {
    var deferred = $q.defer();

    _sessionsResource.changepw(params, function ( response ) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function ( error ) {
      if (error.status == 404) {
        deferred.reject({ status: 404, label: 'User not found', user: error.data.user });
      } else if (error.status == 406) {
        deferred.reject({ status: 406, label: 'Token Expired', user: error.data.user });
      } else {
        deferred.reject(error.data);
      }
    });

    return deferred.promise;
  };

  /*
   *  Find the user for this confirmation token
   */
  SessionService.userByConfirmToken = function ( token ) {
    var deferred = $q.defer();

    _sessionsResource.confirmUser({
      confirmation_token: token
    }, function ( response ) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function ( error ) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  /*
   *  Submits registration for this user
   */
  SessionService.confirm = function ( params ) {
    var deferred = $q.defer();

    _sessionsResource.confirm(params, function ( response ) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function ( error ) {
      if (error.status == 404) {
        deferred.reject({ status: 404, label: 'User not found', user: error.data.user });
      } else if (error.status == 406) {
        deferred.reject({ status: 406, label: 'Token Expired', user: error.data.user });
      } else {
        deferred.reject({ status: error.status, label: error.data.response_text });
      }
    });

    return deferred.promise;
  };

  /*
   *  Get list of security questions
   */
  SessionService.questions = function () {
    var deferred = $q.defer();

    _sessionsResource.questions({}, function ( response ) {

      deferred.resolve(response.security_questions);

    }, function ( error ) {
      ErrorService.handle(error);
      deferred.reject('An error occurred');
    });

    return deferred.promise;
  };

  return SessionService;
}

angular.module('backlineAdminPlus')
  .factory('SessionService', SessionService);
;
'use strict';

/**
 * Create a typedef for the object model for a Setting
 * @typedef {Object} BacklineSetting
 * @property {number} org_id
 * @property {number} setting_id
 * @property {string} setting_name
 * @property {string} setting_type - the scope of the setting, 'User' | 'Org' | null.
 * @property {number} user_id
 * @property {string} value
 * @property {string} value_type
 */

/**
 * Create a typedef for the object model for a Setting
 * @typedef {Object} Setting
 * @property {string} key
 * @property {number} id
 * @property {string} type
 * @property {string} valueType
 * @property {string | boolean | number} value
 * @property {number} orgId
 * @property {number} userId
 */

/*
 *  Factory for settings
 *  @name backlineAdminPlus.factory:SettingService
 *  @ngInject
 */
function SettingService( $q, $resource, TokenService, BacklineAPI ) {

  var SettingService = {};

  var _settingsResource = $resource(BacklineAPI + '/settings', { id: '@id' }, {

    find: {
      method: 'GET'
    },

    get: {
      url: BacklineAPI + '/settings/:id',
      method: 'GET'
    },

    set: {
      url: BacklineAPI + '/settings/:id',
      method: 'PUT'
    },

    retrieve: {
      method: 'GET'
    }
  });

  /*
   *  Find a setting by name for an org
   */
  SettingService.find = function ( name, org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.find, {
      org_id: org.id,
      settings_query: name
    }, function ( response ) {

      deferred.resolve(response.settings[0]);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  SettingService.findByOrgId = function ( name, orgId ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.find, {
      org_id: orgId,
      settings_query: name
    }, function ( response ) {

      deferred.resolve(response.settings[0]);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  SettingService.findDecrypted = function ( name, org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.find, {
      org_id: org.id,
      settings_query: name,
      apply_decryption: true
    }, function ( response ) {

      deferred.resolve(response.settings[0]);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };
  /**
   * Find a setting by name for an user.
   *
   * @param name the name of the settings
   * @param org the org that has a property of id
   * @param user the user that has a property of id
   * @return {Promise}
   */
  SettingService.userFind = function ( name, org, user ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.find, {
      org_id: org.id,
      user_id: user.id,
      settings_query: name
    }, function ( response ) {

      deferred.resolve(response.settings[0]);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  /*
   *  Get a setting by id for an org
   */
  SettingService.get = function ( id, org ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.get, {
      org_id: org.id,
      id: id
    }, function ( response ) {

      deferred.resolve(response.data);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  /*
   *  Set the value of a setting for an org
   */
  SettingService.set = function ( id, org, value ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.set, {
      id: id,
      org_id: org.id,
      value: value
    }, function ( response ) {

      deferred.resolve(response.setting);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  SettingService.setSystemWide = function ( id, org, value ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.set, {
      id: id,
      org_id: org.id,
      value: value,
      system_wide: true
    }, function ( response ) {

      deferred.resolve(response.setting);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  /**
   * Find a setting by name for an user.
   *
   * @param name the name of the settings
   * @param org the org that has a property of id
   * @param user the user that has a property of id
   * @return {Promise}
   */
  SettingService.userSet = function ( id, org, user, value ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_settingsResource.set, {
      id: id,
      org_id: org.id,
      user_id: user.id,
      value: value
    }, function ( response ) {

      deferred.resolve(response.setting);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  function normalizeValue(valueType, /** string */ value) {
    switch (valueType) {
      case 'boolean':
        return value === '0';
      case 'integer':
        return parseInt(value, 10);
      default:
        return value;
    }
  }

  function denormalizeValue(valueType, /** boolean | string | number */ value) {
    switch (valueType) {
      case 'boolean':
        return value ? '1' : '0';
      case 'integer':
        return '' + value;
      default:
        return value;
    }
  }

  function normalizeSettingType(settingName, /** number | string */ settingType) {
    if (settingType === 0) {
      var dot = settingName.indexOf('.');

      if (dot === -1) {
        return 'user';
      }

      var type = settingName.slice(0, dot);

      switch (type) {
        case 'org':
        case 'network':
          return 'org';

        default:
        case 'user':
        case 'alert':
          return 'user';
      }
    } else if (settingType === 'System') {
      return 'org';
    }
    return settingType.toLowerCase();
  }

  /**
   * Find a setting by key/name for an org, or for the user if given.
   *
   * Still need to pass the org for the user.
   *
   * @param key the key/name of the setting
   * @param orgId the org id
   * @param optionalUserId the user id, optional
   * @return {IPromise<Setting>}
   */
  SettingService.retrieve = function ( key, orgId, optionalUserId ) {
    /** IDeferred<Setting> */
    var deferred = $q.defer();

    var payload = {
      settings_query: key,
      org_id: orgId
    };

    if (typeof optionalUserId === 'number') {
      payload.user_id = optionalUserId;
    }

    TokenService.wrapServiceCall(_settingsResource.retrieve, payload, function (response ) {

      /** @type BacklineSetting[] */
      var settings = response.settings;

      if (!settings || settings.length === 0) {
        deferred.resolve(null);
      } else {
        /** @type BacklineSetting */
        var backlineSetting = settings[0];

        var setting = {
          key: backlineSetting.setting_name,
          id: backlineSetting.setting_id,
          type: normalizeSettingType(backlineSetting.setting_name, backlineSetting.setting_type),
          valueType: backlineSetting.value_type,
          value: normalizeValue(backlineSetting.value_type, backlineSetting.value),
          orgId: backlineSetting.org_id
        };

        switch (setting.type) {
          case 'org': {
            break;
          }

          default:
          case 'user':{
            setting.userId = backlineSetting.user_id;
            break;
          }
        }
        deferred.resolve(setting);
      }

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  /**
   *  Set the value of a setting for an org
   */
  SettingService.update = function ( /** Setting */ setting ) {
    var deferred = $q.defer();

    var params = {
      id: setting.id,
      org_id: setting.orgId,
      value: denormalizeValue(setting.valueType, setting.value)
    };

    if (setting.type === 'user') {
      params.user_id = setting.userId;
    }

    TokenService.wrapServiceCall(_settingsResource.set, params, function (response ) {

      deferred.resolve(response.setting);

    }, function ( error ) {
      deferred.reject(error.data.error_text);
    }, true);

    return deferred.promise;
  };

  return SettingService;
}

angular.module('backlineAdminPlus')
  .factory('SettingService', SettingService);
;
'use strict';
/* jshint -W024 */

/*
 *  Factory for managing reports
 *  @name backlineAdminPlus.factory:SupportService
 *  @ngInject
 */
function SupportService($resource, TokenService, BacklineAdminAPI, BacklineSupportAPI, $q, AppConsts) {

  var SupportService = {};

  var errorHandler = function (response) {
    var status = response.status;

    if (response.data) {
      status = response.data.response_code || status;
    }

    if (status === 401) {
      TokenService.logout();
      return $q.reject('Invalid token');
    }

    return $q.reject(response);
  };

  var DEFAULT_HEADERS = {
    'X-Auth-Token': function () {
      return TokenService.getAuthToken();
    }
  };

  var Agent = $resource(BacklineAdminAPI + '/orgs/:org_id/support/providers/:user_id', {}, {
    query: {
      isArray: true,
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.providers) {
          return json;
        }

        return json.providers;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    inQueue: {
      isArray: true,
      headers: DEFAULT_HEADERS,
      params: {},
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.providers) {
          return json;
        }

        return json.providers;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/providers',
      interceptor: {
        responseError: errorHandler
      }
    },

    get: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.queue) {
          return json;
        }

        return json.queue;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    save: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.providers) {
          return json;
        }

        return json.providers[0];
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    delete: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: {
        org_id: '@org_id',
        user_id: '@user_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json)) {
          return json;
        }

        return json[0];
      },
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  var Manager = $resource(BacklineAdminAPI + '/networks/:network_id/queue_managers/:queue_id/managers', {}, {
    query: {
      isArray: true,
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.managers) {
          return json;
        }

        return json.managers;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    restrictedManagersQuery: {
      isArray: true,
      headers: DEFAULT_HEADERS,
      params: {
        queue_id: '@query_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.restricted_managers) {
          return json;
        }

        return json.restricted_managers;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/managers/restricted',
      interceptor: {
        responseError: errorHandler
      }
    },

    inQueue: {
      isArray: true,
      headers: DEFAULT_HEADERS,
      params: {},
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.managers) {
          return json;
        }

        return json.managers;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/managers',
      interceptor: {
        responseError: errorHandler
      }
    },

    unrestrictManager: {
      isArray: true,
      method: 'POST',
      headers: DEFAULT_HEADERS,
      params: {
        queue_id: '@queue_id',
        user_id: '@user_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.managers) {
          return json;
        }

        return json.managers;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/managers/unrestrict',
      interceptor: {
        responseError: errorHandler
      }
    },

    restrictManager: {
      isArray: true,
      method: 'POST',
      headers: DEFAULT_HEADERS,
      params: {
        queue_id: '@queue_id',
        user_id: '@user_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!json.managers) {
          return json;
        }

        return json.managers;
      },
      url: BacklineAdminAPI + '/support_queues/:queue_id/managers/restrict',
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  var TicketingPools = $resource(BacklineSupportAPI + '/ticketing_pools', {}, {
    query: {
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query',
        org_id: '@org_id'
      },
      url: BacklineSupportAPI + '/ticketing_pools?orgId=:org_id&query=:query',
      isArray: true,
      transformResponse: function (data) {
        return angular.fromJson(data);
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    get: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      params: {
        pool_id: '@pool_id',
        org_id: '@org_id'
      },
      url: BacklineSupportAPI + '/ticketing_pools/:pool_id',
      transformResponse: function (data) {
        return angular.fromJson(data);
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    getAll: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query',
        org_id: '@org_id'
      },
      url: BacklineSupportAPI + '/ticketing_pools?org_id=:org_id&query=:query',
      transformResponse: function (data) {
        return angular.fromJson(data);
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    save: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      params: {
        network_id: '@networkId',
        org_id: '@orgId'
      },
      url: BacklineSupportAPI + '/ticketing_pools',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.ticketing_pool)) {
          return json;
        }

        return json.ticketing_pool;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    update: {
      method: 'PUT',
      headers: DEFAULT_HEADERS,
      params: {
        network_id: '@network_id',
        org_id: '@org_id',
        ticketing_pool_id: '@id'
      },
      url: BacklineSupportAPI + '/ticketing_pools/:ticketing_pool_id',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.ticketing_pool)) {
          return json;
        }

        return json.ticketing_pool;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    deletePool: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: {
        ticketing_pool_id: '@id'
      },
      url: BacklineSupportAPI + '/ticketing_pools/:ticketing_pool_id',
      transformResponse: function (data) {
        return angular.fromJson(data);
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    addUserToPool: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      params: {
        user_id: '@user_id',
        pool_id: '@pool_id'
      },
      url: BacklineSupportAPI + '/ticketing_pools_actors',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.consumer)) {
          return json;
        }

        return json.consumer;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    removeUserFromPool: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: {
        user_id: '@user_id',
        pool_id: '@pool_id'
      },
      url: BacklineSupportAPI + '/ticketing_pools_actors',
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.consumer)) {
          return json;
        }

        return json.consumer;
      },
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  var QueueManager = $resource(BacklineAdminAPI + '/networks/:network_id/queue_managers/:queue_manager_id', {}, {
    query: {
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query'
      },
      isArray: true,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.queue_managers)) {
          return json;
        }

        return json.queue_managers;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    get: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.queue_manager)) {
          return json;
        }

        return json.queue_manager;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    save: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.queue_manager)) {
          return json;
        }

        return json.queue_manager;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    delete: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: {
        network_id: '@network_id',
        org_id: '@org_id',
        queue_manager_id: '@id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.queue_manager)) {
          return json;
        }

        return json.queue_manager;
      },
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  var Submitter = $resource(BacklineAdminAPI + '/orgs/:org_id/support/consumers/:user_id', {}, {
    query: {
      headers: DEFAULT_HEADERS,
      params: {
        query: '@query'
      },
      isArray: true,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.consumers)) {
          return json;
        }

        return json.consumers;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    setTicketingPools: {
      method: 'PUT',
      headers: DEFAULT_HEADERS,
      url: BacklineAdminAPI + '/orgs/:org_id/support/consumers/:user_id/ticketing_pools',
      interceptor: {
        responseError: errorHandler
      }
    },

    get: {
      method: 'GET',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (angular.isUndefined(json.queue)) {
          return json;
        }

        return json.queue;
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    save: {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json.consumers)) {
          return json;
        }

        return json.consumers[0];
      },
      interceptor: {
        responseError: errorHandler
      }
    },

    delete: {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      params: {
        org_id: '@org_id',
        user_id: '@user_id'
      },
      transformResponse: function (data) {
        var json = angular.fromJson(data);

        if (!angular.isArray(json)) {
          return json;
        }

        return json[0];
      },
      interceptor: {
        responseError: errorHandler
      }
    }
  });

  SupportService.canCreateAgent = function (user, org) {
    return user.role_id === 40 || user.role_id === 50 || // Role id 40 drfirst limited and 50 is drfirst full
      user.orgs.some(function (element) {
        return org.id === element.org.id;
      }) && SupportService.canHaveAgents(org);
  };

  SupportService.canHaveAgents = function (org) {
    return org.is_support_provider || org.networks.some(function (network) {
      return network.control_org_id === org.id && network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT;
    });
  };

  /*
   *  Create an agent
   */
  SupportService.queryAgents = function (orgId, query) {
    if (!query) {
      query = '';
    }

    return Agent.query({org_id: orgId, query: query});
  };

  /*
   *  All agents in the given queue
   */
  SupportService.agentsInQueue = function (queueId) {
    var agentsResource = Agent.inQueue({queue_id: queueId});

    agentsResource.$promise.then(function (agents) {
      for (var i = 0; i < agents.length; i++) {
        var agent = agents[i];

        for (var j = 0; j < agent.queue_count; j++) {
          var queue = agent.queues[j];

          if (queue.id === queueId) {
            agent.routing_enabled = queue.routing_enabled;
            break;
          }
        }
      }
    });
    return agentsResource;
  };

  /*
   *  Create an agent
   */
  SupportService.createAgent = function (orgId, userId) {
    var agent = new Agent({
      user_id: userId
    });

    return agent.$save({org_id: orgId});
  };

  /*
   *  Delete an agent
   */
  SupportService.deleteAgent = function (orgId, userId) {
    return Agent.delete({org_id: orgId, user_id: userId});
  };

  /*
   *  query submitter
   */
  SupportService.querySubmitters = function (orgId, query) {
    if (!query) {
      query = '';
    }

    return Submitter.query({org_id: orgId, query: query});
  };

  /**
   * Get all ticketing pools associated with organization ID
   * @param orgId
   * @param query
   * @returns {*|IDBRequest<any[]>|FormDataEntryValue[]|string[]}
   */
  SupportService.getTicketingPools = function (orgId, query) {
    return TicketingPools.getAll({org_id: orgId, query: query});
  };

  /**
   *
   * @param networkId
   * @param ticketingPoolId
   * @returns {any}
   */
  SupportService.getTicketingPool = function (orgId, ticketingPoolId) {
    return TicketingPools.get({org_id: orgId, pool_id: ticketingPoolId});
  };

  /**
   *
   * @param ticketingPoolId {number}
   * @param userId {number}
   * @returns {*}
   */
  SupportService.addSubmitterToTicketingPool = function (ticketingPoolId, userId) {
    return TicketingPools.addUserToPool({pool_id: ticketingPoolId, user_id: userId});
  };

  /**
   *
   * @param userId {number}
   * @param ticketingPoolId
   * @returns {*}
   */
  SupportService.removeSubmitterFromTicketingPool = function (ticketingPoolId, userId) {
    return TicketingPools.removeUserFromPool({user_id: userId, pool_id: ticketingPoolId});
  };

  /**
   *
   * @param orgId {number}
   * @param userId {number}
   * @param ticketingPools {Array}
   * @returns {*|IDBRequest<*[]>|FormDataEntryValue[]|string[]|void}
   */
  SupportService.setSubmitterTicketingPools = function (orgId, userId, ticketingPools) {
    var submitter = new Submitter({
      user_id: userId,
      ticketing_pools: ticketingPools
    });

    return submitter.$setTicketingPools({org_id: orgId, user_id: userId});
  };

  SupportService.canCreateSubmitter = function (user, org) {
    var networks = user.orgs.map(function (e) {
      return e.org.networks;
    });

    networks = [].concat.apply([], networks);
    networks = networks.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });

    var isUserInControlOrgOfUserBeingEdited = networks.some(function (element) {
      return org.networks.some(function (net) {
        return net.control_org_id === element.control_org_id;
      });
    });

    return user.role_id === 40 || user.role_id === 50 || // Role id 40 drfirst limited and 50 is drfirst full
      isUserInControlOrgOfUserBeingEdited ||
      (user.orgs.some(function (element) {
        return org.id === element.org.id;
      }) && SupportService.canHaveSubmitters(org));
  };

  SupportService.canHaveSubmitters = function (org) {
    return org.is_support_consumer || org.networks.some(function (network) {
      return network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT;
    });
  };

  /*
   *  Create a ticketing pool
   */
  SupportService.createTicketingPool = function (networkId, orgId, poolForm) {
    var ticketingPool = new TicketingPools({
      name: poolForm.name
    });

    return ticketingPool.$save({org_id: orgId, network_id: networkId});
  };

  /*
   *  Rename a ticketing pool
   */
  SupportService.renameTicketingPool = function (networkId, orgId, pool) {
    var ticketingPool = new TicketingPools({
      name: pool.name
    });

    return ticketingPool.$update({org_id: orgId, network_id: networkId, ticketing_pool_id: pool.id});
  };

  /*
   *  Delete a ticketing pool
   */
  SupportService.removeTicketingPool = function (poolId) {
    return TicketingPools.deletePool({ticketing_pool_id: poolId});
  };

  /*
   *  Create an submitter
   */
  SupportService.createSubmitter = function (orgId, userId) {
    var submitter = new Submitter({
      user_id: userId
    });

    return submitter.$save({org_id: orgId});
  };

  /*
   *  Delete a submitter
   */
  SupportService.deleteSubmitter = function (orgId, userId) {
    return Submitter.delete({org_id: orgId, user_id: userId});
  };

  /*
   *  query queue managers
   */
  SupportService.queryQueueManagers = function (networkId, orgId, query) {
    if (!query) {
      query = '';
    }

    return QueueManager.query({network_id: networkId, org_id: orgId, query: query});
  };

  /*
  *  restricted queue managers in the given queue
  */
  SupportService.restrictedManagersQuery = function (queueId, query) {
    var managersResource = Manager.restrictedManagersQuery({queue_id: queueId, query: query});

    return managersResource;
  };

  /*
  *  All queue managers in the given queue
  */
  SupportService.managersInQueue = function (queueId) {
    var managersResource = Manager.inQueue({queue_id: queueId});

    return managersResource;
  };

  /*
   *  add an agent's user_id to the given queue
   */
  SupportService.restrictManager = function ( params ) {
      return Manager.restrictManager({
        queue_id: params.queue.id,
        user_id: params.user_id
      });
    };

  /*
   *  remove an agent's user_id from the given queue
   */
  SupportService.unrestrictManager = function ( params ) {
      return Manager.unrestrictManager({
        queue_id: params.queue.id,
        user_id: params.user_id
      });
    };

  SupportService.canCreateQueueManager = function (user, org) {
    return user.role_id === 40 || user.role_id === 50 || // Role id 40 drfirst limited and 50 is drfirst full
      user.orgs.some(function (element) {
        return org.id === element.org.id;
      }) && SupportService.canHaveQueueManagers(org);
  };

  SupportService.canHaveQueueManagers = function (org) {
    return org.is_support_provider && org.networks.some(function (network) {
      return network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT;
    });
  };

  /*
   *  Create an queue manager
   */
  SupportService.createQueueManager = function (networkId, orgId, userId) {
    var queueManager = new QueueManager({
      user_id: userId
    });

    return queueManager.$save({network_id: networkId, org_id: orgId});
  };

  /*
   *  Delete a queue manager
   */
  SupportService.deleteQueueManager = function (networkId, orgId, userId) {
    return QueueManager.delete({network_id: networkId, org_id: orgId, user_id: userId});
  };

  return SupportService;
}

angular.module('backlineAdminPlus')
  .factory('SupportService', SupportService);
;
'use strict';

var INVALID_LANDLINE_CODE = '422.phone.number.9'; // Twilio is giving us an error when we try to register the land line
var INVALID_NUMBER_CODE = '422.phone.number.11'; // The phone number you are trying to register is invalid
var ALREADY_REGISTERED_CODE = '422.phone.number.15'; // Phone number is already verified
var PREMIUM_NUMBER_CODE = '422.phone.number.26'; // Phone number is a premium number or has been blacklisted

/*
 *  Factory for managing twilio landlines
 *  @name backlineAdminPlus.factory:TwilioService
 *  @ngInject
 */
function TwilioServiceFn( $rootScope, $log, $q, $resource, TokenService, OrgSelectorService, ErrorService, BacklineAdminAPI, BacklineAPI ) {

  var TwilioService = {};

  var _twilioResource = $resource(BacklineAdminAPI + '/twilio', {}, {
    register: {
      method: 'POST',
      url: BacklineAdminAPI + '/twilio/register_land_line'
    },

    registrationStatus: {
      method: 'GET',
      url: BacklineAdminAPI + '/twilio/land_line_registration_status'
    },

    unregister: {
      method: 'DELETE',
      url: BacklineAdminAPI + '/twilio/delete_land_line'
    }
  });

  /*
   *  Register a landline with Twilio for Mask Caller ID
   */
  TwilioService.register = function (phoneNumber, extension) {
    var deferred = $q.defer();
    var params = {
      org_id: OrgSelectorService.getOrg().id,
      phone_number: phoneNumber,
      extension: extension
    };

    TokenService.wrapServiceCall(_twilioResource.register, params,
      function (response) {
        deferred.resolve(response.verification_code);
      },
      function (error) {
        if (error.status === 422 && error.data.error_code === ALREADY_REGISTERED_CODE) {

          // NOTE: a resolved promise with a null verification code will tell the UI that no further action is required.
          // Message: Phone number is already verified
          deferred.resolve(null);
        } else if (error.status === 422 && error.data.error_code === INVALID_LANDLINE_CODE) {
          
          // Message: Twilio is giving us an error when we try to register the land line
          deferred.reject(error.data.error_text);
        } else if (error.status === 422 && error.data.error_code === INVALID_NUMBER_CODE) {

          // Message: The phone number you are trying to register is invalid
          deferred.reject(error.data.error_text);
        } else if (error.status === 422 && error.data.error_code === PREMIUM_NUMBER_CODE) {

          // Message: Phone number is a premium number or has been blacklisted
          deferred.reject(error.data.error_text);
        } else {

          // ErrorService.handle(error);
          deferred.reject('An error occurred');
        }
      },
      false
    );

    return deferred.promise;
  };

  /*
   *  Check the status of a pending landline registration
   */
  TwilioService.registrationStatus = function () {
    var deferred = $q.defer();
    var params = {
      org_id: OrgSelectorService.getOrg().id
    };

    TokenService.wrapServiceCall(_twilioResource.registrationStatus, params,
      function (response) {
        deferred.resolve(response.land_line_registration_status);
      },
      function (error) {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      },
      true
    );

    return deferred.promise;
  };

  /*
   *  Unregister the current landline
   */
  TwilioService.unregister = function () {
    var deferred = $q.defer();
    var params = {
      org_id: OrgSelectorService.getOrg().id
    };

    TokenService.wrapServiceCall(_twilioResource.unregister, params,
      function (response) {
        deferred.resolve();
      },
      function (error) {
        ErrorService.handle(error);
        deferred.reject('An error occurred');
      },
      true
    );

    return deferred.promise;
  };

  return TwilioService;
}

angular.module('backlineAdminPlus')
  .factory('TwilioService', TwilioServiceFn);
;
'use strict';

/*
 *  Factory for managing users
 *  @name backlineAdminPlus.factory:UserService
 *  @ngInject
 */
function UserService($log, $q, $resource, TokenService, ErrorService, MultiOrgService, BacklineAdminAPI, BacklineAPI, SettingService, SettingKeys) {

  var UserService = {};

  var _usersResource = $resource(BacklineAdminAPI + '/users', {id: '@id', org: '@org'}, {
    listForFeed: {
      url: BacklineAPI + '/users',
      method: 'GET'
    },

    list: {
      method: 'GET'
    },

    add: {
      method: 'POST'
    },

    show: {
      url: BacklineAdminAPI + '/users/:id',
      method: 'GET'
    },

    update: {
      url: BacklineAdminAPI + '/users/:id',
      method: 'PUT'
    },

    migrate: {
      url: BacklineAdminAPI + '/users/:id/migrate',
      method: 'POST'
    },

    checkNPI: {
      url: BacklineAPI + '/npi/check',
      method: 'GET'
    },

    resetpw: {
      url: BacklineAdminAPI + '/users/:id/reset_password',
      method: 'PUT'
    },

    resend: {
      url: BacklineAdminAPI + '/users/:id/resend-registration',
      method: 'POST'
    },

    activate: {
      url: BacklineAdminAPI + '/users/:id/activate',
      method: 'PUT'
    },

    addOrg: {
      url: BacklineAdminAPI + '/users/:id/orgs',
      method: 'POST'
    },

    updateOrg: {
      url: BacklineAdminAPI + '/users/:id/orgs/:org',
      method: 'PUT'
    },

    removeOrg: {
      url: BacklineAdminAPI + '/users/:id/orgs/:org',
      method: 'DELETE'
    },

    partnerToggle: {
      url: BacklineAdminAPI + '/users/:id/partner',
      method: 'PUT'
    },

    batchUploads: {
      url: BacklineAdminAPI + '/user_batch_uploads',
      method: 'GET'
    }
  });

  var showUserError = function (error, showPopup) {
    if (error.status === 422) {
      var message = '';
      var title = '';

      switch (error.data.error_code) {
        case '422.1':
          message = 'Maximum license count reached. Please contact your account manager to purchase additional licenses.';
          title = 'Insufficient available licenses';
          break;
        case '422.3':
          message = 'No departments specified. Must have at least one.';
          title = 'Department issue';
          break;
        case '422.4':
          message = 'EHR User ID has already been used.';
          title = 'Duplicate ID issue';
          break;
        case '422.181':
          message = 'Some departments not set correctly. Please ensure all departments are setup correctly, refresh this page, and try again.';
          title = 'Department issue';
          break;
        default:
          message = 'Could not complete the operation as specified. Please try again later or contact support.';
          title = 'Error';
      }

      var errorMessage = {
        invalid: true,
        message: message,
        title: title
      };

      if (showPopup) {
        ErrorService.show(error.status, {
          invalid: true,
          message: message,
          title: title
        });
      }

      return errorMessage;
    }
  };

  /*
   *  List all users
   */
  UserService.list = function (params) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.list, params, function (response) {

      deferred.resolve(response);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  UserService.dropdownForDepartments = function (params) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.list, params, function (response) {

      deferred.resolve(response);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  UserService.togglePartner = function (user) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.partnerToggle, {id: user.id}, function (response) {

      deferred.resolve(response);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Attempt to create a user
   */
  UserService.add = function (user) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.add, {
      user: user
    }, function (response) {
      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);
    }, function (error) {
      var errorMessage = showUserError(error, false);

      deferred.reject(errorMessage.message);
    }, false);

    return deferred.promise;
  };

  /*
   *  List all users in pull-down format
   */
  UserService.dropdown = function (orgId, sourceFeedType, adminOnly, showStatuses, showPatients, query) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.list, {
      admins_only: adminOnly,
      forPulldown: true,
      includePatients: showPatients,
      org_id: orgId,
      showStatuses: showStatuses,
      source_feed_type: sourceFeedType,
      query: query
    }, function (response) {

      deferred.resolve(response.users);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  List all users in pull-down format
   */
  UserService.dropdownForFeed = function (orgId, sourceFeedType, query) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.listForFeed, {
      from_admin: true,
      org_id: orgId,
      per_page: 40,
      query: query,
      source_feed_type: sourceFeedType
    }, function (response) {

      deferred.resolve(response.users);

    }, function (error) {
      return 'An error occurred';
    }, true);

    return deferred.promise;
  };

  /*
   *  Get details for user with id
   */
  UserService.getUser = function (id) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.show, {
      id: id
    }, function (response) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Update attributes for supplied user
   */
  UserService.update = function (user) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.update, {
      id: user.id,
      user: user
    }, function (response) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function (error) {
      var errorMessage = showUserError(error, true);

      deferred.reject(errorMessage.message);
    }, false);

    return deferred.promise;
  };

  /*
   *  Sends a reset pw request for supplied user
   */
  UserService.resetpw = function (user) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.resetpw, {
      id: user.id
    }, function (response) {

      MultiOrgService.normalizeUser(response.user);

      deferred.resolve(response.user);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Resends confirmation email for user with id
   */
  UserService.resend = function (id) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.resend, {
      id: id
    }, function (response) {

      MultiOrgService.normalizeUser(response.user);

      deferred.resolve(response.user);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
 *  Activate user with id
 */
  UserService.activate = function (id) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.activate, {
      id: id
    }, function (response) {

      MultiOrgService.normalizeUser(response.user);

      deferred.resolve(response.user);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Checks if this email has been taken
   */
  UserService.checkEmail = function (email) {
    var deferred = $q.defer();

    UserService.list({email: email})
      .then(function (response) {
        if (response.users) {
          deferred.resolve(response.users[0]);
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error);
      });

    return deferred.promise;
  };

  /*
   *  Migrate this user to / from community
   */
  UserService.migrate = function (params) {
    var deferred = $q.defer();

    params = angular.merge({}, params, {departments: params.departments});

    TokenService.wrapServiceCall(_usersResource.migrate, params, function (response) {

      MultiOrgService.normalizeUser(response.user);
      deferred.resolve(response.user);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Checks if NPI is valid or has been taken
   */
  UserService.checkNPI = function (npi) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.checkNPI, {
      npi: npi
    }, function (response) {

      deferred.resolve();

    }, function (error) {
      if (error.status == 400) {

        deferred.reject({
          invalid: true,
          text: 'Invalid NPI'
        });

      } else if (error.status == 403) {

        deferred.reject({
          taken: true,
          text: 'NPI has already been taken'
        });

      } else {

        ErrorService.handle(error);
        deferred.reject({
          text: 'An error occurred'
        });

      }
    }, false);

    return deferred.promise;
  };

  /*
   *  Adds and org to the user
   */
  UserService.addOrg = function (orgUser) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.addOrg, orgUser, function (response) {

      deferred.resolve(response.orgs);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Update org details for user
   */
  UserService.updateOrg = function (orgUser) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.updateOrg, orgUser, function (response) {

      deferred.resolve(response.orgs);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  /*
   *  Remove an org for this user
   */
  UserService.removeOrg = function (userId, orgId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.removeOrg, {
      id: userId,
      org: orgId
    }, function (response) {

      deferred.resolve(response.orgs);

    }, function (error) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  UserService.s3BucketUrl = function (org) {
    return SettingService.find(SettingKeys.s3BucketUrl, org);
  };

  /*
   *  Template for batch upload CSV
   */
  UserService.downloadBatchTemplate = function () {
    window.open(BacklineAdminAPI + '/batch_templates?auth_token=' + TokenService.getAuthToken());
  };

  /*
   *  Template for batch upload CSV
   */
  UserService.downloadLdapBatchTemplate = function (uri) {
    window.open(uri + '/Akario+Backline+LDAP+Batch+Upload+Template.csv');
  };

  /*
   *  URL that the CSV is sent to via AngularFileUploader
   */
  UserService.batchUploadUrl = TokenService.secureFileLocation('/api/enterprise/users/batch');

  /*
   *  Batch csv uploads by the current user
   */
  UserService.batchUploads = function ( params ) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_usersResource.batchUploads, params, function ( response ) {
      deferred.resolve({
        batchUploads: response.batch_uploads,
        pages: response.pages
      });
    }, function ( error ) {
      deferred.reject('An error occurred');
    }, true);

    return deferred.promise;
  };

  return UserService;
}

angular.module('backlineAdminPlus')
  .factory('UserService', UserService);
;
'use strict';

/*
 *  Factory for managing mass messages
 *  @name backlineAdminPlus.factory:MassMessagingService
 *  @ngInject
 */
function MassMessagingService($log, $q, $resource, TokenService, BacklineAdminAPIV2, $http) {

  var MassMessagingService = {};

  var _massMessagingResource = $resource(BacklineAdminAPIV2 + '/mass_messaging', { id: '@id' }, {
    list: {
      method: 'GET'
    },
    remove: {
      url: BacklineAdminAPIV2 + '/mass_messaging/:id',
      method: 'DELETE'
    },
    get: {
      url: BacklineAdminAPIV2 + '/mass_messaging/:id',
      method: 'GET'
    },
    broadcast: {
      url: BacklineAdminAPIV2 + '/mass_messaging/:id/broadcast',
      method: 'POST'
    }
  });

  MassMessagingService.list = function (orgId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_massMessagingResource.list, {
        org_id: orgId
      }, function (response) {
        deferred.resolve(response.mass_messages);
      },
      function (error) {
        deferred.reject(error);
      }, false
    );

    return deferred.promise;
  };

  MassMessagingService.create = function (massMessaging) {
    var deferred = $q.defer();

    $.ajax({
      type: 'POST',
      url: BacklineAdminAPIV2 + '/mass_messaging/',
      headers: {
        'X-Auth-Token': TokenService.getAuthToken(),
        'Content-Type': undefined
      },
      contentType: false,
      processData: false,
      data: massMessaging,
      success: function (data) {
        deferred.resolve(data);
      },
      error: function (error) {
        deferred.reject(error);
      }
    });

    return deferred.promise;
  };

  MassMessagingService.update = function (massMessaging, id) {
    var deferred = $q.defer();

    $.ajax({
      type: 'PUT',
      url: BacklineAdminAPIV2 + '/mass_messaging/' + id,
      headers: {
        'X-Auth-Token': TokenService.getAuthToken(),
        'Content-Type': undefined
      },
      contentType: false,
      processData: false,
      data: massMessaging,
      success: function (data) {
        deferred.resolve(data);
      },
      error: function (error) {
        deferred.reject(error);
      }
    });

    return deferred.promise;
  };

  MassMessagingService.remove = function (massMessagingId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_massMessagingResource.remove, {
        id: massMessagingId
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject(error);
      });
    return deferred.promise;
  };

  MassMessagingService.get = function (massMessagingId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_massMessagingResource.get, {
          id: massMessagingId
        }, function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        });
    return deferred.promise;
  };

  MassMessagingService.template = function (orgId) {
    window.open(BacklineAdminAPIV2 + '/mass_messaging/templates?auth_token=' + TokenService.getAuthToken() + '&org_id=' + orgId);
  };

  MassMessagingService.broadcast = function (massMessagingId, orgId) {
    var deferred = $q.defer();

    TokenService.wrapServiceCall(_massMessagingResource.broadcast, {
        id: massMessagingId,
        org_id: orgId
      }, function (response) {
        deferred.resolve(response);
      },
      function (error) {
        deferred.reject(error);
      });
    return deferred.promise;
  };

  return MassMessagingService;
}

angular.module('backlineAdminPlus').factory('MassMessagingService', MassMessagingService);
;
'use strict';

/*
 *  Controller for admin password reset form
 *  @name backlineAdminPlus.controller:AdminChangePWCtrl
 *  @ngInject
 */
function AdminChangePWCtrl( $log, $stateParams, $timeout, SessionService, Time, WebplusUrl, questions ) {

  var changepw = this,
    _token = $stateParams.reset_password_token;

  // get security questions
  changepw.questions = questions;

  // set default question indices
  changepw.firstQuestion = 1;
  changepw.secondQuestion = 2;

  // selected security questions start invalid, before user input
  changepw.validQuestions = true;

  // security question answers start as invalid (empty), before user input
  changepw.validAnswers = true;

  changepw.checkCount = 0;

  // get user for token
  // unfortunately this cannot be in a resolver because we must show a message if token is invalid
  // a resolver will only not allow navigation to the page on error
  SessionService.userByResetToken(_token)
    .then(function ( response ) {
      changepw.user = response;
    }, function ( error ) {
      if (error.status == 404) {
        changepw.invalid = true;
      } else {
        $log.error(error);
      }
    });

  /*
   *  Input callback
   *  Checks password against requirements, sets `validPasswords`
   */
  changepw.onChangePassword = function () {

    var count = changepw.checkCount;

    console.log('original count = '+count);

    if (changepw.pw && changepw.pw.length > 0) {
      changepw.checkCount = changepw.checkCount + 1;
      SessionService.checkPasswordRequirements(changepw.pw)
        .then(function (response) {
          console.log('count after call = '+(count+1));
          console.log('changepw.checkCount after call = '+changepw.checkCount);
          if (count+1 == changepw.checkCount) {
            console.log('Doing password check update now.');
            changepw.length = changepw.pw && (!response.too_short && !response.too_long);
            changepw.capital = changepw.pw && !response.missing_uppercase;
            changepw.lower = changepw.pw && !response.missing_lowercase;
            changepw.number = changepw.pw && !response.missing_digit;
            changepw.special = changepw.pw && !response.missing_special;
            changepw.pwned = changepw.pw && !response.pwned_password;
            changepw.keyword = changepw.pw && !response.has_forbidden_keyword;
            changepw.match = (changepw.pw && changepw.pw_confirm) && (changepw.pw === changepw.pw_confirm);

            changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
          } else {
            console.log('Skipping password check update now.');
          }
        }, function (error) {

        });
    } else {
      changepw.checkCount = 0;
      changepw.length = changepw.capital = changepw.lower = changepw.number = changepw.special = changepw.pwned = changepw.keyword = changepw.match = false;

      changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
    }

  };

  changepw.checkMatch = function () {
    changepw.match = (changepw.pw && changepw.pw_confirm) && (changepw.pw === changepw.pw_confirm);
    changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
  };

  /*
   *  Input callback
   *  Checks selected security questions against requirements, sets `validQuestions`
   */
  changepw.onChangeQuestions = function () {

    changepw.validQuestions = changepw.firstQuestion != changepw.secondQuestion;
  };

  /*
   *  Input callback
   *  Checks security answers against requirements, sets `validAnswers`
   */
  changepw.onChangeAnswers = function () {

    changepw.validAnswers = changepw.firstAnswer != changepw.secondAnswer;
  };

  /*
   *  View method
   *  Attempts to submit password and questions change
   */
  changepw.submit = function () {

    if (changepw.form.$valid && changepw.validPasswords && changepw.validAnswers) {

      changepw.error = undefined;

      SessionService.changePW({
        reset_password_token: _token,
        pw: changepw.pw,
        pw_confirmation: changepw.pw_confirm,
        question1: changepw.firstQuestion,
        question2: changepw.secondQuestion,
        answer1: changepw.firstAnswer,
        answer2: changepw.secondAnswer
      })
      .then(function ( response ) {

        // show redirect view
        changepw.saved = true;

        // redirect to backline after 3s
        $timeout(function () {
          window.location.href = response.redirect_url;
        }, Time.seconds(3));

      }, function ( error ) {
        changepw.invalid = true;
        changepw.error = error.label;
      });
    } else {

      // if form error is minlength, must be security answers
      if (changepw.form.$error.minlength) {
        changepw.error = 'Security Answers must be at least 3 characters long';
      }
    }
  };
}

/* @ngInject */
AdminChangePWCtrl.resolve = {

  questions: ['SessionService', function ( SessionService ) {

    return SessionService.questions();
  }]
};

angular.module('backlineAdminPlus')
  .controller('AdminChangePWCtrl', AdminChangePWCtrl);
;
'use strict';

/*
 *  Controller for showing dashboard charts
 *  @name backlineAdminPlus.controller:AdminCtrl
 *  @ngInject
 */
function AdminCtrl( $rootScope, TokenService, PermissionsService, ConfirmationService, ActivityService, AdminVersion, JSCSPassed, JSHintPassed, ServerEnv, currentUser, adtEnabled, insightsEnabled, $state, SupportService, massMessagingEnabled ) {

  var admin = this;

  admin.active = $state.get();
  $rootScope.$on('$stateChangeStart', function (event, toState) {
    admin.active = toState.name;
  });

  // get current user from resolver
  admin.user = currentUser;

  // get adt enabled state from resolver
  admin.adtEnabled = adtEnabled.value === '1';
  admin.insightsEnabled = insightsEnabled.value === '1';
  admin.massMessagingEnabled = massMessagingEnabled.value === '1';
  admin.isMassMessagingUser = admin.user.orgs[0].is_mass_messaging_user;

  admin.canSeeAgents = SupportService.canCreateAgent(currentUser, currentUser.primaryOrg.org);
  admin.canSeeQueueManagers = SupportService.canCreateQueueManager(currentUser, currentUser.primaryOrg.org);
  admin.canSeeSubmitters = SupportService.canCreateSubmitter(currentUser, currentUser.primaryOrg.org);

  // get permissions from service
  admin.permissions = PermissionsService;
  admin.hasAdminPrivs = admin.permissions.hasAdminPrivs;
  admin.hasFullAdminPrivs = admin.permissions.hasFullAdminPrivs;
  admin.hasNetworkFullAdminPrivs = admin.permissions.hasNetworkFullAdminPrivs;
  admin.hasNetworkAdminPrivs = admin.permissions.hasNetworkAdminPrivs;
  admin.hasDrFirstFullAdminPrivs = admin.permissions.hasDrFirstFullAdminPrivs;
  admin.hasDrFirstSuperAdminPrivs = admin.permissions.hasDrFirstSuperAdminPrivs;
  admin.hasLimitedAdminOrHigherPrivs = admin.permissions.hasLimitedAdminOrHigherPrivs;
  admin.hasDrFirstAdminPrivs = admin.permissions.hasDrFirstAdminPrivs;
  admin.hasDrFirstSalesAdminPrivs = admin.permissions.hasDrFirstSalesAdminPrivs;

  admin.isSupportESign = admin.permissions.hasFullAdminPrivs || admin.permissions.hasLimitedAdminPrivs;
  admin.canSearchOrgs = admin.permissions.canSearchOrgs;
  admin.isSupportOrg = admin.canSeeAgents || admin.canSeeQueueManagers || admin.canSeeSubmitters;

  // get version
  admin.version = AdminVersion;
  admin.env = ServerEnv;

  // get adt enabled state from resolver
  admin.adtEnabled = adtEnabled.value === '1' && admin.hasDrFirstAdminPrivs;

  // get reporter status if on local
  if (ServerEnv == 'local') {
    admin.jscsPassed = JSCSPassed;
    admin.jshintPassed = JSHintPassed;
  }

  // Handle events
  $rootScope.$on(TokenService.authenticationStatusChanged, function ( ev, isLoggedIn ) {
    if (isLoggedIn) {
      TokenService.getCurrentUser().then(function ( user ) {
        admin.user = user;
      });
    }
  });

  $rootScope.$on(TokenService.currentUserChanged, function ( ev, user ) {
    admin.user = user;
  });

  /*
   *  View helper
   *  Tests if menu item is active
   */
  admin.isActive = function ( str ) {
    return admin.active.indexOf(str) >= 0 || $state.includes(str);
  };

  /*
   *  View helper
   *  Tests if menu item is loading
   */
  admin.isLoading = function ( str ) {

    return admin.active == str && !$state.includes(str);
  };

  /*
   *  View method
   *  Logout of session
   */
  admin.logout = function () {
    TokenService.logout();
  };

  function isSubmenuClicked(menuOpened, str, searchElement) {
    return (menuOpened && admin.active.indexOf(searchElement) >= 0 &&
      (str.indexOf(searchElement) === -1 || str === searchElement)) ||
      str === searchElement;
  }
  /*
   *  View method
   *  Sets active menu item
   */
  admin.setActive = function ( str ) {

    if (isSubmenuClicked(admin.configMenu, str, 'admin.configs')) {
      admin.configMenu = !admin.configMenu;
    }
    if (isSubmenuClicked(admin.supportMenu, str, 'admin.support')) {
      admin.supportMenu = !admin.supportMenu;
    }

    admin.active = str;
  };
}

/* @ngInject */
AdminCtrl.resolve = {
  currentUser: ['TokenService', function ( TokenService ) {
    return TokenService.getCurrentUser();
  }],

  adtEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function ( SettingService, SettingKeys, OrgSelectorService, currentUser ) {
    return SettingService.find(SettingKeys.adtEnabled, OrgSelectorService.getOrg()).then(function (value) {
      return value || {value: '0'};
    });
  }],

  insightsEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser) {
    return SettingService.find(SettingKeys.insightsEnabled, OrgSelectorService.getOrg()).then(function (value) {
      return value || { value: '0'};
    });
  }],

  massMessagingEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser) {
    return SettingService.find(SettingKeys.massMessaging, currentUser.org).then(function (value) {
      return value || { value: '0'};
    });
  }]

};

angular.module('backlineAdminPlus')
  .controller('AdminCtrl', AdminCtrl);
;
'use strict';

/*
 *  Controller for adt feed settings
 *  @name backlineAdminPlus.controller:ADTCtrl
 *  @refactor
 *  @ngInject
 */
function ADTCtrl( $q, $timeout, SettingService, PermissionsService, ToastService, OrgSelectorService, SettingKeys, events, positions, pccs ) {

  var adt = this;

  adt.org = OrgSelectorService.getOrg();

  // wow this is sloppy god save me
  function _updateSettings( events, positions, pccs ) {

    adt.eventSetting = events;
    adt.positionSetting = positions;
    adt.pccSetting = pccs;

    adt.allowedEvents = angular.fromJson(events.value);
    adt.allowedPositions = angular.fromJson(positions.value);
    adt.allowedPCC = angular.fromJson(pccs.value);

    adt.events = [
      {
        label: 'Inpatient Admissions',
        value: 'A01'
      },
      {
        label: 'ED Admissions',
        value: 'A04-E'
      },
      {
        label: 'Transfers Outpatient to Inpatient',
        value: 'A06'
      },
      {
        label: 'Inpatient Discharges',
        value: 'A03-I'
      },
      {
        label: 'ED Discharges',
        value: 'A03-E'
      },
      {
        label: 'Patient Transfer',
        value: 'A02'
      },
      {
        label: 'Patient Information Update',
        value: 'A08'
      },
      {
        label: 'Cancelled Admission',
        value: 'A11'
      },
      {
        label: 'Cancelled Transfer',
        value: 'A12'
      },
      {
        label: 'Cancelled Discharge',
        value: 'A13'
      },
      {
        label: 'Cancelled Pre-Admission',
        value: 'A38'
      }
    ];

    angular.forEach(adt.events, function ( ev ) {
      ev.selected = adt.allowedEvents.indexOf(ev.value) > -1;
    });

    adt.reports = [
      {
        label: 'C-CDA Discharge Instructions',
        value: 'CDA'
      },
      {
        label: 'Discharge Summary',
        value: 'DIS'
      }
    ];

    angular.forEach(adt.reports, function ( report ) {
      report.selected = adt.allowedEvents.indexOf(report.value) > -1;
    });

    adt.nus = {
      label: 'NUS (Notification of Undeliverable Scripts)',
      value: 'NUS',
      selected: adt.allowedEvents.indexOf('NUS') > -1
    };

    adt.positions = [
      {
        label: 'Admitting Physician',
        value: 'AD'
      },
      {
        label: 'Attending Physician',
        value: 'AT'
      },
      {
        label: 'Consulting Physician',
        value: 'CP'
      },
      {
        label: 'Family Health Care Physician',
        value: 'FHCP'
      },
      {
        label: 'Primary Care Physician',
        value: 'PP'
      },
      {
        label: 'Referring Physician',
        value: 'RP'
      },
      {
        label: 'Referred to Physician',
        value: 'RT'
      }
    ];

    angular.forEach(adt.positions, function ( position ) {
      position.selected = adt.allowedPositions.indexOf(position.value) > -1;
    });

    adt.pccs = [
      {
        label: 'Inpatient Admissions',
        value: 'A01'
      },
      {
        label: 'ED Admissions',
        value: 'A04-E'
      },
      {
        label: 'Transfers Outpatient to Inpatient',
        value: 'A06'
      },
      {
        label: 'Inpatient Discharges',
        value: 'A03-I'
      },
      {
        label: 'ED Discharges',
        value: 'A03-E'
      },
      {
        label: 'Patient Transfer',
        value: 'A02'
      },
      {
        label: 'Patient Information Update',
        value: 'A08'
      },
      {
        label: 'Cancelled Admission',
        value: 'A11'
      },
      {
        label: 'Cancelled Transfer',
        value: 'A12'
      },
      {
        label: 'Cancelled Discharge',
        value: 'A13'
      },
      {
        label: 'Cancelled Pre-Admission',
        value: 'A38'
      }
    ];

    angular.forEach(adt.pccs, function ( pcc ) {
      pcc.selected = adt.allowedPCC.indexOf(pcc.value) > -1;
    });
  }

  _updateSettings(events, positions, pccs);

  adt.select = function ( ev ) {

    if (PermissionsService.hasDrFirstAdminPrivs) {
      ev.selected = !ev.selected;
    }
  };

  adt.saveEvents = function () {

    adt.savingEvents = true;

    var values = [];

    angular.forEach(adt.events, function ( ev ) {
      if (ev.selected) {
        values.push(ev.value);
      }
    });

    angular.forEach(adt.reports, function ( report ) {
      if (report.selected) {
        values.push(report.value);
      }
    });

    if (adt.nus.selected) {
      values.push(adt.nus.value);
    }

    SettingService.set(adt.eventSetting.setting_id, adt.org, angular.toJson(values))
      .then(function ( response ) {
        ToastService.show({
          title: 'Saved',
          message: 'Settings saved successfully',
          type: 'success'
        });

        adt.savingEvents = false;
      });
  };

  adt.savePositions = function () {

    adt.savingPositions = true;

    var values = [];

    angular.forEach(adt.positions, function ( position ) {
      if (position.selected) {
        values.push(position.value);
      }
    });

    SettingService.set(adt.positionSetting.setting_id, adt.org, angular.toJson(values))
      .then(function ( response ) {
        ToastService.show({
          title: 'Saved',
          message: 'Settings saved successfully',
          type: 'success'
        });

        adt.savingPositions = false;
      });
  };

  adt.savePCC = function () {

    adt.savingPCC = true;

    var values = [];

    angular.forEach(adt.pccs, function ( pcc ) {
      if (pcc.selected) {
        values.push(pcc.value);
      }
    });

    SettingService.set(adt.pccSetting.setting_id, adt.org, angular.toJson(values))
      .then(function ( response ) {
        ToastService.show({
          title: 'Saved',
          message: 'Settings saved successfully',
          type: 'success'
        });

        adt.savingPCC = false;
      });
  };

  adt.selectOrg = function ( org ) {

    var settingsQuery = {
      events: SettingService.find(SettingKeys.allowedEvents, org),
      positions: SettingService.find(SettingKeys.allowedPositions, org),
      pccs: SettingService.find(SettingKeys.allowedPCCEvents, org)
    };

    $q.all(settingsQuery).then(function ( response ) {
      adt.org = org;
      _updateSettings(response.events, response.positions, response.pccs);
    });
  };
}

/* @ngInject */
ADTCtrl.resolve = {
  events: ['SettingService', 'OrgSelectorService', 'SettingKeys', 'currentUser', function ( SettingService, OrgSelectorService, SettingKeys, currentUser ) {

    return SettingService.find(SettingKeys.allowedEvents, OrgSelectorService.getOrg());
  }],

  positions: ['SettingService', 'OrgSelectorService', 'SettingKeys', 'currentUser', function ( SettingService, OrgSelectorService, SettingKeys, currentUser ) {

    return SettingService.find(SettingKeys.allowedPositions, OrgSelectorService.getOrg());
  }],

  pccs: ['SettingService', 'OrgSelectorService', 'SettingKeys', 'currentUser', function ( SettingService, OrgSelectorService, SettingKeys, currentUser ) {

    return SettingService.find(SettingKeys.allowedPCCEvents, OrgSelectorService.getOrg());
  }],

  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('ADTCtrl', ADTCtrl);
;
 'use strict';
/* jshint -W024 */

/*
 *  Controller for viewing and changing groups
 *  @name backlineAdminPlus.controller:AgentsCtrl
 *  @ngInject
 */
function AgentsCtrl(currentUser, $q, $uibModal, SupportService, OrgService, OrgSelectorService) {

  var ctrl = this;

  // query parameters
  ctrl.query = '';
  ctrl.page = 1;

  // initial groups info
  ctrl.refreshing = true;
  ctrl.agents = [];
  ctrl.totalPages = 0;

  ctrl.canHaveAgents = false;

  ctrl.onSelectOrg = function () {
    ctrl.refreshing = true;
    OrgService.getOrg(OrgSelectorService.getOrg().id)
      .then(function (org) {
        ctrl.canHaveAgents = SupportService.canHaveAgents(org);

        refreshAgents();
      });
  };

  ctrl.onSelectOrg();

  // refresh list of agents
  function refreshAgents() {
    ctrl.refreshing = true;

    SupportService.queryAgents(OrgSelectorService.getOrg().id, ctrl.query).$promise
      .then(function (agents) {
        ctrl.agents = agents;
        ctrl.totalPages = 0;
      })
      .catch(function () {
        ctrl.agents = [];
      })
      .then(function () {
        ctrl.refreshing = false;
      });
  }

  /*
   *  View method
   *  Requests the next page of groups
   */
  ctrl.next = function () {

    ctrl.page++;
    refreshAgents();
  };

  /*
   *  View method
   *  Requests the previous page of groups
   */
  ctrl.previous = function () {

    ctrl.page--;
    refreshAgents();
  };

  /*
   *  Modal callback
   *  Sets the search query to the new group name and searches it
   */
  ctrl.openAssignAgentModal = function ( queue ) {
    var modal = $uibModal.open({
      component: 'createAgentModal',
      resolve: {
        currentUser: currentUser
      }
    });

    modal.result.then(function (agent) {
      if (!agent) {
        return;
      }

      var agentExists = ctrl.agents.some(function (element) {
        return element.user_id === agent.user_id;
      });

      if (agentExists) {
        return;
      }

      ctrl.agents.push(agent);
    }, function () {

    });
  };
}

angular.module('backlineAdminPlus')
  .controller('AgentsCtrl', AgentsCtrl);
;
'use strict';

/*
 *  Controller for searching chat archives
 *  @name backlineAdminPlus.controller:ArchiveCtrl
 *  @ngInject
 */
function ArchiveCtrl( $state, ChatService, OrgSelectorService, ArchiveTypes, FeedTypes, OrgService, currentUser, $rootScope, $scope ) {

  var archives = this,
    _perPage = 10;

  archives.types = ArchiveTypes;

  var listener = $rootScope.$on('$stateChangeStart', function () {
    if (isSubmitterOrg()) {
      OrgSelectorService.select(currentUser.org);
    }
  });

  $scope.$on('$destroy', listener);

  archives.org = null;
  archives.changeOrg = function (org) {
    OrgService.getOrg(org.id)
      .then(function (org2) {
        archives.org = org2;

        if (archives.isDisabled(archives.types[archives.chatType])) {
          if (isSubmitterOrg()) {
            archives.chatType = 5;
          } else {
            archives.chatType = 0;
          }
        }
      });
  };

  archives.isSupportProvider = currentUser.org.is_support_provider;

  function isSubmitterOrg() {
    // Role id 40 drfirst limited and 50 is drfirst full
    if (currentUser.role_id === 40 || currentUser.role_id === 50) {
      return false;
    }

    if (currentUser.org.id === archives.org.id) {
      return false;
    }

    if (currentUser.org.is_support_provider && archives.org.is_support_consumer) {
      return true;
    }

    return false;
  }

  archives.changeOrg(OrgSelectorService.getOrg());

  archives.isDisabled = function (type) {
    if (archives.org === null) {
      return true;
    }

    if (type.label === 'Tickets') {
      return !archives.org.is_support_consumer && !archives.org.is_support_provider;
    }
    if (isSubmitterOrg()) {
      return true;
    }
    return false;
  };

  // set defaults
  archives.page = 1;
  archives.chatType = 0;
  archives.patient = {};
  archives.group = {};

  // refresh list of chats
  function _getChats() {

    // if a specific id was requested, go to the detail page
    if (archives.chatId) {

      $state.go('admin.archives.detail', { id: archives.chatId });
      return;
    }

    // create filter params
    var params = {
      org_id: OrgSelectorService.getOrg().id,
      'patient[lname]': archives.patient.lname,
      'patient[fname]': archives.patient.fname,
      'patient[mrn]': archives.patient.mrn,
      'patient[dob]': archives.patient.dob,
      'group[name]': archives.group.name,
      'user_ids[]': archives.users ? archives.users.map(function ( user ) { return user.id; }) : [],
      per_page: _perPage,
      page: archives.page
    };

    // add defaults based on selected type
    angular.extend(params, archives.types[archives.chatType].params);

    // execute the search
    ChatService.search(params)
      .then(function ( response ) {

        archives.totalPages = response.pages;

        archives.chats = response.chats;
        archives.selectedChat = response.chats[0];
      });
  }

  /*
   *  View method
   *  Search with current parameters
   */
  archives.search = function () {

    archives.page = 1;
    _getChats();
  };

  /*
   *  View method
   *  Reset all search filters
   */
  archives.reset = function () {

    archives.users = [];
    archives.patient = {};
    archives.group = {};
  };

  /*
   *  View method
   *  Go to next page of results
   */
  archives.next = function () {

    archives.page += 1;
    _getChats();
  };

  /*
   *  View method
   *  Go to previous page of results
   */
  archives.previous = function () {

    archives.page -= 1;
    _getChats();
  };
}

/* @ngInject */
ArchiveCtrl.resolve = {
  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasFullAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('ArchiveCtrl', ArchiveCtrl);
;
'use strict';

/*
 *  Controller for viewing chat archive details
 *  @name backlineAdminPlus.controller:ArchiveDetailCtrl
 *  @ngInject
 */
function ArchiveDetailCtrl( $log, $q, ChatService, FeedTypes, FeedInfo, chatInfo ) {

  var archive = this;

  // set defaults
  archive.chat = chatInfo;
  archive.userLog = chatInfo.users;
  archive.patient = chatInfo.patient;
  archive.unregisteredUser = chatInfo.unregistered_user;

  // resolve feed display name
  archive.chatName = FeedInfo[archive.chat.feed_type].label;

  // if this is a private chat with an unregistered user, its a weblink chat
  if (archive.chat.feed_type == FeedTypes.privateChat && archive.chat.unregistered_user_chat) {
    archive.chatName = 'Weblink Chat';
  }

  // resolve if chat is private
  archive.isPrivateFeed = (archive.chat.feed_type == FeedTypes.inviteGroup || archive.patient && archive.patient.is_private);
}

/* @ngInject */
ArchiveDetailCtrl.resolve = {

  chatInfo: ['$stateParams', 'ChatService', function ( $stateParams, ChatService ) {
    return ChatService.show($stateParams.id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('ArchiveDetailCtrl', ArchiveDetailCtrl);
;
'use strict';

/*
 *  Controller for searching audit records
 *  @name backlineAdminPlus.controller:AuditCtrl
 *  @ngInject
 */
function AuditCtrl( $filter, PermissionsService, OrgSelectorService, AuditTypes, Time, currentUser ) {

  var audit = this;

  // set default search params
  audit.entityType = '0';
  audit.types = AuditTypes;
  audit.endDate = new Date();
  audit.startDate = new Date(+new Date() - Time.weeks(2));
  audit.selected = {
    user: undefined
  };

  // resolve org to use
  audit.org = PermissionsService.getAuditOrg();

  /*
   *  Input callback
   *  Resets audit entity when type changes
   */
  audit.onChangeEntityType = function () {
    audit.entity = undefined;
  };

  /*
   *  View method
   *  Search audit records
   */
  audit.search = function () {

    // set search params
    audit.params = {
      start_date: $filter('date')(audit.startDate, 'yyyy-M-d'),
      end_date: $filter('date')(audit.endDate, 'yyyy-M-d'),
      org_id: audit.org.id
    };

    // include entity type if set
    if (audit.entityType > 0) {
      angular.extend(audit.params, {
        entity_type: audit.entityType
      });
    }

    // include entity info if set
    if (audit.entity) {
      angular.extend(audit.params, {
        entity_id: audit.entity.id
      });
    }

    // include admin info if set
    if (audit.selected.user) {
      angular.extend(audit.params, {
        user_id: audit.selected.user.id
      });
    }
  };

  /*
   *  View method
   *  Reset all search filters
   */
  audit.reset = function () {

    audit.params = undefined;

    audit.selected.user = undefined;

    audit.entityType = '0';
    audit.entity = undefined;

    audit.endDate = new Date();
    audit.startDate = new Date(+new Date() - Time.weeks(2));
  };
}

/* @ngInject */
AuditCtrl.resolve = {
  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasFullAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('AuditCtrl', AuditCtrl);
;
'use strict';

/*
 *  Controller for broadcasting messages
 *  @name backlineAdminPlus.controller:BroadcastCtrl
 *  @ngInject
 */
function BroadcastCtrl( $rootScope, BroadcastService, OrgSelectorService, ToastService, bots ) {

  var broadcast = this;

  // org to get broadcasters for
  broadcast.org = OrgSelectorService.getOrg();

  // list of broadcasters
  broadcast.bots = bots;

  // view control vars
  broadcast.selected = undefined;
  broadcast.bot = undefined;
  broadcast.template = undefined;

  // message to send info
  broadcast.message = {};

  // message info defaults
  broadcast.sendTo = 3; // everyone
  broadcast.recipients = [
    'Select Users',
    'Select Departments',
    'Select Groups',
    'Everyone'
  ];

  // get new sent messages and de-select template
  function _refreshHistory() {

    BroadcastService.history(broadcast.bot.id)
      .then(function ( response ) {
        broadcast.history = response;
      });

    broadcast.template = undefined;
  }

  /*
   *  Input callback
   *  When the selected bot is changed, update the selectable history
   */
  broadcast.onChangeBot = function () {

    broadcast.bot = broadcast.bots[broadcast.selected];
    broadcast.message.subject = '';
    broadcast.message.content = '';

    _refreshHistory();
  };

  /*
   *  Input callback
   *  When the selected template is changed, update the message info
   */
  broadcast.onChangeTemplate = function () {

    broadcast.message.subject = broadcast.history[broadcast.template].subject;
    broadcast.message.content = broadcast.history[broadcast.template].content;
  };

  /*
   *  Input callback
   *  Clear any errors generated from validations when changing send type
   */
  broadcast.onChangeSendType = function () {

    broadcast.error = undefined;
  };

  /*
   *  View method
   *  Send the message with details inputed
   */
  broadcast.send = function () {

    var params = angular.copy(broadcast.message);

    // sending to select users
    if (broadcast.sendTo === 0) {

      if (!broadcast.users || broadcast.users.length < 1) {
        broadcast.error = 'Please select at least 1 user';
        return;
      }

      params.user_ids = broadcast.users.map(function ( user ) { return user.id; }).join(', ');

      // sending to select depts
    } else if (broadcast.sendTo === 1) {

      if (!broadcast.departments || broadcast.departments.length < 1) {
        broadcast.error = 'Please select at least 1 department';
        return;
      }

      params.department_ids = broadcast.departments.map(function ( dept ) { return dept.id; }).join(', ');

      // sending to select groups/feeds
    } else if (broadcast.sendTo === 2) {

      if (!broadcast.groups || broadcast.groups.length < 1) {
        broadcast.error = 'Please select at least 1 group';
        return;
      }

      params.feed_ids = broadcast.groups.map(function ( group ) { return group.id; }).join(', ');

    } else {

      params.everyone = true;
    }

    // send message
    BroadcastService.send(broadcast.bot.id, params).then(function ( response ) {

      ToastService.show({
        title: 'Sent',
        message: 'Your message was broadcasted',
        type: 'success'
      });

      // refresh history and reset template
      _refreshHistory();
      broadcast.message = {};
      broadcast.sendTo = 2; // everyone
    });
  };

  /*
   *  Modal callback
   *  Push the new bot onto the list of available bots and select it
   */
  broadcast.onAddBot = function ( bot ) {

    broadcast.bots.push(bot);

    // change to new bot
    broadcast.selected = broadcast.bots.length - 1;
    broadcast.onChangeBot();
  };

  /*
   *  Modal callback
   *  Update details in bot list for selected (edited) bot
   */
  broadcast.onEditBot = function ( bot ) {

    broadcast.bot = bot;
    broadcast.bots[broadcast.selected] = bot;
  };

  /*
   *  Directive callback
   *  When global org is changed, update selectable bots list
   */
  broadcast.onSelectOrg = function ( org ) {

    broadcast.org = org;

    BroadcastService.bots(broadcast.org).then(function ( response ) {
      broadcast.bots = response;
      broadcast.selected = 0;
      broadcast.onChangeBot();
    });
  };
}

/* @ngInject */
BroadcastCtrl.resolve = {
  bots: ['BroadcastService', 'OrgSelectorService', 'currentUser', function ( BroadcastService, OrgSelectorService, currentUser ) {
    return BroadcastService.bots(OrgSelectorService.getOrg());
  }],

  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('BroadcastCtrl', BroadcastCtrl);
;
'use strict';

/*
 *  Controller for changing your password
 *  @name backlineAdminPlus.controller:ChangePWCtrl
 *  @ngInject
 */
function ChangePWCtrl( $log, $stateParams, $timeout, SessionService, WebplusUrl ) {

  var changepw = this,
    _token = $stateParams.reset_password_token;

  // start with first question
  changepw.selectedQuestion = 0;

  // track if user is allowed to switch between questions
  changepw.ableToSwitch = true;

  // track if user is out of question attempts
  changepw.outOfAttempts = false;

  changepw.checkCount = 0;

  // update user info after an answer is submitted
  function _updateUser( user ) {
    changepw.user = user;

    var questionsDisabled = [ false, false ];

    // check if either question is out of attempts
    angular.forEach(user.questions, function ( question, index ) {
      if (question.allowed_attempts == question.attempts) {
        questionsDisabled[index] = true;
      }
    });

    // if both are out of attempts, user is locked out
    changepw.outOfAttempts = questionsDisabled[0] && questionsDisabled[1];

    // if otherwise
    if (!changepw.outOfAttempts) {

      // they are only able to switch if both questions are enabled
      changepw.ableToSwitch = !(questionsDisabled[0] || questionsDisabled[1]);

      // if otherise
      if (!changepw.ableToSwitch) {

        if (questionsDisabled[changepw.selectedQuestion]) {

          // switch to other question if current questions is disabled
          changepw.switchQuestion();
        }
      }
    }
  }

  // find user when page loads
  // unfortunately this cannot be in a resolver because we must show a message if token is invalid
  // a resolver will only not allow navigation to the page on error
  SessionService.userByResetToken(_token)
    .then(function ( response ) {
      _updateUser(response);
    }, function ( error ) {
      if (error.status == 404) {
        // user not found, token invalid
        changepw.invalid = true;
      }
    });

  /*
   *  Input callback
   *  Checks password against requirements, sets `validPasswords`
   */
  changepw.onChangePassword = function () {

    var count = changepw.checkCount;

    if (changepw.pw && changepw.pw.length > 0) {
      changepw.checkCount = changepw.checkCount + 1;
      SessionService.checkPasswordRequirements(changepw.pw)
        .then(function (response) {
          if (count+1 == changepw.checkCount) {
            changepw.length = changepw.pw && (!response.too_short && !response.too_long);
            changepw.capital = changepw.pw && !response.missing_uppercase;
            changepw.lower = changepw.pw && !response.missing_lowercase;
            changepw.number = changepw.pw && !response.missing_digit;
            changepw.special = changepw.pw && !response.missing_special;
            changepw.pwned = changepw.pw && !response.pwned_password;
            changepw.keyword = changepw.pw && !response.has_forbidden_keyword;
            changepw.match = (changepw.pw && changepw.pw_confirm) && (changepw.pw === changepw.pw_confirm);

            changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
          } else {
          }
        }, function (error) {

        });
    } else {
      changepw.checkCount = 0;
      changepw.length = changepw.capital = changepw.lower = changepw.number = changepw.special = changepw.pwned = changepw.keyword = changepw.match = false;

      changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
    }

  };

  changepw.checkMatch = function () {
    changepw.match = (changepw.pw && changepw.pw_confirm) && (changepw.pw === changepw.pw_confirm);
    changepw.validPasswords = changepw.keyword && changepw.pwned && changepw.length && changepw.capital && changepw.lower && changepw.number && changepw.special && changepw.match;
  };

  /*
   *  View helper
   *  Get count of attempts left for selected question
   */
  changepw.attemptsLeft = function () {

    return changepw.user.questions[changepw.selectedQuestion].allowed_attempts - changepw.user.questions[changepw.selectedQuestion].attempts;
  };

  /*
   *  View method
   *  Attempt to answer selected security question
   */
  changepw.submitAnswer = function () {

    SessionService.answer({
      reset_password_token: _token,
      question_id: changepw.user.questions[changepw.selectedQuestion].question_id,
      question_answer: changepw.answer
    })
    .then(function ( response ) {

      // change to pw change form view
      changepw.success = true;

      _updateUser(response);
      changepw.error = undefined;

    }, function ( error ) {

      _updateUser(error.user);

      if (error.status == 400) {
        // incorrect answer
        changepw.error = error.label + ' (' + changepw.attemptsLeft() + ' Attempts Left)';
      } else if (error.status == 406) {
        // token expired
        changepw.invalid = true;
      }
    });
  };

  /*
   *  View method
   *  Submit password change form
   */
  changepw.submit = function () {

    // if no form errors
    if (changepw.success && changepw.changeForm.$valid && changepw.validPasswords) {

      changepw.error = undefined;

      SessionService.changePW({
        pw: changepw.pw,
        pw_confirmation: changepw.pw_confirm,
        reset_password_token: _token
      })
      .then(function ( response ) {

        // change to redirecting view
        changepw.success = false;
        changepw.changed = true;

        // redirect back to backline after 3s
        $timeout(function () {
          window.location.href = response.redirect_url;
        }, 3000);

      }, function ( error ) {

        // token expired
        if (error.status == 406) {
          changepw.invalid = true;
        }
      });
    } else {

      changepw.error = 'Please ensure all fields are filled out correctly before submitting';
    }
  };

  /*
   *  View method
   *  Toggles selected question
   */
  changepw.switchQuestion = function () {

    changepw.selectedQuestion = 1 - changepw.selectedQuestion;
  };
}

angular.module('backlineAdminPlus')
  .controller('ChangePWCtrl', ChangePWCtrl);
;
'use strict';

/*
 *  Controller for showing dashboard charts
 *  @name backlineAdminPlus.controller:DashboardCtrl
 *  @ngInject
 */
function DashboardCtrl($timeout, TokenService, ToastService, OrgService, DashboardService, Time, org, charts) {

  var dashboard = this;

  // set default filters
  dashboard.today = new Date();
  dashboard.end_date = dashboard.today;
  dashboard.start_date = new Date(dashboard.end_date - Time.weeks(6));

  // get initial chart data
  dashboard.chats = charts.chats.data;
  dashboard.usage = charts.usage.data;
  dashboard.average = charts.average.data;
  dashboard.devices = charts.devices.data;
  dashboard.groups = charts.groups.data;

  // update org information
  function _updateOrg(org) {

    // get license information
    org.usedLicenses = org.active_licenses;
    org.totalLicenses = org.user_licenses;

    // set org
    dashboard.org = org;
  }

  // update all charts
  function _updateCharts() {

    var orgId = dashboard.org ? dashboard.org.id : null;

    DashboardService.charts(orgId, dashboard.end_date, dashboard.start_date)
      .then(function (response) {
        dashboard.chats = response.chats.data;
        dashboard.usage = response.usage.data;
        dashboard.average = response.average.data;
        dashboard.devices = response.devices.data;
        dashboard.groups = response.groups.data;
      });
  }

  // set org when page loads
  _updateOrg(org);

  if (DashboardService.firstLoggedIn) {
    if (org) {
      var myLinks = org.linked_orgs.filter(function (link) {
        if ((!link.connected && link.pending) && !link.other_org_pending) {
          return link;
        }
      });

      if (myLinks.length > 0 ) {
        $timeout(function () {ToastService.show({
          title: 'Pending Organization Links',
          message: 'You have pending Cross-Org Link requests.',
          type: 'logininfo',
          delay: 300000
        });}, 100);
      }

    }
    DashboardService.firstLoggedIn = false;
  }

  /*
   *  View method
   *  Go back 6 weeks in time for charts
   */
  dashboard.previous = function () {

    // go back 6 weeks
    dashboard.end_date = new Date(dashboard.end_date - Time.weeks(6));
    dashboard.start_date = new Date(dashboard.start_date - Time.weeks(6));

    // update charts
    _updateCharts();
  };

  /*
   *  View method
   *  Go forward in time 6 weeks for charts
   */
  dashboard.next = function () {

    // go forward 6 weeks
    dashboard.end_date = new Date(+dashboard.end_date + Time.weeks(6));
    dashboard.start_date = new Date(+dashboard.start_date + Time.weeks(6));

    // update charts
    _updateCharts();
  };

  /*
   *  Directive callback
   *  When global org changes, show org details and refresh charts
   */
  dashboard.onSelectOrg = function (org) {

    // if null is passed, get charts for all orgs
    var orgId = org ? org.id : null;

    OrgService.getOrg(orgId).then(function (org) {

      // update full org info
      _updateOrg(org);

      // update charts
      _updateCharts();
    });
  };
}

/* @ngInject */
DashboardCtrl.resolve = {

  org: ['OrgService', 'OrgSelectorService', 'currentUser', function (OrgService, OrgSelectorService, currentUser) {

    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],

  charts: ['DashboardService', 'org', function (DashboardService, org) {

    return DashboardService.charts(org.id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('DashboardCtrl', DashboardCtrl);
;
'use strict';

/*
 *  Controller for displaying debug logs
 *  @name backlineAdminPlus.controller:DebugCtrl
 *  @ngInject
 */
function DebugCtrl( DebugService, DebugTypes ) {

  var debug = this;

  // get logs from debug service
  debug.logs = DebugService.logs;

  // get debug types
  debug.service = DebugTypes.service;
  debug.state = DebugTypes.state;
}

angular.module('backlineAdminPlus')
    .controller('DebugCtrl', DebugCtrl);
;
'use strict';

/*
 *  Controller for setting organization default settings
 *  @name backlineAdminPlus.controller:DefaultsCtrl
 *  @ngInject
 */
function DefaultsCtrl( $q, OrgService, MedHxService, SettingService, ErrorService, ToastService, SettingKeys,
                       ChatWithPatientOptions, WeblinkOptions, MedlistOptions, model, ippEnabled, idmEnabled, idmSystemEnabled,
                       chatwithpatientExpiration, timeZone, weblinkExpiration, medlistExpiration,
                       emsWorkflowEnabled, medHx2Username, medHx2Password,
                       TimeZones ) {

  var defaults = this;

  defaults.chatWithPatientOptions = ChatWithPatientOptions;
  defaults.weblinkOptions = WeblinkOptions;
  defaults.medlistOptions = MedlistOptions;
  defaults.timeZones = TimeZones;

  // update defaults based on new org, settings
  function _updateDefaults( org, ippenabled, idmenabled, idmsystemenabled, chatwithpatient, weblink, medlist, emsworkflow, medhx2username, medhx2password, timezone ) {

    defaults.model = org;

    // update org autopurge settings
    defaults.autopurgePrivate = org.auto_purge_one_on_one;
    defaults.autopurgeGroups = org.auto_purge_groups;
    defaults.autopurgePCC = org.auto_purge_pcc;
    defaults.autopurgeAlerts = org.auto_purge_alerts;
    defaults.autopurgeTimeout = org.auto_purge_time_period_in_seconds / (60 * 60);

    // update org softdelete settings
    defaults.softdeletePrivate = org.soft_delete_one_on_one;
    defaults.softdeleteGroups = org.soft_delete_groups;
    defaults.softdeletePCC = org.soft_delete_pcc;
    defaults.softdeleteAlerts = org.soft_delete_alerts;
    defaults.softdeleteTimeout = org.soft_delete_time_period_in_seconds / (24 * 60 * 60);

    // update weblink timeout setting
    defaults.weblink = weblink;
    defaults.weblinkTimeout = parseInt(weblink.value, 10);

    defaults.ippEnabled = (parseInt(ippenabled.value, 10) == 1);
    if (defaults.ippEnabled) {
      defaults.chatWithPatient = chatwithpatient;
      defaults.chatWithPatientTimeout = parseInt(chatwithpatient.value, 10);
    }

    defaults.idmenabled = idmenabled;
    defaults.idmEnabled = (parseInt(idmenabled.value, 10) == 1);
    defaults.idmsystemenabled = idmsystemenabled;
    defaults.idmSystemEnabled = (parseInt(idmsystemenabled.value, 10) == 1);

    defaults.emsworkflow = emsworkflow;
    defaults.emsWorkflowEnabled = (parseInt(emsworkflow.value, 10) == 1);

    defaults.medlist = medlist;
    if (medlist !== undefined) {
      defaults.medlistExpiration = parseInt(medlist.value, 10);
    } else {
      defaults.medlistExpiration = 14400;
    }

    defaults.pccExpirationTimeInDays = org.pcc_expiration_time_in_seconds / (24 * 60 * 60);
    defaults.medhx2username = medhx2username;
    defaults.medHx2Username = medhx2username.value;
    defaults.medhx2password = medhx2password;
    defaults.medHx2Password = medhx2password.value;
    defaults.medHxResults = null;
    defaults.timezone = timezone;
    defaults.timeZone = timezone.value;
  }

  // set defaults
  _updateDefaults(model, ippEnabled, idmEnabled, idmSystemEnabled, chatwithpatientExpiration, weblinkExpiration, medlistExpiration,
                  emsWorkflowEnabled, medHx2Username, medHx2Password, timeZone);
  defaults.dirty = true;

  function pingMedHx(org, username, password) {
    return MedHxService.ping(org, username, password);
  }

  defaults.pingMedHx = pingMedHx;

  defaults.test_medhx = function () {
    defaults.medHxResults = defaults.pingMedHx(defaults.model, defaults.medHx2Username, defaults.medHx2Password);
  };

  /*
   *  View method
   *  Saves all the settings
   */
  defaults.save = function () {
    defaults.saving = true;

    // do minimum checking for auto purge timeout
    if (defaults.autopurgeTimeout < 12) {
      defaults.autopurgeTimeout = 12;
    }

    // set params for org update request

    var params = {
      id: defaults.model.id,
      auto_purge_one_on_one: defaults.autopurgePrivate,
      auto_purge_groups: defaults.autopurgeGroups,
      auto_purge_pcc: defaults.autopurgePCC,
      auto_purge_alerts: defaults.autopurgeAlerts,
      auto_purge_time_period_in_seconds: defaults.autopurgeTimeout * (60 * 60),
      soft_delete_one_on_one: defaults.softdeletePrivate,
      soft_delete_groups: defaults.softdeleteGroups,
      soft_delete_pcc: defaults.softdeletePCC,
      soft_delete_alerts: defaults.softdeleteAlerts,
      soft_delete_time_period_in_seconds: defaults.softdeleteTimeout * (24 * 60 * 60),
      pcc_expiration_time_in_seconds: defaults.pccExpirationTimeInDays * (24 * 60 * 60)
    };

    // gather necessary calls
    var requests = {
      org: OrgService.update(params),
      ippenabled: SettingService.find(SettingKeys.ippEnabled, defaults.model),
      idmenabled: SettingService.set(defaults.idmenabled.setting_id, defaults.model, defaults.idmEnabled),
      idmsystemenabled: SettingService.find(SettingKeys.idmSystemEnabled, defaults.model),
      weblink: SettingService.set(defaults.weblink.setting_id, defaults.model, defaults.weblinkTimeout),
      medlist: SettingService.set(defaults.medlist.setting_id, defaults.model, defaults.medlistExpiration),
      emsworkflow: SettingService.find(SettingKeys.emsWorkflowEnabled, defaults.model),
      timezone: SettingService.set(defaults.timezone.setting_id, defaults.model, defaults.timeZone)
    };

    if (defaults.medHx2Username) {
      requests.medhx2username = SettingService.set(defaults.medhx2username.setting_id, defaults.model, defaults.medHx2Username);
    }
    if (defaults.medHx2Password) {
      requests.medhx2password = SettingService.set(defaults.medhx2password.setting_id, defaults.model, defaults.medHx2Password);
    }

    if (defaults.ippEnabled) {
      requests.chatwithpatient = SettingService.set(defaults.chatWithPatient.setting_id, defaults.model, defaults.chatWithPatientTimeout);
    }

    // combine calls into one promise
    $q.all(requests).then(function ( response ) {

      defaults.saving = false;

      // update settings
      _updateDefaults(response.org, response.ippenabled, response.idmenabled, response.idmsystemenabled, response.chatwithpatient, response.weblink, response.medlist,
                      response.emsworkflow, (response.medhx2username || ''), (response.medhx2password || ''), response.timezone);

      ToastService.show({
        title: 'Saved',
        message: 'All changes saved successfully',
        type: 'success'
      });

    }, function ( error ) {

      defaults.saving = false;

      ErrorService.handle(error);
    });
  };

  /*
   *  Directive callback
   *  When global org changes, refresh settings
   */
  defaults.onSelectOrg = function ( org ) {

    // gather calls
    var requests = {
      org: OrgService.getOrg(org.id),
      ippenabled: SettingService.find(SettingKeys.ippEnabled, org),
      idmenabled: SettingService.find(SettingKeys.idmEnabled, org),
      idmsystemenabled: SettingService.find(SettingKeys.idmSystemEnabled, org),
      chatwithpatient: SettingService.find(SettingKeys.chatWithPatientExpiration, org),
      weblink: SettingService.find(SettingKeys.weblinkExpiration, org),
      medlist: SettingService.find(SettingKeys.medlistExpiration, org),
      emsworkflow: SettingService.find(SettingKeys.emsWorkflowEnabled, org),
      medhx2username: SettingService.find(SettingKeys.medHx2Username, org),
      medhx2password: SettingService.find(SettingKeys.medHx2Password, org),
      timezone: SettingService.find(SettingKeys.timeZone, org)
    };

    // combine requests into one promise
    $q.all(requests).then(function ( response ) {

      // update settings
      _updateDefaults(response.org, response.ippenabled, response.idmenabled, response.idmsystemenabled, response.chatwithpatient,
                      response.weblink, response.medlist, response.emsworkflow, response.medhx2username, response.medhx2password,
                      response.timezone);

    }, function ( error ) {
      ErrorService.handle(error);
    });
  };
}

/* @ngInject */
DefaultsCtrl.resolve = {
  model: ['OrgService', 'OrgSelectorService', 'currentUser', function ( OrgService, OrgSelectorService, currentUser ) {

    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],

  ippEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.ippEnabled, OrgSelectorService.getOrg());
  }],

  idmEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.idmEnabled, OrgSelectorService.getOrg());
  }],

  idmSystemEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.idmSystemEnabled, OrgSelectorService.getOrg());
  }],

  chatwithpatientExpiration: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function ( SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.chatWithPatientExpiration, OrgSelectorService.getOrg());
  }],

  timeZone: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function ( SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.timeZone, OrgSelectorService.getOrg());
  }],

  weblinkExpiration: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function ( SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.weblinkExpiration, OrgSelectorService.getOrg());
  }],

  medlistExpiration: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function ( SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.medlistExpiration, OrgSelectorService.getOrg());
  }],

  emsWorkflowEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.emsWorkflowEnabled, OrgSelectorService.getOrg());
  }],

  medHx2Username: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.medHx2Username, OrgSelectorService.getOrg());
  }],

  medHx2Password: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {

    return SettingService.find(SettingKeys.medHx2Password, OrgSelectorService.getOrg());
  }],

  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('DefaultsCtrl', DefaultsCtrl);
;
'use strict';

/*
 *  Controller for viewing and changing departments
 *  @name backlineAdminPlus.controller:DepartmentsCtrl
 *  @ngInject
 */
function DepartmentsCtrl(ConfirmationService,
                         DepartmentService,
                         GroupService,
                         OrgSelectorService,
                         OrgService,
                         SettingKeys,
                         SettingService,
                         departmentList,
                         currentUser,
                         org,
                         idmEnabled,
                         idmSystemEnabled) {

  var departments = this,
    _perPage = 20,
    _sortColumn,
    _sortDirection;

  // query parameters
  departments.query = '';
  departments.page = 1;
  departments.selected = undefined;
  departments.filter = 'true';

  // initial departments info
  departments.list = departmentList.departments;
  departments.totalPages = departmentList.pages;
  departments.org = org;
  departments.idmEnabled = (parseInt(idmEnabled.value, 10) == 1);
  departments.idmSystemEnabled = (parseInt(idmSystemEnabled.value, 10) == 1);

  // set sortable columns
  departments.sortableColumns = [
    0      // department name
    // 1,  // topic (feed description)
    // 2,  // members
    // 3,  // active (enabled)
    // 4,  // messages expire? (soft_delete_groups)
    // 5,  // actions
  ];

  departments.filterStatuses = [
    {value: '', label: 'All'},
    {value: 'true', label: 'Active'},
    {value: 'false', label: 'Inactive'}
  ];

  // refresh list of departments
  function _refreshDepartments() {

    DepartmentService.list({
      org_id: OrgSelectorService.getOrg().id,
      query: departments.query,
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      per_page: _perPage,
      page: departments.page,
      enabled: departments.filter
    }).then(function ( response ) {
      departments.list = response.departments;
      departments.totalPages = response.pages;
    });
    SettingService.findByOrgId(SettingKeys.idmEnabled, OrgSelectorService.getOrg().id)
      .then(function (settingValue) {
        departments.idmEnabled = (parseInt(settingValue.value, 10) == 1);
      }, function ( error ) {
        departments.idmEnabled = 0;
      });

    SettingService.findByOrgId(SettingKeys.idmSystemEnabled, OrgSelectorService.getOrg().id)
      .then(function (settingValue) {
        departments.idmSystemEnabled = (parseInt(settingValue.value, 10) == 1);
      }, function ( error ) {
        departments.idmSystemEnabled = 0;
      });
  }

  /*
   *  Input callback
   *  Update department when attributes are toggled
   */
  departments.onToggle = function ( department ) {

    GroupService.toggle(department.group);
  };

  /*
   *  View method
   *  Resets the department list with new parameters
   */
  departments.search = function () {

    departments.page = 1;
    _refreshDepartments();
  };

  /*
   *  View method
   *  Requests the next page of departments
   */
  departments.next = function () {

    departments.page += 1;
    _refreshDepartments();
  };

  /*
   *  View method
   *  Requests the previous page of departments
   */
  departments.previous = function () {

    departments.page -= 1;
    _refreshDepartments();
  };

  /*
   *  View method
   *  Sets this department as selected for use in modals
   */
  departments.setSelectedDepartment = function ( department ) {

    departments.selected = department;
    departments.selectedCopy = angular.copy(departments.selected);
  };

  /*
   *  Modal callback
   *  Updates the selected departments attributes and removes the references
   */
  departments.onUpdateDepartment = function ( updated ) {

    departments.selected.name = updated.name;
    departments.selected.group = angular.copy(updated.group);

    departments.selected = undefined;
    departments.selectedCopy = undefined;
  };

  /*
   *  Modal callback
   *  Sets the search query to the new department name and searches it
   */
  departments.onAddDepartment = function ( department ) {

    departments.query = department.name;
    departments.search();
  };

  /*
   *  Directive callback
   *  When the global org changes, reset departments
   */
  departments.onSelectOrg = function ( org ) {

    OrgService.getOrg(org.id).then(function (org) {
      departments.org = org;
    });

    departments.search();
  };

  /*
   *  Directive callback
   *  When sorting info changes, refresh list to reflect
   */
  departments.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    departments.search();
  };

  /*
   *  View method
   *  Removes this department, asks to confirm
   */
  departments.removeDepartment = function ( department ) {

    ConfirmationService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to remove this department?',
      callback: function () {

        DepartmentService.remove(department)
          .then(function ( response ) {
            _refreshDepartments();
          });
      }
    });
  };
}

/* @ngInject */
DepartmentsCtrl.resolve = {
  departmentList: ['DepartmentService', 'OrgSelectorService', 'currentUser', function ( DepartmentService, OrgSelectorService, currentUser ) {
    return DepartmentService.list({ org_id: OrgSelectorService.getOrg().id, query: '', per_page: 20, page: 1, enabled: 'true' });
  }],
  org: ['OrgService', 'OrgSelectorService', 'currentUser', function ( OrgService, OrgSelectorService, currentUser ) {
    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],
  idmEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {
    return SettingService.find(SettingKeys.idmEnabled, OrgSelectorService.getOrg());
  }],
  idmSystemEnabled: ['SettingService', 'SettingKeys', 'OrgSelectorService', 'currentUser', function (SettingService, SettingKeys, OrgSelectorService, currentUser ) {
    return SettingService.find(SettingKeys.idmSystemEnabled, OrgSelectorService.getOrg());
  }]
};

angular.module('backlineAdminPlus')
  .controller('DepartmentsCtrl', DepartmentsCtrl);
;
'use strict';

/*
 *  Controller for setting organization ehr settings
 *  @name backlineAdminPlus.controller:EHRCtrl
 *  @ngInject
 */
function EHRCtrl( $log, $sce, TokenService, SettingsUrl, OrgSelectorService, currentUser ) {

  var ehr = this;

  // get org
  ehr.org = OrgSelectorService.getOrg();

  // update setting location when org changes
  function _updateURL() {
    ehr.url = $sce.trustAsResourceUrl(SettingsUrl + '/desktop/organization/' + TokenService.getAuthToken() + '/' + ehr.org.id + '/hl7-rules');
  }

  // update when page loads
  _updateURL();

  /*
   *  Directive callback
   *  When global org changes, refresh url
   */
  ehr.onSelectOrg = function ( org ) {

    ehr.org = org;
    _updateURL();
  };
}

angular.module('backlineAdminPlus')
  .controller('EHRCtrl', EHRCtrl);
;
'use strict';

/*
 *  Controller for viewing and changing groups
 *  @name backlineAdminPlus.controller:GroupsCtrl
 *  @ngInject
 */
function GroupsCtrl(GroupService,
                    OrgSelectorService,
                    groupList,
                    currentUser,
                    org,
                    OrgService) {

  var groups = this,
    _perPage = 20,
    _sortColumn,
    _sortDirection;

  // query parameters
  groups.query = '';
  groups.page = 1;
  groups.selected = undefined;

  // initial groups info
  groups.list = groupList.groups;
  groups.totalPages = groupList.pages;
  groups.org = org;

  // set sortable columns
  groups.sortableColumns = [
    0,    // feed name
    1,    // topic (description)
    // 2, // members
    3,    // private (feed_type)
    4     // active (enabled)
    // 5  // messages expire? (soft_delete_groups)
    // 6  // actions
  ];

  // refresh list of groups
  function _refreshGroups(){

    GroupService.list({
      org_id: OrgSelectorService.getOrg().id,
      feed_name: groups.query,
      official: false, // non-departments list only
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      per_page: _perPage,
      page: groups.page
    }).then(function ( response ) {
      groups.list = response.groups;
      groups.totalPages = response.pages;
    });
  }

  /*
   *  Input callback
   *  Update group when attributes are toggled
   */
  groups.onToggle = function ( group ) {

    GroupService.toggle(group);
  };

  /*
   *  View method
   *  Resets the group list with new parameters
   */
  groups.search = function () {

    groups.page = 1;
    _refreshGroups();
  };

  /*
   *  View method
   *  Requests the next page of groups
   */
  groups.next = function () {

    groups.page += 1;
    _refreshGroups();
  };

  /*
   *  View method
   *  Requests the previous page of groups
   */
  groups.previous = function () {

    groups.page -= 1;
    _refreshGroups();
  };

  /*
   *  View method
   *  Sets this group as selected for use in modals
   */
  groups.setSelectedGroup = function ( group ) {

    groups.selected = group;
    groups.selectedCopy = angular.copy(groups.selected);
  };

  /*
   *  Modal callback
   *  Updates the selected groups attributes and removes the references
   */
  groups.onUpdateGroup = function ( updated ) {

    groups.selected.feed_name = updated.feed_name;
    groups.selected.description = updated.description;

    groups.selected = undefined;
    groups.selectedCopy = undefined;
  };

  /*
   *  Modal callback
   *  Sets the search query to the new group name and searches it
   */
  groups.onAddGroup = function ( group ) {

    groups.query = group.feed_name;
    groups.search();
  };

  /*
   *  Directive callback
   *  When the global org changes, reset groups
   */
  groups.onSelectOrg = function ( org ) {

    OrgService.getOrg(org.id).then(function (org) {
      groups.org = org;
    });

    groups.search();
  };

  /*
   *  Directive callback
   *  When sorting info changes, refresh list to reflect
   */
  groups.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    groups.search();
  };
}

/* @ngInject */
GroupsCtrl.resolve = {
  groupList: ['GroupService', 'OrgSelectorService', 'currentUser', function ( GroupService, OrgSelectorService, currentUser ) {
    return GroupService.list({ org_id: OrgSelectorService.getOrg().id, official: false, per_page: 20, page: 1 });
  }],
  org: ['OrgService', 'OrgSelectorService', 'currentUser', function ( OrgService, OrgSelectorService, currentUser ) {
    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('GroupsCtrl', GroupsCtrl);
;
'use strict';

/*
 *  Controller for logging into enterprise
 *  @name backlineAdminPlus.controller:LoginCtrl
 *  @ngInject
 */
function LoginCtrl( $state, TokenService, WebplusUrl, AdminVersion ) {

  var login = this;

  // set view vars
  login.backlineUrl = WebplusUrl;
  login.version = AdminVersion;

  /*
   *  View method
   *  Attempts to use supplied credentials to sign in
   */
  this.signin = function () {
    login.working = true;

    TokenService.login(login.email, login.pw).then(function (response) {
      if (!response.user) {
        $state.go('loginotp', { email: login.email, password: login.pw, firstTime: response.firstTime });
      } else {

        $state.go('admin.dashboard').then(function () {

          // don't stop showing working state until state change completes because
          // dashboard controller resolvers can take a while
          login.working = false;
        });
      }
    }, function ( error ) {

      login.working = false;

      // reset password to empty
      login.password = undefined;

      login.error = 'That email address and/or password does not match our records';
    });
  };
}

angular.module('backlineAdminPlus')
  .controller('LoginCtrl', LoginCtrl);
;
'use strict';

/*
 *  Controller for logging into enterprise with 2FA
 *  @name backlineAdminPlus.controller:LoginOtpCtrl
 *  @ngInject
 */
function LoginOtpCtrl( $state, $stateParams, TokenService, AdminVersion ) {

  var loginOtp = this;

  loginOtp.password = $stateParams.password;
  loginOtp.email = $stateParams.email;
  loginOtp.firstTime = $stateParams.firstTime;

  // set view vars
  loginOtp.version = AdminVersion;
  if (!loginOtp.password || !loginOtp.email) {
    $state.go('login');
  }

  /*
   *  View method
   *  Attempts to use supplied credentials to sign in
   */
  this.signinOtp = function () {

    loginOtp.working = true;

    TokenService.loginOtp(loginOtp.email, loginOtp.password, loginOtp.otpAttempt).then(function () {

      $state.go('admin.dashboard').then(function () {

        // don't stop showing working state until state change completes because
        // dashboard controller resolvers can take a while
        loginOtp.working = false;
      });

    }, function ( error ) {
      loginOtp.working = false;

      // reset password to empty
      loginOtp.opt = undefined;

      loginOtp.error = 'That OTP is not correct';
    });
  };

  this.regenerateOtp = function () {
    TokenService.regenerateOtp(loginOtp.email, loginOtp.password).then(function () {
      loginOtp.success = true;
      loginOtp.regenerationSuccess = true;
      loginOtp.error = null;
    }, function ( error ) {
      loginOtp.success = false;
      loginOtp.regenerationSuccess = false;
      loginOtp.error = 'Something went wrong. Please contact to the administrator';
    });
  };
}

angular.module('backlineAdminPlus')
  .controller('LoginOtpCtrl', LoginOtpCtrl);
;
'use strict';

/*
 *  Controller for showing network details
 *  @name backlineAdminPlus.controller:NetworkDetailCtrl
 *  @ngInject
 */
function NetworkDetailCtrl( $q, $filter, $state, NetworkService, ErrorService, ConfirmationService, OrgSelectorService, model ) {

  var network = this;

  network.selectedSuborg = OrgSelectorService.getOrg();

  // update view vars when model changes
  function _updateNetwork( model ) {

    network.model = model;

    network.id = model.id;
    network.name = model.name;
    network.controlOrg = model.control_org;
    network.searchability = model.searchability;
    network.business_type = model.business_type;

    network.external_patient_data_source = model.external_patient_data_source =
      NetworkService.external_patient_data_source_id(model.external_patient_data_source);

    // options
    network.searchability_options = NetworkService.searchability_options();
    network.external_patient_data_source_options = NetworkService.external_patient_data_source_options();
    network.business_type_options = NetworkService.business_type_options();
  }

  // update list when suborgs change
  function _updateSubOrgs( suborgs ) {

    network.suborgs = $filter('filter')(suborgs, { enabled: true });
  }

  // update when page loads
  _updateNetwork(model);
  _updateSubOrgs(model.orgs);

  /*
   *  Modal callback
   *  When network changes, call for update
   *  @refactor
   */
  network.edit = function () {
    var deferred = $q.defer();

    NetworkService.update(network.model)
      .then(function ( response ) {
        _updateNetwork(response);
        deferred.resolve();
      }, function ( error ) {
        deferred.reject();

        ErrorService.show(error);
      });

    return deferred.promise;
  };

  /*
   *  View method
   *  Attempts to add `selectedSuborg` to this networks orgs
   */
  network.addSuborg = function () {

    if (network.selectedSuborg) {

      network.error = undefined;

      NetworkService.addSuborg(network.id, network.selectedSuborg.id)
        .then(function ( response ) {
          _updateSubOrgs(response);
          network.selectedSuborg = undefined;
        });

    } else {
      network.error = 'No Sub-Organization selected';
    }
  };

  /*
 *  View method
 *  Attempts to move `selectedSuborg` to this networks orgs
 */
  network.moveSuborg = function () {
    if (network.selectedSuborg) {
      network.error = undefined;

      NetworkService.moveSuborg(network.id, network.selectedSuborg.id)
        .then(function (response) {
          _updateSubOrgs(response);
          network.selectedSuborg = undefined;
        });
    } else {
      network.error = 'No Sub-Organization selected';
    }
  };

  /*
   *  View method
   *  Removes this suborg, asks to confirm
   */
  network.removeSuborg = function ( suborg ) {

    ConfirmationService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to remove this suborg?',
      callback: function () {

        NetworkService.removeSuborg(network.id, suborg)
          .then(function ( response ) {
            _updateSubOrgs(response);
          });
      }
    });
  };

  /*
   *  View method
   *  Delete this network, asks to confirm
   */
  network.deleteNetwork = function () {

    ConfirmationService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete this network?',
      callback: function () {

        NetworkService.deleteNetwork(network.id)
          .then(function ( response ) {
            $state.go('admin.networks', {}, { reload: true });
          });
      }
    });
  };

  /*
   *  Directive Callback
   *  When org changes, update tracking var
   */
  network.onSelectOrg = function ( org ) {

    network.selectedSuborg = org;
  };
}

/* @ngInject */
NetworkDetailCtrl.resolve = {
  model: ['$stateParams', 'NetworkService', function ( $stateParams, NetworkService ) {
    return NetworkService.show($stateParams.id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('NetworkDetailCtrl', NetworkDetailCtrl);
;
'use strict';

/*
 *  Controller for viewing and creating networks
 *  @name backlineAdminPlus.controller:NetworksCtrl
 *  @ngInject
 */
function NetworksCtrl( $q, NetworkService, networkList ) {

  var networks = this,
    _perPage = 20;

  networks.page = 1;

  // get networks list
  networks.list = networkList;

  /*
   *  Modal callback
   *  When network is added, add to list
   */
  networks.onAddNetwork = function (network) {
    networks.list.push(network);
  };

  // update list vars based on response
  function _updateNetworks(response) {
    networks.list = response.networks;
    networks.totalPages = response.meta['X-Total-Pages'];
  }

  // refresh list of networks
  function _refreshNetworks() {

    NetworkService.list({
      page: networks.page,
      per_page: _perPage
    }).then(function (response) {
      _updateNetworks(response);
    });
  }

  _updateNetworks(networkList);

  /*
     *  View method
     *  Get next page of results
     */
  networks.next = function () {

    networks.page += 1;
    _refreshNetworks();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  networks.previous = function () {

    networks.page -= 1;
    _refreshNetworks();
  };
}

/* @ngInject */
NetworksCtrl.resolve = {
  networkList: ['NetworkService', function (NetworkService) {
    return NetworkService.list({page: 1});
  }]
};

angular.module('backlineAdminPlus')
  .controller('NetworksCtrl', NetworksCtrl);
;
'use strict';

/*
 *  Controller for uploading user csv
 *  @name backlineAdminPlus.controller:UserBatchCtrl
 *  @ngInject
 */
function UserBatchCtrl($scope, $log, $interval, $filter, $stateParams, OrgSelectorService, OrgService, UserService, ErrorService, FileUploader, SettingService, SettingKeys, currentUser, batchPastUpload) {

  var batch = this;
  var batchResultRefresh;

  $scope.isLoading = false;
  $scope.creationProgressPercent = 0;
  $scope.unprocessedRecords = 1;

  batch.page = 1;
  $scope.totalProcessedItems = 0;

  // set defaults
  batch.sendEmails = true;
  $scope.previousUpload = batchPastUpload.batchUploads[0];
  batch.batch_uploads = [];
  batch.totalPages = batchPastUpload.pages;
  SettingService.find(SettingKeys.s3BucketUrl, $stateParams).then(function (url) {
    batch.s3BucketUrl = url;
  });

  // change selected org to query param id, if requested
  if ($stateParams.org) {
    selectOrg($stateParams.org);
  } else {
    selectOrg(OrgSelectorService.getOrg().id);
  }

  $scope.hasFinishedUpload = function () {
    return !$scope.isLoading && $scope.unprocessedRecords === 0 && $scope.totalProcessedItems > 0;
  };

  $scope.openTab = function (evt, tabName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' active';
  };

  // initialize uploader
  batch.uploader = new FileUploader({
    url: UserService.batchUploadUrl,
    autoUpload: true,
    formData: [
      {
        /* random 24 chars for db key */
        db_key: Math.random().toString(35).substring(2, 26)
      }
    ]
  });

  function _refreshbatch() {
    UserService.batchUploads({
      user_id: currentUser.id,
      org_id: OrgSelectorService.getOrg().id,
      per_page: 1,
      page: 1
    }).then(function ( response ) {
      batch.batch_uploads = response.batchUploads;
      $scope.totalProcessedItems = response.batchUploads[0].total_rows;
      $scope.unprocessedRecords = response.batchUploads[0].total_rows - ( response.batchUploads[0].created_count + response.batchUploads[0].error_count );
      $scope.creationProgressPercent = (($scope.totalProcessedItems - $scope.unprocessedRecords) * 100) / $scope.totalProcessedItems;
      $scope.totalPages = response.pages;
      return batch.batch_uploads;
    });
  }

  function _loadPastUpload() {
    UserService.batchUploads({
      user_id: currentUser.id,
      org_id: OrgSelectorService.getOrg().id,
      per_page: 1,
      page: batch.page
    }).then(function ( response ) {
      $scope.previousUpload = response.batchUploads[0];
      $scope.totalPages = response.pages;
    });
  }

  batch.next = function () {
    batch.page += 1;
    _loadPastUpload();
  };

  /*
   *  View method
   *  Requests the previous page of departments
   */
  batch.previous = function () {
    batch.page -= 1;
    _loadPastUpload();
  };

  function clearResultsInterval() {
    $interval.cancel(batchResultRefresh);
  }

  // add csv filter to the file selector
  batch.uploader.filters.push({
    name: 'csvFilter',
    fn: function (item) {
      if (item.type === 'application/vnd.ms-excel') {

        // allow excel files if they end in .csv
        return item.name.indexOf('.csv') >= 0;

      } else {

        // otherwise only allow mime type of csv
        return item.type === 'text/csv' || item.name.indexOf('.csv', item.name.length - '.csv'.length) !== -1;
      }
    }
  });

  batch.uploader.onAfterAddingFile = function (item) {
    if (batch.uploader.queue.length > 1) {
      batch.uploader.removeFromQueue(0);
      $scope.totalProcessedItems = 0;
      $scope.unprocessedRecords = 1;
    }
  };

  /*
   *  Input callback
   *  Check if the CSV filter prevented this file from being selected. If so, inform the user
   */
  batch.uploader.onWhenAddingFileFailed = function (item, filter) {
    if (filter.name === 'csvFilter') {
      batch.error = 'This is not a CSV File. Please attach a correctly formatted file';
      batch.uploader.clearQueue();
    }
  };

  /*
   *  Input callback
   *  Before uploading file to the server, add some information that may have changed since initialization
   */
  batch.uploader.onBeforeUploadItem = function (item) {
    $scope.isLoading = true;
    item.formData.push({
      org_id: batch.org.id,
      send_emails: batch.sendEmails
    });
  };

  /*
   *  Input callback
   *  When an error occurs during upload, inform user
   */
  batch.uploader.onErrorItem = function (item, response, status) {
    $scope.isLoading = false;
    ErrorService.show(status, {
      title: 'Unable to complete upload',
      message: response.error_text
    });
  };

  /*
   *  Input callback
   *  When the file uploads successfully, show results
   */
  batch.uploader.onSuccessItem = function () {
    $scope.isLoading = true;
    batch.page += 1;
    batchResultRefresh = $interval(function () {
      _refreshbatch();
      if ($scope.unprocessedRecords === 0){
        clearResultsInterval();
        angular.element(document).find('#file-select')[0].value = '';
        $scope.isLoading = false;
      }
    }, 1000);
  };

  /*
   *  View method
   *  Download template csv file
   */
  batch.downloadTemplate = function () {
    UserService.downloadBatchTemplate();
  };

  /*
   *  View method
   *  Download template csv file
   */
  batch.downloadLdapTemplate = function () {
    UserService.downloadLdapBatchTemplate(batch.s3BucketUrl.value);
  };

  /*
   *  Directive callback
   *  When context org is changed, update tracking org
   */
  batch.onSelectOrg = function (org) {
    selectOrg(org.id);
  };

  function selectOrg(id) {
    OrgService.getOrg(id).then(function (response) {
      batch.org = response;
    });
    _loadPastUpload();
  }

  // $scope.batchUploadFinished = function () {
  //   return $filter('filter')(batch.uploader.queue, 'medium');
  // };
}

/* @ngInject */
UserBatchCtrl.resolve = {
  batchPastUpload: ['UserService', 'OrgSelectorService', 'currentUser', function ( UserService, OrgSelectorService, currentUser ) {
    return UserService.batchUploads({ user_id: currentUser.id, org_id: OrgSelectorService.getOrg().id, per_page: 1, page: 1 });
  }]
};

angular.module('backlineAdminPlus')
  .controller('UserBatchCtrl', UserBatchCtrl);
;
'use strict';
/* globals JSON */

/*
 *  Controller for showing org database in raw format
 *  @name backlineAdminPlus.controller:OrgDatabaseCtrl
 *  @ngInject
 */
function OrgDatabaseCtrl( OrgService, model ) {

  var org = this;

  org.model = model;

  org.string = JSON.stringify(org.model, null, '    ');
}

/* @ngInject */
OrgDatabaseCtrl.resolve = {
  model: ['$stateParams', 'OrgService', function ( $stateParams, OrgService ) {
    return OrgService.getOrg($stateParams.id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('OrgDatabaseCtrl', OrgDatabaseCtrl);
;
'use strict';

/*
 *  Controller for showing org details
 *  @name backlineAdminPlus.controller:OrgDetailCtrl
 *  @ngInject
 */
function OrgDetailCtrl( $q, $state, OrgService, UserService, OrgSelectorService, model, users,
                        orgHieEnabled, ippEnabled, assignmentsEnabled, ImplList,
                        externalPatientSearch, ExternalPatientSearchOptions, insightsEnabled,
                        emsWorkflowEnabled, emsWebWorkflowEnabled, esignEnabled, twilioVideoChatEnabled, twilioExamRoomsEnabled,
                        webMsgProtocol, pointClickCare, populusMedia, schedules, SettingService, SettingKeys, LdapService, massMessaging,
                        crossNetworkFeatureEnabled, currentUser) {

  var org = this,
    _perPage = 25,
    _sortColumn,
    _sortDirection,
    _crossNetworkFeatureEnabled = crossNetworkFeatureEnabled;

  org.orgHieEnabled = orgHieEnabled;
  org.ippEnabled = ippEnabled;
  org.insightsEnabled = insightsEnabled;
  org.emsWorkflowEnabled = emsWorkflowEnabled;
  org.emsWebWorkflowEnabled = emsWebWorkflowEnabled;
  org.esignEnabled = esignEnabled;
  org.twilioVideoChatEnabled = twilioVideoChatEnabled;
  org.twilioExamRoomsEnabled = twilioExamRoomsEnabled;
  org.webMsgProtocol = webMsgProtocol;
  org.pointClickCare = pointClickCare;
  org.populusMedia = populusMedia;
  org.schedules = schedules;
  org.massMessaging = massMessaging;
  org.assignmentsEnabled = assignmentsEnabled;
  if (externalPatientSearch) {
    org.externalPatientSearch = parseInt(externalPatientSearch.value, 10);
    console.log(org.externalPatientSearch);
  } else {
    org.externalPatientSearch = null;
  }
  org.externalPatientSearchOptions = ExternalPatientSearchOptions;
  org.crossNetworkFeatureEnabledDirty = false;
  org.currentUser = currentUser;
  org.implementation_status = model.implementation_status;
  org.implList = ImplList;
  org.usingPatientFilter = false;

  org.crossNetworkFeatureEnabled = function (newValue) {
    if (arguments.length === 1) {
      org.crossNetworkFeatureEnabledDirty = true;
      SettingService.set(_crossNetworkFeatureEnabled.setting_id, org.model, newValue ? '1' : '0')
        .then(function (setting) {
          org.crossNetworkFeatureEnabledDirty = false;
          _crossNetworkFeatureEnabled.value = setting.value;
        });
    } else {
      return _crossNetworkFeatureEnabled.value === '1';
    }
  };

  org.toggleCrossNetworkFeatureEnabled = function () {
    org.crossNetworkFeatureEnabled(!org.crossNetworkFeatureEnabled());
  };

  // called to update attributes with new information from server
  function _updateOrg( model ) {
    org.model = model;

    // profile info
    org.id = model.id;
    org.network = model.network;
    org.networks = model.networks;
    org.name = model.name;
    org.backline = model.backline_id;
    org.npi = model.npi;
    org.address = model.address;
    org.city = model.city;
    org.state = model.state;
    org.postal_code = model.postal_code;
    org.phone = model.phone_number;
    org.fax = model.fax_number;
    org.moxy_name = model.moxy_name;
    org.is_test_org = model.is_test_org;
    org.external_id = model.external_id;
    org.external_account_id = model.external_account_id;
    org.implementation_status = model.implementation_status;
    org.crm_customer_id = model.crm_customer_id;

    // license info
    org.usedLicenses = model.active_licenses;
    org.totalLicenses = model.user_licenses;
    org.schedulingLicenses = model.scheduling_licenses;
    org.schedulingLicensesRemaining = model.scheduling_licenses_remaining;
    org.telehealthLicenses = model.telehealth_licenses;
    org.telehealthLicensesRemaining = model.telehealth_licenses_remaining;

    org.populus_keywords = model.populus_keywords;

    // plugin settings
    org.hl7PccEnabled = model.hl7_enabled;
    org.hl7NotificationsEnabled = model.hl7_notifications_enabled;
    org.ldapEnabled = model.ldap_enabled;
    org.activeDirectorySync = model.active_directory_sync;
    org.activeDirectoryIsLegacy = model.active_directory_is_legacy;
    org.ldapDryRunEnabled = model.ldap_dry_run;

    org.ssoEnabled = model.sso_enabled;

    var emailDomain = [];

    if (Array.isArray(model.sso_email_domains)) {
      model.sso_email_domains.forEach(function (domain) {
        emailDomain.push({value: domain});
      });
    }

    // Adding empty placeholder if there is no any email_domain set
    if (emailDomain.length === 0) {
      emailDomain.push({value: ''});
    }

    org.ssoEmailDomains = emailDomain;

    var ldapAuth = model.ldap_auth || {method: 'simple', username: '', password: ''};
    var ldapAuthKeys = Object.keys(ldapAuth);

    function isSimpleAuth(keys) {
      for (var i = keys.length - 1; i >= 0; i--) {
        if (keys[i] !== 'method' && keys[i] !== 'username' && keys[i] !== 'password') {
          return false;
        }
      }
      return true;
    }

    // TODO Move to a directive
    if (isSimpleAuth(ldapAuthKeys)) {
      org.ldap = {
        simple: ldapAuth,
        manual: [
          {key:'method', value: ldapAuth.method},
          {key:'username', value: ldapAuth.username},
          {key:'password', value: ldapAuth.password}
        ]
      };
      org.auth = 'simple';
    } else {
      org.ldap = {
        simple: {
          method: 'simple',
          username: ldapAuth.username || '',
          password: ldapAuth.password || ''
        }
      };
      org.ldap.manual = [];
      ldapAuthKeys.forEach(function (key) {
        org.ldap.manual.push({key: key, value: ldapAuth[key]});
      });
      org.auth = 'manual';
    }

    // security settings
    org.security = {
      defaultPatientSearch: model.default_patient_search_allowed,
      webTimeout: model.inactivity_timeout_seconds,
      pinTimeout: model.default_pin_delay,
      communityMessaging: model.allow_public_searchability,
      weblinkMessaging: model.allow_private_unregistered_user_communication,
      allowPrivateExternal: model.allow_one_to_one_cross_org_communication,
      allowPrivateGroupExternal: model.allow_private_groups_cross_org_communication,
      allowPublicGroupExternal: model.allow_public_groups_cross_org_communication,
      allowPrivateExternalAttachments: model.allow_one_to_one_external_attachments,
      allowPrivateGroupExternalAttachments: model.allow_invite_only_groups_external_attachments,
      allowPublicGroupExternalAttachments: model.allow_public_groups_external_attachments,
      allowPCCExternalAttachments: model.allow_patient_centered_external_attachments
    };
  }

  // update user information based on server data
  function _updateUsers( data ) {
    org.users = data.aaData;
    org.totalUsers = data.iTotalDisplayRecords;
    org.totalPages = Math.ceil(org.totalUsers / _perPage);
  }

  // called to refresh list of users
  function _getUsers() {

    // join user selected status filters
    var statusList;

    if (org.statuses && org.statuses.length > 0) {
      statusList = org.statuses.map(function ( status ) { return status.id; }).join(', ');
      org.usingPatientFilter = statusList.indexOf('10') >= 0;
    } else {
      org.usingPatientFilter = false;
    }

    // collect params
    var params = {
      forSummary: true,
      forOrgUserSummary: true,
      query: org.query,
      showStatuses: statusList,
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      page: org.page,
      per_page: _perPage
    };

    // refresh
    OrgService.users(org.id, params)
      .then(function ( response ) {
        _updateUsers(response);
      });
  }

  // set defaults
  _updateOrg(model);
  _updateUsers(users);

  org.query = '';
  org.page = 1;

  /*
   *  Input callback
   *  When query updates, search for users
   */
  org.search = function () {

    org.page = 1;
    _getUsers();
  };

  /*
   *  View method
   *  Get next page of results
   */
  org.next = function () {

    org.page += 1;
    _getUsers();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  org.previous = function () {

    org.page -= 1;
    _getUsers();
  };

  /*
   *  View method
   *  Update org to be enabled / disabled
   */
  org.enable = function ( status ) {

    org.model.enabled = status;
    OrgService.update(org.model).then(function ( response ) {
      _updateOrg(response);
    });
  };

  /*
   *  View method
   *  Change org context to this org and navigate to batch upload
   */
  org.batch = function () {

    OrgSelectorService.select(org.model);
    $state.go('admin.batch');
  };

  /*
   *  View method
   *  Change org context to this org and navigate to active directory sync
   */
  org.adSync = function ($event) {
    $event.target.disabled = true;
    $event.target.textContent = 'Sync In Progess';

    OrgService.adSync(org.model);
  };

  /*
   *  Directive callback
   *  Change org context to this org and navigate to security settings
   */
  org.configure = function () {

    OrgSelectorService.select(org.model);
    $state.go('admin.configs.security');
  };

  /*
   *  View method
   *  Export org users
   */
  org.exportUsers = function () {

    OrgService.exportUsers(org.model.id);
  };

  /*
 *  Modal callback
 *  Update org model with new attributes
 *  @refactor
 */
  org.onEdit = function () {
    var deferred = $q.defer();

    if (org.auth === 'simple') {
      org.model.ldap_auth = org.ldap.simple;
    } else if (org.auth === 'manual') {
      org.model.ldap_auth = {};
      org.ldap.manual.forEach(function (pair) {
        org.model.ldap_auth[pair.key] = pair.value;
      });
    }
    org.model.ldap_auth = JSON.stringify(org.model.ldap_auth);
    org.model.implementation_status = org.implementation_status;

    var ssoEmailDomainsArr = [];

    org.ssoEmailDomains.forEach(function (params, index) {
      ssoEmailDomainsArr[index] = params.value.trim();
    });

    org.model.sso_email_domains = ssoEmailDomainsArr.filter(function (el) { return el.length !== 0; });

    OrgService.update(org.model).then(function ( response ) {
      _updateOrg(response);
      deferred.resolve();
    }, function ( error ) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  /*
   *  Directive callback
   *  Update information when org is updated
   */
  org.onUpdate = function ( org ) {

    _updateOrg(org);
  };

  /*
   *  Directive callback
   *  Refresh users when list of status filters changes
   */
  org.onStatusListChange = function () {

    org.search();
  };

  /*
   *  Directive callback
   *  Search for the recently created user for easy access
   */
  org.onAddUser = function ( user ) {

    org.usedLicenses += 1;

    org.query = user.lname;
    org.search();
  };

  /*
   *  Directive callback
   *  Update sorting information and refresh users
   */
  org.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    org.search();
  };

  /**
   * Toggle HIE for this org.
   *
   * This function will use {@see org.orgHieEnabled} to negate the value
   */
  org.toggleHie = function () {
    org.orgHieEnabled.value = org.orgHieEnabled.value === '1' ? '0' : '1';
    SettingService.set(orgHieEnabled.setting_id, org.model, org.orgHieEnabled.value);
  };

  /**
   * Toggle IPP for this org.
   *
   * This function will use {@see org.ippEnabled} to negate the value
   */
  org.toggleIpp = function () {
    org.ippEnabled.value = org.ippEnabled.value === '1' ? '0' : '1';
    SettingService.set(ippEnabled.setting_id, org.model, org.ippEnabled.value);
  };

  /**
   * Toggle access to Insights reporting for this org
   */
  org.toggleInsights = function () {
    org.insightsEnabled.value = org.insightsEnabled.value === '1' ? '0' : '1';
    SettingService.set(insightsEnabled.setting_id, org.model, org.insightsEnabled.value);
  };

  org.toggleEmsWorkflow = function () {
    org.emsWorkflowEnabled.value = org.emsWorkflowEnabled.value === '1' ? '0' : '1';
    SettingService.set(emsWorkflowEnabled.setting_id, org.model, org.emsWorkflowEnabled.value);
  };

  org.toggleEmsWebWorkflow = function () {
    org.emsWebWorkflowEnabled.value = org.emsWebWorkflowEnabled.value === '1' ? '0' : '1';
    SettingService.set(emsWebWorkflowEnabled.setting_id, org.model, org.emsWebWorkflowEnabled.value);
  };

  org.toggleEsign = function () {
    org.esignEnabled.value = org.esignEnabled.value === '1' ? '0' : '1';
    SettingService.set(esignEnabled.setting_id, org.model, org.esignEnabled.value);
  };

  org.toggleTwilioVideoChatEnabled = function () {
    org.twilioVideoChatEnabled.value = org.twilioVideoChatEnabled.value === '1' ? '0' : '1';
    SettingService.set(twilioVideoChatEnabled.setting_id, org.model, org.twilioVideoChatEnabled.value);
  };

  org.toggleTwilioExamRoomsEnabled = function () {
    org.twilioExamRoomsEnabled.value = org.twilioExamRoomsEnabled.value === '1' ? '0' : '1';
    SettingService.set(twilioExamRoomsEnabled.setting_id, org.model, org.twilioExamRoomsEnabled.value);
  };

  org.toggleWebMsgProtocol = function () {
    org.webMsgProtocol.value = org.webMsgProtocol.value === '1' ? '2' : '1';
    SettingService.set(webMsgProtocol.setting_id, org.model, org.webMsgProtocol.value);
  };

  org.togglePointClickCare = function () {
    org.pointClickCare.value = org.pointClickCare.value === '1' ? '0' : '1';
    SettingService.set(pointClickCare.setting_id, org.model, org.pointClickCare.value);
  };

  org.togglePopulusMedia = function () {
    org.populusMedia.value = org.populusMedia.value === '1' ? '0' : '1';
    SettingService.set(populusMedia.setting_id, org.model, org.populusMedia.value);
  };

  org.toggleSchedules = function () {
    org.schedules.value = org.schedules.value === '1' ? '0' : '1';
    SettingService.set(schedules.setting_id, org.model, org.schedules.value);
  };

  org.toggleAssignments = function () {
    org.assignmentsEnabled.value = org.assignmentsEnabled.value === '1' ? '0' : '1';
    SettingService.set(assignmentsEnabled.setting_id, org.model, org.assignmentsEnabled.value);
  };

  org.toggleMassMessaging = function () {
    org.massMessaging.value = org.massMessaging.value === '1' ? '0' : '1';
    SettingService.set(massMessaging.setting_id, org.model, org.massMessaging.value);
  };

  org.setExternalPatientSearch = function () {
    var deferred = $q.defer();

    SettingService.set(externalPatientSearch.setting_id, org.model, org.externalPatientSearch);

    deferred.resolve();
    return deferred.promise;
  };

  /**
   * pings the ldap server
   * @param host the host of the ldap server
   * @param port the port of the host
   * @param user the canonical name for the user
   * @param password the password
   */
  function pingLdap(host, port, base) {
    return LdapService.ping(host, port, org.ldap.simple.username, org.ldap.simple.password, org.model.ldap_connect_str);
  }

  org.pingLdap1 = function () {
    org.pingLdap1Result = pingLdap(org.model.ldap_host, org.model.ldap_port);
  };

  org.pingLdap2 = function () {
    org.pingLdap2Result = pingLdap(org.model.ldap_host2, org.model.ldap_port2);
  };
}

/* @ngInject */
OrgDetailCtrl.resolve = {
  model: ['$stateParams', 'OrgService', function ( $stateParams, OrgService ) {
    return OrgService.getOrg($stateParams.id);
  }],

  users: ['$stateParams', 'OrgService', function ( $stateParams, OrgService ) {
    return OrgService.users($stateParams.id, { forSummary: true, forOrgUserSummary: true, page: 1, per_page: 25 });
  }],

  orgHieEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.orgHieEnabled, $stateParams);
  }],

  ippEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.ippEnabled, $stateParams);
  }],

  assignmentsEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ($stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.assignmentsEnabled, $stateParams);
  }],

  insightsEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.insightsEnabled, $stateParams);
  }],

  emsWorkflowEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.emsWorkflowEnabled, $stateParams);
  }],

  emsWebWorkflowEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.emsWebWorkflowEnabled, $stateParams);
  }],

  esignEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.esignEnabled, $stateParams);
  }],

  twilioVideoChatEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.twilioVideoChatEnabled, $stateParams);
  }],

  twilioExamRoomsEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.twilioExamRoomsEnabled, $stateParams);
  }],

  webMsgProtocol: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.webMsgProtocol, $stateParams);
  }],

  pointClickCare: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.pointClickCare, $stateParams);
  }],

  populusMedia: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.populusMedia, $stateParams);
  }],

  schedules: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.schedules, $stateParams);
  }],

  massMessaging: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.massMessaging, $stateParams);
  }],

  crossNetworkFeatureEnabled: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.crossNetworkFeatureEnabled, $stateParams);
  }],

  externalPatientSearch: ['$stateParams', 'SettingService', 'SettingKeys', function ( $stateParams, SettingService, SettingKeys ) {
    return SettingService.find(SettingKeys.externalPatientSearch, $stateParams);
  }]
};

angular.module('backlineAdminPlus')
  .controller('OrgDetailCtrl', OrgDetailCtrl);
;
'use strict';

/*
 *  Controller listing organizations
 *  @name backlineAdminPlus.controller:OrgsCtrl
 *  @ngInject
 */
function OrgsCtrl( $log, $timeout, $state, TokenService, OrgService, PermissionsService, AppConsts, orgList ) {

  var orgs = this,
    _perPage = 25,
    _sortColumn,
    _sortDirection;

  // set default search params
  orgs.page = 1;
  orgs.query = '';
  orgs.totalOrgs = 0;
  orgs.totalPages = 0;
  orgs.showDisabled = false;

  // set banner title
  var _user = TokenService.getCachedUser();

  var network = _user.org.networks.filter(function (network) {
    if (network.type_id === AppConsts.NETWORK_TYPE.PARENT_CHILD) {
      return network;
    }
  })[0];

  orgs.display = PermissionsService.hasDrFirstAdminPrivs ?
    'Organizations' :
    (network ? network.name+' Orgs' : 'Organizations');

  // update list vars based on response
  function _updateOrgsList( newList ) {

    orgs.list = newList.aaData;
    orgs.totalOrgs = newList.iTotalDisplayRecords;
    orgs.totalPages = Math.ceil(orgs.totalOrgs / _perPage);
  }

  // refresh list of orgs
  function _getOrgs() {

    OrgService.list({
      forSummary: true,
      page: orgs.page,
      per_page: _perPage,
      query: orgs.query,
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      showDisabled: orgs.showDisabled
    }).then(function ( response ) {
      _updateOrgsList(response);
    });
  }

  // update when page loads
  _updateOrgsList(orgList);

  /*
   *  Input callback
   *  When query updates, search for orgs
   */
  orgs.search = function () {

    orgs.page = 1;
    _getOrgs();
  };

  /*
   *  View method
   *  Get next page of results
   */
  orgs.next = function () {

    orgs.page += 1;
    _getOrgs();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  orgs.previous = function () {

    orgs.page -= 1;
    _getOrgs();
  };

  /*
   *  View method
   *  When disabled filter is toggled, refresh
   */
  orgs.onToggleDisabled = function () {
    orgs.search();
  };

  /*
   *  Modal callback
   *  When org is added, go to their detail page
   */
  orgs.onAddOrg = function ( org ) {

    // wait for modal to close
    $timeout(function () {
      $state.go('admin.orgs.detail', { id: org.id });
    }, 200);
  };

  /*
   *  Directive callback
   *  When sorting info changes, refresh with new sorting params
   */
  orgs.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    orgs.search();
  };
}

/* @ngInject */
OrgsCtrl.resolve = {
  orgList: ['OrgService', function ( OrgService ) {
    return OrgService.list({ forSummary: true });
  }]
};

angular.module('backlineAdminPlus')
  .controller('OrgsCtrl', OrgsCtrl);
;
'use strict';

/*
 *  Controller for viewing and changing patients
 *  @name backlineAdminPlus.controller:PatientsCtrl
 *  @ngInject
 */
function PatientsCtrl( $q, ErrorService, PatientService, OrgSelectorService, patientList, currentUser, org, hieEnabled, hieOids ) {

  var patients = this,
    _perPage = 20,
    _sortColumn,
    _sortDirection;

  // default to global org
  patients.org = org;

  patients.hieEnabled = hieEnabled;
  patients.hieOids = hieOids;

  // set default search params
  patients.query = '';
  patients.page = 1;
  patients.selected = undefined;

  // set sortable columns
  patients.sortableColumns = [
    0,    // name
    1,    // mrn,
    2,    // dob
    3,    // gender
    5,    // private
    6   // enabled
  ];

  // update list vars based on response
  function _updatePatients( response ){
    patients.list = response.patients;
    patients.totalPages = response.meta['X-Total-Pages'];
  }

  // refresh list of patients
  function _refreshPatients() {

    PatientService.list({
      org_id: patients.org.id,
      query: patients.query,
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      page: patients.page,
      per_page: _perPage
    }).then(function ( response ) {
      _updatePatients(response);
    });
  }

  // update when page loads
  _updatePatients(patientList);

  /*
   *  Input callback
   *  Triggers an update of patient when attributes are toggled
   */
  patients.onToggle = function ( patient ) {

    PatientService.update(patient)
      .then(function ( response ) {}, function ( error ) {
        ErrorService.handle(error);
      });
  };

  /*
   *  Input callback
   *  Search for patients
   */
  patients.search = function () {

    patients.page = 1;
    _refreshPatients();
  };

  /*
   *  View method
   *  Get next page of results
   */
  patients.next = function () {

    patients.page += 1;
    _refreshPatients();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  patients.previous = function () {

    patients.page -= 1;
    _refreshPatients();
  };

  /*
   *  View method
   *  Sets the selected patient, and makes a copy for editing
   */
  patients.setSelectedPatient = function ( patient ) {

    patients.selected = patient;
  };

  /*
   *  Modal callback
   *  When copied selected patient is updated, update selected attributes
   */
  patients.onUpdatePatient = function ( copied ) {

    angular.copy(copied, patients.selected);

    patients.selected = undefined;
  };

  /*
   *  Modal callback
   *  Triggers an update of copy of selected patient
   *  @refactor
   */
  patients.onEdit = function (response) {
    patients.onUpdatePatient(response.patient);
  };

  /*
   *  Modal callback
   *  When a patient is added, search for their last name for convenience
   */
  patients.onAddPatient = function ( patient ) {

    patients.query = patient.lname;
    patients.search();
  };

  /*
   *  Directive callback
   *  When global org changes, refresh patients
   */
  patients.onSelectOrg = function ( org ) {

    patients.org = org;
    patients.search();
  };

  /*
   *  Directive callback
   *  When sorting info changes, refresh list to reflect
   */
  patients.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    patients.search();
  };
}

/* @ngInject */
PatientsCtrl.resolve = {
  patientList: ['PatientService', 'OrgSelectorService', 'currentUser', function ( PatientService, OrgSelectorService, currentUser ) {
    return PatientService.list({ org_id: OrgSelectorService.getOrg().id, per_page: 20 });
  }],

  org: ['OrgService', 'OrgSelectorService', 'currentUser', function ( OrgService, OrgSelectorService, currentUser ) {
    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],
  orgHieEnabled: ['OrgSelectorService', 'currentUser', 'SettingService', 'SettingKeys', function ( OrgSelectorService, currentUser, SettingService, SettingKeys ) {
    if (currentUser.org.id != OrgSelectorService.getOrg().id) {
      return false;
    }

    return SettingService.find(SettingKeys.orgHieEnabled, currentUser.org).then(function (data) {
      return data.value === '1';
    });
  }],
  userHieEnabled: ['OrgSelectorService', 'currentUser', 'SettingService', 'SettingKeys', function ( OrgSelectorService, currentUser, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.userHieEnabled, currentUser.org, currentUser).then(function (data) {
      return data.value === '1';
    });
  }],
  hieEnabled: ['orgHieEnabled', 'userHieEnabled', function ( orgHieEnabled, userHieEnabled ) {
    return orgHieEnabled && userHieEnabled;
  }],
  hieOids: ['OrgSelectorService', 'currentUser', 'SettingService', 'hieEnabled', 'SettingKeys', function ( OrgSelectorService, currentUser, SettingService, hieEnabled, SettingKeys ) {
    if (hieEnabled) {
      return SettingService.find(SettingKeys.hieOids, currentUser.org).then(function (setting) {
        return JSON.parse(setting.possible_values);
      });
    }
    return [];
  }]
};

angular.module('backlineAdminPlus')
  .controller('PatientsCtrl', PatientsCtrl);
;
'use strict';
/* jshint -W024 */

/*
 *  Controller for viewing and changing groups
 *  @name backlineAdminPlus.controller:QueueManagersCtrl
 *  @ngInject
 */
function QueueManagersCtrl(currentUser, $q, $uibModal, SupportService, OrgService, OrgSelectorService, AppConsts) {

  var ctrl = this;

  // query parameters
  ctrl.query = '';
  ctrl.page = 1;

  // initial groups info
  ctrl.refreshing = true;
  ctrl.networks = [];
  ctrl.queueManagers = [];
  ctrl.totalPages = 0;

  ctrl.canHaveQueueManagers = false;

  ctrl.onSelectOrg = function () {
    ctrl.refreshing = true;
    OrgService.getOrg(OrgSelectorService.getOrg().id)
      .then(function (org) {
        ctrl.networks = [];
        ctrl.canHaveQueueManagers = SupportService.canHaveQueueManagers(org);
        for (var i = 0; i < org.networks.length; i++) {
          var network = org.networks[i];

          if (network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT) {
            ctrl.networks.push(network);
          }
        }

        refreshQueueManagers();
      });
  };

  ctrl.onSelectOrg();

  // refresh list of queue managers
  function refreshQueueManagers() {
    ctrl.refreshing = true;
    ctrl.queueManagers = [];

    var promises = [];
    var queueManagersResolve = function (response) {
      ctrl.queueManagers.push.apply(ctrl.queueManagers, response);
    };

    for (var i = 0; i < ctrl.networks.length; i++) {
      var promise = SupportService.queryQueueManagers(ctrl.networks[i].id, OrgSelectorService.getOrg().id, ctrl.query).$promise
        .then(queueManagersResolve)
        .catch(angular.noop);

      promises.push(promise);
    }
    $q.all(promises)
      .then(function () {
        ctrl.totalPages = 0;
        ctrl.refreshing = false;
      });
  }

  /*
   *  View method
   *  Requests the next page of groups
   */
  ctrl.next = function () {

    ctrl.page++;
    refreshQueueManagers();
  };

  /*
   *  View method
   *  Requests the previous page of groups
   */
  ctrl.previous = function () {

    ctrl.page--;
    refreshQueueManagers();
  };

  /*
   *  Modal callback
   *  Sets the search query to the new group name and searches it
   */
  ctrl.openAssignQueueManagerModal = function ( ) {

    var modal = $uibModal.open({
      component: 'createQueueManagerModal',
      resolve: {
        networkId: ctrl.networks[0].id
      }
    });

    modal.result.then(function (queueManager) {
      if (!queueManager) {
        return;
      }

      var queueManagerExists = ctrl.queueManagers.some(function (element) {
        return element.user.id === queueManager.user.id;
      });

      if (queueManagerExists) {
        return;
      }

      ctrl.queueManagers.push(queueManager);
    }, function () {

    });
  };
}

angular.module('backlineAdminPlus')
  .controller('QueueManagersCtrl', QueueManagersCtrl);
;
'use strict';
/* jshint -W024 */

/*
 *  Controller for viewing and changing groups
 *  @name backlineAdminPlus.controller:QueuesCtrl
 *  @ngInject
 */
function QueuesCtrl(currentUser, $q, $uibModal, QueueService, SupportService, filterFilter, OrgService, OrgSelectorService, AppConsts, SettingService, SettingKeys, timeZone) {

  var ctrl = this;

  // query parameters
  ctrl.query = '';
  ctrl.page = 1;

  // initial groups info
  ctrl.refreshing = true;
  ctrl.networks = [];
  ctrl.network = undefined;
  ctrl.queues = [];
  ctrl.org = undefined;
  ctrl.timeZone = timeZone;

  ctrl.canCreateQueues = false;

  ctrl.getCanCreateQueues = function () {
    if (ctrl.org && ctrl.network) {
      return currentUser.role_id === 40 || currentUser.role_id === 50 || ctrl.network.control_org_id === ctrl.org.id;
    }
    return false;
  };

  /*
   *  Directive callback
   *  When the global org changes, reset groups
   */
  ctrl.onSelectOrg = function () {
    ctrl.refreshing = true;
    OrgService.getOrg(OrgSelectorService.getOrg().id)
      .then(function (org) {
        ctrl.org = org;
        ctrl.networks = [];
        ctrl.canCreateQueues = SupportService.canCreateAgent(currentUser, org) && SupportService.canHaveAgents(org);
        for (var i = 0; i < org.networks.length; i++) {
          var network = org.networks[i];

          if (network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT) {
            ctrl.networks.push(network);
          }
        }

        ctrl.network = ctrl.networks[0];
        ctrl.refreshQueues();
      });
  };

  ctrl.onSelectOrg();

  // refresh list of queues
  ctrl.refreshQueues = function () {
    if (ctrl.network) {
      ctrl.refreshing = true;
      ctrl.queues = [];

      QueueService.query(ctrl.network.id, OrgSelectorService.getOrg().id, ctrl.query).$promise
        .then(function (queues) {
          ctrl.queues = queues;
        })
        .catch(function () {
          ctrl.queues = [];
        })
        .then(function () {
          ctrl.refreshing = false;
        });
    } else {
      ctrl.refreshing = false;
    }
  };

  ctrl.onSelectOrgNetwork = function (network) {
    ctrl.network = network;
    ctrl.refreshQueues();
  };

  /*
   *  Modal callback
   *  Sets the search query to the new group name and searches it
   */
  ctrl.openCreateQueueModal = function ( queue ) {

    var modal = $uibModal.open({
      component: 'createQueueModal',
      resolve: {
        networkId: ctrl.network.id,
        timeZone: ctrl.timeZone
      }
    });

    modal.result.then(function (queue) {
      if (!queue) {
        return;
      }

      ctrl.queues.push(queue);
    }, function () {

    });
  };
}

/* @ngInject */
QueuesCtrl.resolve = {
  timeZone: ['SettingService', 'SettingKeys', 'OrgSelectorService', function ( SettingService, SettingKeys, OrgSelectorService ) {

    return SettingService.find(SettingKeys.timeZone, OrgSelectorService.getOrg());
  }]
};

angular.module('backlineAdminPlus')
  .controller('QueuesCtrl', QueuesCtrl);
;
'use strict';

/**
 *  Controller for registering users
 *  @name backlineAdminPlus.controller:RegistrationCtrl
 *  @ngInject
 */
function RegistrationCtrl( $log, $stateParams, $timeout, SessionService, WebplusUrl, questions ) {
  var register = this,
    _token = $stateParams.confirmation_token;

  // get security questions
  register.questions = questions;

  // set default indices of selected questions
  register.firstQuestion = 1;
  register.secondQuestion = 2;

  // selected security questions start valid, before user input
  register.validQuestions = true;

  // security questions answers start invalid, before user input
  register.validAnswers = false;

  register.checkCount = 0;

  // get user by registration token
  // unfortunately this cannot be in a resolver because we must show a message if token is invalid
  // a resolver will only not allow navigation to the page on error
  SessionService.userByConfirmToken(_token)
    .then(function ( response ) {
      register.user = response;
    }, function ( error ) {
      register.invalid = true;
      $log.error(error);
    });

  /*
   *  Input callback
   *  Check password against requirements, sets `validPasswords`
   */
  register.onChangePassword = function () {

    var count = register.checkCount;

    if (register.pw && register.pw.length > 0) {
      register.checkCount = register.checkCount + 1;
      SessionService.checkPasswordRequirements(register.pw)
        .then(function (response) {
          if (count+1 == register.checkCount) {
            register.length = register.pw && (!response.too_short && !response.too_long);
            register.capital = register.pw && !response.missing_uppercase;
            register.lower = register.pw && !response.missing_lowercase;
            register.number = register.pw && !response.missing_digit;
            register.special = register.pw && !response.missing_special;
            register.pwned = register.pw && !response.pwned_password;
            register.keyword = register.pw && !response.has_forbidden_keyword;
            register.match = (register.pw && register.pw_confirm) && (register.pw === register.pw_confirm);

            register.validPasswords = register.keyword && register.pwned && register.length && register.capital && register.lower && register.number && register.special && register.match;
          } else {
          }
        }, function (error) {

        });
    } else {
      register.checkCount = 0;
      register.length = register.capital = register.lower = register.number = register.special = register.pwned = register.keyword = register.match = false;

      register.validPasswords = register.keyword && register.pwned && register.length && register.capital && register.lower && register.number && register.special && register.match;
    }

  };

  register.checkMatch = function () {
    register.match = (register.pw && register.pw_confirm) && (register.pw === register.pw_confirm);
    register.validPasswords = register.keyword && register.pwned && register.length && register.capital && register.lower && register.number && register.special && register.match;
  };

  /*
   *  Input callback
   *  Check questions against requirements, sets `validQuestions`
   */
  register.onChangeQuestions = function () {

    register.validQuestions = register.firstQuestion != register.secondQuestion;
  };

  /*
   *  Input callback
   *  Checks answers against requirements, sets `validAnswers`
   */
  register.onChangeAnswers = function () {

    register.validAnswers = register.firstAnswer != register.secondAnswer;
  };

  /*
   *  View method
   *  Submits the registrations form if all parts valid
   */
  register.submit = function () {

    // check form validity
    if (register.form.$valid && register.validPasswords && register.validAnswers) {

      register.error = undefined;

      SessionService.confirm({
        confirmation_token: _token,
        pw: register.pw,
        pw_confirmation: register.pw_confirm,
        question1: register.firstQuestion,
        question2: register.secondQuestion,
        answer1: register.firstAnswer,
        answer2: register.secondAnswer
      })
      .then(function ( response ) {

        register.saved = true;

        // after 3s delay, redirect to backline
        $timeout(function () {
          window.location.href = response.redirect_url;
        }, 3000);

      }, function ( error ) {
        register.invalid = true;
        register.error = error.label;
      });
    } else {

      // if the form gives us a minlength error, it must be security questions
      if (register.form.$error.minlength) {
        register.error = 'Security Answers must be at least 3 characters long';
      }
    }
  };
}

/* @ngInject */
RegistrationCtrl.resolve = {
  questions: ['SessionService', function ( SessionService ) {
    return SessionService.questions();
  }]
};

angular.module('backlineAdminPlus')
  .controller('RegistrationCtrl', RegistrationCtrl);
;
'use strict';

/*
 *  Controller for displaying reports
 *  @name backlineAdminPlus.controller:ReportsCtrl
 *  @ngInject
 */
function ReportsCtrl( $timeout, TokenService, ReportService, OrgSelectorService, currentUser, reportList ) {

  var reports = this;

  // list of reports
  reports.list = reportList;
  reports.auth_token = TokenService.getAuthToken();

  /*
   *  Refresh the list of reports
   */
  function _updateReports() {

    ReportService.list(OrgSelectorService.getOrg().id)
      .then(function ( response ) {
        reports.list = response;
      });
  }

  /*
   *  Polls reports every 1s until report with index is complete
   */
  function _pollUntilComplete( index ) {

    ReportService.list(OrgSelectorService.getOrg().id)
      .then(function ( response ) {
        if (response[index].status == 3) {
          reports.list[index] = response[index];
        } else {
          $timeout(function () {
            _pollUntilComplete(index);
          }, 1000);
        }
      });
  }

  /*
   *  View method
   *  Remove org id from request
   */
  reports.showAll = function () {

    ReportService.list().then(function ( response ) {
      reports.list = response;
    });
  };

  /*
   *  View method
   *  Gets a humanized name for status code
   */
  reports.statusName = function ( status ) {

    return [ '', 'Not run', 'Running...', 'Done', 'Error' ][status];
  };

  /*
   *  View method
   *  Runs a report with the given index in the list
   *  After, start polling reports until it is complete
   */
  reports.run = function ( index ) {

    ReportService.run(reports.list[index].id)
      .then(function ( response ) {
        reports.list[index] = response;
        _pollUntilComplete(index);
      });
  };

  /*
   *  View method
   *  Download the report with id
   */
  reports.download = function ( id ) {

    ReportService.download(id);
  };

  /*
   *  Directive callback
   *  When global org changes, refresh reports
   */
  reports.onSelectOrg = function ( org ) {

    _updateReports();
  };
}

/* @ngInject */
ReportsCtrl.resolve = {
  reportList: ['ReportService', 'OrgSelectorService', 'currentUser', function ( ReportService, OrgSelectorService, currentUser ) {
    return ReportService.list(OrgSelectorService.getOrg().id);
  }]
};

angular.module('backlineAdminPlus')
  .controller('ReportsCtrl', ReportsCtrl);
;
'use strict';

/*
 *  Controller for displaying escalations
 *  @name backlineAdminPlus.controller:EscalationsCtrl
 *  @ngInject
 */
function EscalationsCtrl($timeout, EscalationService, OrgSelectorService, currentUser, ConfirmationService) {

  var escalations = this;

  escalations.enabled = true;
  escalations.page = 1;
  escalations.totalPages = 1;
  escalations.selectedEscalation = undefined;
  _updateEscalations();

  /*
   *  Refresh the list of escalations
   */
  function _updateEscalations() {

    EscalationService.enabled(OrgSelectorService.getOrg().id)
      .then(function (response) {
        escalations.enabled = response.value == '1';
      });

    EscalationService.list(OrgSelectorService.getOrg().id, escalations.page)
      .then(function (response) {
        escalations.list = response.data;
        escalations.totalPages = response.headers('x-total-pages');
      });

  }

  escalations.toggleEscalation = function () {
    escalations.enabled = !escalations.enabled;
    EscalationService.saveSetting(OrgSelectorService.getOrg().id, escalations.enabled)
      .then(function (response) {
        escalations.enabled = response.setting.value == '1';
      });
  };

  /*
   *  View method
   *  Get next page of results
   */
  escalations.next = function () {
    escalations.page += 1;
    _updateEscalations();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  escalations.previous = function () {
    escalations.page -= 1;
    _updateEscalations();
  };

  /*
   *  Directive callback
   *  When global org changes, refresh escalations
   */
  escalations.onSelectOrg = function () {
    _updateEscalations();
  };

  /*
 *  Directive callback
 *  When successfully create or update escalation, refresh escalations list
 */
  escalations.createOrUpdate = function () {
    _updateEscalations();
  };

  /*
 *  View method
 *  Deletes an escalation, asks to confirm
 */
  escalations.remove = function (escalation) {
    ConfirmationService.confirm({
      title: 'Are you sure?',
      callback: function () {
        EscalationService.remove(escalation, OrgSelectorService.getOrg().id).then(function () {
          _updateEscalations();
        });
      }
    });
  };

  /*
   *  View method
   *  Sets this escalation as selected for use in add modal
   */
  escalations.setSelectedEscalation = function (escalation) {
    if (!escalation) {
      escalations.selectedEscalation = undefined;
    }
    escalations.selectedEscalation = angular.copy(escalation);
  };
}

angular.module('backlineAdminPlus').controller('EscalationsCtrl', EscalationsCtrl);
;
'use strict';

/*
 *  Controller for reseting your password
 *  @name backlineAdminPlus.controller:ResetPWCtrl
 *  @ngInject
 */
function ResetPWCtrl( SessionService ) {

  var resetpw = this;

  /*
   *  View method
   *  Attempt to save the reset of password
   */
  resetpw.reset = function () {

    SessionService.resetpw(resetpw.email)
      .then(function ( response ) {

        resetpw.success = true;

      }, function ( error ) {
        resetpw.error = error;
      });
  };
}

angular.module('backlineAdminPlus')
  .controller('ResetPWCtrl', ResetPWCtrl);
;
'use strict';

/*
 *  Controller for setting organization selective cross-org settings and choosing matching orgs
 *  @name backlineAdminPlus.controller:SearchabilityCtrl
 *  @ngInject
 */
function SearchabilityCtrl($q, $filter, OrgService, OrgSelectorService, SettingService, ErrorService, ToastService, SettingKeys, currentOrg) {

  var searchability = this;

  searchability.currentOrg = currentOrg;
  searchability.selectedOrg = null;

  // update defaults based on new org, settings
  function updateSearchability(updatedOrg) {

    var orderedLinkedOrgs = $filter('orderBy')(updatedOrg.linked_orgs, name.toUpperCase());
    var filteredLinkedOrgs = $filter('filter')(orderedLinkedOrgs, { enabled: true });

    searchability.selectiveCrossOrg = updatedOrg.allow_cross_org_searchability;
    searchability.selectiveCrossOrgsList = filteredLinkedOrgs;
    searchability.allowExternalPrivate = updatedOrg.allow_one_to_one_cross_org_communication;
    searchability.allowExternalInviteGroups = updatedOrg.allow_private_groups_cross_org_communication;
    searchability.allowExternalPublicGroups = updatedOrg.allow_public_groups_cross_org_communication;
  }

  updateSearchability(currentOrg);

  searchability.onSelectSelectiveOrg = function (org) {
    searchability.selectedOrg = org;
  };

  /*
 *  Directive callback
 *  When global org changes, refresh selective cross-org settings
 */
  searchability.onSelectOrg = function ( org ) {
    var orgId = org.id;

    searchability.selected = undefined;

    OrgService.getOrg(orgId).then(function ( org ) {
      searchability.currentOrg = org;
      updateSearchability(org);
    });
  };

  searchability.setAllowPublicGroupsCrossOrgCommunication = function (value) {

    var updatedOrg = OrgSelectorService.getOrg();

    updatedOrg.allow_public_groups_cross_org_communication = value;

    OrgService.update(updatedOrg)
      .then(function (response) {

        updateSearchability(response);

        ToastService.show({
          title: 'Organization Updated',
          message: 'Organization updated successfully',
          type: 'success'
        });

      }, function (error) {
        ErrorService.handle(error);
      });

  };

  searchability.setAllowPrivateGroupsCrossOrgCommunication = function (value) {

    var updatedOrg = OrgSelectorService.getOrg();

    updatedOrg.allow_private_groups_cross_org_communication = value;

    OrgService.update(updatedOrg)
      .then(function (response) {

        updateSearchability(response);

        ToastService.show({
          title: 'Organization Updated',
          message: 'Organization updated successfully',
          type: 'success'
        });

      }, function (error) {
        ErrorService.handle(error);
      });

  };

  searchability.setAllowOneToOneCrossOrgCommunication = function (value) {

    var updatedOrg = OrgSelectorService.getOrg();

    updatedOrg.allow_one_to_one_cross_org_communication = value;

    OrgService.update(updatedOrg)
      .then(function (response) {

        updateSearchability(response);

        ToastService.show({
          title: 'Organization Updated',
          message: 'Organization updated successfully',
          type: 'success'
        });

      }, function (error) {
        ErrorService.handle(error);
      });

  };

  searchability.setAllowCrossOrgSearchability = function (value) {
    searchability.settingAllowCrossOrgSearchability = true;

    var updatedOrg = OrgSelectorService.getOrg();

    updatedOrg.allow_cross_org_searchability = value;

    OrgService.update(updatedOrg)
      .then(function (response) {

        searchability.settingAllowCrossOrgSearchability = false;
        updateSearchability(response);

        ToastService.show({
          title: 'Organization Updated',
          message: 'Organization updated successfully',
          type: 'success'
        });

      }, function (error) {
        searchability.settingAllowCrossOrgSearchability = false;
        ErrorService.handle(error);
      });

  };

  /*
 *  View method
 *  Initiates a link between this org and the selected org
 */
  searchability.add = function (org) {
    searchability.adding = true;

    // check to see if the org is my org.  Do not add if my org is passed in.
    if (org.id == searchability.currentOrg.id) {return;}

    // set params for org update request
    var params = {
      id: OrgSelectorService.getOrg().id,
      linked_org_id: org.id
    };

    OrgService.linkOrgs(params)
      .then(function (response) {

        searchability.adding = false;
        updateSearchability(response);

        ToastService.show({
          title: 'Organization Added',
          message: 'Organization added successfully',
          type: 'success'
        });

      }, function (error) {
        searchability.saving = false;
        ErrorService.handle(error);

      });

  };

  /*
*  View method
*  Removes a link between this org and the selected org
*/
  searchability.remove = function (org) {
    searchability.removing = true;

    // set params for org update request

    var params = {

      id: OrgSelectorService.getOrg().id,
      linked_org_id: org.id

    };

    OrgService.unlinkOrgs(params)
      .then(function (response) {

        searchability.removing = false;

        updateSearchability(response);

        ToastService.show({
          title: 'Organization Removed',
          message: 'Organization removed successfully',
          type: 'success'
        });

      }, function (error) {

        searchability.removing = false;
        ErrorService.handle(error);
      });
  };

}

/* @ngInject */
SearchabilityCtrl.resolve = {

  currentOrg: ['OrgService', 'OrgSelectorService', 'currentUser', function (OrgService, OrgSelectorService, currentUser) {

    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],

  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('SearchabilityCtrl', SearchabilityCtrl);
;
'use strict';

/*
 *  Controller for setting organization security settings
 *  @name backlineAdminPlus.controller:SecurityCtrl
 *  @ngInject
 */
function SecurityCtrl( $log, $window, OrgService, OrgSelectorService, ErrorService, TwilioService, ToastService, Time, PinOptions, model, SettingKeys, SettingService, AdobeProvisioningService, PermissionsService, currentUser) {

  var security = this,
    _allowCrossNetworkCommunication,
    _fingerprintEnabled,
    _maskCallerIDEnabled,
    _esignEnabled,
    _esignProvisioned,
    _esignProvisionInfo,
    _allowShareAttachments,
    _twoFactorAuthenticationEnabled,
    _adobeIsPartnerOrg,
    _adobeClientId,
    _adobeClientSecret,
    _adobeWebhookDomain,
    _adobeRedirectURL,
    _adobeCompletionRedirectURL,
    _adobeRefreshToken;

  security.pinOptions = PinOptions;
  security.isParentOrg = false;
  security.showAllowCrossNetworkCommunication = false;
  security.allowCrossNetworkCommunication = false;
  security.fingerprintEnabled = false;
  security.maskCallerIDEnabled = false;
  security.allowShareAttachments = true;
  security.twoFactorAuthenticationEnabled = false;
  security.esignEnabled = false;
  security.isRegisteredForEsign = false;
  security.isCurrentlyRegisteringForAdobeEsign = false;
  security.adobeOauthUrl = '';
  security.adobeIsPartnerOrg = false;
  security.adobeClientId = '';
  security.adobeClientSecret = '';
  security.adobeWebhookDomain = '';
  security.adobeRedirectURL = '';
  security.adobeCompletionRedirectURL = '';
  security.esignShowPassword = false;

  // update settings for org
  function _updateSettings( org ) {

    security.model = org;

    security.isParentOrg = false;
    security.networks = org.networks;
    security.networks.forEach(function (network, index) {
      if (!security.isParentOrg) {
        security.isParentOrg = org.id === network.control_org_id;
      }
    });

    // security.isParentOrg = org.network && org.id === org.network.control_org_id;
    if (security.isParentOrg) {
      SettingService.find(SettingKeys.crossNetworkFeatureEnabled, org)
        .then(function (setting) {
          security.showAllowCrossNetworkCommunication = setting.value === '1';
        });
      SettingService.find(SettingKeys.crossNetworkEnabled, org)
        .then(function (setting) {
          _allowCrossNetworkCommunication = setting;
          security.allowCrossNetworkCommunication = setting.value === '1';
        });
    }

    // Get the fingerprint setting
    SettingService.find(SettingKeys.fingerprintEnabled, org)
      .then(function (setting) {
        _fingerprintEnabled = setting;
        security.fingerprintEnabled = setting.value === '1';
      });

    // Get the masking caller id setting
    SettingService.find(SettingKeys.maskCallerIDEnabled, org)
      .then(function (setting) {
        _maskCallerIDEnabled = setting;
        security.maskCallerIDEnabled = setting.value === '1';
      });

    SettingService.find(SettingKeys.esignEnabled, org)
      .then(function (setting) {
        _esignEnabled = setting;
        security.esignEnabled = setting.value === '1';
      });

    SettingService.find(SettingKeys.esignProvisioned, org)
      .then(function (setting) {
        _esignProvisioned = setting;
        security.esignProvisioned = setting.value;
      });

    AdobeProvisioningService.getProvisioningInfo(org)
      .then(function (response) {
        if (security.esignEnabled) {
          _esignProvisionInfo = response;
          security.esignProvisionInfo = response;
        }
      });

    // Get the sharing attachment id setting
    SettingService.find(SettingKeys.allowShareAttachments, org)
      .then(function (setting) {
        _allowShareAttachments = setting;
        security.allowShareAttachments = setting.value === '1';
      });

    SettingService.find(SettingKeys.adobeIsPartnerOrg, org)
      .then(function (setting) {
        _adobeIsPartnerOrg = setting;
        security.adobeIsPartnerOrg = setting.value === '1';
      });

    if (PermissionsService.hasDrFirstSuperAdminPrivs) {
      // Get the 2FA id setting
      SettingService.find(SettingKeys.twoFactorAuthenticationEnabled, org)
        .then(function (setting) {
          _twoFactorAuthenticationEnabled = setting;
          security.twoFactorAuthenticationEnabled = setting.value === '1';
        });
    }

    if (currentUser.orgs[0].role_id > 50) {
      SettingService.findDecrypted(SettingKeys.adobeClientId, org)
        .then(function (setting) {
          _adobeClientId = setting;
          security.adobeClientId = setting.value;
        });

      SettingService.findDecrypted(SettingKeys.adobeClientSecret, org)
        .then(function (setting) {
          _adobeClientSecret = setting;
          security.adobeClientSecret = setting.value;
        });

      SettingService.find(SettingKeys.adobeRefreshToken, org)
        .then(function (setting) {
          _adobeRefreshToken = setting;
          security.adobeRefreshToken = setting.value;
        });

      SettingService.find(SettingKeys.adobeWebhookDomain, org)
        .then(function (setting) {
          _adobeWebhookDomain = setting;
          security.adobeWebhookDomain = setting.value;
        });

      SettingService.find(SettingKeys.adobeRedirectURL, org)
        .then(function (setting) {
          _adobeRedirectURL = setting;
          security.adobeRedirectURL = setting.value;
        });

      SettingService.find(SettingKeys.adobeCompletionRedirectURL, org)
        .then(function (setting) {
          _adobeCompletionRedirectURL = setting;
          security.adobeCompletionRedirectURL = setting.value;
        });
    }

    security.defaultPatientSearch = org.default_patient_search_allowed;
    security.webTimeout = Time.inMinutes(org.inactivity_timeout_seconds);

    security.pinTimeout = org.default_pin_delay;

    security.allowExternalPrivate = org.allow_one_to_one_cross_org_communication;
    security.allowExternalInviteGroups = org.allow_private_groups_cross_org_communication;
    security.allowExternalPublicGroups = org.allow_public_groups_cross_org_communication;

    security.allowCrossOrgSearchability = org.allow_cross_org_searchability;

    security.allowCommunity = org.allow_public_searchability;

    security.allowUnreg = org.allow_private_unregistered_user_communication;

    security.allowPrivateExternalAttachments = org.allow_one_to_one_external_attachments;
    security.allowInviteGroupsExternalAttachments = org.allow_invite_only_groups_external_attachments;
    security.allowPublicGroupsExternalAttachments = org.allow_public_groups_external_attachments;
    security.allowPCCExternalAttachments = org.allow_patient_centered_external_attachments;

    security.isRegisteredForEsign = org.has_esign_token;
    security.landline = org.land_line;

    security.adobeOauthUrl = org.adobe_oauth_url;
  }

  // update when page loads
  _updateSettings(model);

  /*
   *  Input callback
   *  When allow external private changes to false, automatically turn off other settings
   */
  security.onToggleExternalPrivate = function () {

    if (!security.allowExternalPrivate) {
      security.allowExternalInviteGroups = false;
      security.allowExternalPublicGroups = false;
      security.allowCommunity = false;
    }
  };

  security.registerForAdobeEsign = function () {
    security.isCurrentlyRegisteringForAdobeEsign = true;
  };

  security.resetEsignOauth = function () {
    security.isRegisteredForEsign = false;
    security.isCurrentlyRegisteringForAdobeEsign = false;

    SettingService.set(_adobeRefreshToken.setting_id, security.model, null);
  };

  security.hideEsignPassword = function () {
    security.esignShowPassword = false;
    return false;
  };

  security.showEsignPassword = function () {
    security.esignShowPassword = true;
    return false;
  };

  security.completeProvisioning = function () {
    security.esignProvisioned = 'completed';
    SettingService.set(_esignProvisioned.setting_id, security.model, 'completed');
    security.resetProvisioningInfo();
    return false;
  };

  security.resetProvisioning = function () {
    security.esignProvisioned = 'not_started';
    SettingService.set(_esignProvisioned.setting_id, security.model, 'not_started');
    security.resetProvisioningInfo();
    return false;
  };

  security.resetProvisioningInfo = function () {
    AdobeProvisioningService.getProvisioningInfo(security.model)
      .then(function (response) {
        _esignProvisionInfo = response;
        security.esignProvisionInfo = response;
      });
  };

  $window.onfocus = function () {
    OrgService.getOrg( model.id ).then(function ( org ) {
      security.isRegisteredForEsign = org.has_esign_token;
      if (security.isRegisteredForEsign) {
        security.isCurrentlyRegisteringForAdobeEsign = false;
      }
    }, function ( error ) {
      security.isRegisteredForEsign = false;
      security.isCurrentlyRegisteringForAdobeEsign = false;
      ErrorService.handle(error);
    });
  };

  /*
   *  View method
   *  Save the current settings
   */
  security.save = function () {

    security.saving = true;

    // do checking for web timeout
    if (security.webTimeout < 1) {

      security.webTimeout = 1;

    } else if (security.webTimeout > 1440) {

      security.webTimeout = 1440;
    }

    // collect settings for payload
    var params = {
      id: security.model.id,
      default_patient_search_allowed: security.defaultPatientSearch,
      inactivity_timeout_seconds: security.webTimeout * 60,
      default_pin_delay: security.pinTimeout,
      allow_one_to_one_cross_org_communication: security.allowExternalPrivate,
      allow_private_groups_cross_org_communication: security.allowExternalInviteGroups,
      allow_public_groups_cross_org_communication: security.allowExternalPublicGroups,
      allow_cross_org_searchability: security.allowCrossOrgSearchability,
      allow_public_searchability: security.allowCommunity,
      allow_private_unregistered_user_communication: security.allowUnreg,
      allow_one_to_one_external_attachments: security.allowPrivateExternalAttachments,
      allow_invite_only_groups_external_attachments: security.allowInviteGroupsExternalAttachments,
      allow_public_groups_external_attachments: security.allowPublicGroupsExternalAttachments,
      allow_patient_centered_external_attachments: security.allowPCCExternalAttachments
    };

    if (security.isParentOrg) {
      SettingService.set(_allowCrossNetworkCommunication.setting_id, security.model, security.allowCrossNetworkCommunication ? '1' : '0')
        .then(function (setting) {
          security.allowCrossNetworkCommunication = setting.value === '1';
        });
    }

    // Update fingerprint setting
    SettingService.set(_fingerprintEnabled.setting_id, security.model, security.fingerprintEnabled ? '1' : '0');

    // Update allow sharing attachment setting
    SettingService.set(_allowShareAttachments.setting_id, security.model, security.allowShareAttachments ? '1' : '0');

    // Update the CID masking setting
    SettingService.set(_maskCallerIDEnabled.setting_id, security.model, security.maskCallerIDEnabled ? '1' : '0');

    if (PermissionsService.hasDrFirstSuperAdminPrivs) {
      // Update enable for 2FA setting
      SettingService.set(_twoFactorAuthenticationEnabled.setting_id, security.model, security.twoFactorAuthenticationEnabled ? '1' : '0');
    }

    // _adobeClientId,
    // _adobeClientSecret,
    // _adobeWebhookDomain,
    // _adobeRedirectURL,
    // _adobeCompletionRedirectURL;

    if (currentUser.orgs[0].role_id > 50) {
      // settings for Adobe
      SettingService.setSystemWide(_adobeClientId.setting_id, security.model, security.adobeClientId);
      SettingService.setSystemWide(_adobeClientSecret.setting_id, security.model, security.adobeClientSecret);
      SettingService.setSystemWide(_adobeWebhookDomain.setting_id, security.model, security.adobeWebhookDomain);
      SettingService.setSystemWide(_adobeRedirectURL.setting_id, security.model, security.adobeRedirectURL);
      SettingService.setSystemWide(_adobeCompletionRedirectURL.setting_id, security.model, security.adobeCompletionRedirectURL);
    }

    // Update settings
    OrgService.update(params)
      .then(function ( response ) {

        _updateSettings(response);

        security.saving = false;

        ToastService.show({
          title: 'Saved',
          message: 'All changes saved successfully',
          type: 'success'
        });

      }, function ( error ) {
        security.saving = false;
      });
  };

  /*
   *  Directive callback
   *  When global org changes, update info
   */
  security.onSelectOrg = function ( org ) {

    OrgService.getOrg( org.id )
      .then(function ( response ) {
        _updateSettings(response);
      }, function ( error ) {
        ErrorService.handle(error);
      });
  };

  /*
   * Directive callback
   * When register landline comes back with success, refresh info
   */
  security.onRegisterLandline = function () {
    var orgId = OrgSelectorService.getOrg().id;

    // NOTE: this assumes that the setting to enable mask caller id was updated
    //   on the server when the landline was verified.
    return OrgService.getOrg(orgId)
      .then(function (org) {
        _updateSettings(org);

        ToastService.show({
          title: 'Registered',
          message: 'Landline was registered successfully',
          type: 'success'
        });
      }, function (error) {
        ErrorService.handle(error);
      });
  };

  security.adobeProvisioningEvent = function (org) {
    AdobeProvisioningService.getProvisioningInfo(org)
      .then(function (response) {
        _esignProvisionInfo = response;
        security.esignProvisionInfo = response;
      });

    SettingService.find(SettingKeys.esignProvisioned, org)
      .then(function (setting) {
        _esignProvisioned = setting;
        security.esignProvisioned = setting.value;
      });
  };

  security.changeMaskCallerIDEnabled = function ( enabled ) {
    return SettingService.set(_maskCallerIDEnabled.setting_id, security.model, enabled ? '1' : '0')
      .then(function ( setting ) {
        _maskCallerIDEnabled = setting;
        security.maskCallerIDEnabled = setting.value === '1';

        ToastService.show({
          title: 'Saved',
          message: 'Mask caller setting changed.',
          type: 'success'
        });
      }, function ( error ) {
        ErrorService.handle(error);
      });
  };

  security.onUnregisterLandline = function () {
    // NOTE: this assumes that the setting to disable mask caller id was updated
    //   on the server when the landline was de-registered.
    return TwilioService.unregister()
      .then(function () {
        security.maskCallerIDEnabled = false;
        security.landline = '';
      });
  };
}

/* @ngInject */
SecurityCtrl.resolve = {
  model: ['OrgService', 'OrgSelectorService', 'currentUser', function ( OrgService, OrgSelectorService, currentUser ) {

    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }],

  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('SecurityCtrl', SecurityCtrl);
;
'use strict';
/* jshint -W024 */

/*
 *  Controller for viewing and changing groups
 *  @name backlineAdminPlus.controller:SubmittersCtrl
 *  @ngInject
 */
function SubmittersCtrl(currentUser, $q, $uibModal, SupportService, PermissionsService, OrgService, OrgSelectorService) {

  var ctrl = this;

  // query parameters
  ctrl.query = '';
  ctrl.page = 1;

  // initial groups info
  ctrl.refreshing = true;
  ctrl.submitters = [];
  ctrl.totalPages = 0;
  ctrl.canHaveSubmitters = false;

  if (currentUser.support && currentUser.support.networks && currentUser.support.networks.length > 0) {
    ctrl.network = currentUser.support.networks[0];
    ctrl.networkOrgs = ctrl.network.orgs;
    if (currentUser.org && currentUser.org.network) {
      ctrl.controlOrgId = currentUser.org.network.control_org_id;
    }
  }
  ctrl.isDrFirstAdmin = PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs;
  ctrl.isControllingOrg = ctrl.controlOrgId ? ctrl.controlOrgId === currentUser.org.id : false;
  ctrl.isSingleOrg = (!ctrl.isControllingOrg || !ctrl.network || ctrl.networkOrgs.length < 2);

  ctrl.onTableEditCompleted = function () {
    refreshSubmitters();
  };

  ctrl.onSelectOrg = function (_selectedOrganization) {
    ctrl.refreshing = true;
    OrgService.getOrg(_selectedOrganization ? _selectedOrganization.id : OrgSelectorService.getOrg().id)
      .then(function (org) {
        ctrl.canHaveSubmitters = SupportService.canHaveSubmitters(org);

        refreshSubmitters(_selectedOrganization);
      });
  };

  ctrl.onSelectOrg();

  // refresh list of submitters
  function refreshSubmitters(_selectedOrganization) {
    ctrl.refreshing = true;
    SupportService.querySubmitters(_selectedOrganization ? _selectedOrganization.id : OrgSelectorService.getOrg().id, ctrl.query).$promise
      .then(function (submitters) {
        ctrl.submitters = submitters;
        ctrl.totalPages = 0;
      })
      .catch(function () {
        ctrl.submitters = [];
      })
      .then(function () {
        ctrl.refreshing = false;
      });
  }

  /*
   *  View method
   *  Requests the next page of groups
   */
  ctrl.next = function () {

    ctrl.page++;
    refreshSubmitters();
  };

  /*
   *  View method
   *  Requests the previous page of groups
   */
  ctrl.previous = function () {

    ctrl.page--;
    refreshSubmitters();
  };

  /*
   *  Modal callback
   *  Sets the search query to the new group name and searches it
   */
  ctrl.openAssignSubmitterModal = function ( ) {
    var modal = $uibModal.open({
      component: 'createSubmitterModal',
      resolve: {
        currentUser: currentUser
      }
    });

    modal.result.then(function (submitter) {
      if (!submitter) {
        return;
      }

      var submitterExists = ctrl.submitters.some(function (element) {
        return element.user_id === submitter.user_id;
      });

      if (submitterExists) {
        return;
      }

      ctrl.submitters.push(submitter);
    }, function () {

    });
  };
}

angular.module('backlineAdminPlus')
  .controller('SubmittersCtrl', SubmittersCtrl);
;
'use strict';

/*
 *  Controller for setting organization default settings
 *  @name backlineAdminPlus.controller:SettingsCtrl
 *  @ngInject
 */
function SettingsCtrl($q, currentUser, OrgService, OrgSelectorService, SettingService, SettingKeys, ErrorService, ToastService, PermissionsService) {

  var ctrl = this;

  if (currentUser.support && currentUser.support.networks && currentUser.support.networks.length > 0) {
    ctrl.network = currentUser.support.networks[0];
    ctrl.networkOrgs = ctrl.network.orgs;
    if (currentUser.org && currentUser.org.network) {
      ctrl.controlOrgId = currentUser.org.network.control_org_id;
    }
  }
  ctrl.isDrFirstAdmin = PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs;
  ctrl.isControllingOrg = ctrl.controlOrgId ? ctrl.controlOrgId === currentUser.org.id : false;
  ctrl.isSingleOrg = (!ctrl.isControllingOrg || !ctrl.network || ctrl.networkOrgs.length < 2);

  ctrl.autoCloseDuration = 0;
  ctrl.reminderInterval = 0;
  ctrl.autoCloseDurationId = 0;
  ctrl.reminderIntervalId = 0;
  ctrl.ticketsIppEnabledId = 0;
  ctrl.isAutoClose = true;
  ctrl.isTicketsIppEnabled = true;
  ctrl.ticketsIppEnabled = true;
  ctrl.loadingAutoClose = true;
  ctrl.loadingReminder = true;
  ctrl.loadingTicketsIppEnabled = true;

  ctrl.changeStatus = function () {
    ctrl.isAutoClose = !ctrl.isAutoClose;
  };
  
  ctrl.changeTicketsIppEnabled = function () {
    ctrl.isTicketsIppEnabled = !ctrl.isTicketsIppEnabled;
  };

  ctrl.onSelectOrg = function (_selectedOrganization) {
    ctrl.refreshing = true;
    OrgService.getOrg(_selectedOrganization ? _selectedOrganization.id : OrgSelectorService.getOrg().id)
      .then(function (org) {
        ctrl.org = org;

        SettingService.find(SettingKeys.awaitingResponseAutoCloseDuration, org)
          .then(function (setting) {
            ctrl.autoCloseDuration = Math.ceil(parseInt(setting.value, 10) / 3600);
            ctrl.autoCloseDurationId = setting.setting_id;
            ctrl.isAutoClose = ctrl.autoCloseDuration !== 0;
            ctrl.loadingAutoClose = false;
          });

        SettingService.find(SettingKeys.awaitingResponseReminderInterval, org)
          .then(function (setting) {
            ctrl.reminderInterval = Math.ceil(parseInt(setting.value, 10) / 3600);
            ctrl.reminderIntervalId = setting.setting_id;
            ctrl.loadingReminder = false;
          });
          
        SettingService.find(SettingKeys.ticketsIppEnabled, org)
          .then(function (setting) {
            ctrl.ticketsIppEnabled = (parseInt(setting.value, 10) == 1);
            ctrl.ticketsIppEnabledId = setting.setting_id;
            ctrl.isTicketsIppEnabled = (parseInt(setting.value, 10) == 1);
            ctrl.loadingTicketsIppEnabled = false;
          });
      });
  };

  ctrl.save = function () {
    ctrl.saving = true;

    // gather necessary calls
    var requests = {
      autoCloseDuration: SettingService.set(ctrl.autoCloseDurationId, ctrl.org, ctrl.isAutoClose ? ctrl.autoCloseDuration * 3600 : 0),
      reminderInterval: SettingService.set(ctrl.reminderIntervalId, ctrl.org, ctrl.reminderInterval * 3600),
      ticketsIppEnabled: SettingService.set(ctrl.ticketsIppEnabledId, ctrl.org, ctrl.isTicketsIppEnabled ? 1 : 0)
    };

    // combine calls into one promise
    $q.all(requests).then(function ( response ) {
      ctrl.saving = false;

      ToastService.show({
        title: 'Saved',
        message: 'All changes saved successfully',
        type: 'success'
      });

    }, function ( error ) {

      ctrl.saving = false;

      ErrorService.handle(error);
    });
  };

  ctrl.onSelectOrg();
}

angular.module('backlineAdminPlus')
  .controller('SettingsCtrl', SettingsCtrl);
;
'use strict';

/* jshint -W024 */

/**
 *  Controller for viewing and changing groups
 *
 *  @name backlineAdminPlus.controller:TicketingPoolsCtrl
 *  @ngInject
 *
 * @param TokenService
 * @param $uibModal
 * @param SupportService
 * @param OrgService
 * @param OrgSelectorService
 * @param PermissionsService
 * @param AppConsts
 * @constructor
 */
function TicketingPoolsCtrl(TokenService, $uibModal, SupportService, OrgService, OrgSelectorService, PermissionsService, AppConsts) {
  var poolsCtrl = this,
    currentUser = TokenService.getCachedUser();

  // initial ticketing pool info
  poolsCtrl.org = null;
  poolsCtrl.orgNetwork = null;
  poolsCtrl.canHaveTicketingPools = false;
  poolsCtrl.isDrFirstAdmin = PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs;
  poolsCtrl.isFullAdmin = PermissionsService.hasFullAdminPrivs || poolsCtrl.isDrFirstAdmin;

  var resetPageSettings = function (isRefreshing) {
    poolsCtrl.network = null;
    poolsCtrl.controlOrgId = null;
    poolsCtrl.networkOrgs = [];
    poolsCtrl.ticketingPools = [];
    poolsCtrl.totalPages = 0;
    poolsCtrl.refreshing = isRefreshing;

    // query parameters
    poolsCtrl.query = '';
    poolsCtrl.page = 1;
  };

  resetPageSettings(true);

  var loadUserNetworkPermissions = function (selectedOrgNetworks) {
    if (currentUser.support && currentUser.support.networks && currentUser.support.networks.length > 0) {
      poolsCtrl.network = currentUser.support.networks[0];
      poolsCtrl.networkOrgs = poolsCtrl.network.orgs;
      if (currentUser.org && currentUser.org.network) {
        poolsCtrl.controlOrgId = currentUser.org.network.control_org_id;
      }
    }
    poolsCtrl.isControllingOrg = poolsCtrl.controlOrgId ? poolsCtrl.controlOrgId === currentUser.org.id : false;
    poolsCtrl.isSingleOrg = (!poolsCtrl.isControllingOrg || !poolsCtrl.network || poolsCtrl.networkOrgs.length < 2);
    poolsCtrl.isSubOrg = !poolsCtrl.isControllingOrg && !poolsCtrl.isSingleOrg;
    poolsCtrl.canCreate = poolsCtrl.isDrFirstAdmin || (poolsCtrl.isControllingOrg && poolsCtrl.isFullAdmin);
    poolsCtrl.canSeeNetworkOrgSearch = !poolsCtrl.isSingleOrg && !poolsCtrl.isDrFirstAdmin;
  };

  var loadSupportNetwork = function (selectedOrgNetworks) {
    poolsCtrl.orgNetwork = null;
    var customerSupportNetworks = selectedOrgNetworks.filter(function (network) {
      return network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT;
    });
    var parentChildNetworks = selectedOrgNetworks.filter(function (network) {
      return network.type_id === AppConsts.NETWORK_TYPE.PARENT_CHILD;
    });

    if (customerSupportNetworks.length > 0) {
      poolsCtrl.orgNetwork = customerSupportNetworks[0];
    } else {
      poolsCtrl.orgNetwork = parentChildNetworks[0];
    }
  };

  var loadOrganization = function (org) {
    poolsCtrl.org = org;
    poolsCtrl.canHaveTicketingPools = SupportService.canHaveSubmitters(org);
    poolsCtrl.canSeeOrgSearch = poolsCtrl.isDrFirstAdmin || PermissionsService.canSearchOrgs;

    if (poolsCtrl.canHaveTicketingPools) {
      loadSupportNetwork(org.networks);
      loadUserNetworkPermissions(org.networks);
      refreshTicketingPools();
    } else {
      resetPageSettings(false);
    }
  };

  var selectUserOrg = function () {
    OrgService.getOrg(OrgSelectorService.getOrg().id)
      .then(function (org) {
        loadOrganization(org);
      });
  };

  poolsCtrl.onSelectOrg = function (org) {
    poolsCtrl.refreshing = true;
    poolsCtrl.ticketingPools = [];
    OrgService.getOrg(org.id).then(function (loadedOrg) { loadOrganization(loadedOrg);});
  };

  poolsCtrl.onPoolsUpdated = function () {
    refreshTicketingPools();
  };

  // load the org already selected by the user
  selectUserOrg();

  poolsCtrl.openCreateTicketingPoolModal = function () {
    var modal = $uibModal.open({
      component: 'createTicketingPoolModal',
      resolve: {
        currentUser: currentUser,
        org: poolsCtrl.org,
        network: poolsCtrl.orgNetwork
      }
    });

    modal.result.then(function (ticketingPool) {
      if (!ticketingPool) {
        return;
      }

      refreshTicketingPools();
    }, function () {

    });
  };

  // refresh list of ticketing pools
  function refreshTicketingPools() {
    poolsCtrl.refreshing = true;
    var ticketingPoolsResolve = function (response) {
      poolsCtrl.ticketingPools = response;
      poolsCtrl.totalPages = 0;
      poolsCtrl.refreshing = false;
    };

    SupportService.getTicketingPools(poolsCtrl.org.id).$promise.then(ticketingPoolsResolve);
  }

  /**
   *  View method
   *  Requests the next page of groups
   **/
  poolsCtrl.next = function () {
    poolsCtrl.page++;
    refreshTicketingPools();
  };

  /**
   *  View method
   *  Requests the previous page of groups
   */
  poolsCtrl.previous = function () {
    poolsCtrl.page--;
    refreshTicketingPools();
  };

}

angular.module('backlineAdminPlus')
  .controller('TicketingPoolsCtrl', TicketingPoolsCtrl);
;
'use strict';
/* jshint -W024 */

/**
 *  Controller for adding / removing members in a ticketing pool
 *  @name backlineAdminPlus.controller:TicketingPoolsDetailCtrl
 *  @ngInject
 */
function TicketingPoolsDetailCtrl($state, $stateParams, $uibModal, $scope, filterFilter, ConfirmationService, SupportService,
                                  OrgSelectorService, NetworkSelectorService, ToastService, PermissionsService, TokenService,
                                  AppConsts) {

  var poolCtrl = this;

  poolCtrl.selectedSubmitter = null;
  poolCtrl.selectedOrg = {};
  poolCtrl.ticketingPoolOrg = OrgSelectorService.getOrg();
  poolCtrl.cachedUser = TokenService.getCachedUser();
  poolCtrl.networkId = poolCtrl.cachedUser.network ? poolCtrl.cachedUser.network.id : null;
  poolCtrl.loading = true;
  poolCtrl.deleting = false;
  poolCtrl.activeConsumers = [];
  poolCtrl.availableConsumers = [];
  poolCtrl.filteredActive = [];
  poolCtrl.filteredAvailable = [];
  poolCtrl.networkOrgs = [];
  poolCtrl.controlOrgId = null;
  poolCtrl.network = null;

  var loadSupportNetwork = function () {
    console.log(poolCtrl.ticketingPoolOrg);
    var network;
    var userSupportNetworks = poolCtrl.cachedUser.support.networks;
    var defaultSupportNetwork = poolCtrl.cachedUser.org.networks.filter(function (network) {
      return network.type_id === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT;
    })[0];

    if (userSupportNetworks && userSupportNetworks.length > 0) {
      if (userSupportNetworks.length === 1) {
        network = userSupportNetworks[0];
      } else if (!!defaultSupportNetwork) {
        var foundNetwork = userSupportNetworks.filter(function (network) {
          return network.id === defaultSupportNetwork.id;
        })[0];

        network = foundNetwork || defaultSupportNetwork;
      }
    } else {
      network = defaultSupportNetwork;
    }

    return network;
  };

  poolCtrl.network = loadSupportNetwork();

  if (poolCtrl.network) {
    poolCtrl.networkOrgs = poolCtrl.network.orgs;
    if (poolCtrl.cachedUser.org && poolCtrl.cachedUser.org.network) {
      poolCtrl.controlOrgId = poolCtrl.cachedUser.org.network.control_org_id;
    }
  }

  poolCtrl.isDrFirstAdmin = PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs;
  poolCtrl.isFullAdmin = PermissionsService.hasFullAdminPrivs || poolCtrl.isDrFirstAdmin;
  poolCtrl.isControllingOrg = poolCtrl.controlOrgId ? poolCtrl.controlOrgId === poolCtrl.cachedUser.org.id : false;
  poolCtrl.isSubOrg = poolCtrl.controlOrgId ? poolCtrl.controlOrgId !== poolCtrl.cachedUser.org.id : false;
  poolCtrl.isSingleOrg = !poolCtrl.isControllingOrg || !poolCtrl.network || poolCtrl.networkOrgs.length < 2;
  poolCtrl.canEditOrDelete = poolCtrl.isDrFirstAdmin || (poolCtrl.isControllingOrg && poolCtrl.isFullAdmin);

  poolCtrl.getPoolAndSubmitters = function (_orgId) {
    poolCtrl.loading = true;
    poolCtrl.processing = {};
    var orgId = _orgId || null;

    SupportService.getTicketingPool(orgId, $stateParams.id)
      .$promise
      .then( function (response) {
        poolCtrl.selected = response.ticketing_pool;
        console.log(poolCtrl.selected);
        poolCtrl.activeConsumers = poolCtrl.filteredActive = response.ticketing_pool.consumers.active;
        poolCtrl.availableConsumers = poolCtrl.filteredAvailable = response.ticketing_pool.consumers.available;

        poolCtrl.activeConsumers.forEach( function (consumer) {
          poolCtrl.processing[consumer.id] = false;
        });
        poolCtrl.availableConsumers.forEach( function (consumer) {
          poolCtrl.processing[consumer.id] = false;
        });

        poolCtrl.loading = false;
      }, function () {
        poolCtrl.activeConsumers = poolCtrl.filteredActive = [];
        poolCtrl.availableConsumers = poolCtrl.filteredAvailable = [];
        poolCtrl.loading = false;
      });
  };

  poolCtrl.getPoolAndSubmitters();

  /**
   * Open the _rename_modal.html for RenameTicketingPoolModal
   * @param ticketingPool
   */
  var openRenameTicketingPoolModal = function (ticketingPool) {
    poolCtrl.editing = poolCtrl.selected.$processing = true;
    var modal = $uibModal.open({
      component: 'renameTicketingPoolModal',
      resolve: {
        ticketingPool: ticketingPool
      }
    });

    modal.result.then(function (ticketingPool) {
      poolCtrl.editing = false;
      delete poolCtrl.selected.$processing;
      if (!ticketingPool) {
        return;
      }

      poolCtrl.selected.name = ticketingPool.name;
    }, function () {
      poolCtrl.editing = false;
      delete poolCtrl.selected.$processing;
    });
  };

  poolCtrl.onSelectOrg = function (org) {
    var orgId = null;

    if (!org) {
      poolCtrl.selectedOrg = {};
    } else {
      poolCtrl.selectedOrg = org;
      orgId = org.id;
    }

    poolCtrl.getPoolAndSubmitters(orgId);
  };

  poolCtrl.deletePool = function () {
    var ticketingPoolName = poolCtrl.selected.name;
    var ticketingPoolId = poolCtrl.selected.id;

    ConfirmationService.confirm({
      title: 'Confirm',
      message: 'Delete ' + ticketingPoolName + '?\nNote this action cannot be undone.',
      isBold: true,
      callback: function (response) {
        poolCtrl.deleting = poolCtrl.selected.$processing = true;
        SupportService.removeTicketingPool(ticketingPoolId)
          .$promise
          .then(function () {
            delete poolCtrl.selected.$processing;
            poolCtrl.deleting = false;
            poolCtrl.error = null;

            // after successful call, redirect back to ticketing pools
            $state.go('admin.support.ticketing_pools');
          }, function () {
            poolCtrl.deleting = false;
            delete poolCtrl.selected.$processing;
            ToastService.show({
              title: 'Error',
              message: 'There was a problem deleting the ticketing pool. Please try again.',
              type: 'danger'
            });
          }
        );
      }
    });
  };

  poolCtrl.editTicketingPool = function () {
    poolCtrl.selectedCopy = angular.copy(poolCtrl.selected);
    openRenameTicketingPoolModal(poolCtrl.selectedCopy);
  };

  /**
   * Add a submitter to this ticketing pool as active
   * @param submitterId {number}
   */
  poolCtrl.add = function (submitterId) {
    poolCtrl.processing[submitterId] = true;

    SupportService.addSubmitterToTicketingPool(poolCtrl.selected.id, submitterId).$promise.then( function (response) {
      poolCtrl.availableConsumers = poolCtrl.availableConsumers.filter(function (consumer) {
        return consumer.id !== submitterId;
      });

      poolCtrl.activeConsumers.push(response);
      poolCtrl.processing[submitterId] = false;
      poolCtrl.filter();
    }, function () {
      poolCtrl.processing[submitterId] = false;
      ToastService.show({
        title: 'Error',
        message: 'There was a problem adding a user to this pool. Please try again.',
        type: 'danger'
      });
    });
  };

  /**
   * Set this submitter to available in this ticketing pool, no longer active
   * @param submitterId {number}
   */
  poolCtrl.remove = function (submitterId) {
    poolCtrl.processing[submitterId] = true;

    SupportService.removeSubmitterFromTicketingPool(poolCtrl.selected.id, submitterId).$promise.then( function (response) {
      poolCtrl.activeConsumers = poolCtrl.activeConsumers.filter(function (consumer) {
        return consumer.id !== submitterId;
      });

      poolCtrl.availableConsumers.push(response);
      poolCtrl.processing[submitterId] = false;
      poolCtrl.filter();
    }, function () {
      poolCtrl.processing[submitterId] = false;
      ToastService.show({
        title: 'Error',
        message: 'There was a problem removing the user from this pool. Please try again.',
        type: 'danger'
      });
    });
  };

  poolCtrl.filter = function () {
    poolCtrl.filteredAvailable = filterFilter(poolCtrl.availableConsumers, poolCtrl.query);
    poolCtrl.filteredActive = filterFilter(poolCtrl.activeConsumers, poolCtrl.query);
  };

  $scope.$watch('poolCtrl.query', poolCtrl.filter);
}

angular.module('backlineAdminPlus')
  .controller('TicketingPoolsDetailCtrl', TicketingPoolsDetailCtrl);
;
'use strict';
/* jshint -W018 */

/**
 *  Controller for showing user details
 *  @class
 *  @name backlineAdminPlus.controller:UserDetailCtrl
 *  @ngInject
 *
 *  @param {service:LdapService} LdapService
 */
function UserDetailCtrl( $log, $q, $state, UserService, PermissionsService, ConfirmationService, LdapService, LicenseTypes, PinOptions, model, orgHieEnabled, orgSchedulingEnabled, userHieEnabled, networkOnly, deleteSchedulesEnabled, scheduleSiteManageEnabled, scheduleEntriesManageEnabled, scheduleableEnabled, scheduleViewEnabled, SettingService, SettingKeys, currentUser ) {

  var user = this;

  user.orgHieEnabled = orgHieEnabled;
  user.userHieEnabled = userHieEnabled;
  user.networkOnly = networkOnly;
  user.deleteSchedulesEnabled = deleteSchedulesEnabled;
  user.scheduleSiteManageEnabled = scheduleSiteManageEnabled;
  user.scheduleEntriesManageEnabled = scheduleEntriesManageEnabled;
  user.scheduleableEnabled = scheduleableEnabled;
  user.scheduleViewEnabled = scheduleViewEnabled;
  user.orgSchedulingEnabled = orgSchedulingEnabled;

  // update local variables base on new model
  function _updateUser( model ) {

    user.model = model;

    user.suffix = model.suffix;
    user.lname = model.lname;
    user.fname = model.fname;
    user.fullName = user.lname + ', ' + user.fname;

    user.network = model.network;
    user.type = model.type;
    user.email = model.email;
    user.ldapDefaultName = LdapService.createDefaultUsername(model.email);
    user.npi = model.npi || '---';
    user.phone = model.phone || '---';
    user.degrees = model.degrees || '---';

    user.community = model.license_type === LicenseTypes.community;

    user.status = model.reg_status;

    user.title = model.title;
    user.speciality = model.speciality || '--';
    user.org_search = model.privacy_searchability_org ? 'Yes' : 'No';
    user.backline_search = model.privacy_searchability_all_backline ? 'Yes' : 'No';
    user.email_privacy = model.privacy_email_visibility;
    user.phone_privacy = model.privacy_phone_visibility;

    user.orgs = model.orgs;
    user.org = model.org;
    user.primaryOrg = model.primaryOrg;

    user.departments = model.departments;

    user.passwordReset = angular.isString(model.admin_reset_password_token);
    user.resetUrl = model.reset_password_tiny_url;

    user.confirmationUrl = model.confirmation_tiny_url;

    for (var i = 0; i < model.orgs.length; i++) {
      var orgUser = model.orgs[i];

      for (var j = 0; j < model.support.networks.length; j++) {
        var network = model.support.networks[j];

        for (var k = 0; k < network.orgs.length; k++) {
          var org = network.orgs[k];

          if (org.id === orgUser.org.id) {
            orgUser.network_id = network.id;
            orgUser.network_name = network.name;

            if (org.user.is_consumer) {
              orgUser.is_submitter = true;
            }

            if (org.user.is_provider) {
              orgUser.is_agent = true;
            }

            if (org.user.is_queue_manager) {
              orgUser.is_queue_manager = true;
            }
          }
        }
      }
    }

    if (angular.isDefined(model.userHieEnabled)) {
      user.userHieEnabled = model.userHieEnabled;
    }
  }

  // update when page loads
  _updateUser(model);

  /*
   *  View helper
   *  Turns privacy id into human readable format
   */
  user.humanizePrivacy = function ( privacy ) {

    if (privacy == 1) {
      return 'No one';
    } else if (privacy == 2) {
      return 'Organization Only';
    } else {
      return 'Everyone';
    }
  };

  /*
   *  View helper
   *  Turns org mobile pin setting into human readable format
   */
  user.mobilePin = function () {

    if (user.model.org && user.model.org.mobile_pin_required > 0) {

      var pinTimeout = '12 minutes';

      angular.forEach(PinOptions, function ( option ) {
        if (option.value == user.model.org.default_pin_delay) {
          pinTimeout = option.label;
        }
      });

      return 'Yes (' + pinTimeout + ')';

    } else {
      return 'No';
    }
  };

  /*
   *  View method
   *  Reset this users password
   */
  user.resetpw = function () {

    UserService.resetpw(user.model).then(function ( response ) {
      _updateUser(response);
    });
  };

  /*
   *  View method
   *  Disables this user
   */
  user.disable = function () {

    user.model.reg_status = 4;

    UserService.update(user.model).then(function ( response ) {
      _updateUser(response);
    });
  };

  user.togglePartner = function () {
    UserService.togglePartner(user.model).then(function ( response ) {
      user.type = response.user.type;
    });
  };

  user.togglePartner = function () {
    UserService.togglePartner(user.model).then(function ( response ) {
      user.type = response.user.type;
    });
  };

  user.toggleDeleteSchedulesEnabled = function () {
    user.deleteSchedulesEnabled.value = user.deleteSchedulesEnabled.value === '1' ? '0' : '1';
    SettingService.userSet(deleteSchedulesEnabled.setting_id, user.org, user.model, user.deleteSchedulesEnabled.value);
  };

  user.toggleScheduleSiteManageEnabled = function () {
    user.scheduleSiteManageEnabled.value = user.scheduleSiteManageEnabled.value === '1' ? '0' : '1';
    SettingService.userSet(scheduleSiteManageEnabled.setting_id, user.org, user.model, user.scheduleSiteManageEnabled.value);
  };

  user.toggleScheduleEntriesManageEnabled = function () {
    user.scheduleEntriesManageEnabled.value = user.scheduleEntriesManageEnabled.value === '1' ? '0' : '1';
    SettingService.userSet(scheduleEntriesManageEnabled.setting_id, user.org, user.model, user.scheduleEntriesManageEnabled.value);
  };

  user.toggleScheduleableEnabled = function () {
    user.scheduleableEnabled.value = user.scheduleableEnabled.value === '1' ? '0' : '1';
    return SettingService.userSet(scheduleableEnabled.setting_id, user.org, user.model, user.scheduleableEnabled.value);
  };

  user.toggleScheduleViewEnabled = function () {
    user.scheduleViewEnabled.value = user.scheduleViewEnabled.value === '1' ? '0' : '1';
    SettingService.userSet(scheduleViewEnabled.setting_id, user.org, user.model, user.scheduleViewEnabled.value);
  };

  /*
   *  View method
   *  Enables this user
   */
  user.enable = function () {

    user.model.reg_status = 3;

    UserService.update(user.model).then(function ( response ) {
      _updateUser(response);
    });
  };

  /*
   *  View method
   *  Resend this users confirmation instructions
   */
  user.resend = function () {

    UserService.resend(user.model.id).then(function ( response ) {
      _updateUser(response);
    });
  };

  /*
 *  View method
 *  Activate this user
 */
  user.activate = function () {
    UserService.activate(user.model.id).then(function ( response ) {
      _updateUser(response);
    });
  };

  /*
   *  View method
   *  Deletes a pending user, asks to confirm
   */
  user.remove = function () {

    ConfirmationService.confirm({
      title: 'Are you sure?',
      message: 'Deleted users cannot be re-enabled',
      callback: function () {

        user.model.reg_status = 5;

        UserService.update(user.model).then(function ( response ) {
          _updateUser(response);
        });

        $state.go('admin.users');
      }
    });
  };

  /*
   *  View method
   *  Sets the org as the one to edit in modal
   */
  user.setEditingOrg = function ( org ) {

    user.editOrg = org;
  };

  /*
   *  Modal callback
   *  When an org is added to this users, update list
   */
  user.onAddOrg = function ( orgs ) {

    user.orgs = orgs;
  };

  /*
   *  Modal callback
   *  Update local vars when user changes
   */
  user.onEditUser = function ( user ) {
    _updateUser(user);
  };
}

/* @ngInject */
UserDetailCtrl.resolve = {

  model: ['$stateParams', 'UserService', function ( $stateParams, UserService ) {

    return UserService.getUser($stateParams.id);
  }],
  orgHieEnabled: ['currentUser', 'SettingService', 'SettingKeys', function ( currentUser, SettingService, SettingKeys ) {
    if (currentUser.org) {
      return SettingService.find(SettingKeys.orgHieEnabled, currentUser.org).then(function (data) {
        return data.value === '1';
      });
    }
  }],
  orgSchedulingEnabled: ['SettingService', 'SettingKeys', 'model', function ( SettingService, SettingKeys, model ) {
    if (model.org) {
      return SettingService.find(SettingKeys.schedules, model.org).then(function (data) {
        return data.value === '1';
      });
    }
  }],
  deleteSchedulesEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.deleteSchedulesEnabled, model.org, $stateParams);
  }],
  scheduleSiteManageEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.scheduleSiteManageEnabled, model.org, $stateParams);
  }],
  scheduleEntriesManageEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.scheduleEntriesManageEnabled, model.org, $stateParams);
  }],
  scheduleableEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.scheduleableEnabled, model.org, $stateParams);
  }],
  scheduleViewEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    return SettingService.userFind(SettingKeys.scheduleViewEnabled, model.org, $stateParams);
  }],
  userHieEnabled: ['model', '$stateParams', 'SettingService', 'SettingKeys', function ( model, $stateParams, SettingService, SettingKeys ) {
    if (model.org) {
      return SettingService.userFind(SettingKeys.userHieEnabled, model.org, $stateParams).then(function (data) {
        return data.value === '1';
      });
    }
  }],

  networkOnly: ['PermissionsService', function ( PermissionsService ) {
    var admin = {
      hasNetworkFullAdminPrivs: PermissionsService.hasNetworkFullAdminPrivs,
      hasNetworkAdminPrivs: PermissionsService.hasNetworkAdminPrivs,
      hasDrFirstFullAdminPrivs: PermissionsService.hasDrFirstFullAdminPrivs,
      hasDrFirstAdminPrivs: PermissionsService.hasDrFirstAdminPrivs
    };

    if (admin.hasDrFirstFullAdminPrivs) {
      return false;
    } else {
      return true;
    }
  }]
};

angular.module('backlineAdminPlus')
  .controller('UserDetailCtrl', UserDetailCtrl);
;
'use strict';

/*
 *  Controller listing organizations
 *  @name backlineAdminPlus.controller:UsersCtrl
 *  @ngInject
 */
function UsersCtrl( $log, $q, $state, $timeout, UserService, userList, currentUser, org, orgHieEnabled, orgModel ) {

  var users = this,
    _perPage = 25,
    _sortColumn,
    _sortDirection;

  // set default filters
  users.page = 1;
  users.query = '';
  users.totalUsers = 0;
  users.totalPages = 0;
  console.log('orgModel', orgModel);
  if (orgModel) {
    users.ldapEnabled = orgModel.ldap_enabled;
    users.activeDirectorySync = orgModel.active_directory_sync;
  }
  users.orgHieEnabled = orgHieEnabled;
  users.currentUser = currentUser;
  users.usingPatientFilter = false;

  users.org = currentUser.org;

  // update user list variables based on new response
  function _updateUserList( newList ) {

    users.list = newList.aaData;
    users.totalUsers = newList.iTotalDisplayRecords;
    users.totalPages = Math.ceil(users.totalUsers / _perPage);
  }

  // refresh user list
  function _refreshUsers() {

    var statusList;

    if (users.statuses) {
      statusList = users.statuses.map(function ( status ) { return status.id; }).join(', ');
      users.usingPatientFilter = statusList.indexOf('10') >= 0;
    } else {
      users.usingPatientFilter = false;
    }

    var options = {
      forSummary: true,
      per_page: _perPage,
      page: users.page,
      query: users.query,
      sortColumn: _sortColumn,
      sortDirection: _sortDirection,
      showStatuses: statusList,
      org_id: org
    };

    UserService.list(options)
      .then(function ( response ) {
        _updateUserList(response);
      });
  }

  // update when page loads
  _updateUserList(userList);

  /*
   *  Input callback
   *  Search for users
   */
  users.search = function () {

    users.page = 1;
    _refreshUsers();
  };

  /*
   *  Input callback
   *  When status filter changes, refresh users
   */
  users.statusListChanged = function () {
    users.search();
  };

  /*
   *  View method
   *  Get next page of results
   */
  users.next = function () {

    users.page += 1;
    _refreshUsers();
  };

  /*
   *  View method
   *  Get previous page of results
   */
  users.previous = function () {

    users.page -= 1;
    _refreshUsers();
  };

  /*
   *  Modal callback
   *  After a user is added, go to their detail page
   */
  users.onAddUser = function ( user ) {

    // wait for modal to close
    $timeout(function () {
      $state.go('admin.users.detail', { id: user.id });
    }, 200);
  };

  /*
   *  Directive callback
   *  When sorting changes, set new params and refresh users
   */
  users.onChangeSorting = function ( column, direction ) {

    _sortColumn = column;
    _sortDirection = direction;

    users.search();
  };
}

/* @ngInject */
UsersCtrl.resolve = {

  org: ['PermissionsService', 'currentUser', function ( PermissionsService, currentUser ) {
    if (PermissionsService.canSearchOrgs) {
      return null;
    } else {
      return currentUser.org.id;
    }
  }],

  userList: ['UserService', 'org', function ( UserService, org ) {
    return UserService.list({ forSummary: true, org_id: org });
  }],

  orgHieEnabled: ['SettingService', 'org', 'SettingKeys', function ( SettingService, org, SettingKeys ) {
    // return false for null org, you cannot create a user on this screen anyway
    if (org === null) {
      return false;
    }

    return SettingService.find(SettingKeys.orgHieEnabled, {id: org}).then(function (data) {
      return data.value === '1';
    });
  }],

  orgModel: ['org', 'OrgService', function ( org, OrgService ) {
    console.log(org);
    if (org === null) {
      return null;
    }
    return OrgService.getOrg(org);
  }]
};

angular.module('backlineAdminPlus')
  .controller('UsersCtrl', UsersCtrl);
;
'use strict';

/*
 *  Controller for displaying escalations
 *  @name backlineAdminPlus.controller:EscalationsCtrl
 *  @ngInject
 */
function MassMessagingCtrl($timeout, $scope, MassMessagingService, OrgSelectorService, currentUser, ConfirmationService) {

  var messaging = this;

  $scope.disabled = false;

  messaging.page = 1;
  messaging.messages = [];
  messaging.selectedMessage = undefined;

  function _updateMassMessages() {
      MassMessagingService.list(OrgSelectorService.getOrg().id)
        .then(function (response) {
          messaging.messages = response;
          $scope.disabled = false;
        }, function (error) {
          if (error.status === 403) {
            messaging.messages = [];
            $scope.disabled = true;
          }
        });
    }

  _updateMassMessages();

  messaging.onSelectOrg = function () {
    _updateMassMessages();
  };

  messaging.createOrUpdate = function () {
    _updateMassMessages();
  };

  messaging.removeMassMessage = function (id) {
    ConfirmationService.confirm({
      title: 'Are you sure?',
      message: 'This will delete the mass message',
      callback: function () {
        MassMessagingService.remove(id)
                    .then(function () {
                      _updateMassMessages();
                    });
      }
    });
  };

  messaging.setSelectedMessage= function (message) {
    if (!message) {
      messaging.selectedMessage = undefined;
    }
    messaging.selectedMessage = angular.copy(message);
  };
}

/* @ngInject */
MassMessagingCtrl.resolve = {
  authenticate: ['$q', '$state', '$timeout', 'PermissionsService', 'currentUser', function ($q, $state, $timeout, PermissionsService, currentUser) {
    if (PermissionsService.hasDrFirstFullAdminPrivs ||
        (currentUser.orgs[0].is_mass_messaging_org && currentUser.orgs[0].is_mass_messaging_user && PermissionsService.hasFullAdminPrivs)) {
      return $q.when();
    } else {
      $timeout(function () {
        $state.go('admin.dashboard');
      });
      return $q.reject();
    }
  }]
};

angular.module('backlineAdminPlus').controller('MassMessagingCtrl', MassMessagingCtrl);
;
'use strict';

/*
 *  Directive for showing version information and debug tools
 *  @name backlineAdminPlus.directives:AboutModal
 *  @ngInject
 */
function AboutModal( $state, $timeout, DebugService, ServerEnv, AdminVersion, AdminSha ) {

  return {

    restrict: 'E',
    replace: true,
    templateUrl: 'views/directives/modals/_about.html',

    link: function ( scope, elem, attr ) {

      // expose variables to scope
      scope.consoleOutput = DebugService.logToConsole;

      scope.version = AdminVersion;
      scope.sha = AdminSha;
      scope.env = ServerEnv;

      /*
             *  View method
             *  Toggle console debug output and update local variable
             */
      scope.toggleConsoleOutput = function () {

        DebugService.toggleConsoleOutput();

        scope.consoleOutput = DebugService.logToConsole;
      };

      /*
       *  View method
       *  Close modal and go to debug logs
       */
      scope.viewLogs = function () {

        elem.modal('hide');

        // wait for modal to close completely before state change
        $timeout(function () {
          $state.go('admin.debug');
        }, 200);
      };
    }
  };
}

angular.module('backlineAdminPlus')
    .directive('aboutModal', AboutModal);
;
'use strict';

/*
 *  Directive for creating broadcast bots
 *  @name backlineAdminPlus.directives:AddBroadcastModal
 *  @ngInject
 */
function AddBroadcastModal( BroadcastService, OrgSelectorService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      callback: '&success'
    },
    templateUrl: 'views/broadcast/_add_modal.html',

    link: function ( scope, elem, attr ) {

      // generate random characters of length `number`
      function _randomChars( number ) {

        return Math.random().toString(35).substring(2, number + 2);
      }

      // reset bot info to defaults
      function _resetBot() {
        scope.bot = {
          email: 'broadcastbot' + _randomChars(8) + '@backline.bot',
          pw: _randomChars(16),
          bot_type: 10
        };
      }

      // reset when directive compiles
      _resetBot();

      /*
       *  View method
       *  Save current bot information and close modal
       */
      scope.save = function () {

        scope.saving = true;

        BroadcastService.add(OrgSelectorService.getOrg(), scope.bot)
          .then(function ( response ) {

            scope.saving = false;
            scope.callback({ bot: response });
            elem.modal('hide');

          }, function ( error ) {

            scope.saving = false;
            scope.error = error;
          });
      };

      // reset bot on modal dismiss
      elem.on('hidden.bs.modal', function () {
        _resetBot();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addBroadcastModal', AddBroadcastModal);
;
'use strict';

/*
 *  Directive for creating departments in a modal
 *  @name backlineAdminPlus.directives:AddDepartmentModal
 *  @ngInject
 */
function AddDepartmentModal( DepartmentService, OrgSelectorService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      callback: '&success'
    },
    templateUrl: 'views/departments/_add_modal.html',

    link: function ( scope, elem, attr ) {

      // reset department to default info
      function _resetDepartment() {

      }

      // reset department when directive compiles
      _resetDepartment();

      /*
       *  View method
       *  Save new department
       */
      scope.save = function () {

        scope.saving = true;

        DepartmentService.add(OrgSelectorService.getOrg(), scope.department)
          .then( function ( response ) {

            scope.saving = false;
            scope.callback({ department: response });
            elem.modal('hide');

          }, function ( error ) {

            scope.saving = false;
            scope.error = error;
          });
      };

      // reset department when modal is dismissed
      elem.on('hidden.bs.modal', function () {
        _resetDepartment();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addDepartmentModal', AddDepartmentModal);
;
'use strict';

/*
 *  Directive for creating groups in a modal
 *  @name backlineAdminPlus.directives:AddGroupModal
 *  @ngInject
 */
function AddGroupModal( GroupService, OrgSelectorService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      callback: '&success'
    },
    templateUrl: 'views/groups/_add_modal.html',

    link: function ( scope, elem, attr ) {

      // reset group to default info
      function _resetGroup() {
        scope.group = {
          feed_type: 2
        };
      }

      // reset group when directive compiles
      _resetGroup();

      /*
       *  View method
       *  Save new group
       */
      scope.save = function () {

        scope.saving = true;

        GroupService.add(OrgSelectorService.getOrg(), scope.group)
          .then( function ( response ) {

            scope.saving = false;
            scope.callback({ group: response });
            elem.modal('hide');

          }, function ( error ) {

            scope.saving = false;
            scope.error = error;
          });
      };

      // reset group when modal is dismissed
      elem.on('hidden.bs.modal', function () {
        _resetGroup();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addGroupModal', AddGroupModal);
;
'use strict';

/*
 *  Directive for creating networks in a modal
 *  @name backlineAdminPlus.directives:AddNetworkModal
 *  @ngInject
 */
function AddNetworkModal( $q, NetworkService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&success'
    },
    templateUrl: 'views/networks/_add_modal.html',

    link: function ( scope, elem, attr ) {

      scope.network = {
        type: 1,
        business_type: null,
        searchability: 0,
        external_patient_data_source: 0
      };

      scope.types = NetworkService.types();
      scope.searchability_options = NetworkService.searchability_options();
      scope.external_patient_data_source_options = NetworkService.external_patient_data_source_options();
      scope.business_type_options = NetworkService.business_type_options();

      /*
       *  Input callback
       *  When org is changed, update network control org
       */
      scope.onSelectOrg = function ( org ) {

        scope.network.control_org_id = org.id;
      };

      /*
       *  View method
       *  Save new network
       */
      scope.save = function () {
        var deferred = $q.defer();

        // only if form is complete
        if (scope.network.name && scope.network.control_org_id) {

          NetworkService.add(scope.network)
            .then( function ( response ) {

              scope.callback({ network: response });
              deferred.resolve();

            }, function ( error ) {

              deferred.reject(error);
            });
        } else {

          deferred.reject('Please fill out all fields');
        }

        return deferred.promise;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addNetworkModal', AddNetworkModal);
;
'use strict';

/*
 *  Directive for creating orgs in a modal
 *  @name backlineAdminPlus.directives:AddOrgModal
 *  @ngInject
 */
function AddOrgModal( $log, $q, OrgService, UserService, Time, PermissionsService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      callback: '&'
    },
    templateUrl: 'views/orgs/_add_modal.html',

    link: function ( scope, elem, attr ) {

      // reset org to default values
      function _resetOrg() {
        scope.org = {
          inactivity_timeout_seconds: Time.minutes(8),
          user_licenses: 10,
          nus_enabled: false,
          type_id: 1
        };
        scope.errors = {};
      }

      scope.types = [
        {value: 1, label: 'enterprise'},
        {value: 2, label: 'community'}
      ];

      OrgService.types().then(function (types) {
        scope.types = types;
      });

      OrgService.states().then(function (states) {
        scope.states = states;
      });

      scope.typeChangeable = OrgService.typeChangeable;

      scope.dfAdmin = PermissionsService.hasDrFirstAdminPrivs;

      // reset org when directive compiles
      _resetOrg();

      /*
       *  Input callback
       *  Checks NPI is valid and unused
       */
      scope.onChangeNPI = function () {

        if (!scope.org.npi || scope.org.npi === '') {
          scope.errors.npi = undefined;
          return;
        }

        UserService.checkNPI(scope.org.npi)
          .then(function ( response ) {
            scope.errors.npi = undefined;
          }, function ( error ) {
            scope.errors.npi = error.text;
          });
      };

      /*
       *  View method
       *  Save new org
       */
      scope.submit = function () {

        if (!scope.errors.npi) {

          scope.saving = true;
          if (scope.org.nus_enabled) {
            scope.org.type = undefined;
          }

          OrgService.add(scope.org)
            .then( function ( response ) {

              scope.saving = false;
              _resetOrg();

              elem.modal('hide');
              scope.callback({ org: response });

            }, function ( error ) {

              scope.saving = false;
              scope.errors.top = error;
            });
        } else {

          scope.errors.top = 'Please enter all fields';
        }
      };

      // reset org on modal dismiss
      elem.on('hidden.bs.modal', function () {
        _resetOrg();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addOrgModal', AddOrgModal);
;
'use strict';

/*
 *  Directive for adding users to orgs from a modal
 *  @name backlineAdminPlus.directives:AddOrgUserModal
 *  @ngInject
 */
function AddOrgUserModal( $q, UserService, OrgSelectorService, DepartmentService ) {

  return {

    restrict: 'E',
    scope: {
      user: '=',
      networkOnly: '=',
      callback: '&success'
    },
    templateUrl: 'views/users/_add_org_user_modal.html',

    link: function ( scope, elem, attr ) {

      // reset org user to defaults
      function _resetOrgUser() {
        scope.errors = {};
        scope.selected = {
          departments: []
        };
        scope.org = OrgSelectorService.getOrg();
        scope.orgUser = {
          id: scope.user.id,
          org_id: scope.org.id,
          role_id: 1,
          is_primary: false,
          is_contact: false,
          login: scope.user.email
        };
        DepartmentService.list({org_id: scope.org.id, query: '', per_page: 200, page: 1, enabled: 'true'})
          .then(function (response) {
            var departmentNames = [];

            response.departments.forEach(function (department) {
              departmentNames.push(department.name);
            });
            scope.departments = departmentNames;
          });
      }

      // reset org user when directive compiles
      _resetOrgUser();

      /*
       *  View method
       *  Save new org user
       */
      scope.save = function () {
         var deferred = $q.defer();

         if (!scope.selected.departments || scope.selected.departments.length === 0) {
           scope.errors.departments = 'Please enter at least 1 department';
         } else {
           scope.errors.departments = undefined;
         }
         if (!(scope.errors.departments)) {
           // reverse depts from array to string
           scope.orgUser.departments = scope.selected.departments.join(', ');
           UserService.addOrg( scope.orgUser )
             .then(function ( response ) {

               deferred.resolve();
               scope.callback({ orgs: response });
               elem.modal('hide');
             }, function ( error ) {
               scope.errors.top = error;
               deferred.reject();
             });
         } else {
           deferred.reject('Please fill out all fields');
         }
         return deferred.promise;
       };

      /*
       *  Direcitve callback
       *  When org changes, update org and org user
       */
      scope.select = function ( org ) {
        if (org) {
          scope.org = org;
          scope.orgUser.org_id = org.id;
          DepartmentService.list({org_id: org.id, query: '', per_page: 2000, page: 1, enabled: 'true'})
            .then(function (response) {
              var departmentNames = [];

              response.departments.forEach(function (department) {
                departmentNames.push(department.name);
              });
              scope.departments = departmentNames;
            });
        }
      };

      // reset bot on modal dismiss
      elem.on('hidden.bs.modal', function () {
        _resetOrgUser();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addOrgUserModal', AddOrgUserModal);
;
'use strict';

/*
 *  Directive for creating patients in a modal
 *  @name backlineAdminPlus.directives:AddPatientModal
 *  @ngInject
 */
function AddPatientModal( $q, OrgSelectorService, PatientService, SettingService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&success',
      hieEnabled: '=hieEnabled',
      hieOids: '=hieOids'
    },
    templateUrl: 'views/patients/_add_modal.html',

    link: function ( scope, elem, attr ) {

      scope.patient = {
        is_private: false,
        deceased: false,
        gender: 'Unknown'
      };

      scope.save = function () {
        var deferred = $q.defer();

        if (scope.patient.lname && scope.patient.fname && scope.patient.dob) {

          PatientService.add(OrgSelectorService.getOrg(), scope.patient)
            .then( function ( response ) {
              scope.callback({ patient: response });
              deferred.resolve();
            }, function ( error ) {
              deferred.reject(error);
            });
        } else {

          deferred.reject('Please fill out all required fields');
        }

        return deferred.promise;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('addPatientModal', AddPatientModal);
;
'use strict';

/**
 *  Directive for creating users in a modal
 *  @class
 *  @name backlineAdminPlus.directives:AddUserModal
 *  @ngInject
 *
 *  @param $q
 *  @param UserService
 *  @param ToastService
 *  @param LdapService
 * @param SettingService
 * @param SettingKeys
 * @param SupportService
 * @param DepartmentService
 */
function AddUserModal($q, UserService, ToastService, LdapService, SettingService, SettingKeys, SupportService,
                      DepartmentService) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      org: '=',
      currentUser: '=',
      callback: '&success',
      orgHieEnabled: '='
    },
    templateUrl: 'views/users/_add_modal.html',

    link: function (scope, elem) {

      scope.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // default to create one only
      scope.createAnother = false;
      scope.support = {
        isAgent: false,
        isQueueManager: false,
        isSubmitter: false
      };
      scope.ldapDefaultName = '';
      scope.$watch('user.email', function (/** string */ newValue) {
        scope.ldapDefaultName = LdapService.createDefaultUsername(newValue);
      });

      scope.canHaveAgents = SupportService.canHaveAgents(scope.org);
      scope.canCreateAgent = SupportService.canCreateAgent(scope.currentUser, scope.org);

      scope.canHaveQueueManagers = SupportService.canHaveQueueManagers(scope.org);
      scope.canCreateQueueManager = SupportService.canCreateQueueManager(scope.currentUser, scope.org);

      scope.canHaveSubmitters = SupportService.canHaveSubmitters(scope.org);
      scope.canCreateSubmitter = SupportService.canCreateSubmitter(scope.currentUser, scope.org);

      if (scope.org.departments) {
        // if org includes departments array, use them.
        scope.departments = scope.org.departments;
      } else {
        // otherwise split up departments_list string
        scope.departments = scope.org.departments_list.split(', ');
      }

      // reset user to defaults
      function _resetUser() {

        var totalUsers = scope.org.active_licenses,
          totalLicenses = scope.org.user_licenses;

        if (totalUsers >= totalLicenses) {
          // user cannot be created, no licenses
          scope.user = {};
          scope.licenseLimitReached = true;
        } else {
          // reset user to default values
          scope.user = {
            poc: false,
            patient_search_allowed: scope.org.default_patient_search_allowed,
            role_id: 1,
            org_id: scope.org.id,
            ldap_enabled: scope.org.ldap_enabled || true
          };

          // reset selected departments
          scope.selected = {
            departments: []
          };
        }

        scope.errors = {};
      }

      // reset user when directive compiles
      _resetUser();

      /*
       *  Input callback
       *  When email changes, check uniqueness on adama
       */
      scope.onChangeEmail = function () {

        UserService.checkEmail(scope.user.email)
          .then(function (response) {
            if (response) {

              scope.errors.email = 'Email address already taken by: ' + response.lname + ', ' + response.fname + '.';

            } else {

              scope.errors.email = undefined;
            }
          }, function (error) {

            scope.errors.top = 'Error encountered checking email';
          });
      };

      /*
       *  Input callback
       *  When npi changes, check uniqueness on adama
       */
      scope.onChangeNPI = function () {

        if (!scope.user.npi || scope.user.npi === '') {
          scope.errors.npi = undefined;
          delete scope.user.npi; // delete npi field from user object if it's not set
          return;
        }

        UserService.checkNPI(scope.user.npi)
          .then(function (response) {
            scope.errors.npi = undefined;
          }, function (error) {
            scope.errors.npi = error.text;
          });
      };

      /*
       *  View method
       *  Save new user
       */
      scope.submit = function () {
        // make sure depts were filled out, since we can't use form validation for ui-select
        if (!scope.selected.departments || scope.selected.departments.length === 0) {
          scope.errors.departments = 'Please enter at least 1 department';
        } else {
          scope.errors.departments = undefined;
        }

        // if form is truely filled out
        if (!(scope.errors.email || scope.errors.npi || scope.errors.departments)) {
          scope.saving = true;

          // reverse depts from array to string
          scope.user.departments = scope.selected.departments.join(', ');
          if (scope.user.login === '') {
            scope.user.login = null;
          }

          UserService.add(scope.user)
            .then(function (response) {
              if (scope.orgHieEnabled && scope.user.hieEnabled === true) {
                SettingService.userFind(SettingKeys.userHieEnabled, response.org, response).then(function (data) {
                  SettingService.userSet(data.setting_id, response.org, response, '1');
                });
              }

              if (scope.support.isAgent) {
                SupportService.createAgent(response.org.id, response.id);
              }

              if (scope.support.isQueueManager) {
                SupportService.createQueueManager(response.org.network.id, response.org.id, response.id);
              }

              if (scope.support.isSubmitter) {
                SupportService.createSubmitter(response.org.id, response.id);
              }

              scope.saving = false;
              scope.org.pending_users += 1;
              _resetUser();

              if (scope.createAnother) {
                // give user feedback of success if create another is active
                ToastService.show({
                  title: 'Saved',
                  message: 'User was created successfully',
                  type: 'success'
                });

              } else {
                // otherwise hide modal
                elem.modal('hide');
                scope.callback({user: response});
              }

            }, function (error) {
              scope.saving = false;
              scope.errors.top = error;
            });

        } else {

          scope.errors.top = 'Please enter all fields';
        }
      };

      // reset user on modal dismiss
      elem.on('hidden.bs.modal', function () {
        scope.createAnother = false;
        _resetUser();
      });

      // get the list of departments after modal is shown to fix issue TS1-24197
      elem.on('show.bs.modal', function () {
        DepartmentService.list({org_id: scope.org.id, query: '', per_page: 2000, page: 1, enabled: 'true'})
          .then(function (response) {
            var departmentNames = [];

            response.departments.forEach(function (department) {
              departmentNames.push(department.name);
            });
            scope.departments = departmentNames;
          });
      });
    }
  };
}

/* @ngInject */
AddUserModal.resolve = {
  departmentList: ['DepartmentService', 'OrgSelectorService', function (DepartmentService, OrgSelectorService) {
    return DepartmentService.list({
      org_id: OrgSelectorService.getOrg().id,
      query: '',
      per_page: 2000,
      page: 1,
      enabled: 'true'
    });
  }],
  org: ['OrgService', 'OrgSelectorService', function (OrgService, OrgSelectorService) {
    return OrgService.getOrg(OrgSelectorService.getOrg().id);
  }]
};

angular.module('backlineAdminPlus')
  .directive('addUserModal', AddUserModal);
;
'use strict';

/**
 *  Directive for creating escalations in a modal
 *  @class
 *  @name backlineAdminPlus.directives:AddEscalationModal
 *  @ngInject
 *
 *  @param $q
 *  @param EscalationService
 *  @param OrgSelectorService
 */
function AddEscalationModal($q, EscalationService, OrgSelectorService) {

  return {
    restrict: 'E',
    replace: true,
    scope: {
      callback: '&',
      selected: '='
    },
    templateUrl: 'views/escalations/_add_modal.html',

    link: function (scope, elem) {

      var initEscalation = {
        name: '',
        description: '',
        expirationMinutes: 1,
        escalation_steps: []
      };

      scope.targetTypes = [
        { value: 'Feed', description: 'Group' },
        { value: 'User', description: 'User' }
      ];
      scope.reqError = false;
      scope.validationError = null;
      scope.enableSubmit = true;
      scope.disableAddStep = false;
      scope.maxSteps = 2;

      scope.mapStepDataToUx = function (newSelectedEscalation) {
        return newSelectedEscalation.escalation_steps.map(function (step) {
          return {
            escalation_order: step.escalation_order,
            escalation_targets: step.escalation_targets,
            is_disabled: false,
            escalateAfterMinutes: step.escalate_after_in_seconds / 60,
            name: step.name,
            id: step.id
          };
        });
      };

      scope.$watch('selected', function (newSelectedEscalation) {
        if (newSelectedEscalation) {
          scope.escalation = newSelectedEscalation;
          scope.escalation.expirationMinutes = newSelectedEscalation.path_expiration_in_seconds / 60;
          scope.escalation.escalation_steps = scope.mapStepDataToUx(newSelectedEscalation);
        } else {
          scope.escalation = JSON.parse(JSON.stringify(initEscalation));
        }
      });

      scope.buildEscalationRequest = function () {
        // define this here so when we remove don't have to alter page id and esc order
        var escalationSteps = scope.escalation.escalation_steps.map(function (step, i) {
          var target = {
            entitiable_id: step.escalation_targets[0].entitiable_id,
            entitiable_type: step.escalation_targets[0].entitiable_type
          };

          if (scope.selected && step.escalation_targets[0].id){
            target.id = step.escalation_targets[0].id;
          }
          var stepReq = {
            escalation_order: i + 1,
            escalation_targets: [ target ],
            is_disabled: false,
            escalate_after_in_seconds: step.escalateAfterMinutes * 60
          };

          if (scope.selected && step.id){
            stepReq.id = step.id;
          }
          return stepReq;
        });

        var escalation = { name: scope.escalation.name,
          description: scope.escalation.description,
          path_expiration_in_seconds: scope.escalation.expirationMinutes * 60,
          org_id: OrgSelectorService.getOrg().id,
          escalation_steps: escalationSteps
        };

        if (scope.selected){
          escalation.id = scope.selected.id;
          escalation.org_id = scope.selected.org_id;
        }

        return escalation;
      };

      /*
       *  View method
       *  Save new or Edit escalation
       */
      scope.submit = function () {
        scope.saving = true;
        var escalationRequest = scope.buildEscalationRequest();
        var serviceCall;

        if (scope.selected) {
          serviceCall = EscalationService.update;
        } else {
          serviceCall = EscalationService.create;
        }

        serviceCall(escalationRequest)
          .then(function () {
            scope.callback();
            scope.saving = false;
            elem.modal('hide');
          }, function (error) {
            scope.saving = false;
            scope.reqError = error.data.error_text;
          });
      };

      scope.addEscalation = function () {
        scope.escalation.escalation_steps.push({
          escalateAfterMinutes: 1,
          is_disabled: false,
          escalation_targets: [ { entitiable_id: '', entitiable_type: '' } ]
        });
      };

      scope.removeStep = function (index) {
        scope.escalation.escalation_steps.splice(index, 1);
      };

      scope.onSelectTarget = function (target) {
        scope.escalation.escalation_steps[target.idx].escalation_targets[0].entitiable_id = target.id;

        // need to pass down name from parent to child component to maintain correct data
        scope.escalation.escalation_steps[target.idx].escalation_targets[0].name = target.name;
      };

      scope.checkForError = function (escalation) {
        scope.validationError = null;
        scope.reqError = null;
        var stepCount = escalation.escalation_steps.length;

        // from one minute to one day (1440minutes)
        var acceptableExpiration = escalation.expirationMinutes > 0 && escalation.expirationMinutes <= 1440;

        if (!acceptableExpiration) {
          scope.validationError = 'Required Path Expiration (Minutes): Min: 1  Max: 1440';
        }

        scope.disableAddStep = stepCount === scope.maxSteps;
        if (stepCount === 0) {
          scope.enableSubmit = false;
          return;
        }

        var everyStepFilledOut = escalation.escalation_steps.every(function (step) {
          var acceptableTime = step.escalateAfterMinutes >= 1 && step.escalateAfterMinutes <= 1440;
          var hasTargetType = !!step.escalation_targets[0].entitiable_type;
          var hasTarget = !!step.escalation_targets[0].entitiable_id;

          if (!acceptableTime) {
            scope.validationError = 'Required minimum 1 minute, maximum 1440 minutes (1 day).';
          }
          return acceptableTime && hasTargetType && hasTarget;
        });

        scope.enableSubmit = everyStepFilledOut && !!escalation.name && !!acceptableExpiration;
      };

      // deep watch of escalation object: dynamic data requires multiple fields filled out
      scope.$watch('escalation', function (escalation) {
        scope.checkForError(escalation);
      }, true);

    }
  };
}

/* @ngInject */
AddEscalationModal.resolve = {};

angular.module('backlineAdminPlus').directive('addEscalationModal', AddEscalationModal);
;
'use strict';

/**
 *  Directive for creating escalations in a modal
 *  @class
 *  @name backlineAdminPlus.directives:AddMassMessageModal
 *  @ngInject
 *
 *  @param $q
 *  @param MassMessagingService
 *  @param OrgSelectorService
 */
function AddMassMessageModal($q, $interpolate, $stateParams, MassMessagingService, OrgSelectorService, FileUploader, SettingService, SettingKeys, UserService, ConfirmationService, $timeout) {

  return {
    restrict: 'E',
    replace: true,
    scope: {
      callback: '&',
      selected: '='
    },
    templateUrl: 'views/mass-messaging/_add_modal.html',

    link: function (scope, elem) {

      var initMassMessage = {
        id: undefined,
        name: '',
        description: '',
        message: '',
        file_name: undefined,
        headers: [],
        progress: undefined,
        file_size: undefined,
        orgId: undefined,
        records: undefined,
        file: undefined,
        saving: false,
        showResults: false,
        showUploadedNumbers: false
      };

      scope.uploadErrorTemplate = $interpolate('The specified file {{filename}} could not be uploaded due to {{reason}}. Please ensure the file is in the proper format and try again or try uploading an alternate file.”');
      scope.limit = 160;
      scope.loading = false;

      scope.setDefaults = function () {
        scope.massMessage.fileStatus = 'No file selected';
        scope.massMessage.headers = [];
        scope.massMessage.reqErrors = [];
        scope.massMessage.validationErrors = [];
      };

      scope.removeCsvFile = function () {
        scope.massMessage.file_name = undefined;
        scope.massMessage.progress = 0;
        scope.massMessage.showResults = false;
        scope.massMessage.showUploadedNumbers = false;
        scope.massMessage.file = undefined;
      };

      scope.$watch('selected', function (selectedValue) {
        if (selectedValue) {
          scope.loading = true;
          MassMessagingService.get(selectedValue.id).then(function (response) {
            scope.validationErrors = [];
            scope.reqErrors = [];
            scope.massMessage = response.mass_message[0];
            scope.massMessage.showResults = response.mass_message[0].file_name !== '';
            scope.massMessage.progress = 100;
            scope.massMessage.fileStatus = 'Saved';
            scope.massMessage.showUploadedNumbers = response.mass_message[0].uploaded_numbers !== undefined && response.mass_message[0].uploaded_numbers[0] !== undefined;

            scope.loading = false;
          });
        } else {
          scope.massMessage = JSON.parse(JSON.stringify(initMassMessage));
        }
        scope.massMessage.orgId = OrgSelectorService.getOrg().id;
        scope.setDefaults();
      });

      elem.on('hidden.bs.modal', function () {
        scope.massMessage = JSON.parse(JSON.stringify(initMassMessage));
        scope.removeCsvFile();
        scope.setDefaults();
      });

      scope.selectFile = function () {
        scope.input = document.createElement('input');
        scope.input.type = 'file';
        scope.input.required = true;
        scope.input.name = 'file';
        scope.input.addEventListener('change', scope.readFile, false);
        scope.input.click();
      };

      scope.readFile = function (event) {
        scope.validationErrors = [];
        scope.massMessage.file = event.target.files[0];
        if (!scope.massMessage.file) {
          return;
        }

        // check if file less than 2MB
        if (scope.massMessage.file.size > 2097152) {
          scope.massMessage.validationErrors.push('Error: Files must be less than 2MB. Please decrease the file size and try uploading again.');
        }

        // check if file format is csv
        if (scope.massMessage.file.type !== 'text/csv') {
          scope.massMessage.validationErrors.push('Error: This is not a CSV File. Please attach a correctly formatted file.');
        }

        if (scope.validationErrors.length > 0) {
          scope.massMessage.massMessage.file = undefined;
          return;
        }

        var reader = new FileReader();

        reader.onload = function (event) {
          var csvText = event.target.result;
          var lines = csvText.split('\n');
          var records = lines.filter(function (line) {
            return line.trim() !== '';
          });
          var headers = lines[0].split(',');

          scope.massMessage.records = records.length - 1; // subtract 1 for header row
          scope.massMessage.headers = headers;
          scope.massMessage.file_name = scope.massMessage.file.name;
          scope.massMessage.file_size = scope.massMessage.file.size;
          scope.$apply();
          scope.massMessage.showResults = true;
          scope.massMessage.showUploadedNumbers = false;
        };

        reader.onprogress = function (event) {
          if (event.lengthComputable) {
            scope.massMessage.progress = (event.loaded / event.total) * 100;
            scope.massMessage.fileStatus = scope.massMessage.progress < 100 ? 'Loading...' : 'Loaded';
          }
        };

        reader.onerror = function () {
          scope.massMessage.progress = 100;
          scope.massMessage.validationErrors.push(scope.uploadErrorTemplate({filename: scope.massMessage.file_name, reason: 'an unknown error'}));
          scope.massMessage.fileStatus = 'Error';
        };
        reader.readAsText(scope.massMessage.file);
      };

      scope.buildMassMessageRequest = function () {
        var formData = new FormData();

        formData.append('name', scope.massMessage.name);
        formData.append('description', scope.massMessage.description);
        formData.append('message', scope.massMessage.message);
        if (scope.massMessage.file || (scope.selected && !scope.massMessage.file_name)) {
          formData.append('file', scope.massMessage.file);
        }
        formData.append('org_id', OrgSelectorService.getOrg().id);
        return formData;
      };

      scope.validateForm = function () {
        scope.massMessage.reqErrors = [];
        scope.massMessage.validationErrors = [];
        if (scope.massMessageForm.$invalid) {
          console.log(scope.massMessageForm.$error);
          if (scope.massMessageForm.$error.required) {
            for (var i = 0; i < scope.massMessageForm.$error.required.length; i++) {
              scope.massMessage.validationErrors.push('Error: Field ' + scope.massMessageForm.$error.required[i].$name + ' is required');
            }
          }
          if (scope.massMessageForm.$error.maxlength) {
            for (var i = 0; i < scope.massMessageForm.$error.maxlength.length; i++) {
              scope.massMessage.validationErrors.push('Error: Field ' + scope.massMessageForm.$error.maxlength[i].$name + ' must be less than ' + scope.limit + ' characters');
            }
          }
        }
        if ((!scope.massMessage.id && !scope.massMessage.file )) {{
          scope.massMessage.validationErrors.push('Error: Mass message file is required');
        }}

        if (!scope.massMessage.file_name && scope.massMessage.id && !scope.massMessage.file) {
          scope.massMessage.validationErrors.push('Error: You must upload a new file to update the mass message');
        }

        if (scope.massMessage.file && scope.massMessage.records < 1) {
          scope.massMessage.validationErrors.push('Error: File has no phone number records. Please attach a file that contains at least one record.');
        }

        return scope.massMessage.validationErrors.length <= 0;
      };

      scope.createOrUpdate = function () {
        scope.massMessage.saving = true;
        var massMessageRequest = scope.buildMassMessageRequest();
        var serviceCall;

        if (scope.massMessage.id) {
          serviceCall = MassMessagingService.update;
        } else {
          serviceCall = MassMessagingService.create;
        }

        return serviceCall(massMessageRequest, scope.massMessage.id);
      };

      scope.save = function () {
        if (!scope.validateForm()) {
          return;
        }
        scope.createOrUpdate()
          .then(function () {
            scope.massMessage.saving = false;
            scope.callback();
            elem.modal('hide');
          }, function (error) {
            scope.massMessage.saving = false;
            scope.massMessage.file = undefined;
            if (error.responseJSON.response_code >= 500) {
              scope.massMessage.reqErrors = [ scope.uploadErrorTemplate({filename: scope.massMessage.file_name, reason: error.responseJSON.response_text}) ];
            }
            scope.massMessage.reqErrors.push(error.responseJSON.error_text[0]);
          });
      };

      scope.sendMessages = function () {
        ConfirmationService.confirm({
          title: 'Are you sure?',
          generateHtml: true,
          message: 'Caution: Mass Messages are outbound messages utilizing SMS to unverified phone numbers. They are <strong>unsecured</strong> and should <b>never</b> include PHI. Use discretion.',
          callback: function () {
            if (!scope.validateForm()) {
              return;
            }
            scope.createOrUpdate()
              .then(function (response) {
                MassMessagingService.broadcast(response.mass_message.id, OrgSelectorService.getOrg().id)
                    .then(function () {
                      $timeout(function () {
                        scope.callback();
                        scope.massMessage.saving = false;
                        elem.modal('hide');
                      }, 2000);
                    }, function (error) {
                      scope.massMessage.saving = false;
                      scope.massMessage.reqErrors.push(error.responseJSON.error_text[0]);
                    });
              }, function (error) {
                scope.massMessage.saving = false;
                if (error.responseJSON.response_code >= 500) {
                  scope.massMessage.reqErrors = [ scope.uploadErrorTemplate({filename: scope.massMessage.file_name, reason: error.responseJSON.response_text}) ];
                }
                scope.massMessage.reqErrors.push(error.responseJSON.error_text[0]);
              });
          }
        });
      };

      scope.downloadTemplate = function () {
        MassMessagingService.template(OrgSelectorService.getOrg().id);
      };

    }
  };
}
/* @ngInject */
AddMassMessageModal.resolve = {};

angular.module('backlineAdminPlus').directive('addMassMessageModal', AddMassMessageModal);
;
'use strict';
/*
 *  Directive for viewing/editing mask caller id settings in a modal
 *  @name backlineAdminPlus.directives:AdobeProvisioningModal
 *  @ngInject
 */
function AdobeProvisioningModal( $q, AdobeProvisioningService, OrgService, OrgSelectorService ) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      org: '=?',
      emailAddress: '=?',
      lastName: '=?',
      firstName: '=?',
      phoneNumber: '=?',
      currentPassword: '=?',
      password_confirmation: '=?',
      onChange: '&?'
    },
    templateUrl: 'views/configs/_adobe_provisioning_modal.html',

    link: function ( scope, elem, attr ) {
      scope.errors = {};
      scope.saving = false;
      scope.hadError = false;
      scope.wasSuccess = false;
      scope.done = false;
      scope.validPasswords = false;
      scope.errors.top = '';

      AdobeProvisioningService.getProvisioningInfo(OrgSelectorService.getOrg())
        .then(function (response) {
          if (response.provisioning) {
            if (!response.provisioning.success) {
              scope.emailAddress = response.provisioning.data.email;
              scope.firstName = response.provisioning.data.firstName;
              scope.lastName = response.provisioning.data.lastName;
              scope.phone = response.provisioning.data.phone;
            }
          }
        });

      scope.submit = function () {
        if (!scope.validPasswords) {
          scope.errors.top = 'Passwords must match';
          return false;
        }
        scope.saving = true;

        AdobeProvisioningService.provision(OrgSelectorService.getOrg(), scope.emailAddress, scope.firstName, scope.lastName, scope.phone, scope.currentPassword)
         .then(function (response) {
           scope.saving = false;
           scope.done = true;

           if (response.register_account_result.success) {
             scope.wasSuccess = true;
             scope.hadError = false;
           } else {
             scope.wasSuccess = false;
             scope.hadError = true;
           }
           scope.$parent.security.adobeProvisioningEvent(scope.$parent.security.model);
         }, function (error) {
           scope.saving = false;
           scope.done = true;
         });
      };

      scope.close = function () {
        elem.modal('hide');

        scope.done = false;
        scope.wasSuccess = false;
        scope.hadError = false;

        scope.$parent.security.adobeProvisioningEvent(scope.$parent.security.model);
      };

      scope.retry = function () {
        scope.done = false;
        scope.wasSuccess = false;
        scope.hadError = false;
      };

      scope.onChangePassword = function () {
        scope.errors.top = '';

        scope.length = scope.currentPassword && scope.currentPassword.length >= 3;
        scope.capital = true;
        scope.lower = true;
        scope.number = true;
        scope.match = (scope.currentPassword && scope.password_confirmation) && (scope.currentPassword === scope.password_confirmation);

        scope.validPasswords = scope.length && scope.capital && scope.lower && scope.number && scope.match;
      };

      scope.$watch('enabled', function ( value ) {});
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('adobeProvisioningModal', AdobeProvisioningModal);
;
'use strict';

/*
 *  Directive for displaying orgs in dropdown format
 *  @name backlineAdminPlus.directives:AgentDropdown
 *  @ngInject
 */
function AgentDropdown( SupportService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&',
      orgId: '='
    },
    templateUrl: 'views/support/agents/_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.agents = [];

      $scope.select = function ( $select, agent ) {
        $scope.callback({ agent: agent });
      };

      $scope.search = function ( query ) {
        if (!query || query === '') {
          $scope.agents = [];
          return;
        }

        SupportService.queryAgents($scope.orgId, query).$promise
          .then(function (agents) {
            $scope.agents = agents;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('agentDropdown', AgentDropdown);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for adding / removing queues from an Agent
 *
 *  resolve: {
 *    agent?: Agent, (or) user?: User,
 *  }
 *  @name backlineAdminPlus.directives:AgentQueuesModal
 *  @ngInject
 */
function AgentQueuesModal(QueueService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/agents/_queues_modal.html',

    controller: ['$scope', function ($scope) {
      if (angular.isUndefined($scope.resolve.agent)) {
        $scope.user = $scope.resolve.user;
        $scope.orgId = $scope.resolve.user.orgs[0].id;
        $scope.agent = {
          queue_count: 0
        };
      } else {
        $scope.agent = $scope.resolve.agent;
        $scope.user = $scope.resolve.agent.user;
        $scope.orgId = $scope.resolve.agent.org_id;
      }
      $scope.selectedQueue = null;
      $scope.loading = false;
      $scope.deleting = false;

      if (angular.isUndefined($scope.queues)) {
        $scope.loading = true;
        $scope.queues = QueueService.withAgent($scope.orgId, $scope.user.id);
        $scope.queues.$promise
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.loading = false;
          });
      }

      $scope.$on('modal.closing', function (event, result) {
        if (!event.defaultPrevented && result !== $scope.queues) {
          event.preventDefault();
          $scope.close({ $value: $scope.queues });
        }
      });

      $scope.add = function (queue) {
        if (!queue) {
          return;
        }

        $scope.loading = true;
        QueueService.addAgent(queue, $scope.user.id).$promise
          .then(function () {
            $scope.queues.push(queue);
            $scope.agent.queue_count++;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.loading = false;
          });
      };

      $scope.toggleRouting = function (queue) {
        queue.$processing = true;
        var $promise;

        if (queue.routing_enabled) {
          $promise = QueueService.disableRouting(queue, $scope.user.id).$promise;
        } else {
          $promise = QueueService.enableRouting(queue, $scope.user.id).$promise;
        }
        $promise
          .then(function () {
            queue.routing_enabled = !queue.routing_enabled;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            delete queue.$processing;
          });
      };

      $scope.remove = function (queue, index) {
        $scope.deleting = queue.$processing = true;
        QueueService.removeAgent(queue, $scope.user.id).$promise
          .then(function () {
            $scope.queues.splice(index, 1);
            $scope.agent.queue_count--;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.deleting = false;
            delete queue.$processing;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('agentQueuesModal', AgentQueuesModal);
;
'use strict';

/*
 *  Directive for listing audit records
 *  @name backlineAdminPlus.directives:AuditList
 *  @ngInject
 */
function AuditList( AuditService ) {

  return {

    restrict: 'E',
    scope: {
      params: '='
    },
    templateUrl: 'views/audits/_list.html',

    link: function ( scope, elem, attr ) {

      var _perPage = 50;

      // set default params
      scope.events = [];
      scope.page = 1;
      scope.totalPages = 0;

      // watch params given to us for changes so we can update
      scope.$watch('params', function ( newValue, oldValue ) {
        if (newValue) {
          scope.visible = true;
          scope.page = 1;
          _update();
        }
      });

      // update list of audit events
      function _update() {

        angular.extend(scope.params, {
          page: scope.page,
          per_page: _perPage
        });

        AuditService.list( scope.params )
          .then(function ( response ) {

            scope.events = response.events;
            scope.totalPages = response.pages;
          });
      }

      // update when directive compiles, if given defined params
      if (scope.params) {
        _update();
      }

      /*
       *  View method
       *  Get next page of results
       */
      scope.next = function () {

        scope.page += 1;
        _update();
      };

      /*
       *  View method
       *  Get previous page of results
       */
      scope.previous = function () {

        scope.page -= 1;
        _update();
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('auditList', AuditList);
;
'use strict';

/*
 *  Directive for closing modals on state changes
 *  @name backlineAdminPlus.directives:AutoCloseModal
 *  @ngInject
 */
function AutoCloseModal() {

  return {

    restrict: 'A',

    link: function ( scope, element ) {
      if (!!element && element.hasClass('modal') && !!element.modal) {
        scope.$on('$stateChangeStart', function () {
          element.modal('removeBackdrop');
          element.modal('hide');
        });
      }
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('autoCloseModal', AutoCloseModal);
;
'use strict';

/*
 *  Directive for showing message averages
 *  @name backlineAdminPlus.directives:AverageChart
 *  @ngInject
 */
function AverageChart () {

  return {
     restrict: 'E',
     replace: true,
     templateUrl: 'views/dashboards/_average_chart.html',
     scope: {
       average: '='
     },

     link: function ( scope, elem, attr ) {

       // create morris area chart on this element
       var _instance = Morris.Line({
         element: elem,
         data: scope.average,
         xkey: 'date',
         ykeys: [
           'total_messages',
           'avg_msgs'
         ],
         labels: [
           'Total',
           'Average'
         ],
         lineColors: [
           '#0072b2',
           '#1bc8fc'
         ],
         pointSize: 2
       });

       // watch for changes in data
       scope.$watch('average', function ( newVal, oldVal ) {
         _instance.setData(scope.average);
       });
     }
   };
}

angular.module('backlineAdminPlus')
  .directive('averageChart', AverageChart);
;
'use strict';

/*
 *  Directive for listing chats
 *  @name backlineAdminPlus.directives:ChatList
 *  @ngInject
 */
function ChatList( FeedTypes, FeedInfo ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      parent: '=',
      chats: '='
    },
    templateUrl: 'views/archives/_chat_list.html',

    link: function ( scope, elem, attr ) {

      scope.feedInfo = FeedInfo;

      // default selection
      scope.selected = 0;

      // resolve the title and detail text of the chat
      function _setDetails() {

        angular.forEach(scope.chats, function ( chat ) {

          // set defaults
          var title = FeedInfo[chat.feed_type].label,
            detail = chat.feed_name,
            icon = FeedInfo[chat.feed_type].icon;

          // change title and icon for depts
          if (chat.official) {
            title = 'Department';
            icon = 'fa-hospital-o';
          }

          // change title and icon for weblinks
          if (chat.unregisterd_user_chat) {
            title = 'Weblink';
            icon = 'fa-chain';
          }

          // change detail for 1-on-1's
          if (chat.feed_type == FeedTypes.privateChat) {

            // set detail to list of user names from display name
            detail = chat.display_name.split(': ')[1];
          }

          chat.display_title = title;
          chat.display_detail = detail;
          chat.display_icon = icon;
        });
      }

      // when the chat list changes, set details
      scope.$watch('chats', function ( newValue, oldValue ) {
        if (newValue) {
          _setDetails();
        }
      });

      /*
       *  View method
       *  Select this chat, update highlight index and inform controller
       */
      scope.select = function ( index, chat ) {

        scope.selected = index;
        scope.parent.selectedChat = chat;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('chatList', ChatList);
;
'use strict';

/*
 *  Directive for showing counts of chats in types
 *  @name backlineAdminPlus.directives:ChatTypeChart
 *  @ngInject
 */
function ChatTypeChart () {

  return {
     restrict: 'E',
     replace: true,
     templateUrl: 'views/dashboards/_chat_type_chart.html',
     scope: {
       data: '='
     },

     link: function ( scope, elem, attr ) {

       // create morris area chart on this element
       if (!angular.isArray(scope.data) || scope.data.length === 0) {
         scope.data = [
           {
             date: moment().format('YYYY-MM-DD'),
             private_count: 0,
             group_count: 0,
             patient_count: 0,
             unregistered_counter: 0,
             notification_count: 0
           }
         ];
       }
       var _instance = Morris.Bar({
         element: elem,
         data: scope.data,
         xkey: 'date',
         ykeys: [
           'private_count',
           'group_count',
           'patient_count',
           'unregistered_count',
           'notification_count'
         ],
         labels: [
           'Private',
           'Group',
           'PCC',
           'Weblink',
           'Notification'
         ],
         barColors: [
           '#0072b2',
           '#d55e00',
           '#009e73',
           '#777',
           '#663399'
         ],
         stacked: true,
         resize: true
       });

       // watch for changes in data
       scope.$watch('data', function ( newVal, oldVal ) {
         _instance.setData(scope.data || []);
       });
     }
   };
}

angular.module('backlineAdminPlus')
  .directive('chatTypeChart', ChatTypeChart);
;
'use strict';

/*
 *  Directive for showing confirmation dialogs
 *  @name backlineAdminPlus.directives:Confirm
 *  @ngInject
 */
function Confirm( $timeout, ConfirmationService ) {

  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'views/directives/modals/_confirm.html',
    link: function ( scope, elem, attr ) {

      var showMessage = function ($event, params) {
        scope.title = params.title;
        scope.message = params.message;
        scope.callback = params.callback;
        scope.isBold = params.isBold;
        scope.generateHtml = params.generateHtml;
        elem.modal('show');
      };

      // set default params
      scope.title = 'Confirm';
      scope.message = 'Are you sure?';
      scope.generateHtml = false;
      scope.callback = function () {};

      // listen for confirmation events. when one is fired, show modal using params
      scope.$on(ConfirmationService.fireConfirm, showMessage);

      // when modal is dismissed, unset callback
      elem.on('hidden.bs.modal', function () {
        scope.callback = undefined;
      });

      /*
       *  View method
       *  User confirms action, hide modal and do callback after brief delay
       *  Delay gives time for modal to close in case callback involves changing state
       */
      scope.confirm = function () {
        elem.modal('hide');
        $timeout(function () {
          scope.callback();
        }, 200);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('confirm', Confirm);
;
'use strict';

/*
 *  Directive for creating a queue in a modal
 *  @name backlineAdminPlus.directives:CreateAgentModal
 *  @ngInject
 */
function CreateAgentModal(SupportService, OrgSelectorService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/agents/_create_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.selected = {
        user: undefined
      };
      $scope.user = {
        id: null
      };
      $scope.orgId = OrgSelectorService.getOrg().id;

      $scope.errors = {};

      $scope.saving = false;

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      $scope.onUsersSelect = function (user) {
        $scope.user.id = user.id;
      };

      /*
       *  View method
       *  Save new org
       */
      $scope.submit = function () {

        $scope.saving = true;

        SupportService.createAgent($scope.orgId, $scope.user.id)
          .then( function ( user ) {

            $scope.saving = false;
            $scope.close({ $value: user });
          }, function (error) {

            $scope.saving = false;
            $scope.errors.top = error;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('createAgentModal', CreateAgentModal);
;
'use strict';

/*
 *  Directive for creating a queue in a modal
 *  @name backlineAdminPlus.directives:CreateQueueManagerModal
 *  @ngInject
 */
function CreateQueueManagerModal(SupportService, OrgSelectorService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queue_managers/_create_modal.html',

    controller: ['$scope', function ($scope) {

      $scope.networkId = $scope.resolve.networkId;
      $scope.selected = {
        user: undefined
      };
      $scope.user = {
        id: null
      };

      $scope.orgId = OrgSelectorService.getOrg().id;

      $scope.errors = {};

      $scope.saving = false;

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      $scope.onUsersSelect = function (user) {
        $scope.user.id = user.id;
      };

      /*
       *  View method
       *  Save new org
       */
      $scope.submit = function () {

        $scope.saving = true;

        SupportService.createQueueManager($scope.networkId, $scope.orgId, $scope.user.id)
          .then( function ( queueManager ) {

            $scope.saving = false;
            $scope.close({ $value: queueManager });
          }, function (error) {

            $scope.saving = false;
            $scope.errors.top = error;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('createQueueManagerModal', CreateQueueManagerModal);
;
'use strict';

/*
 *  Directive for creating a queue in a modal
 *  @name backlineAdminPlus.directives:CreateQueueModal
 *  @ngInject
 */
function CreateQueueModal( $q, QueueService, Time, BusinessHourQueueTimeouts, OffHourQueueTimeouts, AppStrings, TimeZones, InactiveAgentTimeouts ) {
  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queues/_create_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.networkId = $scope.resolve.networkId;
      $scope.timeZone = $scope.resolve.timeZone.value;
      $scope.timeZones = TimeZones;
      $scope.manualTimeZone = $scope.resolve.timeZone.value;

      $scope.manualTimeZoneEnabled = false;

      $scope.queue = {
        name: '',
        business_time_start: 8,
        business_time_end: 18,
        business_hour_timeout_message: AppStrings.DEFAULT_BUSINESS_HOUR_TIMEOUT_MESSAGE,
        business_hour_timeout_in_seconds: 60,
        off_hour_timeout_message: AppStrings.DEFAULT_OFF_HOUR_TIMEOUT_MESSAGE,
        off_hour_timeout_in_seconds: 300,
        closed_display_in_seconds: 24,
        manual_timezone_enabled: false,
        inactive_agent_enabled: false,
        inactive_agent_timeout_in_seconds: null
      };

      $scope.businessTimeStart = Time.stringToDate($scope.queue.business_time_start, 0);
      $scope.businessTimeEnd = Time.stringToDate($scope.queue.business_time_end, 0);

      $scope.inactiveAgentTimeouts = InactiveAgentTimeouts;
      $scope.businessHourQueueTimeouts = BusinessHourQueueTimeouts;
      $scope.offHourQueueTimeouts = OffHourQueueTimeouts;

      $scope.errors = {};
      $scope.saving = false;

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      $scope.setInactiveAgent = function () {
        $scope.queue.inactive_agent_enabled = !$scope.queue.inactive_agent_enabled;
        if (!$scope.queue.inactive_agent_enabled) {
          $scope.queue.inactive_agent_timeout_in_seconds = null;
        }
      };

      /*
       *  View method
       *  Create new queue
       */
      $scope.save = function () {

        $scope.saving = true;

        if ( $scope.businessTimeStart === null || $scope.businessTimeEnd === null ) {
          $scope.saving = false;
          $scope.errors.top = 'Invalid Business Time';
          return;
        }

        QueueService.create($scope.networkId, $scope.queue, $scope.businessTimeStart, $scope.businessTimeEnd, $scope.manualTimeZoneEnabled, $scope.manualTimeZone)
          .then(function (queue) {

            $scope.saving = false;
            $scope.close({ $value: queue });
          }, function (error) {
            // To have value in hours
            $scope.queue.closed_display_in_seconds = Math.ceil($scope.queue.closed_display_in_seconds / 3600);

            $scope.saving = false;
            $scope.errors.top = error;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('createQueueModal', CreateQueueModal);
;
'use strict';

/*
 *  Directive for creating a queue in a modal
 *  @name backlineAdminPlus.directives:CreateSubmitterModal
 *  @ngInject
 */
function CreateSubmitterModal(SupportService, OrgSelectorService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/submitters/_create_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.selected = {
        user: undefined
      };
      $scope.user = {
        id: null
      };
      $scope.orgId = OrgSelectorService.getOrg().id;

      $scope.errors = {};

      $scope.saving = false;

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      $scope.onUsersSelect = function (user) {
        $scope.user.id = user.id;
      };

      /*
       *  View method
       *  Save new org
       */
      $scope.submit = function () {

        $scope.saving = true;

        SupportService.createSubmitter($scope.orgId, $scope.user.id)
          .then( function ( user ) {

            $scope.saving = false;
            $scope.close({ $value: user });
          }, function (error) {

            $scope.saving = false;
            $scope.errors.top = error;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('createSubmitterModal', CreateSubmitterModal);
;
'use strict';

/**
 *  Directive for editing users in a modal
 *  @name backlineAdminPlus.directives:CreateTicketingPoolModal
 *  @ngInject
 *
 *  @param $q
 *  @param PermissionsService
 *  @param SupportService
 * @param NetworkSelectorService
 * @param OrgSelectorService
 */
function CreateTicketingPoolModal($q, PermissionsService, SupportService, NetworkSelectorService, OrgSelectorService) {

  return {
    restrict: 'E',
    replace: true,
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/ticketing_pools/_create_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.errors = {};
      $scope.org = $scope.resolve.org;
      $scope.network = $scope.resolve.network;
      $scope.admin = {
        hasAdminPrivs: PermissionsService.hasAdminPrivs,
        hasFullAdminPrivs: PermissionsService.hasFullAdminPrivs,
        hasNetworkFullAdminPrivs: PermissionsService.hasNetworkFullAdminPrivs,
        hasNetworkAdminPrivs: PermissionsService.hasNetworkAdminPrivs,
        hasDrFirstFullAdminPrivs: PermissionsService.hasDrFirstFullAdminPrivs,
        hasDrFirstAdminPrivs: PermissionsService.hasDrFirstAdminPrivs,
        hasDrFirstSalesAdminPrivs: PermissionsService.hasDrFirstSalesAdminPrivs
      };

      function resetTicketingPool() {
        $scope.ticketingPool = {
          name: ''
        };
      }

      resetTicketingPool();

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      /*
       *  Input callback
       *  Validates the name entered
       */
      $scope.onChangeName = function () {
        if ($scope.ticketingPool.name === '') {
          $scope.errors.name = 'Name cannot be empty';
        } else {
          $scope.errors.name = undefined;
        }
      };

      /*
       *  View method
       *  Save changes made to this user
       */
      $scope.submit = function () {
        // if there are no errors with the form
        if (!($scope.errors.name)) {
          $scope.saving = true;

          SupportService.createTicketingPool($scope.network ? $scope.network.id : NetworkSelectorService.getNetwork().id, $scope.org.id, $scope.ticketingPool)
            .then(function (response) {
              $scope.saving = false;
              $scope.close({$value: response});
            }, function (error) {
              $scope.saving = false;
              $scope.errors.top = error.data.response_text;
            });

        } else {
          // if there are errors with the form, notify
          $scope.errors.top = 'Please correct any errors below.';
        }
      };

    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('createTicketingPoolModal', CreateTicketingPoolModal);
;
'use strict';

/*
 *  Directive for adding / removing members from departments
 *  @name backlineAdminPlus.directives:DepartmentMembersModal
 *  @ngInject
 */
function DepartmentMembersModal( $state, $timeout, DepartmentService, OrgSelectorService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      department: '='
    },
    templateUrl: 'views/departments/_members_modal.html',

    link: function ( scope, elem, attr ) {

      function initialize() {
        scope.error = undefined;
        scope.user = undefined;
        scope.selected = {
          user: undefined
        };
      }

      initialize();

      // clear all attributes when the modal is hidden
      elem.on('hide.bs.modal', function () {
        initialize();
      });

      scope.select = function ( user ) {
        scope.user = user;
      };

      scope.add = function () {

        if (scope.selected.user) {

          DepartmentService.addUser(scope.department, scope.selected.user.id)
            .then(function ( response ) {
              scope.department.users = response;
              scope.selected.user = undefined;
            }, function ( error ) {
              scope.error = error;
            });

        } else {
          scope.error = 'Please select a user';
        }
      };

      scope.remove = function ( user ) {

        DepartmentService.removeUser(scope.department, user.id)
          .then(function ( response ) {
            scope.department.users = response;
          }, function ( error ) {
            scope.error = error;
          });
      };

      scope.gotoUser = function ( id ) {
        elem.modal('hide');
        $timeout(function () {
          $state.go('admin.users.detail', { id: id });
        }, 200);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('departmentMembersModal', DepartmentMembersModal);
;
'use strict';

/*
 *  Directive for multi select of departments
 *  @name backlineAdminPlus.directives:DepartmentSelect
 *  @ngInject
 */
function DepartmentSelect($filter) {

  return {

    restrict: 'E',
    scope: {
      selected: '=',
      departments: '='
    },
    templateUrl: 'views/users/_department_select.html',

    link: function ( scope, elem, attr ) {
      if (!angular.isObject(scope.selected)) {
        scope.selected = {
          departments: []
        };
      }
      if (!angular.isArray(scope.selected.departments)) {
        scope.selected.departments = [];
      }

      /**
       * Subset of the full list of departments meant for ui-select suggestions.
       */
      scope.queriedDepartments = [];

      /**
       * We use a separate method to filter out the results to have a debouncing affect on ui-select suggestions.
       *
       * @param query the query that will be used to filter out departments
       */
      scope.refreshDepartments = function (query) {
        scope.queriedDepartments = $filter('orderBy')($filter('filter')(scope.departments, query));
      };

      // watch selected just in case their departments change
      scope.$watch('selected', function ( newValue, oldValue ) {
        if (newValue) {
          if (!scope.selected.departments || scope.selected.departments[0] === '') {
            scope.selected.departments = [];
          }
        }
      });

      scope.$watch('departments', function ( newValue, oldValue ) {
        if (newValue) {
          scope.departments = newValue;
          scope.refreshDepartments('');
        }
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('departmentSelect', DepartmentSelect);
;
'use strict';

/*
 *  Directive for showing counts of chats by device
 *  @name backlineAdminPlus.directives:DeviceChart
 *  @ngInject
 */
function DeviceChart () {

  return {
     restrict: 'E',
     replace: true,
     templateUrl: 'views/dashboards/_device_chart.html',
     scope: {
       data: '='
     },

     link: function ( scope, elem, attr ) {

       // put data into format donut chart can read
       function _normalizeData() {

         angular.forEach(scope.data, function ( record ) {
          angular.extend(record, {
            label: record.device_type,
            value: record.message_count
          });
        });
       }

       // create morris area chart on this element
       _normalizeData();
       var _instance = Morris.Donut({
         element: elem,
         data: scope.data && scope.data.length !== 0 ? scope.data : [ { label:'No Data', value:100 } ],
         colors: [
           '#ffab4d',
           '#1bc8fc',
           '#009e73'
         ]
       });

       // watch for changes in data
       scope.$watch('data', function ( newVal, oldVal ) {
         _normalizeData();
         _instance.setData(scope.data && scope.data.length !== 0 ? scope.data : [ { label:'No Data', value:100 } ]);
       });
     }
   };
}

angular.module('backlineAdminPlus')
  .directive('deviceChart', DeviceChart);
;
'use strict';

/*
 *  Directive for editing broadcast bots
 *  @name backlineAdminPlus.directives:EditBroadcastModal
 *  @ngInject
 */
function EditBroadcastModal( BroadcastService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      bot: '=',
      callback: '&success'
    },
    templateUrl: 'views/broadcast/_edit_modal.html',

    link: function ( scope, elem, attr ) {

      // reset bot copy to original
      function _resetBot() {
        scope.botCopy = {
          id: scope.bot.id,
          name: scope.bot.name
        };
      }

      // when bot changes, reset bot copy to new bot
      scope.$watch('bot', function ( newValue, oldValue ) {
        if (newValue) {
          _resetBot();
        }
      });

      /*
       *  View method
       *  Save new bot
       */
      scope.save = function () {

        scope.saving = true;

        BroadcastService.edit(scope.botCopy)
          .then( function ( response ) {

            scope.saving = false;
            scope.callback({ bot: response });
            elem.modal('hide');

          }, function ( error ) {

            scope.saving = false;
            scope.error = error;
          });
      };

      // reset bot copy on modal dismiss
      elem.on('hidden.bs.modal', function () {
        _resetBot();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editBroadcastModal', EditBroadcastModal);
;
'use strict';

/*
 *  Directive for editing departments in a modal
 *  @name backlineAdminPlus.directives:EditDepartmentModal
 *  @ngInject
 */
function EditDepartmentModal( $q, DepartmentService, OrgSelectorService, OrgService, SettingKeys, SettingService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      department: '=',
      callback: '&'
    },
    templateUrl: 'views/departments/_edit_modal.html',

    link: function ( scope, elem, attr ) {

      function _resetDepartment() {

        SettingService.findByOrgId(SettingKeys.idmEnabled, OrgSelectorService.getOrg().id)
          .then(function (settingValue) {
            scope.idmEnabled = (parseInt(settingValue.value, 10) == 1);
          }, function ( error ) {
            scope.idmEnabled = 0;
          });

        SettingService.findByOrgId(SettingKeys.idmSystemEnabled, OrgSelectorService.getOrg().id)
          .then(function (settingValue) {
            scope.idmSystemEnabled = (parseInt(settingValue.value, 10) == 1);
          }, function ( error ) {
            scope.idmSystemEnabled = 0;
          });

        scope.newName = scope.department.name;
        scope.newIdm = scope.department.group.allow_to_create_distribution_list;
        scope.newShortname = scope.department.group.short_name;
        scope.idmSelected = true;
        scope.shortname_error = null;
        scope.error = null;
      }

      scope.$watch('department', function ( newValue, oldValue ) {
        if (newValue) {
          _resetDepartment();
        }
      });

      scope.save = function () {

        scope.saving = true;
        if (scope.idmSystemEnabled && scope.newIdm && (!scope.newShortname || scope.newShortname.trim().length === 0)) {
          scope.saving = false;
          scope.shortname_error = 'Short name cannot be empty.';
        } else {
          DepartmentService.edit(scope.department, scope.newName, scope.newIdm, scope.newShortname)
            .then(function (response) {

              scope.saving = false;
              scope.callback({department: response});
              elem.modal('hide');

            }, function (error) {

              scope.saving = false;
              if (error == 'Validation failed: Short name has already been taken') {
                scope.shortname_error = 'Short name already in use, please choose another.';
              } else {
                scope.error = error;
              }
            });
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editDepartmentModal', EditDepartmentModal);
;
'use strict';

/*
 *  Directive for editing groups in a modal
 *  @name backlineAdminPlus.directives:EditGroupModal
 *  @ngInject
 */
function EditGroupModal( $q, GroupService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      group: '=',
      callback: '&'
    },
    templateUrl: 'views/groups/_edit_modal.html',

    link: function ( scope, elem, attr ) {

      function _resetGroup() {
        scope.groupCopy = {
          id: scope.group.id,
          feed_name: scope.group.feed_name,
          description: scope.group.description
        };
      }

      scope.$watch('group', function ( newValue, oldValue ) {
        if (newValue) {
          _resetGroup();
        }
      });

      scope.save = function () {

        scope.saving = true;

        GroupService.edit( scope.groupCopy )
          .then(function ( response ) {

            scope.saving = false;
            scope.callback({ group: response });
            elem.modal('hide');

          }, function ( error ) {

            scope.saving = false;
            scope.error = error;
          });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editGroupModal', EditGroupModal);
;
'use strict';

/*
 *  Directive for editing orgs in a modal
 *  @name backlineAdminPlus.directives:EditOrgModal
 *  @ngInject
 */
function EditOrgModal( $q, OrgService, UserService, PermissionsService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      org: '=',
      callback: '&success'
    },
    templateUrl: 'views/orgs/_edit_modal.html',

    link: function ( scope, elem, attr ) {

      function _resetOrg(org) {
        org = org || scope.org;
        scope.orgCopy = {
          id: org.id,
          name: org.name,
          backline_id: org.backline_id,
          external_id: org.external_id,
          external_account_id: org.external_account_id,
          address: org.address,
          city: org.city,
          state: org.state,
          postal_code: org.postal_code,
          phone_number: org.phone_number,
          fax_number: org.fax_number,
          user_licenses: org.user_licenses,
          scheduling_licenses: org.scheduling_licenses,
          telehealth_licenses: org.telehealth_licenses,
          nus_enabled: org.nus_enabled,
          is_test_org: org.is_test_org,
          type_id: org.type_id,
          npi: org.npi,
          crm_customer_id: org.crm_customer_id,
          populus_keywords: org.populus_keywords
        };
        scope.errors = {};
      }

      scope.typeChangeable = OrgService.typeChangeable;

      scope.types = [
        {value: 1, label: 'Enterprise'},
        {value: 2, label: 'Community'}
      ];

      OrgService.types().then(function (types) {
        scope.types = types;
      });

      OrgService.states().then(function (states) {
        scope.states = states;
      });

      scope.dfAdmin = PermissionsService.hasDrFirstAdminPrivs;

      _resetOrg();

      scope.checkNPI = function () {

        if (!scope.orgCopy.npi || scope.orgCopy.npi === '') {
          scope.errors.npi = undefined;
          return;
        }

        UserService.checkNPI(scope.orgCopy.npi)
          .then(function ( response ) {
            scope.errors.npi = undefined;
          }, function ( error ) {
            scope.errors.npi = error.text;
          });
      };

      scope.submit = function () {

        if (!scope.errors.npi) {

          scope.saving = true;
          if (scope.orgCopy.nus_enabled) {
            scope.orgCopy.type = undefined;
          }

          if (scope.orgCopy.is_test_org) {
            scope.orgCopy.type = undefined;
          }

          OrgService.update(scope.orgCopy)
            .then( function ( response ) {

              scope.saving = false;

              elem.modal('hide');
              scope.callback({ updated: response });
              _resetOrg(response);

            }, function ( error ) {

              scope.saving = false;
              scope.errors.top = error;
            });
        } else {

          scope.errors.top = 'Please enter all fields';
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editOrgModal', EditOrgModal);
;
'use strict';
/* jshint -W018 */
/* jshint -W024 */

/*
 *  Directive for editing roles of org users from a modal
 *  @name backlineAdminPlus.directives:EditOrgUserModal
 *  @ngInject
 */
function EditOrgUserModal( $q, UserService, ConfirmationService, SupportService, ErrorService, TokenService, AdminRoles) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      orgUser: '=',
      isDrFirstFullAdmin: '=',
      callback: '&success'
    },
    templateUrl: 'views/users/_edit_org_user_modal.html',

    link: function ( scope, elem, attr ) {
      scope.$watch('orgUser', function ( newValue, oldValue ) {
        if (newValue) {
          _updateOrgUser(newValue);
        }
      });

      function _updateOrgUser(orgUser) {
        scope.orgUser = orgUser;
        scope.isMassMessagingOrgEnabled = orgUser.is_mass_messaging_org;
        scope.isEditedUserOrgFullAdmin = AdminRoles.fullAdmin === orgUser.role_id;

        scope.massMessaging = {
          is_mass_messaging_user: orgUser.is_mass_messaging_user
        };

        scope.params = {
          id: orgUser.user_id,
          org: orgUser.org.id,
          role_id: orgUser.role_id,
          ehr_user_id: orgUser.ehr_user_id,
          employee_id: orgUser.employee_id,
          pager_number: orgUser.pager_number,
          pager_email: orgUser.pager_email,
          is_primary: orgUser.is_primary,
          is_contact: orgUser.is_contact,
          is_ems_workflow_user: orgUser.is_ems_workflow_user,
          is_ems_workflow_org: orgUser.is_ems_workflow_org,
          is_esign_user: orgUser.is_esign_user,
          is_esign_org: orgUser.is_esign_org,
          is_videochat_org: orgUser.is_videochat_org,
          is_videochat_user: orgUser.is_videochat_user,
          is_telehealth_org: true,
          is_telehealth_user: orgUser.is_telehealth_user,
          disabled: orgUser.disabled,
          sso_id: orgUser.sso_id
        };

        scope.support = {
          isAgent: orgUser.is_agent,
          isQueueManager: orgUser.is_queue_manager,
          isSubmitter: orgUser.is_submitter
        };

        scope.canHaveAgents = SupportService.canHaveAgents(orgUser.org);
        scope.canCreateAgent = SupportService.canCreateAgent(TokenService.getCachedUser(), orgUser.org);

        scope.canHaveQueueManagers = SupportService.canHaveQueueManagers(orgUser.org);
        scope.canCreateQueueManager = SupportService.canCreateQueueManager(TokenService.getCachedUser(), orgUser.org);

        scope.canHaveSubmitters = SupportService.canHaveSubmitters(orgUser.org);
        scope.canCreateSubmitter = SupportService.canCreateSubmitter(TokenService.getCachedUser(), orgUser.org);
      }

      if (scope.orgUser) {
        _updateOrgUser(scope.orgUser);
      }

      scope.disable = function (disableValue) {
        angular.extend(scope.params, { disabled: disableValue });
        scope.save();
      };

      scope.save = function () {
        var paramsMassMessaging = scope.isEditedUserOrgFullAdmin && scope.isDrFirstFullAdmin ? angular.extend(scope.params, scope.massMessaging) : scope.params;
        var params = paramsMassMessaging.ehr_user_id ? paramsMassMessaging : angular.extend(paramsMassMessaging, { ehr_user_id: null });

        scope.saving = true;

        UserService.updateOrg( params )
          .then(function ( response ) {
            var promise;
            var orgUser;

            for (var j = 0; j < response.length; j++) {
              var newOrg = response[j];

              if (scope.orgUser.id === newOrg.id) {
                orgUser = newOrg;
                break;
              }
            }

            orgUser.is_agent = scope.orgUser.is_agent;
            orgUser.is_queue_manager = scope.orgUser.is_queue_manager;
            orgUser.is_submitter = scope.orgUser.is_submitter;

            if (scope.support.isAgent !== scope.orgUser.is_agent) {

              if (scope.support.isAgent) {
                promise = SupportService.createAgent(scope.orgUser.org.id, scope.orgUser.user_id)
                  .then(function (agent) {
                    orgUser.is_agent = scope.support.isAgent = true;
                  });
              } else {
                promise = SupportService.deleteAgent(scope.orgUser.org.id, scope.orgUser.user_id).$promise
                  .then(function () {
                    orgUser.is_agent = scope.support.isAgent = false;
                  });
              }

              promise.catch(function (error) {
                ErrorService.handle(error, error.data.response_code || error.status);
              });
            }

            if (scope.support.isQueueManager !== scope.orgUser.is_queue_manager) {

              if (scope.support.isQueueManager) {
                promise = SupportService.createQueueManager(scope.orgUser.network_id, scope.orgUser.org.id, scope.orgUser.user_id)
                  .then(function (queueManager) {
                    orgUser.is_queue_manager = scope.support.isQueueManager = true;
                  });
              } else {
                promise = SupportService.deleteQueueManager(scope.orgUser.network_id, scope.orgUser.org.id, scope.orgUser.user_id).$promise
                  .then(function () {
                    orgUser.is_queue_manager = scope.support.isQueueManager = false;
                  });
              }

              promise.catch(function (error) {
                ErrorService.handle(error, error.data.response_code || error.status);
              });
            }

            if (scope.support.isSubmitter !== scope.orgUser.is_submitter) {

              if (scope.support.isSubmitter) {
                promise = SupportService.createSubmitter(scope.orgUser.org.id, scope.orgUser.user_id)
                  .then(function (submitter) {
                    orgUser.is_submitter = scope.support.isSubmitter = true;
                  });
              } else {
                promise = SupportService.deleteSubmitter(scope.orgUser.org.id, scope.orgUser.user_id).$promise
                  .then(function () {
                    orgUser.is_submitter = scope.support.isSubmitter = false;
                  });
              }

              promise.catch(function (error) {
                ErrorService.handle(error, error.data.response_code || error.status);
              });
            }

            scope.callback({ orgs: response });
            scope.org = undefined;

            scope.saving = false;
            elem.modal('hide');

          }, function ( error ) {
            ErrorService.show(error.status, {
              message: 'You do not have permissions to edit the role of this user.',
              title: 'Cannot Edit Role'
            });

            scope.saving = false;
          });
      };

      // reset params on hide
      elem.on('hidden.bs.modal', function () {
        _updateOrgUser(scope.orgUser);
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editOrgUserModal', EditOrgUserModal);
;
'use strict';

/*
 *  Directive for creating patients in a modal
 *  @name backlineAdminPlus.directives:EditPatientModal
 *  @ngInject
 */
function EditPatientModal( $q, OrgSelectorService, PatientService, SettingService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&success',
      selectedPatient: '=patient',
      org: '=',
      hieEnabled: '=hieEnabled',
      hieOids: '=hieOids'
    },
    templateUrl: 'views/patients/_add_modal.html',

    controller: ['$scope', function ($scope) {

      $scope.id = 'edit-patient';
      $scope.action = 'Edit';

      $scope.$watch('selectedPatient', function () {
        if (angular.isUndefined($scope.selectedPatient)) {
          return;
        }
        $scope.patient = angular.copy($scope.selectedPatient);
        $scope.patient.dob = moment.utc($scope.patient.dob).local(true).toDate();
        if (angular.isString($scope.patient.admit_date)) {
          $scope.patient.admit_date = moment($scope.patient.admit_date).toDate();
        }
        if (angular.isString($scope.patient.discharge_date)) {
          $scope.patient.discharge_date = moment($scope.patient.discharge_date).toDate();
        }
        if (angular.isString($scope.patient.status)) {
          $scope.patient.status = $scope.patient.status.trim();
        }
      });

      $scope.save = function () {

        if (angular.isDate($scope.patient.admit_date) && angular.isDate($scope.patient.discharge_date)) {
          if ($scope.patient.admit_date > $scope.patient.discharge_date) {
            return $q.reject('Admit date must happen before Discharge date');
          }
        }

        if ($scope.patient.lname && $scope.patient.fname && $scope.patient.dob) {

          return PatientService.update($scope.patient)
            .then( function ( response ) {
              $scope.callback()({ patient: response });
            });
        } else {

          return $q.reject('Please fill out all required fields');
        }
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('editPatientModal', EditPatientModal);
;
'use strict';

/*
 *  Directive for editing a queue in a modal
 *  @name backlineAdminPlus.directives:EditQueueModal
 *  @ngInject
 */
function EditQueueModal( $q, QueueService, Time, BusinessHourQueueTimeouts, OffHourQueueTimeouts, TimeZones, InactiveAgentTimeouts ) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queues/_edit_modal.html',

    controller: ['$scope', function ($scope) {

      $scope.queue = $scope.resolve.queue;
      $scope.timeZone = $scope.resolve.timeZone.value;
      $scope.timeZones = TimeZones;
      $scope.manualTimeZone = $scope.resolve.timeZone.value;

      $scope.manualTimeZoneEnabled = $scope.queue.manual_timezone_enabled;
      if ($scope.manualTimeZoneEnabled) {
        $scope.manualTimeZone = $scope.queue.timezone;
      }

      // To have value in hours
      $scope.closedDisplayInSeconds = $scope.queue.closed_display_in_seconds;
      if ($scope.closedDisplayInSeconds) {
        $scope.closedDisplayInSeconds = Math.ceil($scope.closedDisplayInSeconds / 3600);
      }

      $scope.businessTimeStart = Time.stringToDate($scope.queue.business_hour_start, $scope.queue.business_minute_start);
      $scope.businessTimeEnd = Time.stringToDate($scope.queue.business_hour_end, $scope.queue.business_minute_end);

      $scope.inactiveAgentTimeouts = InactiveAgentTimeouts;
      $scope.businessHourQueueTimeouts = BusinessHourQueueTimeouts;
      $scope.offHourQueueTimeouts = OffHourQueueTimeouts;

      $scope.manualTimeZoneEnabled = $scope.queue.manual_timezone_enabled;

      $scope.errors = {};
      $scope.saving = false;

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      $scope.setInactiveAgent = function () {
        $scope.queue.inactive_agent_enabled = !$scope.queue.inactive_agent_enabled;
        if (!$scope.queue.inactive_agent_enabled) {
          $scope.queue.inactive_agent_timeout_in_seconds = null;
        }
      };

      /*
       *  View method
       *  Update queue
       */
      $scope.save = function () {

        $scope.saving = true;

        if ( $scope.businessTimeStart === null || $scope.businessTimeEnd === null) {
          $scope.saving = false;
          $scope.errors.top = 'Invalid Business Time';
          return;
        }

        QueueService.update($scope.queue, $scope.businessTimeStart, $scope.businessTimeEnd, $scope.manualTimeZoneEnabled, $scope.manualTimeZone, $scope.closedDisplayInSeconds)
          .then(function (queue) {

            $scope.saving = false;
            $scope.close({ $value: queue });
          }, function (error) {
            // To have value in hours
            $scope.queue.closed_display_in_seconds = Math.ceil($scope.queue.closed_display_in_seconds / 3600);

            $scope.saving = false;
            $scope.errors.top = error;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('editQueueModal', EditQueueModal);
;
'use strict';

/**
 *  Directive for editing users in a modal
 *  @class
 *  @name backlineAdminPlus.directives:EditUserModal
 *  @ngInject
 *
 *  @param $q
 *  @param UserService
 *  @param LdapService
 * @param SettingService
 * @param SettingKeys
 */
function EditUserModal($q, PermissionsService, UserService, LdapService, SettingService, SettingKeys) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      user: '=',
      callback: '&',
      orgHieEnabled: '=',
      userHieEnabled: '='
    },
    templateUrl: 'views/users/_edit_modal.html',

    link: function (scope, elem) {

      scope.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // called to reset user back to base
      function _resetUser() {

        var ldapEnabled = false;
        var login = '';

        if (scope.user.primaryOrg) {
          ldapEnabled = scope.user.primaryOrg.ldap_enabled;
          login = scope.user.primaryOrg.login;
        }

        // create copy so we don't edit original user in js
        scope.userCopy = {
          id: scope.user.id,
          fname: scope.user.fname,
          mname: scope.user.mname,
          lname: scope.user.lname,
          email: scope.user.email,
          npi: scope.user.npi,
          suffix: scope.user.suffix,
          degrees: scope.user.degrees,
          role_id: scope.user.role_id,
          patient_search_allowed: scope.user.patient_search_allowed,
          poc: scope.user.poc,
          ldap_enabled: ldapEnabled,
          login: login,
          userHieEnabled: scope.userHieEnabled
        };

        scope.ldapDefaultName = LdapService.createDefaultUsername(scope.user.email);

        // find departments for selection
        scope.org = scope.user.org;
        scope.departments = (scope.org && angular.isDefined(scope.org.departments)) ? scope.org.departments : [];

        // reset selected departments
        scope.selected = {
          departments: (scope.user.departments || '').split(', ')
        };

        // clear errors
        scope.errors = {};

        scope.admin = {
          hasAdminPrivs: PermissionsService.hasAdminPrivs,
          hasFullAdminPrivs: PermissionsService.hasFullAdminPrivs,
          hasNetworkFullAdminPrivs: PermissionsService.hasNetworkFullAdminPrivs,
          hasNetworkAdminPrivs: PermissionsService.hasNetworkAdminPrivs,
          hasDrFirstFullAdminPrivs: PermissionsService.hasDrFirstFullAdminPrivs,
          hasDrFirstAdminPrivs: PermissionsService.hasDrFirstAdminPrivs,
          hasDrFirstSalesAdminPrivs: PermissionsService.hasDrFirstSalesAdminPrivs
        };
      }

      // when user changes, reset copy, departments, and errors
      scope.$watch('user', function (newValue) {
        if (newValue) {
          _resetUser();
        }
      });

      /*
       *  Input callback
       *  Validates the user entered npi on the server
       */
      scope.onChangeNPI = function () {

        // skip if npi is blank
        if (!scope.userCopy.npi || scope.userCopy.npi === '') {
          scope.errors.npi = undefined;
          return;
        }

        // ignore this npi check if it is the same as the original
        if (scope.userCopy.npi === scope.user.npi) {
          scope.errors.npi = undefined;
          return;
        }

        // check the npi
        UserService.checkNPI(scope.userCopy.npi)
          .then(function () {
            scope.errors.npi = undefined;
          }, function (error) {
            scope.errors.npi = error.text;
          });
      };

      /*
       *  Input callback
       *  Validates the user entered npi on the server
       */
      scope.onChangeEmail = function () {
        UserService.checkEmail(scope.userCopy.email)
          .then(function (response) {
            if (scope.userCopy.email === '') {
              scope.errors.email = 'Email address cannot be empty.';
            } else if (response && scope.userCopy.email !== scope.user.email) {
              scope.errors.email = 'Email address already taken by: ' + response.lname + ', ' + response.fname + '.';
            } else {

              scope.errors.email = undefined;
            }
          }, function (error) {

            scope.errors.top = 'Error encountered checking email';
          });
      };

      /*
       *  View method
       *  Save changes made to this user
       */
      scope.submit = function () {

        // skip if no departments added
        if (!scope.selected.departments || scope.selected.departments.length === 0) {
          scope.errors.departments = 'Please enter at least 1 department';

        } else {
          scope.errors.departments = undefined;
        }

        var emailRegExError = (scope.userForm.email.$error.pattern || scope.userForm.email.$error.email);

        // if there are no errors with the form
        if (!(scope.errors.npi || scope.errors.departments || scope.errors.email || emailRegExError)) {
          scope.saving = true;

          // transform departments array into comma delimited string
          scope.userCopy.departments = scope.selected.departments.join(', ');

          if (scope.userCopy.login === '') {
            scope.userCopy.login = null;
          }

          // call update service
          UserService.update(scope.userCopy)
            .then(function (response) {

              if (scope.orgHieEnabled && scope.userHieEnabled !== scope.userCopy.userHieEnabled) {
                SettingService.userFind(SettingKeys.userHieEnabled, scope.org, scope.userCopy.id).then(function (data) {
                  SettingService.userSet(data.setting_id, scope.org, scope.userCopy, scope.userCopy.userHieEnabled ? '1' : '0');
                });
              }

              scope.saving = false;
              scope.callback({changed: angular.extend(response, {userHieEnabled: scope.userCopy.userHieEnabled})});
              elem.modal('hide');

            }, function (error) {

              scope.saving = false;
              scope.errors.top = error;
            });
        } else {

          // if there are errors with the form, notify
          scope.errors.top = 'Please correct any errors below.';
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('editUserModal', EditUserModal);
;
'use strict';

/*
 *  Directive for displaying audit entities in dropdown format
 *  @name backlineAdminPlus.directives:EntityDropdown
 *  @ngInject
 */
function EntityDropdown( AuditService, PermissionsService, OrgSelectorService, AuditTypes ) {

  return {

    restrict: 'E',
    scope: {
      parent: '=',
      entityType: '='
    },
    templateUrl: 'views/audits/_entity_dropdown.html',

    link: function ( scope, elem, attr ) {

      // resolve org to use
      scope.org = PermissionsService.getAuditOrg();

      scope.$watch('entityType', function ( newValue, oldValue ) {
        if (newValue) {
          refresh(newValue);
        }
      });

      scope.entities = [];
      scope.display = [];

      function refresh( type ) {

        if (AuditTypes[type].dropdown) {

          scope.visible = true;

          AuditService.entities(scope.org.id, type)
            .then(function ( response ) {

              scope.entities = response;
              scope.search(undefined);

            }, function ( error ) {

              console.error(error);
            });

        } else {

          scope.visible = false;
          scope.entities = [];
          scope.display = [];
        }
      }

      scope.search = function ( query ) {
        scope.display = [];

        if (!query || query === '') {
          scope.display = scope.entities.slice(0, 10);
          return;
        }

        query = query.toLowerCase();

        angular.forEach(scope.entities, function ( entity ) {
          if (scope.display.length < 10 && entity.display_name.toLowerCase().indexOf(query) != -1) {
            this.push(entity);
          }
        }, scope.display);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('entityDropdown', EntityDropdown);
;
'use strict';

/*
 *  Directive for showing errors in a modal
 *  @name backlineAdminPlus.directives:ErrorModal
 *  @ngInject
 */
function ErrorModal( ErrorService, BacklineAPI ) {

  return {

    restrict: 'E',
    replace: true,
    templateUrl: 'views/directives/modals/_error.html',

    link: function ( scope, elem, attr ) {

      // listen for when there is an error event
      scope.$on(ErrorService.fireModal, function ( $event, params ) {

        // default showing details to false
        scope.details = false;

        // store information from broadcast
        scope.payload = params;

        scope.title = params.title;
        scope.message = params.message;
        scope.status = params.status;

        // only add this information if this came from a 'handle'
        if (params.error) {

          // if there is an error response, you can show details for it
          scope.hasDetails = true;

          scope.error = params.error;
          scope.url = params.url.replace(BacklineAPI, '');
          scope.params = params.params.replace(/,/g, ', ');
          scope.code = params.code;
        }

        // show modal
        elem.modal('show');
      });

      /*
       *  View method
       *  Show details view for response
       */
      scope.toggleDetails = function () {

        scope.details = !scope.details;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('errorModal', ErrorModal);
;
'use strict';

/*
 *  Directive for showing modals with forms
 *  @name backlineAdminPlus.directives:FormModal
 *  @ngInject
 */
function FormModal() {

  return {

    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      action: '@',
      item: '=',
      formName: '@',
      'save': '&save'
    },
    templateUrl: 'views/directives/modals/_form.html',

    link: function ( scope, elem, attr ) {

      scope.submit = function () {

        scope.saving = true;

        scope.save().then(function ( success ) {

          scope.saving = false;
          scope.error = undefined;

          elem.modal('hide');

        }, function ( error ) {

          scope.saving = false;
          scope.error = error;
        });
      };

      scope.close = function () { };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('formModal', FormModal);
;
'use strict';

/*
 *  Directive for adding / removing members from groups
 *  @name backlineAdminPlus.directives:GroupMembersModal
 *  @ngInject
 */
function GroupMembersModal( $state, $timeout, GroupService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      group: '='
    },
    templateUrl: 'views/groups/_members_modal.html',

    link: function ( scope, elem, attr ) {

      scope.user = undefined;
      scope.selected = {
        user: undefined
      };

      scope.select = function ( user ) {

        scope.user = user;
      };

      scope.add = function () {

        if (scope.selected.user) {

          GroupService.addUser(scope.group.id, scope.selected.user.id)
            .then(function ( response ) {
              scope.group.users = response;
              scope.selected.user = undefined;
            }, function ( error ) {
              scope.error = error;
            });

        } else {
          scope.error = 'Please select a user';
        }
      };

      scope.remove = function ( user ) {

        GroupService.removeUser(scope.group.id, user.id)
          .then(function ( response ) {
            scope.group.users = response;
          }, function ( error ) {
            scope.error = error;
          });
      };

      scope.gotoUser = function ( id ) {

        elem.modal('hide');

        $timeout(function () {
          $state.go('admin.users.detail', { id: id });
        }, 200);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('groupMembersModal', GroupMembersModal);
;
'use strict';

/*
 *  Directive for viewing/editing mask caller id settings in a modal
 *  @name backlineAdminPlus.directives:MaskCallerSettingsModal
 *  @ngInject
 */
function MaskCallerSettingsModal( $q ) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      enabled: '=',
      phoneNumber: '=',
      onChange: '&'
    },
    templateUrl: 'views/configs/_mask_caller_settings_modal.html',

    link: function ( scope, elem, attr ) {
      scope.settingEnabled = scope.enabled;
      scope.errors = {};
      scope.saving = false;

      scope.changeRegisteredNumber = function () {
        elem.modal('hide');
        $(document.getElementById('register-landline')).modal();
      };

      scope.unregisterNumber = function () {
        elem.modal('hide');
        $(document.getElementById('unregister-landline')).modal();
      };

      scope.submit = function () {
        scope.saving = true;

        scope.onChange({ enabled: scope.settingEnabled })
          .then(function () {
            scope.saving = false;
            elem.modal('hide');
          }, function () {
            scope.saving = false;
          });
      };

      scope.$watch('enabled', function ( value ) {
        scope.settingEnabled = value;
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('maskCallerSettingsModal', MaskCallerSettingsModal);
;
'use strict';

/*
 *  Directive for listing messages in a chat
 *  @name backlineAdminPlus.directives:MessageList
 *  @ngInject
 */
function MessageList( ChatService, FeedInfo, DeviceTypes, MessageTypes ) {

  return {

    restrict: 'E',
    scope: {
      detail: '=',
      chat: '='
    },
    templateUrl: 'views/archives/_message_list.html',

    link: function ( scope, elem, attr ) {

      var _perPage = 25;

      scope.deviceTypes = DeviceTypes;
      scope.feedInfo = FeedInfo;
      scope.msgTypes = MessageTypes;

      // defaults
      scope.messages = [];
      scope.page = 1;
      scope.showReads = -1;

      // refresh messages when chat changes
      scope.$watch('chat', function ( newValue, oldValue ) {
        if (newValue && newValue != oldValue) {
          scope.page = 1;
          _getMessages();
        }
      });

      // refresh messages
      function _getMessages() {

        ChatService.messages(scope.chat.id, {
          page: scope.page,
          per_page: _perPage
        }).then(function ( response ) {

          scope.showReads = -1;

          scope.messages = response.messages;
          angular.forEach(scope.messages, _combineReadsWithAcks);
          scope.totalPages = response.pages;
        });
      }

      // combine reads with acknowledgements for easy view construction
      function _combineReadsWithAcks( message ) {
        if (!message.acks.length) { return; }

        angular.forEach(message.reads, function ( read ) {
          angular.forEach(message.acks, function ( ack ) {
            if (read.user.id == ack.user.id) {
              read.acknowledged = true;
            }
          });
        });
      }

      // get messages when directive is loaded if a chat is set
      if (scope.chat) {
        _getMessages();
      }

      /*
       *  View method
       *  Get next page of results
       */
      scope.next = function () {

        scope.page += 1;
        _getMessages();
      };

      /*
       *  View method
       *  Get previous page of results
       */
      scope.previous = function () {

        scope.page -= 1;
        _getMessages();
      };

      /*
       *  View method
       *  Show reads drawer for selected index
       */
      scope.showReadsFor = function ( index ) {

        var message = scope.messages[index];

        // ignore clicks from non readable messages
        if (!MessageTypes.isReadable(message.type)) { return; }

        // if this message is already selected, unselect all
        if (index == scope.showReads) {

          scope.showReads = -1;

        } else {

          scope.showReads = index;
        }
      };

      /*
       *  View method
       *  Download attachment with url
       */
      scope.openAttachment = function ( attachment ) {

        ChatService.openAttachment(attachment);
      };

      /*
       *  View method
       *  Export chat archive
       */
      scope.exportPDF = function () {

        ChatService.exportPDF(scope.chat.id);
      };

      /*
       *  View helper
       *  Gets font awesome icon name for file type
       */
      scope.fileTypeIcon = function ( type ) {

        // return nothing if type is not defined or a string
        if (!(type && typeof type == 'string')) { return ''; }

        // image
        if (type.match(/image/)) { return 'fa-file-image-o'; }

        // video
        if (type.match(/video/)) { return 'fa-file-video-o'; }

        // audio
        if (type.match(/audio/)) { return 'fa-file-sound-o'; }

        // msword
        if (type.match(/msword|wordprocessingml/)) { return 'fa-file-word-o'; }

        // msexcel
        if (type.match(/ms-excel|spreadsheetml/)) { return 'fa-file-excel-o'; }

        // pdf
        if (type.match(/pdf/)) { return 'fa-file-pdf-o'; }

        // default to text
        return 'fa-file-text-o';
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('messageList', MessageList);
;
'use strict';

/*
 *  Directive for migrating users to / from community
 *  @name backlineAdminPlus.directives:MigrateUserModal
 *  @ngInject
 */
function MigrateUserModal( $timeout, UserService, OrgService, OrgSelectorService, LicenseTypes ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      user: '=',
      callback: '&success'
    },
    templateUrl: 'views/users/_migrate_modal.html',

    link: function ( scope, elem, attr ) {

      function _init() {

        scope.errors = {};
        scope.community = scope.user.license_type == LicenseTypes.community;
        scope.selected = {
          org_id: OrgSelectorService.getOrg().id,
          departments: []
        };

        _getDepartments(scope.selected.org_id);
      }

      function _reinit() {

        scope.errors = {};
        scope.community = scope.user.license_type == LicenseTypes.community;
        scope.selected.departments = [];
      }

      function _getDepartments( orgId ) {

        scope.departments = [];

        OrgService.active_departments(orgId).then(function ( response ) {
          scope.departments = response.map(function ( department ) { return department.department; });
        });
      }

      function _hasDepartments() {
        
        var hasDepartments = scope.selected.departments && scope.selected.departments.length > 0;

        return hasDepartments;
      }

      _init();

      scope.selectOrg = function ( org ) {

        scope.selected.org_id = org.id;

        _getDepartments(scope.selected.org_id);
      };

      scope.save = function () {

        if (scope.community && _hasDepartments() === false) {

          scope.errors.departments = 'Please enter at least 1 department';
          scope.saving = false;
          return;
        }

        var params = {
          id: scope.user.id,
          org_id: scope.community ? scope.selected.org_id : null,
          departments: scope.selected.departments.join(',')
        };

        scope.errors.departments = undefined;
        scope.saving = true;

        UserService.migrate(params)
          .then(function ( response ) {

            scope.saving = false;
  
            scope.callback({ changed: response });
            elem.modal('hide');
  
          }, function ( error ) {
            
            scope.saving = false;

          });
      };

      elem.on('hidden.bs.modal', function () {
        _reinit();
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('migrateUserModal', MigrateUserModal);
;
'use strict';

/*
 *  Directive for displaying orgs in dropdown format
 *  @name backlineAdminPlus.directives:NetworkOrgDropdown
 *  @ngInject
 */
function NetworkOrgDropdown(NetworkService) {

  return {

    restrict: 'E',
    scope: {
      callback: '&',
      networkId: '=',
      forPools: '=?',
      networkOrgs: '=?',
      resetOnLoad: '=?'
    },
    templateUrl: 'views/networks/_orgs_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.orgs = [];
      if ($scope.resetOnLoad) {
        $scope.selected = null;
      }

      $scope.select = function ( $select, org ) {
        $scope.callback({ org: org });
      };

      $scope.search = function ( query ) {
        $scope.loading = true;
        if ($scope.forPools) {
          $scope.orgs = $scope.networkOrgs;
          $scope.loading = false;
        } else {
          NetworkService.suborgs($scope.networkId, query)
            .then(function (orgs) {
              $scope.orgs = orgs;
              $scope.loading = false;
            });
        }

      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('networkOrgDropdown', NetworkOrgDropdown);
;
'use strict';

/*
 *  Directive for displaying targets in dropdown format
 *  @name backlineAdminPlus.directives:EscalationTargetDropdown
 *  @ngInject
 */
function EscalationTargetDropdown(OrgSelectorService, GroupService, UserService) {

  return {
    restrict: 'E',
    scope: {
      callback: '&',
      target: '=',
      targetType: '=',
      index: '=',
      name: '='
    },
    templateUrl: 'views/escalations/_targets_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.selected = $scope.target;

      $scope.select = function ($select, target) {
        $scope.callback({ target: { idx: $scope.index, id: target.id, name: $select.selected.name } });
      };

      $scope.search = function (query) {
        if ($scope.targetType === 'Feed') {
          GroupService.list({
            org_id: OrgSelectorService.getOrg().id,
            feed_name: query,
            official: false, // non-departments list only
            enabled: true
          }).then(function (response) {
            $scope.targets = response.groups.map(function (group) {
              return {
                id: group.id,
                name: group.feed_name
              };
            });
          });
        } else if ($scope.targetType === 'User') {
          var options = {
            forPulldown: true,
            org_id: OrgSelectorService.getOrg().id,
            query: query,
            showStatuses: 3 // asks only for active users
          };

          UserService.list(options)
            .then(function (response) {
              $scope.targets = response.users.map(function (user) {
                return {
                  id: user.id,
                  name: user.lname + ', ' + user.fname
                };
              });
            });
        }
      };
    }]
  };
}

angular.module('backlineAdminPlus').directive('escalationTargetDropdown', EscalationTargetDropdown);
;
'use strict';

/**
 *  Directive for displaying networks in dropdown format
 *  @name backlineAdminPlus.directives:NetworksDropdown
 *  @ngInject
 *
 * @param PermissionsService
 * @param NetworkSelectorService
 * @param OrgService
 * @param OrgSelectorService
 * @param AppConsts
 * @returns {{controller: controller, scope: {callback: string}, restrict: string, templateUrl: string}}
 * @constructor
 */
function NetworksDropdown(PermissionsService, NetworkSelectorService, OrgService, OrgSelectorService, AppConsts) {

  return {
    restrict: 'E',
    scope: {
      selected: '=?',
      global: '=?',
      callback: '&',
      overrideSearch: '=?'
    },
    templateUrl: 'views/networks/_networks_dropdown.html',

    link: function (scope, element, attrs) {
      var childScope = element.find('ui-select,.ui-select-container').scope();

      scope.$select = {search: ''};
      if (angular.isUndefined(childScope)) {
        return;
      }

      childScope.$watch('$select', function (oldValue, newValue) {
        scope.$select = newValue;
      });
    },
    controller: ['$scope', '$filter', '$timeout', 'NetworkSelectorService', 'NetworkService', function ($scope, $filter, $timeout, NetworkSelectorService, NetworkService) {
      // default global to true
      if ($scope.global === undefined) {
        $scope.global = true;
      }

      /**
       * Subset of the full list of networks meant for ui-select suggestions.
       *
       * @type {Array}
       */
      $scope.queriedNetworks = [];
      $scope.selected = $scope.selected || NetworkSelectorService.getNetwork();

      NetworkService.list({page: 1, per_page: 100})
        .then(function (response) {
          $scope.networks = [];
          var allNetworks = response.networks;

          for (var i = 0; i < allNetworks.length; i++) {
            var current = allNetworks[i];

            if (current.type === AppConsts.NETWORK_TYPE.CUSTOMER_SUPPORT) {
              $scope.networks.push({
                id: current.id,
                display_name: current.display_name || current.name,
                name: current.name,
                backline_id: current.id
              });
            }
          }
          $scope.refreshNetworks($scope.$select.search);
        });

      /**
       * We use a separate method to filter out the results to have a debouncing affect on ui-select suggestions.
       *
       * @param query the query that will be used to filter out networks
       */
      $scope.refreshNetworks = function (query) {
        if (!query) { query = '';}
        $scope.queriedNetworks = $filter('limitTo')($filter('filter')($scope.networks, query), 40);
      };

      $scope.select = function (_select, network) {
        if ($scope.global) {
          NetworkSelectorService.select(network);
        }
        $scope.selected = network;
        $scope.callback({network: network});
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('networksDropdown', NetworksDropdown);
;
'use strict';

/*
 *  Directive for showing confirmation dialogs
 *  @name backlineAdminPlus.directives:NumberStringFormatter
 *  @ngInject
 */
function NumberStringFormatter() {

  return {

    restrict: 'A',
    require: 'ngModel',

    link: function ( scope, elem, attr, ngModel ) {
      ngModel.$parsers.push(function (value) {
        return parseInt(value, 10);
      });
      ngModel.$formatters.push(function (value) {
        return '' + value;
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('numberStringFormatter', NumberStringFormatter);
;
'use strict';

/*
 *  Directive for multi select of org groups
 *  @name backlineAdminPlus.directives:OrgGroupSelect
 *  @ngInject
 */
function OrgGroupSelect( GroupService ) {

  return {

    restrict: 'E',
    scope: {
      parent: '=',
      org: '='
    },
    templateUrl: 'views/orgs/_group_select.html',

    link: function ( scope, elem, attr ) {

      scope.groups = [];

      GroupService.dropdown(scope.org.id).then(function ( response ) {
        scope.groups = response;
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgGroupSelect', OrgGroupSelect);
;
'use strict';

/*
 *  Directive for multi select of org departments
 *  @name backlineAdminPlus.directives:OrgDepartmentSelect
 *  @ngInject
 */
function OrgDepartmentSelect( OrgService ) {

  return {

    restrict: 'E',
    scope: {
      parent: '=',
      org: '='
    },
    templateUrl: 'views/orgs/_department_select.html',

    link: function ( scope, elem, attr ) {

      scope.departments = [];

      OrgService.active_departments(scope.org.id).then(function ( response ) {
        scope.departments = response;
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgDepartmentSelect', OrgDepartmentSelect);
;
'use strict';

/**
 *  Directive for displaying orgs in dropdown format
 *  @name backlineAdminPlus.directives:OrgDropdown
 *  @ngInject
 */
function OrgDropdown( OrgService, OrgSelectorService, PermissionsService ) {

  return {

    restrict: 'E',
    scope: {
      selected: '=?',
      global: '=?',
      callback: '&',
      overrideSearch: '=',
      resetOnLoad: '=?',
      networkOnly: '='
    },
    templateUrl: 'views/orgs/_dropdown.html',

    link: function ( scope, element, attrs ) {
      var childScope = element.find('ui-select,.ui-select-container').scope();

      scope.$select = {search: ''};
      if (angular.isUndefined(childScope)) {
        return;
      }

      childScope.$watch('$select', function (newValue, _oldValue) {
        scope.$select = newValue;
      });
    },

    controller: ['$scope', '$filter', function ( $scope, $filter ) {

      // prevent forbidden errors from background loading
      if (!PermissionsService.canSearchOrgs && !$scope.overrideSearch) { return; }

      // default global to true
      if ($scope.global === undefined) { $scope.global = true; }

      $scope.orgs = [];

      /**
       * Subset of the full list of organizations meant for ui-select suggestions.
       *
       * @type {Array}
       */
      $scope.queriedOrganizations = [];
      if ($scope.resetOnLoad) {
        $scope.selected = null;
      } else if (!$scope.selected) {
        $scope.selected = OrgSelectorService.getOrg();
      }
      
      OrgService.dropdown($scope.networkOnly)
        .then(function ( response ) {
          $scope.orgs = response;
          $scope.refreshOrganizations($scope.$select.search);
        });

      /**
       * We use a separate method to filter out the results to have a debouncing affect on ui-select suggestions.
       *
       * @param query the query that will be used to filter out departments
       */
      $scope.refreshOrganizations = function (query) {
        if (!query || query === ''){
          $scope.queriedOrganizations = $filter('limitTo')($scope.orgs, 40);
        } else {
          $scope.queriedOrganizations = $filter('limitTo')($filter('filter')($scope.orgs, query), 40);
        }
      };

      /*
       *  Input callback
       *  User has selected an org
       */
      $scope.select = function ( org ) {

        if ($scope.global) {
          OrgSelectorService.select(org);
        }

        $scope.callback({ org: org });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('orgDropdown', OrgDropdown);
;
'use strict';

/*
 *  Directive for displaying orgs that have selective
 *  org enabled in dropdown format.
 *  @name backlineAdminPlus.directives:SelectiveOrgDropdown
 *  @ngInject
 */
function SelectiveOrgDropdown(OrgService, OrgSelectorService, PermissionsService) {

  return {

    restrict: 'E',
    scope: {
      selected: '=',
      global: '=?',
      callback: '&',
      is: '=',
      isDisabled: '=ngDisabled'
    },
    templateUrl: 'views/orgs/_selectivedropdown.html',

    link: function (scope, element, attrs) {
      var childScope = element.find('ui-select,.ui-select-container').scope();

      scope.$select = {search: ''};

      if (angular.isUndefined(childScope)) {
        return;
      }

      childScope.$watch('$select', function (oldValue, newValue) {
        scope.$select = newValue;
      });
    },

    controller: ['$scope', '$filter', function ($scope, $filter) {
      // default global to true
      if ($scope.global === undefined) {
        $scope.global = true;
      }

      $scope.selectiveCrossOrgsList = [];

      $scope.queriedOrganizations = [];

      /**
       * We use a separate method to filter out the results to have a debouncing affect on ui-select suggestions.
       *
       * @param query the query that will be used to filter out departments
       */

      $scope.refreshOrganizations = function (query) {
        // $scope.queriedOrganizations = $filter('orderBy')($filter('limitTo')($filter('filter')($scope.selectiveCrossOrgsList, query), 40), name.toUpperCase());

        var parentOrg = OrgSelectorService.getOrg();

        OrgService.selectiveOrgsDropDown(parentOrg)
          .then(function (response) {
            // $scope.selectiveCrossOrgsList = response;

            $scope.queriedOrganizations = $filter('orderBy')($filter('limitTo')
              ($filter('filter')($filter('filter')(response, function (value, index) {return value.id !== parentOrg.id;}), query), 40), name.toUpperCase());
          });
      };

      /*
       *  Input callback
       *  User has selected an org
       */
      $scope.select = function (org) {
        $scope.callback({org: org});
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('selectiveOrgDropdown', SelectiveOrgDropdown);
;
'use strict';

/*
 *  Directive for displaying org security settings
 *  @name backlineAdminPlus.directives:OrgSecurityDetail
 *  @ngInject
 */
function OrgSecurityDetail( PinOptions, SettingService, SettingKeys ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      orgId: '=',
      settings: '=',
      callback: '&configure'
    },
    templateUrl: 'views/orgs/_security_detail.html',

    link: function ( scope, elem, attr ) {

      // update local variables with given values
      function _updateSettings( values ) {

        scope.defaultPatientSearch = values.defaultPatientSearch ? 'Yes' : 'No';
        scope.webTimeout = values.webTimeout / 60;

        // find pin option
        angular.forEach(PinOptions, function ( option ) {
          if (option.value === values.pinTimeout) {
            scope.pinTimeout = option.label;
          }
        });

        scope.communityMessaging = values.communityMessaging ? 'Enabled' : 'Disabled';
        scope.weblinkMessaging = values.weblinkMessaging ? 'Enabled' : 'Disabled';

        SettingService.find(SettingKeys.maskCallerIDEnabled, {id: scope.orgId})
          .then(function (setting) {
            scope.maskCallerId = setting.value === '1' ? 'Enabled' : 'Disabled';
          });

        scope.externalMessaging = [];

        if (values.allowPrivateExternal) {
          scope.externalMessaging.push('1-on-1');
        }

        if (values.allowPrivateGroupExternal) {
          scope.externalMessaging.push('Invite-only Groups');
        }

        if (values.allowPublicGroupExternal) {
          scope.externalMessaging.push('Public Groups');
        }

        if (scope.externalMessaging.length === 0) {
          scope.externalMessaging.push('Disabled');

        } else if (scope.externalMessaging.length === 3) {
          scope.externalMessaging = [ 'All' ];
        }

        scope.externalAttachments = [];

        if (values.allowPrivateExternalAttachments) {
          scope.externalAttachments.push('1-on-1');
        }

        if (values.allowPrivateGroupExternalAttachments) {
          scope.externalAttachments.push('Invite-only Groups');
        }

        if (values.allowPublicGroupExternalAttachments) {
          scope.externalAttachments.push('Public Groups');
        }

        if (values.allowPCCExternalAttachments) {
          scope.externalAttachments.push('PCC');
        }

        if (scope.externalAttachments.length === 0) {
          scope.externalAttachments.push('Disabled');

        } else if (scope.externalAttachments.length === 4) {
          scope.externalAttachments = [ 'All' ];
        }
      }

      /*
       *  View method
       *  Triggers callback to decide how to change settings
       */
      scope.configure = function () {

        scope.callback();
      };

      // update settings when directive compiles
      _updateSettings(scope.settings);
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgSecurityDetail', OrgSecurityDetail);
;
'use strict';

/*
 *  Directive for showing and changing org status
 *  @name backlineAdminPlus.directives:OrgStatus
 *  @ngInject
 */
function OrgStatus( OrgService ) {

  return {

    restrict: 'E',
    scope: {
      orgId: '=',
      enabled: '=',
      size: '@'
    },
    templateUrl: 'views/orgs/_status.html',

    link: function ( scope, elem, attr ) {

      scope.statusChange = function () {

        scope.enabled = !scope.enabled;

        var org = {
          id: scope.orgId,
          enabled: scope.enabled
        };

        OrgService.update(org);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgStatus', OrgStatus);
;
'use strict';

/*
 *  Directive for adding / removing members from patient groups
 *  @name backlineAdminPlus.directives:PatientMembersModal
 *  @ngInject
 */
function PatientMembersModal( $state, $timeout, PatientService ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      patient: '='
    },
    templateUrl: 'views/patients/_members_modal.html',

    link: function ( scope, elem, attr ) {

      scope.user = undefined;
      scope.selected = {
        user: undefined
      };

      scope.add = function () {

        if (scope.selected.user) {

          PatientService.addUser(scope.patient.id, scope.selected.user.id)
            .then(function ( response ) {
              scope.patient.users = response;
              scope.selected.user = undefined;
            }, function ( error ) {
              scope.error = error;
            });

        } else {
          scope.error = 'Please select a user';
        }
      };

      scope.remove = function ( user ) {

        PatientService.removeUser(scope.patient.id, user.id)
          .then(function ( response ) {
            scope.patient.users = response;
          }, function ( error ) {
            scope.error = error;
          });
      };

      scope.gotoUser = function ( id ) {

        elem.modal('hide');

        $timeout(function () {
          $state.go('admin.users.detail', { id: id });
        }, 200);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('patientMembersModal', PatientMembersModal);
;
'use strict';

/*
 *  Directive for showing confirmation dialogs
 *  @name backlineAdminPlus.directives:Confirm
 *  @ngInject
 */
function PortValidation() {

  return {

    restrict: 'A',
    require: 'ngModel',

    link: function ( scope, elem, attr, ngModel ) {
      ngModel.$validators.validPort = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        var match = /[0-9]+/.exec(value);

        if (!match) {
          return false;
        }

        var port = match[0];

        return port > 0 && port <= 65535;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('portValidation', PortValidation);
;
'use strict';

/*
 *  Directive for displaying orgs in dropdown format
 *  @name backlineAdminPlus.directives:QueueDropdown
 *  @ngInject
 */
function QueueDropdown( QueueService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&',
      networkId: '='
    },
    templateUrl: 'views/support/queues/_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.queues = [];

      $scope.select = function ( $select, queue ) {
        $scope.callback({ queue: queue });
      };

      $scope.search = function ( query ) {
        if (!query || query === '') {
          $scope.queues = [];
          return;
        }

        QueueService.query($scope.networkId, query).$promise
          .then(function (queues) {
            $scope.queues = queues;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueDropdown', QueueDropdown);
;
'use strict';

/*
 *  Directive for displaying managers in dropdown format
 *  @name backlineAdminPlus.directives:QueueDropdown
 *  @ngInject
 */
function QueueManagersDropdown( SupportService ) {

  return {

    restrict: 'E',
    scope: {
      callback: '&',
      networkId: '='
    },
    templateUrl: 'views/support/queue_managers/_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.queue = $scope.$parent.queue;
      $scope.queueManagers = [];

      $scope.select = function ( $select, manager ) {
        $scope.callback({ manager: manager });
      };

      $scope.search = function ( manager ) {
        if (!manager || manager === '') {
          $scope.queueManagers = [];
          return;
        }

        SupportService.restrictedManagersQuery($scope.queue.id, manager).$promise
          .then(function (managers) {
            $scope.queueManagers = managers;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueManagersDropdown', QueueManagersDropdown);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for removing queues from an Manager
 *
 *  @name backlineAdminPlus.directives:QueueManagersQueuesModal
 *  @ngInject
 */
function QueueManagersQueuesModal(QueueService, SupportService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queue_managers/_queues_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.manager = $scope.resolve.queueManager;
      $scope.user = $scope.resolve.queueManager.user;
      $scope.orgId = $scope.resolve.queueManager.org_id;
      $scope.networkId = $scope.resolve.queueManager.network_id;
      $scope.selectedManager = null;
      $scope.loading = false;
      $scope.deleting = false;

      if (angular.isUndefined($scope.queues)) {
        $scope.loading = true;
        $scope.queues = QueueService.withManager($scope.orgId, $scope.user.id, $scope.networkId);
        $scope.queues.$promise
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.loading = false;
          });
      }

      $scope.$on('modal.closing', function (event, result) {
        if (!event.defaultPrevented && result !== $scope.queues) {
          event.preventDefault();
          $scope.close({ $value: $scope.queues });
        }
      });

      $scope.remove = function (queue, index) {
        $scope.deleting = queue.$processing = true;
        SupportService.restrictManager({ queue: queue, user_id: $scope.manager.id}).$promise
          .then(function () {
            $scope.queues.splice(index, 1);
            $scope.manager.queue_count--;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.deleting = false;
            delete queue.$processing;
          });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueManagersQueuesModal', QueueManagersQueuesModal);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for adding / removing members from groups
 *  @name backlineAdminPlus.directives:QueueAgentsModal
 *  @ngInject
 */
function QueueAgentsModal($state, SupportService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queues/_agents_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.queue = $scope.resolve.queue;
      $scope.agents = SupportService.agentsInQueue($scope.queue.id);
      $scope.selectedAgent = null;
      $scope.loading = true;
      $scope.deleting = false;

      $scope.agents.$promise.then(function () {
        $scope.loading = false;
      });

      $scope.$on('modal.closing', function (event, result) {
        if (!event.defaultPrevented && result !== $scope.agents) {
          event.preventDefault();
          $scope.close({ $value: $scope.agents });
        }
      });

      $scope.add = function (agent) {
        if (!agent) {
          return;
        }

        $scope.loading = true;
        $scope.queue.$addAgent({ user_id: agent.user.id })
          .then(function () {
            var agentExists = $scope.agents.some(function (element) {
              return element.user.id === agent.user.id;
            });

            if (agentExists) {
              return;
            }
            $scope.agents.push(agent);
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.loading = false;
          });
      };

      $scope.toggleRouting = function (agent) {
        agent.$processing = true;
        var $promise;

        if (agent.routing_enabled) {
          $promise = $scope.queue.$disableRouting({ user_id: agent.user.id });
        } else {
          $promise = $scope.queue.$enableRouting({ user_id: agent.user.id });
        }
        $promise
          .then(function () {
            agent.routing_enabled = !agent.routing_enabled;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            delete agent.$processing;
          });
      };

      $scope.remove = function (agent, index) {
        $scope.deleting = agent.$processing = true;
        $scope.queue.$removeAgent({ user_id: agent.user.id })
          .then(function () {
            $scope.agents.splice(index, 1);
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.deleting = false;
            delete agent.$processing;
          });
      };

      $scope.gotoUser = function ( id ) {
        $scope.dismiss();
        $state.go('admin.users.detail', { id: id });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueAgentsModal', QueueAgentsModal);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for adding / removing members from groups
 *  @name backlineAdminPlus.directives:QueueManageraModal
 *  @ngInject
 */
function QueueManagersModal($state, SupportService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queues/_managers_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.queue = $scope.resolve.queue;
      $scope.managers = SupportService.managersInQueue($scope.queue.id);
      $scope.restrictedManager = null;
      $scope.loading = true;
      $scope.deleting = false;

      $scope.managers.$promise.then(function () {
        $scope.loading = false;
      });

      $scope.$on('modal.closing', function (event, result) {
        if (!event.defaultPrevented && result !== $scope.managers) {
          event.preventDefault();
          $scope.close({ $value: $scope.managers });
        }
      });

      $scope.add = function (manager) {
        if (!manager) {
          return;
        }

        $scope.loading = true;
        SupportService.unrestrictManager({ queue: $scope.queue, user_id: manager.id}).$promise
        .then(function () {
          var managerExists = $scope.managers.some(function (element) {
            return element.user.id === manager.user.id;
          });

          if (managerExists) {
            return;
          }
          $scope.managers.push(manager);
        })
        .catch(function (error) {
          $scope.error = error;
        })
        .then(function () {
          $scope.loading = false;
        });
      };

      $scope.remove = function (manager, index) {
        $scope.deleting = manager.$processing = true;
        SupportService.restrictManager({ queue: $scope.queue, user_id: manager.id}).$promise
        .then(function () {
          $scope.managers.splice(index, 1);
          $scope.queue.manager_count--;
        })
        .catch(function (error) {
          $scope.error = error;
        })
        .then(function () {
          $scope.deleting = false;
          delete manager.$processing;
        });
      };

      $scope.gotoUser = function ( id ) {
        $scope.dismiss();
        $state.go('admin.users.detail', { id: id });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueManagersModal', QueueManagersModal);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for adding / removing members from groups
 *  @name backlineAdminPlus.directives:QueueOrgsModal
 *  @ngInject
 */
function QueueOrgsModal($state, OrgService, QueueService) {

  return {

    restrict: 'E',
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/queues/_orgs_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.queue = $scope.resolve.queue;
      $scope.orgs = OrgService.inQueue($scope.queue.id);
      $scope.selectedOrg = null;
      $scope.loading = true;
      $scope.deleting = false;
      $scope.isDefaultQueue = false;

      $scope.orgs.$promise.then(function () {
        $scope.loading = false;
      });

      $scope.add = function (org) {
        if (!org) {
          return;
        }

        $scope.loading = true;
        QueueService.addOrg(0, $scope.queue.id, org.id, $scope.isDefaultQueue).$promise
          .then(function (response) {
            $scope.orgs = response.orgs;
            $scope.queue.org_count = response.orgs.length;
          }).catch(function (error) {
            $scope.error = error;
          }).then(function () {
            $scope.loading = false;
            $scope.isDefaultQueue = false;
          });
      };

      $scope.remove = function (org, index) {
        $scope.deleting = org.deleting = true;
        $scope.queue.$removeOrg({ org_id: org.id })
          .then(function () {
            $scope.orgs.splice(index, 1);
            $scope.queue.org_count--;
          })
          .catch(function (error) {
            $scope.error = error;
          })
          .then(function () {
            $scope.deleting = false;
            delete org.deleting;
          });
      };

      $scope.gotoOrg = function ( id ) {
        $scope.dismiss();
        $state.go('admin.orgs.detail', { id: id });
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueOrgsModal', QueueOrgsModal);
;
'use strict';

/*
 *  Directive for registering a landline in a modal
 *  @name backlineAdminPlus.directives:RegisterLandlineModal
 *  @ngInject
 */
function RegisterLandlineModal( $q, TwilioService ) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      onSuccess: '&'
    },
    templateUrl: 'views/configs/_register_landline_modal.html',

    link: function ( scope, elem, attr ) {
      scope.phoneNumber = '';
      scope.extension = '';

      scope.saving = false;
      scope.errors = {};

      function _showVerificationModal() {
        $(document.getElementById('verify-landline')).modal({
          // NOTE: prevent user from closing the modal on accident by disabling click
          //   outside to close and ESC to close.
          // SEE: https://stackoverflow.com/a/16155852
          backdrop: 'static',
          keyboard: false
        });
      }

      function _showSettingsModal() {
        elem.modal('hide');
        $(document.getElementById('mask-caller-settings')).modal();
      }

      scope.submit = function () {
        if (scope.verificationCode) { return; }

        scope.saving = true;

        TwilioService.register(scope.phoneNumber, scope.extension)
          .then(function (verificationCode) {
            scope.saving = false;
            scope.verificationCode = verificationCode;

            if (verificationCode) {
              _showVerificationModal();
            } else {
              // NOTE: if we are successful but verification code is missing, the
              //   phone number provided has been pre-approved and no action is required
              scope.onSuccess()
                .then(function () {
                  _showSettingsModal();
                });
            }
          }, function (error) {

            scope.errors.top = error;
            scope.saving = false;
          });
      };

      elem.on('hidden.bs.modal', function () {
        scope.phoneNumber = '';
        scope.extension = '';
        scope.verificationCode = null;
        scope.errors = {};
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('registerLandlineModal', RegisterLandlineModal);
;
'use strict';

/**
 *  Directive for editing users in a modal
 *  @class
 *  @name backlineAdminPlus.directives:RenameTicketingPoolModal
 *  @ngInject
 *
 *  @param $q
 *  @param PermissionsService
 *  @param SupportService
 * @param NetworkSelectorService
 * @param OrgSelectorService
 */
function RenameTicketingPoolModal($q, PermissionsService, SupportService, NetworkSelectorService, OrgSelectorService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/ticketing_pools/_rename_modal.html',

    controller: ['$scope', function ($scope) {
      $scope.errors = {};

      // called to reset user back to base
      function _resetTicketingPool() {
        // create copy so we don't edit original ticketingPool in js
        $scope.ticketingPool = $scope.resolve.ticketingPool;
        $scope.ticketingPoolCopy = angular.copy($scope.ticketingPool);

        $scope.admin = {
          hasAdminPrivs: PermissionsService.hasAdminPrivs,
          hasFullAdminPrivs: PermissionsService.hasFullAdminPrivs,
          hasNetworkFullAdminPrivs: PermissionsService.hasNetworkFullAdminPrivs,
          hasNetworkAdminPrivs: PermissionsService.hasNetworkAdminPrivs,
          hasDrFirstFullAdminPrivs: PermissionsService.hasDrFirstFullAdminPrivs,
          hasDrFirstAdminPrivs: PermissionsService.hasDrFirstAdminPrivs,
          hasDrFirstSalesAdminPrivs: PermissionsService.hasDrFirstSalesAdminPrivs
        };
      }

      _resetTicketingPool();

      // when user changes, reset copy, departments, and errors
      $scope.$watch('ticketingPool', function (newValue) {
        if (newValue) {
          _resetTicketingPool();
        }
      });

      $scope.$on('$locationChangeStart', function () {
        $scope.dismiss();
      });

      /*
       *  Input callback
       *  Validates the name entered
       */
      $scope.onChangeName = function () {
        if ($scope.ticketingPoolCopy.name === '') {
          $scope.errors.name = 'Name cannot be empty';
        } else {
          $scope.errors.name = undefined;
        }
      };

      /*
       *  View method
       *  Save changes made to this user
       */
      $scope.submit = function () {
        // if there are no errors with the form
        if (!($scope.errors.name)) {
          $scope.saving = true;
          SupportService.renameTicketingPool(NetworkSelectorService.getNetwork().id, OrgSelectorService.getOrg().id, $scope.ticketingPoolCopy)
            .then(function (response) {
              $scope.saving = false;
              $scope.close({$value: response});

            }, function (error) {
              $scope.saving = false;
              $scope.errors.top = error.data.response_text;
            });
        } else {

          // if there are errors with the form, notify
          $scope.errors.top = 'Please correct any errors below.';
        }
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('renameTicketingPoolModal', RenameTicketingPoolModal);
;
'use strict';

/*
 *  Directive for selecting roles
 *  @name backlineAdminPlus.directives:RoleSelect
 *  @ngInject
 */
function RoleSelect( PermissionsService, AdminRoles, RoleList, AppConsts ) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      org: '=',
      selected: '=',
      network: '='
    },
    templateUrl: 'views/users/_role_select.html',

    link: function ( scope, elem, attr ) {

      var _adminRole,
        _networkAdmin;

      scope.$watch('org', function ( newValue, oldValue ) {
        if (newValue) {
          updateOrg(newValue);
        }
      });

      function updateOrg( org ) {

        _adminRole = PermissionsService.orgRole(scope.org.id);

        var parentChildNetwork = null;

        if (scope.org.networks) {
          angular.forEach(scope.org.networks, function (network) {
            if (network.type_id === AppConsts.NETWORK_TYPE.PARENT_CHILD) {
              parentChildNetwork = network;
            }
          });
        }

        /* if the org context has a network, check 1. that this is not the control org and 2. this admin has network admin privs in this network. Can be overriden by network scope variable */
        _networkAdmin = scope.network;
        var networkBool = false;

        if (parentChildNetwork) {
          networkBool = (parentChildNetwork && parentChildNetwork.control_org_id != scope.org.id && PermissionsService.orgRole(parentChildNetwork.control_org_id) >= AdminRoles.limitedAdmin);
        }

        _networkAdmin = networkBool || scope.network;

        scope.roleList = [];

        angular.forEach(RoleList, function ( role ) {
          // you can add someone less than or equal in role to you always, or in a network you control, up to full admin
          if (role.id <= _adminRole || (_networkAdmin && role.id <= AdminRoles.fullAdmin)) {

            scope.roleList.push(role);
          }
        }, scope.roleList);

        if (scope.roleList.length === 0) {
          angular.forEach(RoleList, function ( role ) {
            if (role.id <= 20) {
              scope.roleList.push(role);
            }
          }, scope.roleList);
        }
      }
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('roleSelect', RoleSelect);
;
'use strict';

/*
 *  Directive for showing confirmation dialogs
 *  @name backlineAdminPlus.directives:SessionTimeout
 *  @ngInject
 */
function SessionTimeout( ActivityService, TokenService, AuthStatus ) {

  return {

    restrict: 'E',
    replace: true,
    templateUrl: 'views/directives/modals/_session_timeout.html',

    link: function ( scope, elem, attr ) {

      scope.$on(ActivityService.fireWarning, function () {
        if (elem && elem.modal) {
          elem.modal('show');
        }
      });
      scope.$on(TokenService.authenticationStatusChanged, function ($event, status) {
        if (status === AuthStatus.loggedOut && !!elem) {
          elem.modal('removeBackdrop');
        }
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('sessionTimeout', SessionTimeout);
;
'use strict';

/*
 *  Directive for showing confirmation dialogs
 *  @name backlineAdminPlus.directives:StringNumberFormatter
 *  @ngInject
 */
function StringNumberFormatter() {

  return {

    restrict: 'A',
    require: 'ngModel',

    link: function ( scope, elem, attr, ngModel ) {
      ngModel.$parsers.push(function (value) {
        return '' + value;
      });
      ngModel.$formatters.push(function (value) {
        return parseInt(value, 10);
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('stringNumberFormatter', StringNumberFormatter);
;
'use strict';

/*
 *  Directive for setting sorting attributes on tables
 *  @name backlineAdminPlus.directives:Sortable
 *  @ngInject
 */
function Sortable() {

  return {

    restrict: 'A',
    scope: {
      start: '=',
      columns: '=',
      callback: '&onSort'
    },

    link: function ( scope, elem, attr ) {

      var ASCENDING = 'asc',
        DESCENDING = 'desc';

      // add sortable functionality to all <th> elements
      elem.find('th').each( function ( index, header ) {

        // if this th index isn't a member of the sortable columns whitelist, skip
        if (scope.columns.indexOf(index) == -1) { return; }

        // cast to jQ object and add sortable class
        header = $(header);
        header.addClass('sortable');

        // add click event listener to trigger callback
        header.on('click', function () {
          scope.sort(index, header);
        });

        // if index is default, add ascending class
        if (index == scope.start) {
          header.addClass(ASCENDING);
        }
      });

      /*
       *  View method
       *  When a column is selected, toggle sorting status
       */
      scope.sort = function ( index, header ) {

        if (header.hasClass(ASCENDING)) {

          elem.find('th').removeClass(DESCENDING);
          elem.find('th').removeClass(ASCENDING);

          header.addClass(DESCENDING);

          scope.callback({ column: index, direction: DESCENDING });

        } else {

          elem.find('th').removeClass(DESCENDING);
          elem.find('th').removeClass(ASCENDING);

          header.addClass(ASCENDING);

          scope.callback({ column: index, direction: ASCENDING });
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('sortable', Sortable);
;
'use strict';

/**
 *  Directive for adding / removing members from patient groups
 *  @class
 *  @name backlineAdminPlus.directives:SubmitterTicketingPoolModal
 *  @ngInject
 *
 *  @param $state
 *  @param $timeout
 *  @param SupportService
 *  @param NetworkSelectorService
 *  @param OrgSelectorService
 */
function SubmitterTicketingPoolModal($state, $timeout, SupportService, NetworkSelectorService, OrgSelectorService) {

  return {
    restrict: 'E',
    replace: true,
    scope: {
      resolve: '=',
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'views/support/submitters/_submitter_ticketing_pool_modal.html',

    link: function (scope, elem, attr) {
      scope.submitter = scope.resolve.submitter;
      scope.error = null;

      var _resetTicketingPools = function (pools) {
        scope.ticketingPools = pools;

        for (var i = 0; i < scope.ticketingPools.length; i++) {
          scope.ticketingPools[i].active = false;
          for (var j = 0; j < scope.submitter.ticketing_pools.length; j++) {
            var submitterPool = scope.submitter.ticketing_pools[j];
            var ticketingPool = scope.ticketingPools[i];

            if (submitterPool.id === ticketingPool.id) {
              scope.ticketingPools[i].active = true;
            }
          }
        }
      };

      scope.getTicketingPools = function () {
        SupportService.getTicketingPools(OrgSelectorService.getOrg().id).$promise.then(function (result) {
          _resetTicketingPools(result.ticketing_pools);
        });
      };

      scope.getTicketingPools();

      /*********************  Public Methods  **************************/
      scope.save = function () {
        var pools = [];
        var selectedPools = scope.ticketingPools.filter(function (pool) {
          return pool.active;
        });

        for (var i = 0; i < scope.ticketingPools.length; i++) {
          pools.push({
            id: scope.ticketingPools[i].id,
            active: scope.ticketingPools[i].active
          });
        }

        SupportService.setSubmitterTicketingPools(OrgSelectorService.getOrg().id, scope.submitter.id, pools).then(function () {
          scope.error = null;
          scope.close({$value: selectedPools});
        }, function () {
          scope.error = 'There was an error updating the ticketing pools for this submitter.';
        });
      };

      scope.removeSubmittingPermissions = function () {
        SupportService.deleteSubmitter(OrgSelectorService.getOrg().id, scope.submitter.user_id)
          .$promise
          .then( function () {
            scope.close({$value: null});
          }, function () {
            scope.error = 'There was an error removing the permissions for this submitter.';
          });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('submitterTicketingPoolModal', SubmitterTicketingPoolModal);
;
'use strict';

/*
 *  Directive for showing toast messages
 *  @name backlineAdminPlus.directives:Toast
 *  @ngInject
 */
function Toast( $timeout, ToastService ) {

  return {

    restrict: 'E',
    replace: true,
    templateUrl: 'views/directives/_toast.html',

    link: function ( scope, elem, attr ) {

      scope.$on(ToastService.fireToast, function ( $event, params ) {
        scope.title = params.title;
        scope.message = params.message;
        scope.type = params.type;
        scope.refresh = params.refresh;
        scope.dashboard = params.dashboard;
        scope.delayTime = params.delay || 3000;

        elem.removeClass(scope.type);
        elem.addClass(scope.type);
        elem.addClass('active');

        if (!scope.refresh) {
          $timeout(scope.close, scope.delayTime);
        }
      });

      scope.close = function () {
        elem.removeClass('active');
      };

      scope.refreshPage = function () {
        window.location.reload(true);
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('toast', Toast);
;
'use strict';

/*
 *  Directive for unregistering a landline in a modal
 *  @name backlineAdminPlus.directives:UnregisterLandlineModal
 *  @ngInject
 */
function UnregisterLandlineModal( $q, TwilioService, ToastService ) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      phoneNumber: '=',
      onSubmit: '&'
    },
    templateUrl: 'views/configs/_unregister_landline_modal.html',

    link: function ( scope, elem, attr ) {
      scope.saving = false;
      scope.errors = {};

      scope.submit = function () {
        scope.saving = true;

        scope.onSubmit()
          .then(function () {
            scope.saving = false;
            elem.modal('hide');
          }, function () {
            scope.saving = false;
          });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('unregisterLandlineModal', UnregisterLandlineModal);
;
'use strict';

/*
 *  Directive for showing user utilization chart
 *  @name backlineAdminPlus.directives:UsageChart
 *  @ngInject
 */
function UsageChart () {

  return {
     restrict: 'E',
     replace: true,
     templateUrl: 'views/dashboards/_usage_chart.html',
     scope: {
       data: '='
     },

     link: function ( scope, elem, attr ) {

       // create morris area chart on2 this element
       var _instance = Morris.Area({
         element: elem,
         data: scope.data,
         xkey: 'date',
         ykeys: [
           'registered_users_count',
           'unique_active_count'
         ],
         labels: [
           'Total',
           'Active'
         ],
         lineColors: [
           '#ffab4d',
           '#1bc8fc'
         ],
         behaveLikeLine: true,
         pointSize: 2,

         hoverCallback: function ( index, options, content, row ) {
          var heading = '<strong>' + row.date + '</strong><br>',
            total = '<span style="color: #ffab4d">Total: ' + row.registered_users_count + '</span><br>',
            active = '<span style="color: #1bc8fc">Active: ' + row.unique_active_count + '</span><br>',
            percent = '<em>' + ((row.unique_active_count / row.registered_users_count) * 100).toFixed(2) + '% Usage</em>';

          return heading + total + active + percent;
        }
       });

       // watch for changes in data
       scope.$watch('data', function ( newVal, oldVal ) {
         _instance.setData(scope.data);
       });
     }
   };
}

angular.module('backlineAdminPlus')
  .directive('usageChart', UsageChart);
;
'use strict';

/*
 *  Directive for displaying orgs in dropdown format
 *  @name backlineAdminPlus.directives:UserDropdown
 *  @ngInject
 */
function UserDropdown( UserService, PermissionsService ) {
  var dropDownCall = 0;

  return {

    restrict: 'E',
    scope: {
      selected: '=',
      adminOnly: '=',
      orgId: '=',
      departmentId: '=',
      allowAll: '=',
      audit: '=',
      sourceFeedType: '=',
      showStatuses: '@',
      showPatients: '@',
      callback: '&'
    },
    templateUrl: 'views/users/_dropdown.html',

    link: function ( scope, elem, attr ) {

      scope.$watch('orgId', function ( newValue, oldValue ) {
        if (newValue || (scope.audit && PermissionsService.canSearchOrgs)) {
          scope.selected.user = undefined;
          scope.refresh();
        }
      });

      scope.users = [];
      scope.display = [];
      scope.selected = scope.selected || { user: undefined };

      scope.refresh = function () {
        if (angular.isUndefined(scope.sourceFeedType)) {
          UserService.dropdown(scope.orgId, scope.sourceFeedType, scope.adminOnly, scope.showStatuses, scope.showPatients)
            .then(function ( response ) {
              scope.users = response;
              scope.display = scope.users.slice(0, 40);

              if (scope.allowAll) {
                scope.display.unshift({
                  id: null,
                  display_name: 'All ' + (scope.adminOnly ? 'Administrators' : 'Users')
                });
              }
            });
        }
      };

      scope.select = function ( user ) {
        scope.callback({ user: user });
      };

      scope.search = function ( query ) {
        if (!query || query === '') {
          scope.display = [];
          return;
        }

        if (angular.isUndefined(scope.sourceFeedType)) {
          scope.display = [];
          query = query.toLowerCase();

          angular.forEach(scope.users, function ( user ) {
            if (scope.display.length < 40 && user.display_name.toLowerCase().indexOf(query) != -1) {
              this.push(user);
            }
          }, scope.display);

          if (scope.allowAll) {
            scope.display.unshift({
              id: null,
              display_name: 'All ' + (scope.adminOnly ? 'Administrators' : 'Users')
            });
          }
        } else {
          if (scope.sourceFeedType === -1) {
            UserService.dropdownForDepartments({org_id: scope.orgId, department_id: scope.departmentId, query: query, forPulldown: true})
              .then(function (response) {
                var users = response.users;

                for (var i = 0; i < users.length; i++) {
                  users[i].display_name = users[i].fname + ' ' + users[i].lname + ' (' + users[i].email + ')';
                }
                scope.display = users;
              });
          } else {
            UserService.dropdownForFeed(scope.orgId, scope.sourceFeedType, query)
              .then(function (response) {
                for (var i = 0; i < response.length; i++) {
                  response[i].display_name = response[i].fname + ' ' + response[i].lname + ' (' + response[i].email + ')';
                }
                scope.display = response;
              });
          }
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('userDropdown', UserDropdown);
;
'use strict';

/**
 *  Directive for displaying org networks in dropdown format
 *  @name backlineAdminPlus.directives:OrgNetworksDropdown
 *  @ngInject
 *
 * @param AppConsts
 * @returns {{controller: controller, scope: {callback: string}, restrict: string, templateUrl: string}}
 * @constructor
 */
function OrgNetworksDropdown(AppConsts) {

  return {
    restrict: 'E',
    scope: {
      networks: '=?',
      selected: '=?',
      callback: '&'
    },
    templateUrl: 'views/orgs/_networks_dropdown.html',

    controller: ['$scope', function ($scope) {
      $scope.select = function (_select, network) {
        $scope.callback({network: network});
      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('orgNetworksDropdown', OrgNetworksDropdown);
;
'use strict';

/*
 *  Directive for showing and editing org info for a user
 *  @name backlineAdminPlus.directives:UserOrgs
 *  @ngInject
 */
function UserOrgs( UserService, PermissionsService ) {

  return {

    restrict: 'E',
    scope: {
      user: '=',
      orgs: '=',
      departments: '=',
      community: '=',
      networkOnly: '='
    },
    templateUrl: 'views/users/_orgs.html',

    link: function ( scope, elem, attr ) {

      // make sure we pick up on dept changes
      scope.$watch('departments', function () {});

      scope.hasDrFirstFullAdminPrivs = PermissionsService.hasDrFirstFullAdminPrivs;

      scope.canAddorgs = function ( ) {
        var admin = {
          hasNetworkDrFirstFullAdminPrivs: PermissionsService.hasNetworkDrFirstFullAdminPrivs,
          hasDrFirstFullAdminPrivs: PermissionsService.hasDrFirstFullAdminPrivs,
          hasDrFirstSuperAdminPrivs: PermissionsService.hasDrFirstSuperAdminPrivs
        };

        if (admin.hasNetworkDrFirstFullAdminPrivs || admin.hasDrFirstFullAdminPrivs || admin.hasDrFirstSuperAdminPrivs) {
          return true;
        } else {
          return false;
        }
      };

      scope.canAddOrgs = scope.canAddorgs();

      // view helpers
      scope.roleLabelClass = function ( role ) {

        if (role == 1) {
          return 'label-primary';
        } else if (role < 30) {
          return 'label-warning';
        } else {
          return 'label-danger';
        }
      };

      scope.humanizeRole = PermissionsService.humanize;

      // controller actions
      scope.focusOrg = function ( orgUser ) {

        scope.focusedOrgUser = orgUser;
      };

      scope.changeOrgs = function ( orgs ) {

        for (var i = 0; i < scope.orgs.length; i++) {
          var currentOrg = scope.orgs[i];

          for (var j = 0; j < orgs.length; j++) {
            var newOrg = orgs[j];

            if (currentOrg.id === newOrg.id) {
              newOrg.agent = currentOrg.agent;
              newOrg.queue_manager = currentOrg.queue_manager;
              newOrg.submitter = currentOrg.submitter;

              // TODO determine where ticketing pools are
              newOrg.ticketing_pool = currentOrg.ticketing_pool;
            }
          }
        }
        scope.orgs = orgs;
        scope.user.orgs = orgs;
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('userOrgs', UserOrgs);
;
'use strict';

/*
 *  Directive for showing and changing user reg status
 *  @name backlineAdminPlus.directives:UserRegStatus
 *  @ngInject
 */
function UserRegStatus( UserService ) {

  return {

    restrict: 'E',
    scope: {
      userId: '=',
      status: '=',
      canChange: '=?',
      size: '@'
    },
    templateUrl: 'views/users/_reg_status.html',

    link: function ( scope, elem, attr ) {
      if (angular.isUndefined(scope.canChange)) {
        scope.canChange = true;
      }

      scope.statusChange = function ( status ) {

        scope.status = status;

        // resend registration email
        if (status == 1) {

          UserService.resend(scope.userId);

        // enable/disable
        } else {

          var user = {
            id: scope.userId,
            reg_status: status
          };

          UserService.update(user);
        }
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('userReg', UserRegStatus);
;
'use strict';

/*
 *  Directive for multi select of users
 *  @name backlineAdminPlus.directives:UserSelect
 *  @ngInject
 */
function UserSelect(UserService, OrgSelectorService) {

  return {
    restrict: 'E',
    scope: {
      parent: '=',
      container: '=?'
    },
    templateUrl: 'views/users/_select.html',

    link: function (scope, elem, attr) {
      scope.org = OrgSelectorService.getOrg();
      scope.display = [];

      scope.placeholderText = 'Type to search users';
      if (scope.container === 'archives') {
        scope.placeholderText = 'Enter First Name, Last Name or Email [3 Character Min.]';
      }

      scope.$on(OrgSelectorService.orgChanged, function ($event, org) {
        scope.org = org;
        scope.parent.users = undefined;
        scope.display = [];
      });

      scope.select = function () {
        scope.display = [];
      };

      scope.search = function (query) {
        query = query.toLowerCase();
        var invalidQuery = !query || query === '';
        var invalidArchiveQuery = query.length < 3 && scope.container === 'archives';

        if (invalidQuery || invalidArchiveQuery) {
          scope.display = [];
          return;
        }

        UserService.dropdown(scope.org.id, scope.adminOnly, null, null, null, query)
          .then(function (filteredUsers) {
            scope.display = filteredUsers.slice(0, 50);
          });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('userSelect', UserSelect);
;
'use strict';

/*
 *  Directive for showing and editing org info for a user
 *  @name backlineAdminPlus.directives:UserSupport
 *  @ngInject
 */
function UserSupport($uibModal) {

  return {

    restrict: 'E',
    scope: {
      support: '=',
      orgs: '=',
      user: '='
    },
    templateUrl: 'views/users/_support.html',

    controller: ['$scope', function ($scope) {

      $scope.openAgentQueuesModal = function (user, queues) {
        var modal = $uibModal.open({
          component: 'agentQueuesModal',
          resolve: {
            agent: {
              org_id: user.org.id,
              queues: queues,
              user: user
            }
          }
        });

        modal.result.then(function (value) {
        }, function () {
        });

      };
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('userSupport', UserSupport);
;
'use strict';

/*
 *  Directive for multi select of user statuses
 *  @name backlineAdminPlus.directives:UserStatusSelect
 *  @ngInject
 */
function UserStatusSelect( UserStatuses ) {

  return {

    restrict: 'E',
    scope: {
      parent: '=',
      callback: '&'
    },
    templateUrl: 'views/users/_status_select.html',

    link: function ( scope, elem, attr ) {

      scope.userStatuses = UserStatuses;

      scope.change = function () {
        scope.callback();
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('userStatusSelect', UserStatusSelect);
;
'use strict';

/*
 *  Directive for verifying a landline in a modal
 *  @name backlineAdminPlus.directives:VerifyLandlineModal
 *  @ngInject
 */
function VerifyLandlineModal( $q, $interval, TwilioService, TwilioRegStatus ) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      code: '=',
      onSuccess: '&'
    },
    templateUrl: 'views/configs/_verify_landline_modal.html',
    link: function ( scope, elem, attr ) {
      var _interval = null;

      scope.apiErrorCount = 0;
      scope.failed = false;

      function _showSettingsModal() {
        $('.modal').modal('hide');
        $(document.getElementById('mask-caller-settings')).modal();
      }

      function _getRegistrationStatus() {
        TwilioService.registrationStatus()
          .then(function (status) {
            scope.apiErrorCount = 0;

            if (status === TwilioRegStatus.success) {
              scope.onSuccess()
                .then(function () {
                  _showSettingsModal();
                });
            } else if (status === TwilioRegStatus.failed) {
              scope.failed = true;
            }
          }, function (error) {
            scope.apiErrorCount ++;
          });
      }

      elem.on('shown.bs.modal', function () {
        _interval = $interval(_getRegistrationStatus, 5000);
      });

      elem.on('hidden.bs.modal', function () {
        if (_interval) { $interval.cancel(_interval); }
      });
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('verifyLandlineModal', VerifyLandlineModal);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for tabulation of users
 *  @name backlineAdminPlus.directives:AgentsTable
 *  @ngInject
 */
function AgentsTable($state, $uibModal, filterFilter) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      query: '=',
      agents: '=',
      isRefreshing: '='
    },
    templateUrl: 'views/support/agents/table.html',

    controller: ['$scope', function ($scope) {

      $scope.openAgentQueuesModal = function (agent) {
        var modal = $uibModal.open({
          component: 'agentQueuesModal',
          resolve: {
            agent: agent
          }
        });

        modal.result.then(function (value) {
        }, function () {
        });

      };

      $scope.toggleRouting = function (agent) {
        agent.routing = !agent.routing;
      };

      $scope.gotoUser = function (userId) {
        $state.go('admin.users.detail', { id: userId });
      };

      $scope.removeFrom = function (agents, agent) {
        for (var i in agents) {
          if (agent.id === agents[i].id) {
            agents.splice(i, 1);
          }
        }
      };

      $scope.delete = function (agent) {
        $scope.deleting = agent.$processing = true;
        agent.$delete()
          .then(function () {
            $scope.removeFrom($scope.agents, agent);
          })
          .catch(function () {

          })
          .then(function () {
            $scope.deleting = false;
            delete agent.$processing;
          });
      };

      $scope.filter = function () {
        $scope.filtered = filterFilter($scope.agents, $scope.query);
      };

      $scope.$watch('query', $scope.filter);
      $scope.$watch('agents', $scope.filter);
      $scope.$watch('agents.length', $scope.filter);
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('agentsTable', AgentsTable);
;
'use strict';

/*
 *  Directive for tabulation of networks
 *  @name backlineAdminPlus.directives:NetworksTable
 *  @ngInject
 */
function NetworksTable(NetworkService) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      networks: '='
    },
    templateUrl: 'views/networks/_table.html',

    controller: ['$scope', function ($scope) {

      $scope.types = NetworkService.types();
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('networksTable', NetworksTable);
;
'use strict';

/*
 *  Directive for tabulation of orgs
 *  @name backlineAdminPlus.directives:OrgsTable
 *  @ngInject
 */
function OrgsTable() {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      orgs: '=',
      sort: '&'
    },
    templateUrl: 'views/orgs/_table.html',

    link: function ( scope, elem, attrs ) {

      /*
       *  Directive callback
       *  When sorting info changes, alert controller
       */
      scope.onChangeSorting = function ( column, direction ) {

        scope.sort({ column: column, direction: direction });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgsTable', OrgsTable);
;
'use strict';

/*
 *  Directive for tabulation of users for a particular org
 *  @name backlineAdminPlus.directives:OrgUsersTable
 *  @ngInject
 */
function OrgUsersTable() {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      users: '=',
      sort: '&'
    },
    templateUrl: 'views/orgs/_users_table.html',

    link: function ( scope, elem, attr ) {

      /*
       *  Directive callback
       *  Alerts controller that sort column or direction has changed
       */
      scope.onChangeSorting = function ( column, direction) {

        scope.sort({ column: column, direction: direction });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('orgUsersTable', OrgUsersTable);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for tabulation of users
 *  @name backlineAdminPlus.directives:QueueManagersTable
 *  @ngInject
 */
function QueueManagersTable($state, $uibModal, filterFilter) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      query: '=',
      queues: '=',
      queueManagers: '=',
      isRefreshing: '='
    },
    templateUrl: 'views/support/queue_managers/table.html',

    controller: ['$scope', function ($scope) {

      $scope.openQueueManagersQueuesModal = function (queueManager) {
        var modal = $uibModal.open({
          component: 'queueManagersQueuesModal',
          resolve: {
            queueManager: queueManager
          }
        });

        modal.result.then(function (value) {
        }, function () {
        });
      };

      $scope.removeFrom = function (queueManagers, queueManager) {
        for (var i in queueManagers) {
          if (queueManager.id === queueManagers[i].id) {
            queueManagers.splice(i, 1);
          }
        }
      };

      $scope.delete = function (queueManager) {
        $scope.deleting = queueManager.$processing = true;
        queueManager.$delete()
          .then(function () {
            $scope.removeFrom($scope.queueManagers, queueManager);
          })
          .catch(function () {

          })
          .then(function () {
            $scope.deleting = false;
            delete queueManager.$processing;
          });
      };

      $scope.filter = function () {
        $scope.filtered = filterFilter($scope.queueManagers, $scope.query);
      };

      $scope.$watch('query', $scope.filter);
      $scope.$watch('queueManagers', $scope.filter);
      $scope.$watch('queueManagers.length', $scope.filter);
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queueManagersTable', QueueManagersTable);
;
'use strict';

/**
 *  Directive for tabulation of ticketing pools
 *  @name backlineAdminPlus.directives:TicketingPoolsTable
 *  @ngInject
 */
function TicketingPoolsTable($state, $uibModal, $q, filterFilter, ConfirmationService, SupportService,
                             NetworkSelectorService, OrgSelectorService, PermissionsService, TokenService) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      query: '=',
      ticketingPools: '=',
      isRefreshing: '=',
      canHavePools: '=',
      callback: '&'
    },
    templateUrl: 'views/support/ticketing_pools/table.html',
    controller: ['$scope', function ($scope) {
      var currentUser = TokenService.getCachedUser();

      $scope.isDrFirstAdmin = PermissionsService.hasDrFirstFullAdminPrivs || PermissionsService.hasDrFirstAdminPrivs;
      $scope.isFullAdmin = PermissionsService.hasFullAdminPrivs || $scope.isDrFirstAdmin;

      $scope.error = null;
      $scope.controlOrgId = null;
      $scope.network = null;
      $scope.networkOrgs = [];

      if ($scope.canHavePools) {
        if (currentUser.support && currentUser.support.networks && currentUser.support.networks.length > 0) {
          $scope.network = currentUser.support.networks[0];
          $scope.networkOrgs = $scope.network.orgs;
        }
        if (currentUser.org && currentUser.org.network) {
          $scope.controlOrgId = currentUser.org.network.control_org_id;
        }

        $scope.isControllingOrg = $scope.controlOrgId ? $scope.controlOrgId === currentUser.org.id : false;
        $scope.isSubOrg = $scope.controlOrgId ? $scope.controlOrgId !== currentUser.org.id : false;
        $scope.isSingleOrg = !$scope.isControllingOrg || !$scope.network || $scope.networkOrgs.length < 2;
        $scope.canEditOrDelete = $scope.isDrFirstAdmin || ($scope.isControllingOrg && $scope.isFullAdmin);
      } else {
        $scope.isControllingOrg = $scope.isSubOrg = $scope.isSingleOrg = $scope.canEditOrDelete = false;
      }

      /**
       * Open the _rename_modal.html for RenameTicketingPoolModal
       * @param ticketingPool
       */
      var openRenameTicketingPoolModal = function (ticketingPool) {
        var modal = $uibModal.open({
          component: 'renameTicketingPoolModal',
          resolve: {
            ticketingPool: ticketingPool
          }
        });

        modal.result.then(function (ticketingPool) {
          $scope.editing = false;
          delete $scope.selected.$processing;
          if (!ticketingPool) {
            return;
          }

          $scope.replaceWith($scope.ticketingPools.ticketing_pools, $scope.selected, ticketingPool);
        }, function () {
          $scope.editing = false;
          delete $scope.selected.$processing;
        });
      };

      $scope.goToManagePools = function (poolId) {
        $state.go('admin.support.ticketing_pools.detail', {id: poolId});
      };

      $scope.editSelectedPool = function (ticketingPool) {
        if (!ticketingPool || !ticketingPool.id) {
          return;
        }
        $scope.selected = ticketingPool;
        $scope.selectedCopy = angular.copy(ticketingPool);
        $scope.editing = $scope.selected.$processing = true;
        openRenameTicketingPoolModal($scope.selectedCopy);
      };

      /**
       * Method used to open a confirmation dialog for deleting a ticketing pool
       * @param ticketingPool
       */
      $scope.deletePool = function (ticketingPool) {
        var ticketingPoolName = ticketingPool.name;
        var ticketingPoolId = ticketingPool.id;

        ConfirmationService.confirm({
          title: 'Confirm',
          message: 'Delete ' + ticketingPoolName + '?\nNote this action cannot be undone.',
          isBold: true,
          callback: function () {
            $scope.deleting = ticketingPool.$processing = true;
            SupportService.removeTicketingPool(ticketingPoolId).$promise.then(function () {
              delete ticketingPool.$processing;
              $scope.deleting = false;
              $scope.error = null;
              $scope.callback();
            }, function () {
              $scope.deleting = false;
              delete ticketingPool.$processing;
              $scope.error = 'There was a problem deleting the ticketing pool. Please try again.';
            });
          }
        });
      };

      /**
       * Remove an item from the displayed list of ticketing pools
       * @param ticketingPools
       * @param ticketingPoolId {number}
       */
      $scope.removeFrom = function (ticketingPools, ticketingPoolId) {
        var removalIndex = -1;

        for (var i = 0; i < ticketingPools.length; i++) {
          if (ticketingPoolId === ticketingPools[i].id) {
            removalIndex = i;
          }
        }

        if (removalIndex > -1) {
          ticketingPools.splice(removalIndex, 1);
        }
        $scope.ticketingPools.ticketing_pools = ticketingPools;
      };

      /**
       * Replace an item in the list of ticketing pools with the selected item
       * @param ticketingPools {Array}
       * @param originalPool {Object}
       * @param newPool {Object}
       */
      $scope.replaceWith = function (ticketingPools, originalPool, newPool) {
        for (var i = 0; i < ticketingPools.length; i++) {
          if (originalPool.id === ticketingPools[i].id) {
            ticketingPools[i].name = newPool.name;
          }
        }

        $scope.ticketingPools.ticketing_pools = ticketingPools;
      };

      $scope.filter = function () {
        $scope.filtered = filterFilter($scope.ticketingPools.ticketing_pools, $scope.query);
      };

      $scope.$watch('query', $scope.filter);
      $scope.$watch('ticketingPools', $scope.filter);
      $scope.$watch('ticketingPools.ticketing_pools', $scope.filter);
      $scope.$watch('ticketingPools.ticketing_pools.length', $scope.filter);
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('ticketingPoolsTable', TicketingPoolsTable);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for tabulation of queues
 *  @name backlineAdminPlus.directives:QueuesTable
 *  @ngInject
 */
function QueuesTable($uibModal, filterFilter) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      query: '=',
      queues: '=',
      canCreateQueues: '=',
      isRefreshing: '=',
      timezone: '='
    },
    templateUrl: 'views/support/queues/table.html',

    controller: ['$scope', function ($scope) {
      $scope.editing = false;
      $scope.deleting = false;

      $scope.openQueueManagersModal = function (queue) {
        var modal = $uibModal.open({
          component: 'queueManagersModal',
          resolve: {
            queue: queue
          }
        });

        modal.result.then(function (managers) {
          queue.manager_count = managers.length;
        }, function () {
        });
      };

      $scope.openQueueAgentsModal = function (queue) {
        var modal = $uibModal.open({
          component: 'queueAgentsModal',
          resolve: {
            queue: queue
          }
        });

        modal.result.then(function (agents) {
          queue.agent_count = agents.length;
        }, function () {
        });
      };

      $scope.openQueueOrgsModal = function (queue) {
        var modal = $uibModal.open({
          component: 'queueOrgsModal',
          resolve: {
            queue: queue
          }
        });

        modal.result.then(function (value) {
        }, function () {
        });
      };

      $scope.replaceIn = function (queues, queue) {
        for (var i in queues) {
          if (queue.id === queues[i].id) {
            queues[i] = queue;
          }
        }
      };

      $scope.removeFrom = function (queues, queue) {
        for (var i in queues) {
          if (queue.id === queues[i].id) {
            queues.splice(i, 1);
          }
        }
      };

      $scope.edit = function (queue, timeZone) {
        $scope.editing = queue.$processing = true;

        var modal = $uibModal.open({
          component: 'editQueueModal',
          resolve: {
            queue: queue,
            timeZone: timeZone
          }
        });

        modal.result.then(function (value) {

          $scope.replaceIn($scope.queues, value);

          $scope.editing = false;
          delete queue.$processing;
        }, function () {

          $scope.editing = false;
          delete queue.$processing;
        });
      };

      $scope.delete = function (queue) {
        $scope.deleting = queue.$processing = true;
        queue.$delete()
          .then(function () {
            $scope.removeFrom($scope.queues, queue);
          })
          .catch(function () {

          })
          .then(function () {
            $scope.deleting = false;
            delete queue.$processing;
          });
      };

      $scope.filter = function () {
        $scope.filtered = filterFilter($scope.queues, $scope.query);
      };

      $scope.$watch('query', $scope.filter);
      $scope.$watch('queues', $scope.filter);
      $scope.$watch('queues.length', $scope.filter);
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('queuesTable', QueuesTable);
;
'use strict';
/* jshint -W024 */

/*
 *  Directive for tabulation of users
 *  @name backlineAdminPlus.directives:SubmittersTable
 *  @ngInject
 */
function SubmittersTable($state, $uibModal, filterFilter) {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      query: '=',
      submitters: '=',
      isRefreshing: '=',
      callback: '&'
    },
    templateUrl: 'views/support/submitters/table.html',

    controller: ['$scope', function ($scope) {

      $scope.toggleRouting = function (submitter) {
        submitter.routing = !submitter.routing;
      };

      $scope.gotoUser = function (userId) {
        $state.go('admin.users.detail', { id: userId });
      };

      $scope.edit = function (submitter) {
        $scope.selectedSubmitter = submitter;
        $scope.editing = submitter.$processing = true;

        var modal = $uibModal.open({
          component: 'submitterTicketingPoolModal',
          resolve: {
            submitter: $scope.selectedSubmitter
          }
        });

        modal.result.then(function (value) {
          $scope.onEditCompleted(value);
          if (!value) {
            $scope.callback();
          }
        }, function () {
          $scope.onEditCompleted(null);
          $scope.callback();
        });
      };

      $scope.onEditCompleted = function (ticketingPools) {
        $scope.editing = false;
        delete $scope.selectedSubmitter.$processing;
        if (ticketingPools) {
          $scope.selectedSubmitter.ticketing_pools = ticketingPools;
        }
      };

      $scope.filter = function () {
        $scope.filtered = filterFilter($scope.submitters, $scope.query);
      };

      $scope.$watch('query', $scope.filter);
      $scope.$watch('submitters', $scope.filter);
      $scope.$watch('submitters.length', $scope.filter);
    }]
  };
}

angular.module('backlineAdminPlus')
  .directive('submittersTable', SubmittersTable);
;
'use strict';

/*
 *  Directive for tabulation of users
 *  @name backlineAdminPlus.directives:UsersTable
 *  @ngInject
 */
function UsersTable() {

  return {

    restrict: 'E',
    replace: true,
    scope: {
      users: '=',
      sort: '&'
    },
    templateUrl: 'views/users/_table.html',

    link: function ( scope, elem, attr ) {

      /*
       *  Directive callback
       *  When sorting info changes, alert controller
       */
      scope.onChangeSorting = function ( column, direction ) {
        scope.sort({ column: column, direction: direction });
      };
    }
  };
}

angular.module('backlineAdminPlus')
  .directive('usersTable', UsersTable);
;